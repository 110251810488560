<template>
  <div class="Industrial">
    <div class="left_div">
      <div v-for="(item, n) in listl" :key="item.value" class="list_divs" @click="firstMenuClick(item, n)">
        <div :class="['check_items_css', item.type ? 'check_items_2' : 'check_items_1']">
          <div class="h1_check_item">{{ item.lable }}</div>
          <div v-if="item.child" class="hastriangle"></div>
        </div>
        <div v-if="item.type" class="item_child_div" @click.stop>
          <div v-for="(child1, index) in item.child" :key="child1.value" @click.stop="menuChildClick(child1, index, n)">
            <div :class="['first_child_div', child1.type ? 'first_child_div_1' : '']">
              {{ child1.lable }}
              <div v-if="child1.child" class="hastriangle"></div>
            </div>
            <div v-if="child1.type" class="item_child_div">
              <div v-for="(child2) in child1.child" :key="child2.value" :style="{ color: child2.type ? '#2a88ed' : '' }"
                class="first_child_div" @click.stop="menuChildClick2(child2, index, n)">
                {{ child2.lable }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content_div" v-loading="loading">
      <div class="drop_down_div">
        <el-dropdown trigger="click" @command="(item) => { handleCommand(item, 'touzijieduan') }">
          <span class="el-dropdown-link">
            {{ kewObj.touzijieduan || '投资阶段' }}<i class="el-icon-caret-bottom el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="A轮">A轮</el-dropdown-item>
            <el-dropdown-item command="B轮">B轮</el-dropdown-item>
            <el-dropdown-item command="C轮">C轮</el-dropdown-item>
            <el-dropdown-item command="D轮">D轮</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown trigger="click">
          <span class="el-dropdown-link">
            {{ kewObj.sssf || '所在地区' }}<i class="el-icon-caret-bottom el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown" style="position: relative;">
            <el-cascader-panel size="large" :options="options" v-model="selectedOptions" @change="handleChange">
            </el-cascader-panel>
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown trigger="click" @command="(item) => { handleCommand(item, 'clsj') }">
          <span class="el-dropdown-link">
            {{ kewObj.clsj || '成立时间' }}<i class="el-icon-caret-bottom el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="">不限</el-dropdown-item>
            <el-dropdown-item command="1年内">1年内</el-dropdown-item>
            <el-dropdown-item command="1-5年">1-5年</el-dropdown-item>
            <el-dropdown-item command="5-10年">5-10年</el-dropdown-item>
            <el-dropdown-item command="10-15年">10-15年</el-dropdown-item>
            <el-dropdown-item command="15年以上">15年以上</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown trigger="click" @command="(item) => { handleCommand(item, 'zjtx') }">
          <span class="el-dropdown-link">
            {{ kewObj.zjtx || '专精特新' }}<i class="el-icon-caret-bottom el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="国家级">国家级</el-dropdown-item>
            <el-dropdown-item command="省级">省级</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown trigger="click">
          <span class="el-dropdown-link">
            {{ kewObj.ipo || 'IPO' }}<i class="el-icon-caret-bottom el-icon--right"></i>
            <el-dropdown-menu slot="dropdown"></el-dropdown-menu>
          </span>
        </el-dropdown>
        <el-dropdown trigger="click" @command="(item) => { handleCommand(item, 'ssxx') }">
          <span class="el-dropdown-link">
            {{ kewObj.ssxx || '上市公司' }}<i class="el-icon-caret-bottom el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="上交所">上交所</el-dropdown-item>
            <el-dropdown-item command="深交所">深交所</el-dropdown-item>
            <el-dropdown-item command="北交所">北交所</el-dropdown-item>
            <el-dropdown-item command="港交所">港交所</el-dropdown-item>
            <el-dropdown-item command="纽交所">纽交所</el-dropdown-item>
            <el-dropdown-item command="纳斯达克">纳斯达克</el-dropdown-item>
            <el-dropdown-item command="新三板">新三板</el-dropdown-item>
            <el-dropdown-item command="其他">其他</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div v-for="item in list" :key="item.id" class="comp_item" @click="linkToComp(item)">
        <div class="logo_div">
          <img :src="item.gongsilog" alt="">
        </div>
        <div class="info_div">
          <h4>{{ item.title }}</h4>
          <div class="info_1_div">
            <div class="overflow_text">所在地：{{ item.zhucedidian }}</div>
            <div class="overflow_text">注册基金：{{ item.zhuceziben || '暂无' }}</div>
            <div class="overflow_text">成立时间：{{ item.clsj&&item.clsj.includes("T") ? item.clsj.split("T")[0] : item.clsj }}</div>
          </div>
          <div class="info_1_div">
            <span style="color: #0089ef">主营产品：</span>
            <span class="overflow_text">{{ item.keywords }}</span>
          </div>
          <div class="info_1_div">
            <span style="color: #0089ef">配套客户：</span>
            <span class="overflow_text">{{ item.description }}</span>
          </div>
        </div>
      </div>
      <div class="pagination_div">
        <el-pagination :current-page="page" :page-size="pagesize" :total="total" background layout="prev, pager, next"
          next-text="下一页" prev-text="上一页" @current-change="currentChange">
        </el-pagination>
      </div>
    </div>
    <div class="right_div">
      <div class="title_div">
        <img src="../assets/img/tuijian.png">
        <span>推荐企业</span>
      </div>
      <div v-for="item in commendList" :key="item.id" class="t_item" @click="linkTo(item)">
        <img :src="'http://admin.aiauto.shop' + item.logo" alt="">
        <h5>{{ item.name }}</h5>
        <div>{{ item.address }} / {{ item.create_date }} 年</div>
      </div>
    </div>
  </div>
</template>

<script>
import request from '@/api/request.js'
import { regionData, codeToText } from 'element-china-area-data'


export default {
  name: "enterpriseListNew",
  data() {
    return {
      options: regionData,
      selectedOptions: [],
      listl: [
        {
          "id": "22",
          "name": "项目类别",
          "s": "zhaoqiye",
          "child": [
            {
              "id": "23",
              "name": "整车",
              "s": "zhaoqiye",
              "isxj": "1",
              "child": [
                {
                  "id": "30",
                  "name": "轿车",
                  "s": "zhaoqiye",
                  "isxj": "0"
                },
                {
                  "id": "31",
                  "name": "SUV",
                  "s": "zhaoqiye",
                  "isxj": "0"
                },
                {
                  "id": "32",
                  "name": "MPV",
                  "s": "zhaoqiye",
                  "isxj": "0"
                },
                {
                  "id": "33",
                  "name": "客车",
                  "s": "zhaoqiye",
                  "isxj": "0"
                },
                {
                  "id": "34",
                  "name": "卡车",
                  "s": "zhaoqiye",
                  "isxj": "0"
                }
              ]
            },
            {
              "id": "24",
              "name": "电动化",
              "s": "zhaoqiye",
              "isxj": "1",
              "child": [
                {
                  "id": "35",
                  "name": "电池",
                  "s": "zhaoqiye",
                  "isxj": "0"
                },
                {
                  "id": "36",
                  "name": "锂电池",
                  "s": "zhaoqiye",
                  "isxj": "0"
                },
                {
                  "id": "37",
                  "name": "钠电池",
                  "s": "zhaoqiye",
                  "isxj": "0"
                },
                {
                  "id": "38",
                  "name": "固态电池",
                  "s": "zhaoqiye",
                  "isxj": "0"
                }
              ]
            },
            {
              "id": "25",
              "name": "智能化",
              "s": "zhaoqiye",
              "isxj": "1",
              "child": [
                {
                  "id": "39",
                  "name": "自动驾驶",
                  "s": "zhaoqiye",
                  "isxj": "0"
                },
                {
                  "id": "40",
                  "name": "无人驾驶",
                  "s": "zhaoqiye",
                  "isxj": "0"
                },
                {
                  "id": "41",
                  "name": "ADAS辅助驾驶系统",
                  "s": "zhaoqiye",
                  "isxj": "0"
                },
                {
                  "id": "42",
                  "name": "芯片",
                  "s": "zhaoqiye",
                  "isxj": "0"
                }
              ]
            },
            {
              "id": "26",
              "name": "轻量化",
              "s": "zhaoqiye",
              "isxj": "0"
            },
            {
              "id": "27",
              "name": "共享化",
              "s": "zhaoqiye",
              "isxj": "0"
            },
            {
              "id": "28",
              "name": "后市场",
              "s": "zhaoqiye",
              "isxj": "0"
            },
            {
              "id": "29",
              "name": "汽车后市场",
              "s": "zhaoqiye",
              "isxj": "0"
            }
          ]
        },
        {
          "id": "43",
          "name": "投资阶段",
          "s": "zhaoqiye"
        },
        {
          "id": "44",
          "name": "省市区域",
          "s": "zhaoqiye"
        },
        {
          "id": "45",
          "name": "成立时间",
          "s": "zhaoqiye"
        },
        {
          "id": "46",
          "name": "专精特新",
          "s": "zhaoqiye",
          "child": [
            {
              "id": "166",
              "name": "地市级",
              "s": "zhaoqiye",
              "isxj": "0"
            },
            {
              "id": "167",
              "name": "省级",
              "s": "zhaoqiye",
              "isxj": "0"
            }
          ]
        },
        {
          "id": "47",
          "name": "IPO",
          "s": "zhaoqiye"
        },
        {
          "id": "48",
          "name": "上市公司",
          "s": "zhaoqiye",
          "child": [
            {
              "id": "162",
              "name": "北交所",
              "s": "zhaoqiye",
              "isxj": "0"
            },
            {
              "id": "163",
              "name": "深交所",
              "s": "zhaoqiye",
              "isxj": "0"
            },
            {
              "id": "164",
              "name": "上交所",
              "s": "zhaoqiye",
              "isxj": "0"
            },
            {
              "id": "165",
              "name": "港交所",
              "s": "zhaoqiye",
              "isxj": "0"
            }
          ]
        }
      ],
      selected: "项目类别",
      selectedin: '项目类别',
      loading:false,
      pagesize: 20,
      page: 1,
      total: 0,
      list: [],
      xuanz: [],
      xuanzName: [],
      checkedItem: {},
      checkedIds: '',
      selectItem: {},
      // 当前菜单id
      routerId: null,
      //推荐企业
      commendList: [],
      kewObj: {},
      baseUrl: window.location.origin
    };
  },
  mounted() {
    this.routerId = this.$route.query.checktab || 1
    this.column();
    this.$EventBus.$on("globalSearch", (keywords) => {
      this.loading = true;
      request.enterpriseList(this.routerId, this.pagesize, this.page, this.kewObj, keywords).then((res) => {
        if (res.data.data.list) {
          this.list = res.data.data.list;
          this.total = Number(res.data.data.total || 0);
        } else {
          this.list = []
        }
        this.loading = false;
      })
    });
  },
  watch: {
    $route(to, from) {
      this.routerId = to.query.checktab || 1
      this.column();
      this.$EventBus.$on("globalSearch", (keywords) => {
        this.loading = true;
        request.enterpriseList(this.routerId, this.pagesize, this.page, this.kewObj, keywords).then((res) => {
          if (res.data.data.list) {
            this.list = res.data.data.list;
            this.total = Number(res.data.data.total || 0);
          } else {
            this.list = []
          }
          this.loading = false;
        })
      });
    },
    immediate: true,
    deep: true
  },
  methods: {
    linkTo(item) {
      window.open(item.website)
    },
    handleChange(value) {
      let citys = this.getCodeToText(value)
      this.handleCommand(citys, 'sssf')
    },
    getCodeToText(codeArray) {
      let area = "";
      switch (codeArray.length) {
        case 1:
          area += codeToText[codeArray[0]];
          break;
        case 2:
          area += codeToText[codeArray[0]] + "/" + codeToText[codeArray[1]];
          break;
        case 3:
          area +=
            codeToText[codeArray[0]] +
            "/" +
            codeToText[codeArray[1]] +
            "/" +
            codeToText[codeArray[2]];
          break;
        default:
          break;
      }
      return area
    },
    handleCommand(value, key) {
      this.kewObj[key] = value
      this.enterpriseList()
    },
    // 点击一级菜单
    firstMenuClick(item, n) {
      this.dateType(this.listl)
      item.type = true
      this.$set(this.listl, n, item)
      this.routerId = item.value
      this.enterpriseList()
      this.$EventBus.$emit('clearSearch')
    },
    // 点击二级菜单
    menuChildClick(item, index, n) {
      this.dateType(this.listl)
      this.listl[n].type = true
      let newarr1 = this.listl[n].child
      item.type = true
      newarr1[index] = item
      this.$set(this.listl, newarr1, n)
      this.$set(this.listl[n].child, item, index)
      this.routerId = item.value
      this.kewObj = {}
      this.enterpriseList(item.value)
      this.$EventBus.$emit('clearSearch')

    },
    // 点击三级菜单
    menuChildClick2(item, index, n) {
      this.dateType(this.listl)
      this.listl[n].type = true
      this.listl[n].child[index].type = true
      item.type = true
      this.routerId = item.value
      this.kewObj = {}
      this.enterpriseList(item.value)
      this.$EventBus.$emit('clearSearch')
    },
    // 关闭全部级联菜单
    dateType(item) {
      item.forEach(e => {
        if (e.child) {
          this.dateType(e.child)
        }
        e.type = false
      })
    },
    // 递归进行格式转换
    dateTypeChange(item, parentItem = []) {
      let newarr = []
      for (const key in item) {
        if (Object.hasOwnProperty.call(item, key)) {
          const element = item[key];
          if (element.children) {
            element.child = this.dateTypeChange(element.children, [element, ...parentItem])
          }
          if (this.routerId == element.value) {
            element.type = true
            parentItem.forEach(e => {
              e.type = true
            })
          }
          newarr.push(element)
        }
      }
      return newarr
    },
    column() {
      this.enterpriseList();
      request.columnnew().then(({ data }) => {
        if (data.code == 1) {
          let treeDate = data.data.cplb
          this.listl = this.dateTypeChange(treeDate)
        }
      })
      request.getrecommend().then(({ data }) => {
        if (data.code == 1) {
          this.commendList = JSON.parse(data.data)
        }
      })
    },
    enterpriseList() {
      this.loading = true;
      request.enterpriseList(this.routerId, this.pagesize, this.page, this.kewObj).then((res) => {
        if (res.data.data.list) {
          this.list = res.data.data.list;
          this.total = Number(res.data.data.total || 0);
        } else {
          this.list = []
        }
        this.loading = false;
      })
    },
    currentChange($event) {
      this.page = $event;
      this.enterpriseList()
    },
    linkToComp(item) {
      this.$router.push({
        path: '/InvestmentDetailsNew',
        query: { ...this.$route.query, id: item.id }
      })
    },
  },
  beforeDestroy() {
    this.$EventBus.$off("globalSearch");
  },
}
</script>

<style lang="scss" scoped>
.Industrial {
  min-width: 1200px;
  width: 73%;
  min-height: 900px;
  margin: 30px auto 0;
  padding-bottom: 20px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;

  .overflow_text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .left_div {
    width: 16.6%;
    background: #fff;
    height: inherit;
    padding: 10px 0;

    .hastriangle {
      display: flex;

      &:hover {
        &:after {
          border-color: transparent transparent transparent #2a88ed;
        }
      }

      &:after {
        content: "";
        border-style: solid;
        border-width: 7px 7px 7px;
        border-color: transparent transparent transparent #d8d8d8;
      }
    }

    .list_divs {
      width: 95%;
      margin: 0 auto;

      .check_items_css {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 0;
        margin: 0 10px;
        position: relative;

        &:hover {
          color: #2a88ed;
        }

        .h1_check_item {
          display: flex;
          align-items: center;

          &:before {
            content: " ";
            width: 6px;
            height: 6px;
            margin-right: 10px;
            border-radius: 100%;
          }
        }
      }

      .check_items_1 {
        &:hover {

          .h1_check_item:before {
            background: #2a88ed;
          }

          .hastriangle {
            &:after {
              border-color: transparent transparent transparent #2a88ed;
            }
          }

          border-bottom: 1px solid #2a88ed;
        }

        .h1_check_item:before {
          background: #d8d8d8;
        }

        border-bottom: 1px solid #d8d8d8;

      }

      .check_items_2 {
        color: #2a88ed;
        border-bottom: 1px solid #2a88ed;

        .h1_check_item:before {
          background: #2a88ed;
        }

        .hastriangle:after {
          border-color: #2a88ed transparent transparent transparent !important;
        }
      }

      .item_child_div {
        margin: 0 10px;
        padding-left: 30px;
        //border-bottom: 1px solid #d8d8d8;

        .first_child_div {

          &:hover {
            color: #2a88ed;
          }

          margin: 10px 0;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .first_child_div_1 {
          color: #2a88ed;

          .hastriangle:after {
            border-color: #2a88ed transparent transparent transparent !important;
          }
        }
      }
    }

  }

  .content_div {
    width: 58.6%;
    height: inherit;

    .comp_item {
      cursor: pointer;
      margin-bottom: 20px;
      background: #fff;
      padding: 24px 30px;
      display: flex;
      align-items: center;
      border: 1px solid rgba(0, 137, 239, 0);

      &:hover {
        border: 1px solid rgba(0, 137, 239, 1);
      }

      .logo_div {
        display: flex;
        align-items: center;
        margin-right: 20px;

        img {
          width: 81px;
          height: 82px;
        }
      }

      .info_div {
        width: calc(100% - 105px);

        .info_1_div {
          width: 100%;
          line-height: 20px;
          margin: 9px 0 0 1px;
          color: #8D8D8DFF;
          font-size: 14px;
          display: flex;
          align-items: center;
          white-space: nowrap;

          .overflow_text {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          div {
            width: 33.3%;
          }
        }
      }
    }

    .drop_down_div {
      background: #FFF;
      padding: 15px 20px;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
    }

    .pagination_div {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }

  .right_div {
    width: 21.7%;
    background: #fff;
    height: inherit;

    .title_div {
      background-color: rgba(240, 240, 240, 1);
      width: 90%;
      padding: 20px 0;
      margin: 20px auto;
      position: relative;

      span {
        margin-left: 30px;
      }

      img {
        position: absolute;
        left: 0;
        top: 0;
      }
    }

    .t_item {
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 8px;
      border: 1px solid rgba(214, 214, 214, 1);
      width: 90%;
      margin: 20px auto;
      padding: 25px 0;
      cursor: pointer;

      &:hover {
        border: 1px solid rgba(0, 137, 239, 1);
      }

      img {
        width: 175px;
        height: 79px;
      }
    }
  }
}
</style>
<style lang="scss">
.pagination_div {
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background: #2C7CD7;
  }

  .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #2C7CD7;
  }

}
</style>

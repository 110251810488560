<template>
  <div>
    <a v-if="advertisingDel" @click="advertisement(advertisingDel)"><img class="Advertising" :src="advertisingDel.img" alt=""></a>
    <div class="CreateNews">
      <div class="CreateNews_in">
        <div class="CreateNews_left">
          <div
            v-for="(item, index) in label"
            :key="index"
            :class="index == labelIdx&&!firstIn ? 'CreateNews_left_color' : ''"
            @click="cssdawd(index)"
          >
            {{ item.name }}
          </div>
        </div>
        <div class="CreateNews_right">
          <div class="ydd_label" v-if="$route.query.type != 1">
              <div class="swiper-wrapper" style="width: 100%;">
                  <div v-for="(item, index) in label" :key="index" :class="index == labelIdx?'CreateNews_left_color':''" class="swiper-slide" @click="cssdawd(index)">{{item.name}}</div>
            </div>
          </div>
          <div class="CreateNews_title">
            <h4>{{ firstIn?'':label[labelIdx].name }}</h4>
            <div class="ydd_CreateNews_right">
              <div class="swiper-wrapper" style="width: 100%;">
                    <div class="swiper-slide" v-for="(itm, idx) in twoLbel1111"
                    :key="idx"
                    :class="
                      idx == twoLbelIdx
                        ? 'CreateNews_right_in_div_color'
                        : ''
                    "
                    @click="twoLbelIdx = idx">{{itm.name}}</div>
              </div>
            </div>
          </div>
          <div v-if="CreateNewsList[twoLbelIdx].type == 0" style="width: 100%;">
            <PlainTextList :users="list" :advertisingList="advertisingList"></PlainTextList>

          </div>
          <div v-if="CreateNewsList[twoLbelIdx].type == 1">
            <el-table :data="list" style="width: 100%; margin-top: 20px">
              <el-table-column prop="title" label="名称" width="340">
                <template slot-scope="scope">
                  <img
                    class="ssadadasdw"
                    :src="scope.row.thumb"
                    alt=""
                  />
                  <p class="table_name">{{ scope.row.title }}</p>
                </template>
              </el-table-column>
              <el-table-column prop="author" label="作者" width="150"></el-table-column>
              <el-table-column prop="leibie" label="类别" width="150"></el-table-column>
              <el-table-column prop="yeshu" label="页数" width="150"></el-table-column>
              <el-table-column prop="jiage" label="价格" width="150"></el-table-column>
              <el-table-column label="操作" width="200">
                <template slot-scope="scope">
                  <el-button
                    style="width: 100%"
                    type="primary"
                    plain
                    size="mini"
                    @click="adasdasdasd(scope.row,CreateNewsList[twoLbelIdx].type)"
                    >在线看</el-button
                  >
                  <!-- <el-row style="margin-top: 8px" :gutter="8">
                    <el-col :span="12">
                      <a :href="scope.row.ziliao" download>
                        <el-button
                          style="width: 100%"
                          type="info"
                          plain
                          size="mini"
                          >下载</el-button
                        >
                      </a>
                    </el-col>
                    <el-col :span="12">
                      <el-button
                        style="width: 100%"
                        type="info"
                        plain
                        size="mini"
                        >打印</el-button
                      >
                    </el-col> -->
                  </el-row>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div v-if="CreateNewsList[twoLbelIdx].type == 2">
            <Graphiclist :users="label[labelIdx].name" :list="list"></Graphiclist>
          </div>
          <div v-if="CreateNewsList[twoLbelIdx].type == 3" style="width: 100%;">
            <el-row :gutter="42" class="project" style="width: 100%;">
              <el-col :span="6" v-for="(d,i) in list" :key="i">
                <div class="project_list" @click="zxcascas(d)">
                  <div class="team_img_div">
                    <img :src="d.thumb" alt="" />
                  </div>
                  <h4>{{d.title}}</h4>
                  <div><i class="el-icon-arrow-right"></i>{{d.keywords}}</div>
                </div>
              </el-col>
            </el-row>
          </div>
          <el-pagination
            v-if="list.length > 0"
            class="pagination"
            background
            :current-page="page"
            :page-size="pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="sizeChange"
            @current-change="currentChange"
            prev-text="上一页"
            next-text="下一页"
            :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
    <a id="download" href="javascript:void(0);" style="width: 0;height: 0;opacity: 0;"></a>
  </div>
</template>

<script>
import PlainTextList from "@/components/general/PlainTextList.vue";
import carBook from "@/components/general/carBook.vue";
import ListFiles from "@/components/general/ListFiles.vue";
import CommonUtils from "@/utils/CommonUtils";
import Graphiclist from "@/components/general/Graphiclist.vue";
import 'swiper/css/swiper.css'  //引入swiper样式
import Swiper from 'swiper';	//引入swiper
import request from '@/api/request.js'
export default {
  components: {
    PlainTextList,
    carBook,
    ListFiles,
    Graphiclist,
  },
  data() {
    return {
      input2:'',
      label: [],
      labelIdx: 0,
      twoLbel1111:[],
      twoLbelIdx:0,
      list:[],
      pagesize:20,
      page:1,
      total:0,
      formInline: { user: "", region: "" },
      advertisingList:[],
      advertisingDel:{},
      tableData: [],
      CreateNewsList:[],
      firstIn:true
    };
  },
  watch: {
    $route(to, from) {
      if (this.$route.query.type == 1) {
        this.label = CommonUtils.information_label;
        this.CreateNewsList = [
          {name:'备注备注',type:0},
          {name:'备注备注',type:0},
          {name:'备注备注',type:0},
          {name:'备注备注',type:0},
          {name:'备注备注',type:0},
          {name:'备注备注',type:0},
          {name:'备注备注',type:0},
          {name:'备注备注',type:0},
          {name:'备注备注',type:0},
        ]
      } else if (this.$route.query.type == 2) {
        this.label = CommonUtils.Goldcan_label;
        this.CreateNewsList = [
          {name:'学习资料',type:1},
          {name:'文档资料',type:1},
          {name:'课程',type:2},
          {name:'直播',type:2},
          {name:'讲师',type:2},
          {name:'视频学习',type:2},
          {name:'现场培训',type:0},
          {name:'备注',type:0},
          {name:'备注',type:0},
          {name:'备注',type:0}
        ];
        this.JinNengList()
      } else if (this.$route.query.type == 7) {
        this.label = CommonUtils.community;
        this.CreateNewsList = [
          {name:'备注备注',type:3},
          {name:'备注备注',type:3},
          {name:'备注备注',type:3},
          {name:'备注备注',type:3},
          {name:'备注备注',type:3},
          {name:'备注备注',type:3},
          {name:'备注备注',type:3},
          {name:'备注备注',type:3},
          {name:'备注备注',type:3},
        ];
      }
      this.labelIdx = this.$route.query.sublevel;
      this.column(this.$route.query.typeId,0);
      this.advertisingSpace(2, 1, this.$route.query.typeId)
      this.advertisingSpace(2, 2, this.$route.query.typeId)
    },
  },
  mounted() {
    if (this.$route.query.type == 1) {
      this.label = CommonUtils.information_label;
      this.CreateNewsList = [
        {name:'备注备注',type:0},
        {name:'备注备注',type:0},
        {name:'备注备注',type:0},
        {name:'备注备注',type:0},
        {name:'备注备注',type:0},
        {name:'备注备注',type:0},
        {name:'备注备注',type:0},
        {name:'备注备注',type:0},
        {name:'备注备注',type:0},
      ]
    } else if (this.$route.query.type == 2) {
      this.label = CommonUtils.Goldcan_label;
      this.CreateNewsList = [
        {name:'学习资料',type:1},
        {name:'文档资料',type:1},
        {name:'课程',type:2},
        {name:'直播',type:2},
        {name:'讲师',type:2},
        {name:'视频学习',type:2},
        {name:'现场培训',type:0},
        {name:'备注',type:0},
        {name:'备注',type:0},
        {name:'备注',type:0}
      ];
      this.JinNengList()
    } else if (this.$route.query.type == 7) {
      this.label = CommonUtils.community;
      this.CreateNewsList = [
        {name:'备注备注',type:3},
        {name:'备注备注',type:3},
        {name:'备注备注',type:3},
        {name:'备注备注',type:3},
        {name:'备注备注',type:3},
        {name:'备注备注',type:3},
        {name:'备注备注',type:3},
        {name:'备注备注',type:3},
        {name:'备注备注',type:3},
      ];
    }
    this.labelIdx = 0;
    if(window.localStorage.getItem('sublevel') != undefined){
      this.labelIdx = window.localStorage.getItem('sublevel');
    }
    setTimeout(() => {
      var mySwiper=new Swiper('.ydd_label',{
          //配置分页器内容
          setWrapperSize :true,
          slidesPerView: 'auto',
              slideToClickedSlide: true,//设置为true则点击slide会过渡到这个slide。
              slidesOffsetBefore: 0,
              slidesOffsetAfter: 0,
      })
      var mySwiper1=new Swiper('.ydd_CreateNews_right',{
          //配置分页器内容
          setWrapperSize :true,
          slidesPerView: 'auto',
              slideToClickedSlide: true,//设置为true则点击slide会过渡到这个slide。
              slidesOffsetBefore: 0,
              slidesOffsetAfter: 0,
      })
    },400)
    this.column(this.$route.query.typeId,0)
    this.advertisingSpace(2, 1, this.$route.query.typeId)
    this.advertisingSpace(2, 2, this.$route.query.typeId)


    this.$EventBus.$on("globalSearch", (keywords) => {
      this.input2 = keywords
      this.column(this.label[labelIdx].id,1)
    });
  },
  methods:{
    column(idx, i) {
       request.column(idx).then((res) => {
        if(i == 0){
          this.label = res.data.data
          if(this.$route.query.twotypeId){
            this.column(this.$route.query.twotypeId,1)
          }else{
            this.column(this.$route.query.typeId,1)
          }
        }else{
          if(res.data.data.length > 0){
            this.twoLbel1111 = res.data.data;
          }
          this.page = 0;
          this.pagesize = 20;
            if(this.$route.query.type == 1){
              console.log(this.twoLbel1111, this.labelIdx)
              this.industryList(this.twoLbel1111[this.labelIdx].id)
            }else if(this.$route.query.type == 2){
              this.label = CommonUtils.Goldcan_label;
              this.twoLbel1111 = [
                {name:'学习资料',type:1},
                {name:'文档资料',type:1},
                {name:'课程',type:2},
                {name:'直播',type:2},
                {name:'讲师',type:2},
                {name:'视频学习',type:2},
                {name:'现场培训',type:0},
                {name:'备注',type:0},
                {name:'备注',type:0},
                {name:'备注',type:0}
              ];
              this.tableData = []
              this.JinNengList(this.label[this.labelIdx].id)
            }else if(this.$route.query.type == 7){
              this.investmentList(idx)
            }
        }
      })
    },
    industryList(catid){
      request.industryList(catid,this.pagesize,this.page,this.input2).then((res) => {
        if(res.data.data != null){
          this.list = res.data.data.list;
          this.total = res.data.data.total
        }else{
          this.list = []
        }
      })
    },
    JinNengList(catid){
      request.JinNengList(catid,this.pagesize,this.page,this.input2).then((res) => {
        if(res.data.data != null){
          this.list = res.data.data.list;
          this.total = res.data.data.total
        }else{
          this.list = []
        }
      })
    },
    informationList(catid){
      request.informationList(catid,this.pagesize,this.page,this.input2).then((res) => {
        if(res.data.data != null){
          this.list = res.data.data.list;
          this.total = res.data.data.total
        }else{
          this.list = []
        }
      })
    },
    investmentList(catid) {
      if (this.label[this.labelIdx]?.name == '专家人') {
        request.investmentBank11(this.input2, 80, this.pagesize, this.page).then((res) => {
          if (res.data.data != null) {
            this.list = res.data.data.list;
            this.total = res.data.data.total
          }else{
            this.list = []
          }
        })
        return
      }
      request.investmentList(catid,this.pagesize,this.page,this.input2).then((res) => {
        if(res.data.data != null){
          this.list = res.data.data.list;
          this.total = res.data.data.total
        }else{
          this.list = []
        }
      })
    },
    cssdawd(idx) {
      this.input2 = ''
      this.labelIdx = idx
      this.firstIn = false
      this.page = 1;
          this.pagesize = 20;
      this.column(this.label[idx].id,1);
      this.$EventBus.$emit('clearSearch')
    },
    zxcascas(item){
      this.$router.push({
          path:'/communityDetails',
          query:{...this.$route.query,id:item.id,typeName:item.category_name}
      })
    },
    sizeChange($event){
      this.pagesize = $event;
      this.page = 1;
      if(this.$route.query.type == 1){
        this.industryList(this.label[this.labelIdx].id)
      }else if(this.$route.query.type == 2){
        this.label = CommonUtils.Goldcan_label;
        this.CreateNewsList = [
          {name:'学习资料',type:1},
          {name:'文档资料',type:1},
          {name:'课程',type:2},
          {name:'直播',type:2},
          {name:'讲师',type:2},
          {name:'视频学习',type:2},
          {name:'现场培训',type:0},
          {name:'备注',type:0},
          {name:'备注',type:0},
          {name:'备注',type:0}
        ];
        this.JinNengList()
      }else if(this.$route.query.type == 7){
        this.investmentList(this.label[this.labelIdx].id)
      }
      if(this.twoLbel){
        if(this.$route.query.type == 1){
          this.industryList(this.twoLbel1111[this.labelIdx].id)
        }else if(this.$route.query.type == 2){
          this.label = CommonUtils.Goldcan_label;
          this.twoLbel1111 = [
            {name:'学习资料',type:1},
            {name:'文档资料',type:1},
            {name:'课程',type:2},
            {name:'直播',type:2},
            {name:'讲师',type:2},
            {name:'视频学习',type:2},
            {name:'现场培训',type:0},
            {name:'备注',type:0},
            {name:'备注',type:0},
            {name:'备注',type:0}
          ];
          this.tableData = [
            {name:'2022中国餐饮数字化市场专题分析-易观分析+',text:'就吃一口小饼干',type:'汽车图如图一样书',num:'40页',type1:'汽车图书'},
            {name:'2022中国餐饮数字化市场专题分析-易观分析+',text:'就吃一口小饼干',type:'汽车图如图一样书',num:'40页',type1:'汽车图书'},
            {name:'2022中国餐饮数字化市场专题分析-易观分析+',text:'就吃一口小饼干',type:'汽车图如图一样书',num:'40页',type1:'汽车图书'},
            {name:'2022中国餐饮数字化市场专题分析-易观分析+',text:'就吃一口小饼干',type:'汽车图如图一样书',num:'40页',type1:'汽车图书'},
            {name:'2022中国餐饮数字化市场专题分析-易观分析+',text:'就吃一口小饼干',type:'汽车图如图一样书',num:'40页',type1:'汽车图书'},
            {name:'2022中国餐饮数字化市场专题分析-易观分析+',text:'就吃一口小饼干',type:'汽车图如图一样书',num:'40页',type1:'汽车图书'},
            {name:'2022中国餐饮数字化市场专题分析-易观分析+',text:'就吃一口小饼干',type:'汽车图如图一样书',num:'40页',type1:'汽车图书'},
            {name:'2022中国餐饮数字化市场专题分析-易观分析+',text:'就吃一口小饼干',type:'汽车图如图一样书',num:'40页',type1:'汽车图书'},
            {name:'2022中国餐饮数字化市场专题分析-易观分析+',text:'就吃一口小饼干',type:'汽车图如图一样书',num:'40页',type1:'汽车图书'},
            {name:'2022中国餐饮数字化市场专题分析-易观分析+',text:'就吃一口小饼干',type:'汽车图如图一样书',num:'40页',type1:'汽车图书'},
            {name:'2022中国餐饮数字化市场专题分析-易观分析+',text:'就吃一口小饼干',type:'汽车图如图一样书',num:'40页',type1:'汽车图书'},
            {name:'2022中国餐饮数字化市场专题分析-易观分析+',text:'就吃一口小饼干',type:'汽车图如图一样书',num:'40页',type1:'汽车图书'},
            {name:'2022中国餐饮数字化市场专题分析-易观分析+',text:'就吃一口小饼干',type:'汽车图如图一样书',num:'40页',type1:'汽车图书'},
            {name:'2022中国餐饮数字化市场专题分析-易观分析+',text:'就吃一口小饼干',type:'汽车图如图一样书',num:'40页',type1:'汽车图书'},
            {name:'2022中国餐饮数字化市场专题分析-易观分析+',text:'就吃一口小饼干',type:'汽车图如图一样书',num:'40页',type1:'汽车图书'},
          ]
        }
      }else{
        if(this.$route.query.type == 1){
            this.industryList(this.label[this.labelIdx].id)
          }else if(this.$route.query.type == 2){
            this.label = CommonUtils.Goldcan_label;
            this.twoLbel1111 = [
              {name:'学习资料',type:1},
              {name:'文档资料',type:1},
              {name:'课程',type:2},
              {name:'直播',type:2},
              {name:'讲师',type:2},
              {name:'视频学习',type:2},
              {name:'现场培训',type:0},
              {name:'备注',type:0},
              {name:'备注',type:0},
              {name:'备注',type:0}
            ];
            this.tableData = [
              {name:'2022中国餐饮数字化市场专题分析-易观分析+',text:'就吃一口小饼干',type:'汽车图如图一样书',num:'40页',type1:'汽车图书'},
              {name:'2022中国餐饮数字化市场专题分析-易观分析+',text:'就吃一口小饼干',type:'汽车图如图一样书',num:'40页',type1:'汽车图书'},
              {name:'2022中国餐饮数字化市场专题分析-易观分析+',text:'就吃一口小饼干',type:'汽车图如图一样书',num:'40页',type1:'汽车图书'},
              {name:'2022中国餐饮数字化市场专题分析-易观分析+',text:'就吃一口小饼干',type:'汽车图如图一样书',num:'40页',type1:'汽车图书'},
              {name:'2022中国餐饮数字化市场专题分析-易观分析+',text:'就吃一口小饼干',type:'汽车图如图一样书',num:'40页',type1:'汽车图书'},
              {name:'2022中国餐饮数字化市场专题分析-易观分析+',text:'就吃一口小饼干',type:'汽车图如图一样书',num:'40页',type1:'汽车图书'},
              {name:'2022中国餐饮数字化市场专题分析-易观分析+',text:'就吃一口小饼干',type:'汽车图如图一样书',num:'40页',type1:'汽车图书'},
              {name:'2022中国餐饮数字化市场专题分析-易观分析+',text:'就吃一口小饼干',type:'汽车图如图一样书',num:'40页',type1:'汽车图书'},
              {name:'2022中国餐饮数字化市场专题分析-易观分析+',text:'就吃一口小饼干',type:'汽车图如图一样书',num:'40页',type1:'汽车图书'},
              {name:'2022中国餐饮数字化市场专题分析-易观分析+',text:'就吃一口小饼干',type:'汽车图如图一样书',num:'40页',type1:'汽车图书'},
              {name:'2022中国餐饮数字化市场专题分析-易观分析+',text:'就吃一口小饼干',type:'汽车图如图一样书',num:'40页',type1:'汽车图书'},
              {name:'2022中国餐饮数字化市场专题分析-易观分析+',text:'就吃一口小饼干',type:'汽车图如图一样书',num:'40页',type1:'汽车图书'},
              {name:'2022中国餐饮数字化市场专题分析-易观分析+',text:'就吃一口小饼干',type:'汽车图如图一样书',num:'40页',type1:'汽车图书'},
              {name:'2022中国餐饮数字化市场专题分析-易观分析+',text:'就吃一口小饼干',type:'汽车图如图一样书',num:'40页',type1:'汽车图书'},
              {name:'2022中国餐饮数字化市场专题分析-易观分析+',text:'就吃一口小饼干',type:'汽车图如图一样书',num:'40页',type1:'汽车图书'},
            ]
          }
      }
    },
    currentChange($event){
      this.page = $event;
      if(this.$route.query.type == 1){
        this.industryList(this.label[this.labelIdx].id)
      }else if(this.$route.query.type == 2){
        this.label = CommonUtils.Goldcan_label;
        this.CreateNewsList = [
          {name:'学习资料',type:1},
          {name:'文档资料',type:1},
          {name:'课程',type:2},
          {name:'直播',type:2},
          {name:'讲师',type:2},
          {name:'视频学习',type:2},
          {name:'现场培训',type:0},
          {name:'备注',type:0},
          {name:'备注',type:0},
          {name:'备注',type:0}
        ];
        this.JinNengList()
      }else if(this.$route.query.type == 7){
        this.investmentList(this.label[this.labelIdx].id)
      }
      // if(this.twoLbel){
      //   if(this.$route.query.type == 1){
      //     this.industryList(this.twoLbel1111[this.labelIdx].id)
      //   }else if(this.$route.query.type == 2){
      //     this.label = CommonUtils.Goldcan_label;
      //     this.twoLbel1111 = [
      //       {name:'学习资料',type:1},
      //       {name:'文档资料',type:1},
      //       {name:'课程',type:2},
      //       {name:'直播',type:2},
      //       {name:'讲师',type:2},
      //       {name:'视频学习',type:2},
      //       {name:'现场培训',type:0},
      //       {name:'备注',type:0},
      //       {name:'备注',type:0},
      //       {name:'备注',type:0}
      //     ];
      //     this.tableData = [
      //       {name:'2022中国餐饮数字化市场专题分析-易观分析+',text:'就吃一口小饼干',type:'汽车图如图一样书',num:'40页',type1:'汽车图书'},
      //       {name:'2022中国餐饮数字化市场专题分析-易观分析+',text:'就吃一口小饼干',type:'汽车图如图一样书',num:'40页',type1:'汽车图书'},
      //       {name:'2022中国餐饮数字化市场专题分析-易观分析+',text:'就吃一口小饼干',type:'汽车图如图一样书',num:'40页',type1:'汽车图书'},
      //       {name:'2022中国餐饮数字化市场专题分析-易观分析+',text:'就吃一口小饼干',type:'汽车图如图一样书',num:'40页',type1:'汽车图书'},
      //       {name:'2022中国餐饮数字化市场专题分析-易观分析+',text:'就吃一口小饼干',type:'汽车图如图一样书',num:'40页',type1:'汽车图书'},
      //       {name:'2022中国餐饮数字化市场专题分析-易观分析+',text:'就吃一口小饼干',type:'汽车图如图一样书',num:'40页',type1:'汽车图书'},
      //       {name:'2022中国餐饮数字化市场专题分析-易观分析+',text:'就吃一口小饼干',type:'汽车图如图一样书',num:'40页',type1:'汽车图书'},
      //       {name:'2022中国餐饮数字化市场专题分析-易观分析+',text:'就吃一口小饼干',type:'汽车图如图一样书',num:'40页',type1:'汽车图书'},
      //       {name:'2022中国餐饮数字化市场专题分析-易观分析+',text:'就吃一口小饼干',type:'汽车图如图一样书',num:'40页',type1:'汽车图书'},
      //       {name:'2022中国餐饮数字化市场专题分析-易观分析+',text:'就吃一口小饼干',type:'汽车图如图一样书',num:'40页',type1:'汽车图书'},
      //       {name:'2022中国餐饮数字化市场专题分析-易观分析+',text:'就吃一口小饼干',type:'汽车图如图一样书',num:'40页',type1:'汽车图书'},
      //       {name:'2022中国餐饮数字化市场专题分析-易观分析+',text:'就吃一口小饼干',type:'汽车图如图一样书',num:'40页',type1:'汽车图书'},
      //       {name:'2022中国餐饮数字化市场专题分析-易观分析+',text:'就吃一口小饼干',type:'汽车图如图一样书',num:'40页',type1:'汽车图书'},
      //       {name:'2022中国餐饮数字化市场专题分析-易观分析+',text:'就吃一口小饼干',type:'汽车图如图一样书',num:'40页',type1:'汽车图书'},
      //       {name:'2022中国餐饮数字化市场专题分析-易观分析+',text:'就吃一口小饼干',type:'汽车图如图一样书',num:'40页',type1:'汽车图书'},
      //     ]
      //   }
      // }else{
      //   if(this.$route.query.type == 1){
      //       this.industryList(this.label[this.labelIdx].id)
      //   }else if(this.$route.query.type == 2){
      //     this.label = CommonUtils.Goldcan_label;
      //     this.twoLbel1111 = [
      //       {name:'学习资料',type:1},
      //       {name:'文档资料',type:1},
      //       {name:'课程',type:2},
      //       {name:'直播',type:2},
      //       {name:'讲师',type:2},
      //       {name:'视频学习',type:2},
      //       {name:'现场培训',type:0},
      //       {name:'备注',type:0},
      //       {name:'备注',type:0},
      //       {name:'备注',type:0}
      //     ];
      //     this.JinNengList()
      //   }
      // }
    },
    currentChange2($event){
      this.page = $event;
      if(this.twoLbel1111.length > 0 && this.$route.query.type == 0){
        this.informationList(this.twoLbel[this.twoLbelIdx].id)
      }else if(this.$route.query.type == 1){
        this.industryList(this.label[this.labelIdx].id)
      }else if(this.$route.query.type == 7){
        this.investmentList(this.label[this.labelIdx].id)
      }
    },
    adasdasdasd(item,type){
      window.open(item.ziliao,"_blank");
    },
    advertisingSpace(localtion, type, cate_id){
      request.advertisingSpace(localtion, type, cate_id).then((res) => {
        if(type == 2){
          this.advertisingList = res.data.data;
        }else{
          this.advertisingDel = res.data.data[0];
        }
      })
    },
    advertisement(id){
      if(id.url != ''){
        window.open(id.url);
      }else{
        this.$router.push({ path:'/videoDetails',query:{ wzid:id.wzid }})
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.Advertising {
  display: block;
  min-width: 1200px;
  width: 73%;
  margin: 20px auto 0;
  background: burlywood;
}
.CreateNews {
  min-width: 1200px;
  width: 73%;
  margin: 0px auto;
  padding-top: 20px;
  background: #fff;
}
.CreateNews_in {
  width: 100%;
  margin: auto;
  display: flex;

  .CreateNews_left {
    width: 132px;
    margin-right: 20px;
    background: #f6f6f6;
    border-radius: 8px;
    font-size: 18px;
    line-height: 68px;
    color: #666666;
    text-align: center;
    padding: 24px 0;
    div {
      cursor: pointer;
    }
  }
  .CreateNews_right {
    width: calc(100% - 132px);
    .CreateNews_title {
      height: 62px;
      background: #2c7cd7;
      color: #fff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      h4 {
        font-weight: normal;
        font-size: 20px;
        padding-left: 48px;
    width: 128px;
      }
    }
  }
  .CreateNews_left_color {
    color: #2c7cd7;
  }
}
.CreateNews_right_in {
  width: calc(100% - 176px);
}
.CreateNews_right_in_div {
  width: 100%;
  display: inline-block;
  vertical-align: middle;
  div {
    display: flex;
    justify-content: space-around;
    font-size: 14px;
    line-height: 14px;
    div {
      cursor: pointer;
    }
    .CreateNews_right_in_div_color {
      font-size: 18px;
    }
  }
}
.table_name {
  width: calc(100% - 104px - 12px);
  display: inline-block;
  vertical-align: middle;
  margin-left: 12px;
}
.demo-form-inline {
  margin-top: 42px;
  margin-bottom: 30px;
}
.search {
  text-align: right;
  margin-bottom: 23px;
}
.CreateNews_title {
  height: 62px;
  background: #2c7cd7;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h4 {
    font-weight: normal;
    font-size: 20px;
    padding-left: 48px;
    width: 128px;
  }
}
.CreateNews_right_in {
  width: calc(100% - 176px);
  text-align: right;
}
.project{
      margin-top: 30px;
      font-size: 0;
      line-height: 0;
      .project_list{
        width: 100%;
        margin-bottom: 22px;
        display: inline-block;
        vertical-align: top;
        cursor: pointer;
        text-align: center;

        .team_img_div{
          position:relative;
          width:100%;
          height:0;
          padding-top:100%;
          img{
            width:100%;
            height:100%;
            top:0;
            left:0;
            position: absolute;
            border-radius: 100%;
            overflow: hidden;
          }
        }
        img{
          display: block;
          width: 100%;
        }
        h4{
          font-size: 20px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #111111;
          line-height: 28px;
          margin-top: 8px;
        }
        div{
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ACACAC;
          line-height: 20px;
          i{float: right;}
        }
      }
      .project_list:nth-child(4n){margin-right: 0;}
    }
    .ydd_label{
      display: none;
      height: 100px;
      overflow: hidden;
      div{
        div{
          width: 120px;
          text-align: center;
          line-height: 100px;
          font-size: 20px;
          display: inline-block;
              vertical-align: top;
        }
      }
    }
.ydd_CreateNews_right,.pagination12312{display: none;}
@media screen and (max-width:1200px) {
  .CreateNews_in{
    .CreateNews_left,.CreateNews_right_in,.title-label-input{display: none;}
    .ydd_label{display: block;}
  }
  .pagination12312{display: block !important;
    text-align: center;
        margin: 50px auto 0px;
        padding-bottom: 50px;
  }
  .pagination{display: none !important;}
  .CreateNews{
    padding: 0;
    min-width: auto;
    width: 90%;
    margin-top: 20px;
    .CreateNews_right{width: 100%;}
  }
  .Advertising{  width: 100%;}
  .CreateNews_title h4{
    padding: 0 !important;
    text-align: center;
  }
  .ydd_CreateNews_right{
    display: initial;
    width: calc(100% - 128px);overflow: hidden;
    line-height: 62px;
    font-size: 18px;
    div{
      div{
        width: 80px;
        text-align: center;
      }
    }
  }
  .CreateNews_right_in_div_color {
    font-size: 20px;
    line-height: 58px;
  }
}
.ssadadasdw{
  height: 70px !important;
  vertical-align: middle;
}
::v-deep {
  .el-table__body,.el-table__header{width: auto !important;}
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background: #2c7cd7;
  }
  .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #2c7cd7;
  }
  .el-select .el-input.is-focus .el-input__inner,
  .el-select .el-input__inner:focus {
    border-color: #2c7cd7;
  }
  .el-select-dropdown__item.selected {
    color: #2c7cd7;
    font-weight: bold;
  }
  .el-button--primary:hover,
  .el-button--primary:focus,
  .el-button--primary {
    color: #ffffff;
    background: #66b1ff;
    border-color: #66b1ff;
  }
  .el-button--primary {
    color: #ffffff;
    background: #2c7cd7;
    border-color: #2c7cd7;
  }
  .pagination {
    text-align: center;
    display: block;
    margin: 50px auto 0px;
    padding-bottom: 50px;
  }
  .title-label-input {
    width: 160px;
    margin-right: 5%;
    .el-input__inner {
      border-radius: 28px;
      background: transparent;
    }
    .el-input__inner:focus {
      border-color: #fff;
    }
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background: #2c7cd7;
  }
  .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #2c7cd7;
  }
  .title-label-input {
    width: 160px;
    margin-right: 5%;
    .el-input__inner {
      border-radius: 28px;
      background: transparent;
    }
    .el-input__inner:focus {
      border-color: #fff;
    }
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background: #2c7cd7;
  }
  .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #2c7cd7;
  }
  .el-button--primary:hover,
  .el-button--primary:focus,
  .el-button--primary {
    color: #ffffff;
    background: #66b1ff;
    border-color: #66b1ff;
  }
  .el-button--primary,
  .el-button--primary.is-plain:hover,
  .el-button--primary.is-plain:focus {
    color: #ffffff;
    background: #2c7cd7;
    border-color: #2c7cd7;
  }
  .el-button--primary.is-plain {
    border-color: #2c7cd7;
    color: #2c7cd7;
  }
  .el-table th {
    background: #f0f7ff;
  }
  .pagination1{display: none;}
  @media screen and (max-width:1200px) {
    .el-table__body,.el-table__header{width: 800px !important;}
    .pagination{display: none !important;}
    .pagination1{display: block;}
    .pagination12312{
      // .el-pager{width: 70%;}
    }
    .el-col-6{width: 33.33333333%;}
  }
}
</style>

<template>
  <div>
    <div class="CreateNews_title" v-if="$route.path != '/Individual'">
      <h4>{{ users }}</h4>
      <div class="CreateNews_right_in">
        <div class="CreateNews_right_in_div">
          <div>
            <div>学习资料</div>
            <div>文档资料</div>
            <div>课程</div>
            <div>直播</div>
            <div>讲师</div>
            <div>视频学习</div>
            <div>现场培训</div>
            <div>备注</div>
            <div>备注</div>
            <div>备注</div>
            <div>备注</div>
            <div>备注</div>
          </div>
        </div>
      </div>
    </div>
    <el-table :data="tableData" style="width: 100%;margin-top: 20px;">
      <el-table-column prop="name" label="名称" width="340">
        <template slot-scope="scope">
          <img
            style="vertical-align: middle"
            src="../../../public/img/img7.png"
            alt=""
          />
          <p class="table_name">{{ scope.row.name }}</p>
        </template>
      </el-table-column>
      <el-table-column prop="text" label="作者" width="150"></el-table-column>
      <el-table-column prop="type" label="类别" width="150"></el-table-column>
      <el-table-column prop="num" label="页数" width="150"></el-table-column>
      <el-table-column prop="type1" label="类别" width="150"></el-table-column>
      <el-table-column label="操作" width="200">
        <template>
          <el-button style="width:100%" type="primary" plain size="mini">在线看</el-button>
          <el-row style="margin-top:8px;" :gutter="8">
            <el-col :span="12">
              <el-button style="width:100%" type="info" plain size="mini">下载</el-button>
            </el-col>
            <el-col :span="12">
              <el-button style="width:100%" type="info" plain size="mini">打印</el-button>
            </el-col>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      users:"",
    }
  },
  props:{
    users:{           //这个就是父组件中子标签自定义名字
      type:String,
      required:true
    }
  },
  methods: {
    handleClick(row) {
      console.log(row);
    },
  },
};
</script>

<style lang="scss" scoped>
.CreateNews_title {
  height: 62px;
  background: #2c7cd7;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h4 {
    font-weight: normal;
    font-size: 20px;
    padding: 0 0 0 48px;
    width: 176px;
  }
}
.CreateNews_right_in {
  width: calc(100% - 176px);
  text-align: right;
}
.CreateNews_right_in_div {
  width: 100%;
  display: inline-block;
  vertical-align: middle;
  div {
    display: flex;
    justify-content: space-around;
    font-size: 14px;
  }
}
.table_name {
  width: calc(100% - 45px - 12px);
  display: inline-block;
  vertical-align: middle;
  margin-left: 12px;
}

::v-deep {
  .title-label-input {
    width: 160px;
    margin-right: 5%;
    .el-input__inner {
      border-radius: 28px;
      background: transparent;
    }
    .el-input__inner:focus {
      border-color: #fff;
    }
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background: #2c7cd7;
  }
  .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #2c7cd7;
  }
  .el-button--primary:hover,
  .el-button--primary:focus,
  .el-button--primary {
    color: #ffffff;
    background: #66b1ff;
    border-color: #66b1ff;
  }
  .el-button--primary ,
  .el-button--primary.is-plain:hover, .el-button--primary.is-plain:focus{
    color: #ffffff;
    background: #2c7cd7;
    border-color: #2c7cd7;
  }
  .el-button--primary.is-plain {
    border-color: #2c7cd7;
    color: #2c7cd7;
  }
  .el-table th{
    background: #F0F7FF !important;
  }
  @media screen and (max-width:1200px) {
    .el-table__body{
      width: 100% !important;
    }
  }
}
</style>
<template>
  <div class="Industrial">
    <el-breadcrumb separator-class="el-icon-arrow-right" class="DetailsConsultation_breadcrumb">
      <el-breadcrumb-item :to="{ path: '/' }">
		  <i class="el-icon-s-home"></i>首页
	  </el-breadcrumb-item>
      <el-breadcrumb-item :replace="true" :to="{path:title.path}">{{title.val}}</el-breadcrumb-item>
      <el-breadcrumb-item>{{val.title}}</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="IndustrialGraphics">
      <div class="IndustrialGraphics_in">
        <div class="IndustrialGraphics_tit">
          <div class="IndustrialGraphics_img"><img src="../../public/img/img5.png" alt=""></div>
          <div class="IndustrialGraphics_taxt">
            <h4>{{val.title}}</h4>
            <p>作者：xxxxx</p>
            <p>章数：222</p>
            <p>字数共：2222</p>
            <div class="IndustrialGraphics_taxt_div">
              <span>扫码购买</span>
              <div>
                <img src="../../public/img/img6.png" alt="">
              </div>
            </div>
          </div>
        </div>
        <div class="Graphics_val" v-html="val.content"></div>
      </div>
    </div>
  </div>
</template>

<script>
import CommonUtils from "@/utils/CommonUtils";
import request from '@/api/request.js'
export default {
  data(){
    return{
      title:{
        path:"",
        val:""
      },
      val:{}
    }
  },
  mounted(){
    if(this.$route.query.type == 1){
      this.title = {
        path:'/ListIndustries?type='+this.$route.query.type,
        val:'找产业'
      }
    }else if(this.$route.query.type == 2){
      this.title = {
        path:'/ListIndustries?type='+this.$route.query.type,
        val:'找金能'
      }
    }
    this.industryDetails(this.$route.query.id)
  },
  methods:{
    industryDetails(id){
      request.industryDetails(id).then((res) => {
        this.val = res.data.data
      })
    },
    zzz(){
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
.DetailsConsultation_breadcrumb{
      line-height: 44px;
    }
    .Industrial{
      min-width: 1200px;
    width: 73%;
    margin:30px auto 0;
    }
  .IndustrialGraphics{

    background: #fff;
    overflow: hidden;
    .IndustrialGraphics_in{
      width: 90%;
      margin:50px auto 0;
      .IndustrialGraphics_tit{
        overflow: hidden;
        position: relative;
        margin-bottom: 30px;
      }
      .IndustrialGraphics_img{
        float: left;
        width: 350px;
        margin-right: 40px;
        img{
          display: block;
          width: 100%;
        }
      }
      .IndustrialGraphics_taxt{
        float: left;
        height: 100%;
        position: relative;
        h4{
          font-size: 30px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #000000;
          line-height: 42px;
          margin-bottom: 15%;
        }
        p{
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #000000;
          line-height: 25px;
        }
        .IndustrialGraphics_taxt_div{
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #F40000;
          line-height: 25px;
          margin-top: 50%;
          img{
            display: inline-block;
            vertical-align: top;
            width: 130px;
          }
        }
      }
    }
  }
.Graphics_val{
  font-size: 16px;
  line-height: 40px;
  padding-bottom: 30px;
  div{
    font-size: 20px;
    line-height: 60px;
  }
}
  @media screen and (max-width:1200px) {
    .Industrial{
      min-width: auto;
      width: 95%;
    }
    
  }
  @media screen and (max-width:650px){
    .IndustrialGraphics .IndustrialGraphics_in .IndustrialGraphics_img{
      width:calc(100% - 40px - 130px);
    }
  } 
</style>

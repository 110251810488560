<template>
  <div>
    <div class="top_type">
      <ul class="top_type_text">
        <li v-for="(a,i) in tyepList" :key="i">{{a}}</li>
      </ul>
      <div class="top_type_right">
        <img src="../../../public/NewImage/phone.png" alt="">400-999-9999
      </div>
    </div>
    <div class="home_logo">
      <div class="home_logo_in">
        <a href="/" style="float: left;height: 100%;"><img class="home_logo_img" :src="imgLogo" alt=""></a>
        <div class="home_logo_search">
           <el-input placeholder="请输入内容" v-model="input2" class="home_logo_search_input" @keyup.enter.native="globalSearch">
               <template slot="prepend">
                 <el-select v-model="select" slot="prepend" placeholder="请选择" style="width: 120px;">
                   <el-option v-for="item in typeClassification" :label="item.name" :value="item.id"></el-option>
                 </el-select>
               </template>
               <el-button slot="append" class="home_logo_search_button2" @click="globalSearch">搜索</el-button>
           </el-input>
          <button class="home_logo_search_button1" @click="Purchase()">找采购</button>
        </div>
        <div class="home_logo_right" @click="Login()">
          <img :src="userName.avatar" alt="">
          <span>{{userName.name}}</span>
          <el-button v-if="userName.name != '请登录'" class="home_logo_right_button" type="primary" plain @click="exitAccount()">退出</el-button>
        </div>
      </div>
    </div>
    <div v-if="indexShow" style="position: relative;" @mouseleave='typeShow = false'>
      <div class="home_type">
        <div class="home_type_in">
          <div class="home_type_inlet type_main" @mouseenter="typeShow = true"><span>全部分类</span></div>
          <div class="home_type_inlet type_child">
            <ul class="type_child_in">
              <li v-for="(i,d) in typeClassification" :key="d" :class="d == listIndex?'type_child_in_class':''" @click="listClick(i,d)">{{i.name}}</li>
            </ul>
          </div>
        </div>
      </div>
      <transition name="el-zoom-in-top">
        <div class="Special_subject" v-show="typeShow">
          <div @mouseleave='mouseleaveON()' style="display: inline-block;vertical-align: top;width: 100%;">
            <div class="Special_li">
              <div v-for="(a,q) in cplb" :key="q" @mouseenter="handleMouseover(q)" @click="childrenOn(a, 99)" :class="cplbIdx.idx == q?'Special_li_div_color':''">
                {{a.lable}}<i class="el-icon-arrow-right"></i>
              </div>
            </div>
            <div class="Special_swiper" style="position: relative;overflow: inherit;">
              <div class="Special_li_block" v-if="cplbIdx.type">
                <!-- <p>{{cplb[cplbIdx.idx].lable}} <i class="el-icon-arrow-right"></i></p> -->
                <el-row :gutter="20" v-if="cplb[cplbIdx.idx].children">
                  <el-col :span="12" v-for="(d,i) in cplb[cplbIdx.idx].children" :key="i">
                    <div class="Special_li_block_in">
                      <div @click="childrenOn(i, 99)">{{d.lable}} <i class="el-icon-arrow-right"></i></div>
                      <ul v-if="d.children">
                        <li v-for="(item, index) in d.children" :key="index" @click="childrenOn(i, index)">{{item.lable}}</li>
                      </ul>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
  import CommonUtils from "@/utils/CommonUtils";
  import request from '@/api/request.js'
  export default {
  data() {
    return {
      tyepList:['采购商','供应商','产业园区','创业人','投资人','专家人','采购人'],
      typeClassification:[],
      input2:"",
      userName:{avatar:'../../public/NewImage/personage_avatar.png',name:'请登录'},
      cplbIdx:{type:false,idx:0},
      typeShow:false,
      cplb:[],
      indexShow:true,
      imgLogo:'',
      typeaa:true,
      select:'',
      listIndex:99
    };
  },
  created() {
    if(window.localStorage.getItem('code')){
      this.userName = JSON.parse(window.localStorage.getItem('individual'))
    }
    if(this.$router.currentRoute.path == '/search'){
      this.select = this.$route.query.type
      this.input2 = this.$route.query.val
    }else{
      this.select = ''
      this.input2 = ''
      this.$EventBus.$on("clearSearch", () => {
        this.input2 = ''
      });
    }
    this.column(0);
    this.logoIMG();
  },
  methods: {
    logoIMG(){
      request.logoIMG().then((res) => {
        this.imgLogo = res.data.data;
      })
    },
    globalSearch() {
      this.typeaa = false;
      this.$router.push({ path:'/search',query:{type: this.select, val: this.input2}})
    },
    Login(){
      if(window.localStorage.getItem('code')){
        this.$router.push({ path:'/Individual'})
      }else{
        this.$router.push({ path:'/logo',query:{type:1}})
      }
    },
    Purchase(e){
      this.$router.push({
        path:'/purchaseList',
        query:{
          type:9,
          sublevel:0,
          typeId:170
        }
      })
    },
    column(idx){
      let louter = [
        {name:'找企业', louter:'/enterpriseList', type:3},
        {name:'找采购', louter:'/purchaseList', type:9},
        {name:'找活动', louter:'/SearchActivity', type:8},
        {name:'找园区', louter:'/FindListInformation', type:6},
        {name:'找资讯', louter:'/FindListInformation', type:0},
        {name:'找产业', louter:'/ListIndustries', type:1},
        {name:'找投资', louter:'/FindListInformation', type:4},
        {name:'找投行', louter:'/FindListInformation', type:5},
        {name:'找社群', louter:'/ListIndustries', type:7},
        {name:'找数智'},
      ];
      let arr = [];
      request.column(0).then((res) => {
        res.data.data.forEach((item, index) => {
            arr.push({...item,louter:louter[index].louter, type:louter[index].type})
        })
        this.typeClassification = arr;
        if(this.select == ''){
          this.select = arr[0].id
        }
        if(window.location.hash == '#/'){
          window.localStorage.removeItem('type');
          this.indexShow = false;
        }else{
          this.indexShow = true;
          setTimeout(() => {
            if(window.localStorage.getItem('type') == undefined){
              let num;
              res.data.data.forEach((item,index) => {
                if(item.louter == this.$route.path){
                  num = this.$route.query.type
                }
              })
              this.listIndex = num;
            }else{
              this.listIndex = window.localStorage.getItem('type');
            }
            if(window.localStorage.getItem('code') == undefined){this.individual = null}
          },200)
        }
        if(this.$route.query.type){
          let arr = this.typeClassification;
          arr.forEach((item, index) => {
            if(item.type == this.$route.query.type){
              item.class = '0';
            }
          })
          this.typeClassification = arr;
        }
        this.HomeIndexClick()
      })
    },
    HomeIndexClick(){
      if(window.location.hash == '#/'){
        window.localStorage.removeItem('type');
        this.indexShow = false;
      }else{
        this.indexShow = true;
      }
      request.HomeIndex().then((res) => {
        this.cplb = res.data.data.cplb;
      })
    },
    logoasdas(){window.localStorage.removeItem('type');      window.localStorage.removeItem('sublevel');},
    exitAccount(){
      this.logoasdas();
      window.localStorage.removeItem('code');
      window.localStorage.removeItem('individual');
      setTimeout(() => {
        this.$router.push({ path:'/logo',query:{type:1} })
      },100)
    },
    childrenOn(g, f){
      let val = {}
      // let val = [{idx:this.cplbIdx.idx, didx:g, qidx:f}]
      // window.localStorage.setItem('xuanz', JSON.stringify(val))
      if(f == 99){
        val = {type:3,sublevel:0,typeId:9}
      }else{
        val = {type:3,sublevel:0,typeId:9,checktab:f}
      }
      this.indexShow = true;
      this.$router.push({ path:'/enterpriseList',query:val})
    },
    handleMouseover(idx){
      this.cplbIdx.type = true;
      this.cplbIdx.idx = idx;
    },
    mouseleaveON(){ this.cplbIdx.type = false },
    listClick(item,e){
      this.listIndex = e;
      if(item.louter != undefined){
        this.$router.push({
            path:item.louter,
            query:{
              type:item.type,
              sublevel:0,
              typeId:item?item.id:0
            }
        });
        let id = 0
        if(item.child){
        }else{
          id = item.id
        }
        window.localStorage.setItem('type',e);
        window.localStorage.setItem('sublevel',id);
        this.show = true;
      }else{
        this.$router.push({
            path:'/',
        });
      }
    },
  },
  watch: {
    $route(to, from) {
      if(to.name == 'Home'){
        this.indexShow = false;
      }else{
        this.indexShow = true;
      }
    },
    immediate: true,
    deep: true
  },
  beforeDestroy() {
    this.$EventBus.$off("clearSearch");
  },
};
</script>
<style lang="scss" scoped>
.title{
  height: 100PX;
  line-height: 100PX;
  min-width: 1200PX;
  width: 82%;
  margin: auto;
  font-size: 0;
  div{
    display: inline-block;
    vertical-align: top;
    font-size: 16PX;
  }
  a,.logo{width: 107px;height: 100%;display: inline-block;vertical-align: middle;}
  .logo{object-fit: scale-down;}
  .title-list{
    box-sizing: border-box;
    width: 11%;
    cursor: pointer;text-align: center;
    position: relative;
    transition: all 0.6s;
    .title-list_ul{
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 999;
      border: 1px solid #2C7CD7;
      width: calc(100% - 2px);
      li{
        line-height: 35px;
        text-align: center;
        font-size: 16px;
        color: #2C7CD7;
        background-color: #fff;
        cursor: pointer;
      }
      li:hover{
        background-color: #2C7CD7;
        color: #fff;
      }
    }
  }
  .col-md{
    background: #2C7CD7;
    color: #fff;
  }
  .title-list:hover{
    background: #2C7CD7;
    color: #fff;
  }
  .title-label-coed-i{
    .title-label-coed{cursor: pointer;margin-left: 20px;}
  }
}
.Individual_center{
  position: relative;
  .Individual_exit{
    position: absolute;
    top: 90%;
    width: calc(100% + 20px);
    height: 36px;
    text-align: center;
    line-height: 36px;
    font-size: 16px;
    left: 0;
    border-left: 1px solid #2C7CD7;
    border-right: 1px solid #2C7CD7;
    border-bottom: 1px solid #2C7CD7;
    cursor: pointer;
  }
}
.top_type{
    min-width: 1200px;
    width: 74%;
    margin: auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 48px;
    .top_type_text{
      li{
        display: inline-block;
        padding: 0 14px;
        border-right: 1px solid #DCDCDC;
        height: 14px;
        line-height: 14px;
        vertical-align: middle;
        cursor: pointer;
        color: #999;
      }
      li:last-child{
        border: 0;
      }
      li:hover{color: #000;}
    }
    .top_type_right{
      position: absolute;
      top: 0;
      right: 0;
      font-size: 16px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #008DF0;
      img{
        vertical-align: middle;
        margin-right: 10px;
      }
    }
  }
.home_logo{
  background-color: #fff;
  .home_logo_in{
    min-width: 1200px;
    width: 74%;
    margin: auto;
    height: 100px;
    line-height: 100px;
    position: relative;
    .home_logo_img{height: 80%;vertical-align: middle;}
    .home_logo_right{
      float: right;
      font-size: 16px;
      font-family: MicrosoftYaHei;
      color: #999999;
      position: relative;
      img{height: 30px;vertical-align: middle;margin-right: 10px;cursor: pointer;}
      span{cursor: pointer;}
      .home_logo_right_button{
        opacity: 0;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        margin: auto;
        z-index: -1;
        border-color: #0089EF;
        color: #0089EF;
        background-color: rgb(209, 235, 255);
        animation: all 10s;
        cursor: pointer;
        min-width: 70px;
      }
    }
    .home_logo_right:hover .home_logo_right_button{
      opacity: 1;
      z-index: 10;
    }
    .home_logo_search{
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 60%;
      .home_logo_search_input{
        width: calc(100% - 140px);
      }
      &_button2{
        margin: 0;
        height: 38px;
        background: #F5F7FA;
        border: 0;
        color: #909399;
        width: 120px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        cursor: pointer;
      }
      &_button1{
        display: inline-block;vertical-align: middle;
        height: 32px;
        width: 120px;
        border: 0;
        background: #EF5200;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        margin-left: 20px;
        margin-top: -2px;
        cursor: pointer;
      }
    }
  }
}
.home_type{
    height: 60px;
    line-height: 60px;
    background: #0089EF;
    .home_type_in{
      min-width: 1200px;
      width: 74%;
      margin: auto;
      .home_type_inlet{
        display: inline-block;
        vertical-align: top;
      }
      .type_main{
        width: 130px;
        background: #006CBD;
        margin-right: 14px;
        height: 60px;
        span{
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
          margin-left: 17px;
        }
      }
      .type_child{
        width: calc(100% - 14px - 130px);
        height: 60px;
        .type_child_in{
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          li{
            font-size: 20px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            text-align: center;
            cursor: pointer;
            padding: 0 20px;
          }
          li:hover{
            color: #45b0ff;
          }
        }
      }
    }
  }
.Special_subject{
    min-width: 1200px;
    width: 74%;
    margin: auto;
    font-size: 0;
    position: absolute;
    top: 60px;
    right: 0;
    left: 0;
    z-index: 10;
    .Special_li{
      display: inline-block;
      vertical-align: top;
      padding: 14px 0;
      background: #fff;
      width: 130px;
      margin-right: 14px;
      height: 484px;
      position: relative;
      div{
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #4A4A4A;
        line-height: 15px;
        text-align: center;
        padding: 11px 0;
        cursor: pointer;
      }
    }
    .Special_news{
      display: inline-block;
      vertical-align: top;
      width: 330px;
      .Special_news_top{
        height: 150px;
        background-color: #fff;
        border-radius: 8px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        div{position: relative;}
        div:hover>.exclusive{
          opacity: 1;
          z-index: 1000;
        }
        div{cursor: pointer;}
        .exclusive{
          opacity: 0;
          position: absolute;
          top: 100%;
          width: 200px;
          right: -60px;
          z-index: -1000;
          transition: all 0.5s;
        }
        h4{
          img{
            display: block;
            margin: auto;
            margin-bottom: 16px;
          }
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #0E0E0E;
          line-height: 20px;
        }
      }
      .Special_news_bottom{
        display: inline-block;
        vertical-align: top;
        margin-top: 12px;
        width: 288px;
        height: 350px;
        background: #FFFFFF;
        border-radius: 8px;
        padding: 0 21px;
        h3{
          font-size: 20px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #0089EF;
          line-height: 28px;
          padding: 8px 0;
          border-bottom: 1px solid #d2d2d2;
        }
        p{
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #4A4A4A;
          line-height: 18px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          margin-top: 11px;
          cursor: pointer;
        }
        p:hover{color: #0089EF;}
      }
    }
    .Special_swiper{
      display: inline-block;
      vertical-align: top;
      width: calc(100% - 130px - 10px - 14px);
      height: 510px;
      overflow: hidden;
      margin-right: 10px;
      position: relative;
      .Special_swiper_img{width: 100%;height: 100%;}
      .Special_swiper_big{
        width: 100%;
        height: 354px;
        overflow: hidden;
        position: relative;
      }
      .Special_swiper_mySwiper{
        width: 100%;
        height: 140px;
        overflow: hidden;
        margin-top: 16px;
        position: relative;
      }
    }
  }
.Special_li_block{
  position: absolute;
  top: 0;
  left: -14px;
  height: calc(100% - 40px);
  width: calc(100% + 14px - 40px);
  background-color: #fff;
  z-index: 11111;
  padding: 20px;
  overflow-y: auto;
  p{
    font-size: 16px;
    padding: 4px 10px;
    color: #fff;
    cursor: pointer;
    background-color: #006CBD;
  }
  .Special_li_block_in{
    margin-top: 10px;
    div{
      font-size: 14px;
      line-height: 20px;
      margin-top: 0;
      cursor: pointer;
      border-bottom: 1px solid #006CBD;
      color: #fff;
      background-color: #006CBD;
      padding: 4px 10px;
      margin-bottom: 12px;
    }
    // div:hover{color: #006CBD;background-color: #fff;}
    ul{
      display: inline-block;
      vertical-align: top;
      margin: 0 -10px;
      overflow: hidden;
      li{
        display: inline-block;
        vertical-align: top;
        margin: 6px 0;
        font-size: 14px;
        padding: 0 10px;
        height: 16px;
        line-height: 16px;
        white-space: nowrap;
        cursor: pointer;
      }
      li:hover{color: #006CBD;}
    }
  }
}
::v-deep {
  .title-label-input{
    width: 160px;
    margin-right: 6px;
    .el-input__inner{
      border-radius: 28px;
    }
  }
  @media screen and (max-width:1200px) {
    .el-collapse-item__header,.el-collapse-item__wrap{background-color: #000 !important;border:0;color: #fff;font-size: 24px;}
  }
  .el-input-group__append{
    padding: 0;
  }
}
.ydd_title,.ydd_title_top{display: none;}
.type_child_in_class{
  background-color: #006CBD;
  color: #fff;
}
.bar {
  position: relative;
  display: block;
  width: 50px;
  height: 5px;
  margin: 10px auto;
  background-color: #2C7CD7;
  border-radius: 10px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.menu-button:hover .bar:nth-of-type(1) {
  -webkit-transform: translateY(1.5px) rotate(-4.5deg);
  -ms-transform: translateY(1.5px) rotate(-4.5deg);
  transform: translateY(1.5px) rotate(-4.5deg);
}
.menu-button:hover .bar:nth-of-type(2) {
  opacity: 0.9;
}
.menu-button:hover .bar:nth-of-type(3) {
  -webkit-transform: translateY(-1.5px) rotate(4.5deg);
  -ms-transform: translateY(-1.5px) rotate(4.5deg);
  transform: translateY(-1.5px) rotate(4.5deg);
}
.cross .bar:nth-of-type(1) {
  -webkit-transform: translateY(15px) rotate(-45deg);
  -ms-transform: translateY(15px) rotate(-45deg);
  transform: translateY(15px) rotate(-45deg);
}
.cross .bar:nth-of-type(2) {
  opacity: 0;
}
.cross .bar:nth-of-type(3) {
  -webkit-transform: translateY(-15px) rotate(45deg);
  -ms-transform: translateY(-15px) rotate(45deg);
  transform: translateY(-15px) rotate(45deg);
}
.cross:hover .bar:nth-of-type(1) {
  -webkit-transform: translateY(13.5px) rotate(-40.5deg);
  -ms-transform: translateY(13.5px) rotate(-40.5deg);
  transform: translateY(13.5px) rotate(-40.5deg);
}
.cross:hover .bar:nth-of-type(2) {
  opacity: 0.1;
}
.cross:hover .bar:nth-of-type(3) {
  -webkit-transform: translateY(-13.5px) rotate(40.5deg);
  -ms-transform: translateY(-13.5px) rotate(40.5deg);
  transform: translateY(-13.5px) rotate(40.5deg);
}
@media screen and (max-width:1200px) {
  .title{
    display: none;
  }
  .ydd_title_top{display: block;}
  .ydd_title_right_div{
    float: left;
    font-size: 24px;
    padding: 0 10px;
    margin-right: 10px;
  }
  .ydd_title{
    display: block;
    width: 90%;
    padding: 0 5%;
    height: 100px;
    font-size: 0;
    line-height: 100px;
    position: fixed;
    z-index: 10001;
    background-color: #fff;
    top: 0;
    .title-label{
      .logo{
        display: inline-block;
        vertical-align: middle;
        width: 107px;
      }
    }
    .ydd_title_right{
      float: right;
      .menu-button{display: inline-block;vertical-align: middle;}
    }
    .ydd_basck{
      position: fixed;
      top: 100px;
      left: 0;
      width: 100%;
      height: 100%;
      background-color:rgba(0, 0, 0, 1) ;
      z-index: 1000;
      .ydd_basck_collapse{
        width: 90%;
        padding: 0 5%;
        border: 0;
        .ydd_basck_collapse_in{
          line-height: 40px;
          color: #fff;
          border-bottom: 1px solid #fff;
          padding-bottom: 10px;
        }
      }
    }
  }
}
</style>

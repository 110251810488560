import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
import FindListInformation from "@/views/FindListInformation.vue";
import DetailsConsultation from "@/views/DetailsConsultation.vue";
import DetailsConsultationN from "@/views/DetailsConsultationN.vue";
import ListIndustries from "@/views/ListIndustries.vue";
import IndustrialGraphics from "@/views/IndustrialGraphics.vue";
import collegeDetails from "@/views/collegeDetails.vue";
import enterpriseList from "@/views/enterpriseListNew.vue";
import InvestmentDetails from '@/views/InvestmentDetails.vue'
import communityDetails from '@/views/communityDetails.vue'
import SearchActivity from '@/views/SearchActivity.vue'
import logo from '@/views/Logo.vue'
import Individual from '@/views/Individual.vue'
import DetailsEnterprise from '@/views/DetailsEnterprise.vue'
import InvestmentBankDetails from '@/views/InvestmentBankDetails.vue'
import ggDetails from '@/views/ggDetails.vue'
import purchaseList from '@/views/purchaseList.vue'
import purchaseDetail from '@/views/purchaseDetail.vue'
import settlingProcess from '@/views/settlingProcess.vue'
import videoDetails from '@/views/videoDetails.vue'
import search from '@/views/search.vue'

import InvestmentDetailsNew from '@/views/InvestmentDetails_new.vue'
import InvestmentDetailsChange from '@/views/InvestmentDetails_change.vue'

Vue.use(VueRouter);

//保存下来push方法 将来我们还会用到原push方法进行路由跳转
let originPush=VueRouter.prototype.push;

//接下来我们重写push|replace方法
//第一个参数location参数像原push方法声明跳转的地方 resolve和reject传递成功与失败

VueRouter.prototype.push=function(location,resolve,reject){
   if(resolve && reject){
      //如果成功 调用原来的push方法
           originPush.call(this,location,resolve,reject);
               }else{
           originPush.call(this,location,()=>{},()=>{}); }
  }
const router = new VueRouter({
  // mode: "",
  routes: [
    {
      path: "/",
      name: "Home",
      component: Home,
      meta: { title: "车找采" },
    },
    {
      path: "/FindListInformation",
      name: "FindListInformation",
      component: FindListInformation,
      meta: { title: "车找采" },
    },
    {
      path: "/DetailsConsultation",
      name: "DetailsConsultation",
      component: DetailsConsultation,
      meta: { title: "车找采" },
    },
    {
      path: "/DetailsConsultationN",
      name: "DetailsConsultationN",
      component: DetailsConsultationN,
      meta: { title: "车找采" },
    },
    {
      path: "/ListIndustries",
      name: "ListIndustries",
      component: ListIndustries,
      meta: { title: "车找采" },
    },
    {
      path: "/IndustrialGraphics",
      name: "IndustrialGraphics",
      component: IndustrialGraphics,
      meta: { title: "车找采" },
    },
    {
      path: "/collegeDetails",
      name: "collegeDetails",
      component: collegeDetails,
      meta: { title: "车找采" },
    },
    {
      path: "/enterpriseList",
      name: "enterpriseList",
      component: enterpriseList,
      meta: { title: "车找采" },
    },
    {
      path: "/InvestmentDetailsNew",
      name: "InvestmentDetails",
      component: InvestmentDetailsNew,
      meta: { title: "车找采" },
    },
    {
      path: "/communityDetails",
      name: "communityDetails",
      component: communityDetails,
      meta: { title: "车找采" },
    },
    {
      path: "/SearchActivity",
      name: "SearchActivity",
      component: SearchActivity,
      meta: { title: "车找采" },
    },
    {
      path: "/logo",
      name: "logo",
      component: logo,
      meta: { title: "车找采" },
    },
    {
      path: "/Individual",
      name: "Individual",
      component: Individual,
      meta: { title: "车找采" },
    },
    {
      path: "/DetailsEnterprise",
      name: "DetailsEnterprise",
      component: DetailsEnterprise,
      meta: { title: "车找采" },
    },
    {
      path: "/InvestmentBankDetails",
      name: "InvestmentBankDetails",
      component: InvestmentBankDetails,
      meta: { title: "车找采" },
    },
    {
      path: "/ggDetails",
      name: "ggDetails",
      component: ggDetails,
      meta: { title: "车找采" },
    },
    {
      path: "/purchaseList",
      name: "purchaseList",
      component: purchaseList,
      meta: { title: "车找采" },
    },
    {
      path: "/purchaseDetail",
      name: "purchaseDetail",
      component: purchaseDetail,
      meta: { title: "车找采" },
    },
    {
      path: "/settlingProcess",
      name: "settlingProcess",
      component: settlingProcess,
      meta: { title: "车找采" },
    },
    {
      path: "/InvestmentDetailsChange",
      name: "InvestmentDetailsChange",
      component: InvestmentDetailsChange,
      meta: { title: "车找采" },
    },
    {
      path: "/videoDetails",
      name: "videoDetails",
      component: videoDetails,
      meta: { title: "车找采" },
    },
    {
      path: "/vueoffice",
      name: "vueoffice",
      component: () =>
        import('@/views/officeDOC.vue'),
      meta: { title: "车找采" },
    },
    {
      path: "/search",
      name: "search",
      component: search,
      meta: { title: "车找采" },
    }

  ]
});

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  window.pageYOffset = 0;
  next();
});

export default router;

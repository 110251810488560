<template>
  <div class="myPurchase">
    <el-tabs v-model="activeName" @tab-click="tabClick">
      <el-tab-pane label="已发布" name="0">
        <div class="institution_val">
          <el-table
            stripe
            :cell-style="tableStyle"
            :data="tableData"
            style="width: 100%">
            <el-table-column label="标题" width="150">
              <template slot-scope="scope">
                <div style="text-align: left;">{{scope.row.title}}</div>
              </template>
            </el-table-column>
            <el-table-column label="发布日期" prop="inputtimename"></el-table-column>
            <el-table-column label="地区">
              <template slot-scope="scope">
                <div class="FindIndustry_sapn">
                  <div class="institution_val_text">{{scope.row.diquname}}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="公司名称" prop="cplb"></el-table-column>
            <el-table-column label="姓名" prop="xingming"></el-table-column>
            <el-table-column label="邮箱" prop="youxiang"></el-table-column>
            <el-table-column label="手机号" prop="shoujihaoma"></el-table-column>
            <el-table-column label="详情" width="90">
              <template slot-scope="scope">
                <el-button class="institution_val_but" type="text" style="color: #0089EF;" @click="listClisk(scope.row)">详情</el-button>
                <el-button class="institution_val_but" type="text" style="color: #0089EF;" @click="getmycaigouinfo(scope.row)">查看供应商</el-button>
                <el-button class="institution_val_but" type="text" style="color: #6C6C6C;" @click="purchaseOffShelf(scope.row, scope.$index)">{{scope.row.xiajia == '1'?'上架':'下架'}}</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-tab-pane>
      <el-tab-pane label="已下架" name="1">
        <div class="institution_val">
          <el-table
            stripe
            :cell-style="tableStyle"
            :data="tableData"
            style="width: 100%">
            <el-table-column label="标题" width="150">
              <template slot-scope="scope">
                <div style="text-align: left;">{{scope.row.title}}</div>
              </template>
            </el-table-column>
            <el-table-column label="发布日期" prop="inputtimename"></el-table-column>
            <el-table-column label="地区">
              <template slot-scope="scope">
                <div class="FindIndustry_sapn">
                  <div class="institution_val_text">{{scope.row.diquname}}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="公司名称" prop="cplb"></el-table-column>
            <el-table-column label="姓名" prop="xingming"></el-table-column>
            <el-table-column label="邮箱" prop="youxiang"></el-table-column>
            <el-table-column label="手机号" prop="shoujihaoma"></el-table-column>
            <el-table-column label="详情" width="90">
              <template slot-scope="scope">
                <el-button class="institution_val_but" type="text" style="color: #0089EF;" @click="listClisk(scope.row)">查看</el-button>
                <el-button class="institution_val_but" type="text" style="color: #0089EF;" @click="getmycaigouinfo(scope.row)">查看供应商</el-button>
                <el-button class="institution_val_but" type="text" style="color: #6C6C6C;" @click="purchaseOffShelf(scope.row, scope.$index)">上架</el-button>
                <el-button class="institution_val_but" type="text" style="color: #6C6C6C;" @click="purchaseDeletion(scope.row, scope.$index)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-tab-pane>
      <el-tab-pane label="已过期" name="2">
        <div class="institution_val">
          <el-table
            stripe
            :cell-style="tableStyle"
            :data="tableData"
            style="width: 100%">
            <el-table-column label="标题" width="150">
              <template slot-scope="scope">
                <div style="text-align: left;">{{scope.row.title}}</div>
              </template>
            </el-table-column>
            <el-table-column label="发布日期" prop="inputtimename"></el-table-column>
            <el-table-column label="地区">
              <template slot-scope="scope">
                <div class="FindIndustry_sapn">
                  <div class="institution_val_text">{{scope.row.diquname}}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="公司名称" prop="cplb"></el-table-column>
            <el-table-column label="姓名" prop="xingming"></el-table-column>
            <el-table-column label="邮箱" prop="youxiang"></el-table-column>
            <el-table-column label="手机号" prop="shoujihaoma"></el-table-column>
            <el-table-column label="详情" width="90">
              <template slot-scope="scope">
                <el-button class="institution_val_but" type="text" style="color: #0089EF;" @click="listClisk(scope.row)">查看</el-button>
                <el-button class="institution_val_but" type="text" style="color: #0089EF;" @click="getmycaigouinfo(scope.row)">查看供应商</el-button>
                <el-button class="institution_val_but" type="text" style="color: #6C6C6C;" @click="purchaseOffShelf(scope.row, scope.$index)">{{scope.row.xiajia == '1'?'下架':'上架'}}</el-button>
                <el-button class="institution_val_but" type="text" style="color: #6C6C6C;" @click="purchaseDeletion(scope.row, scope.$index)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-tab-pane>
      <el-tab-pane label="我的收藏" name="3">
        <div class="myPurchase_list" v-for="(d, i) in CollectType" :key="i">
          <div class="item_list_inlink">
            <div class="item_list_tit">
              {{d.title}}
              <span>{{d.jjcdname}}</span>
            </div>
            <div class="item_list_text"><i></i>适用车型: {{d.sycxname}}</div>
            <div class="item_list_text"><i></i>认证要求: {{d.rzyqname}}</div>
            <div class="item_list_text"><i></i>采购量：{{d.shuliang}}</div>
          </div>
          <div class="item_list_right">
            <div class="item_list_r">
              <img :src="'https://admin.aiauto.shop'+d.touxiang" alt="">
              <p>{{d.xingming}}</p>
              <span>{{d.inputtimename}}</span>
            </div>
            <div class="item_list_l" @click="setmyCollect(d, i)">
              <img src="../../../public/NewImage/heart2.png" alt="">
              收藏</div>
          </div>
        </div>
        <!-- <div class="institution_val_pagination">
          <el-pagination
            background
            prev-text="上一页"
            next-text="下一页"
            layout="prev, pager, next"
            :total="1000">
          </el-pagination>
        </div> -->
      </el-tab-pane>
      <!-- <el-tab-pane label="采购消息" name="4">
        <div class="myPurchase_list myPurchase_message">
          <div class="item_list_inlink myPurchase_message1">
            <div class="item_list_tit">
              驻车手柄
              <span>紧急采购</span>
            </div>
            <div class="item_list_text"><i></i>适用车型: 不限</div>
            <div class="item_list_text"><i></i>适用车型: 不限</div>
            <div class="item_list_text"><i></i>适用车型: 不限</div>
          </div>
          <div class="myPurchase_message2">
            <div class="myPurchase_message2_in">
              <div class="messagedel1">
                <div>
                  <p>供应商信息</p>
                </div>
              </div>
              <div class="messagedel2">
                <el-row :gutter="20">
                  <el-col :span="12">
                    <div class="item_list_text"><i></i>适用车型: 不限</div>
                    <div class="item_list_text"><i></i>适用车型: 不限</div>
                    <div class="item_list_text"><i></i>适用车型: 不限</div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item_list_text"><i></i>适用车型: 不限</div>
                    <div class="item_list_text"><i></i>适用车型: 不限</div>
                    <div class="item_list_text"><i></i>适用车型: 不限</div>
                  </el-col>
                </el-row>
              </div>
              <div class="messagedel3">
                <div class="messagedel3_div">
                  <div>
                    <el-button class="institution_val_but" type="text" style="color: #0089EF;">查看</el-button>
                    <el-button class="institution_val_but" type="text" style="color: #6C6C6C;">下架</el-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane> -->
    </el-tabs>
    <div class="institution_val_pagination">
      <el-pagination
        background
        prev-text="上一页"
        next-text="下一页"
        layout="prev, pager, next"
        :current-page="purchase.publish.page"
        :page-size="10"
        @current-change="currentChange"
        :total="total">
      </el-pagination>
    </div>
    <button class="myPurchase_but" @click="dialogVisible = true">免费发布采购信息</button>

    <el-dialog
      title="报名"
      :visible.sync="dialogVisible"
      width="80%">
      <div class="publishMessage">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="94px" class="demo-ruleForm"  size="small">
          <el-row>
            <el-col :span="12">
              <el-form-item label="产品类别" prop="name">
                <!-- <el-input v-model="ruleForm.name"></el-input> -->
                <el-cascader
                style="width: 100%;"
                  placeholder="请选择产品类别"
                  v-model="value"
                  :options="options"
                  @change="handleChange">
                    <template slot="append">.com</template>
                  </el-cascader>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="截止时间">
                <el-date-picker
                style="width: 100%;"
                  v-model="ruleForm.region"
                  type="date"
                  placeholder="请选择">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="紧急程度" style="width: 100%;">
               <el-radio-group v-model="ruleForm.radio">
                   <el-radio :label="3">特别紧急</el-radio>
                   <el-radio :label="6">紧急</el-radio>
                   <el-radio :label="9">一般</el-radio>
                 </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="适用车型">
                <el-select v-model="ruleForm.vehicle" placeholder="请选择" style="width: 100%;">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="金额">
                <el-input v-model="input" placeholder="请输入内容" style="width: 100%;"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="数量">
                <el-input v-model="input" placeholder="请输入内容" style="width: 100%;"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="地址">
                <el-select v-model="ruleForm.vehicle" placeholder="请选择" style="width: 100%;">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <div class="publishMessage_border"></div>
            </el-col>
            <el-col :span="12">
              <el-form-item label="公司名称">
                <el-input v-model="input" placeholder="请输入" style="width: 100%;"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="姓名">
                <el-input v-model="input" placeholder="请输入" style="width: 100%;"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="手机号码">
                <el-input v-model="input" placeholder="请输入" style="width: 100%;"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="邮箱">
                <el-input v-model="input" placeholder="请输入" style="width: 100%;"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="备注">
                <el-input v-model="input" placeholder="请输入" style="width: 100%;" type="textarea" maxlength="30" show-word-limit></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <div style="text-align: center;">
                <el-button type="primary" size="small" class="publishMessage_but" style="background-color: #C7C7C7;">取消</el-button>
                <el-button type="primary" size="small" class="publishMessage_but" style="background-color: #C7C7C7;">保存</el-button>
                <el-button type="primary" size="small" class="publishMessage_but" style="margin-right: 0px;">发布</el-button>
              </div>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </el-dialog>

    <el-dialog
      title=""
      :visible.sync="caitable.type"
      width="80%">
      <el-table
        :data="caitable.list"
        stripe
        style="width: 100%">
        <el-table-column prop="companyname" label="公司名称" />
        <el-table-column prop="shouji" label="手机" />
        <el-table-column prop="xingming" label="姓名" />
        <el-table-column prop="youxiang" label="邮箱" />
        <el-table-column prop="zhuying" label="主营" />
        <el-table-column prop="zhengshu" label="证书" />
        <el-table-column label="地址" width="460">
          <template slot-scope="scope">
            <div>{{scope.row.dizhi}}</div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        style="text-align: center;padding-top: 20px;"
        prev-text="上一页"
        next-text="下一页"
        layout="prev, pager, next"
        :current-page="caitable.page"
        :page-size="10"
        @current-change="caiCurrentChange"
        :total="caitable.total">
      </el-pagination>
    </el-dialog>
  </div>
</template>
<script>
import request from '@/api/request.js';
export default {
  data() {
    return {
      tableData:[],
      activeName: 0,
      dialogVisible:false,
      ruleForm: {
        name: '',
        region: '',
        radio:3,
        vehicle:'',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入活动名称', trigger: 'blur' },
          { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        region: [
          { required: true, message: '请选择活动区域', trigger: 'change' }
        ],
        date1: [
          { type: 'date', required: true, message: '请选择日期', trigger: 'change' }
        ],
        date2: [
          { type: 'date', required: true, message: '请选择时间', trigger: 'change' }
        ],
        type: [
          { type: 'array', required: true, message: '请至少选择一个活动性质', trigger: 'change' }
        ],
        resource: [
          { required: true, message: '请选择活动资源', trigger: 'change' }
        ],
        desc: [
          { required: true, message: '请填写活动形式', trigger: 'blur' }
        ]
      },
      input:'',
      options:[],
      value:"",
      total:0,
      CollectType:[],
      caitable:{
        list:[],
        page:1,
        id: 0,
        total:0,
        type:false
      },
      purchase:{
        publish:{
          page:1,
          guoqi:'2',
          xiajia:'2'
        }
      }
    };
  },
  mounted() {
    this.MyHaveReleased()
  },
  methods: {
    tableStyle() { return "height:60px;"},
    MyHaveReleased(){
      let that = this;
      request.MyHaveReleased(that.purchase.publish).then((res) => {
        if(res.data.code == 1){
          this.tableData = res.data.data.list;
          this.total = parseInt(res.data.data.maxnum);
        }
      })
    },
    listClisk(item){
      this.$router.push({
        path:'/purchaseDetail',
        query:{...this.$route.query,id:item.id}
      })
    },
    purchaseOffShelf(d, i){
      request.purchaseOffShelf(d.id).then((res) => {
        this.MyHaveReleased()
        this.$message({
          message: res.data.msg,
          type: 'success'
        });
      })
    },
    purchaseDeletion(d,i){
      request.purchaseDeletion(d.id).then((res) => {
        this.$message({
          message: res.data.msg,
          type: 'success'
        });
      })
    },
    purchaseCollect(){
      request.purchaseCollect().then((res) => {
        if(res.data.code == 1){
          this.CollectType = res.data.data.list
        }
      })
    },
    getmycaigouinfo(data){
      this.caitable.type = true;
      this.caitable.id = data.id;
      request.getmycaigouinfo({page:this.caitable.page,id:this.caitable.id}).then((res) => {
        if(res.data.code == 1){
          this.caitable.list = res.data.data.list;
          this.caitable.total = parseInt(res.data.data.maxnum);
          this.caitable.page = 1;
        }
      })
    },
    setmyCollect(d, i){
      request.setmyCollect(d.id).then((res) => {
        this.purchaseCollect();
        this.$message({
          message: res.data.msg,
          type: 'success'
        });
      })
    },
    tabClick(){
      this.purchase.publish.page = 1
      if(this.activeName == '0'){
        this.purchase.guoqi = '2';
        this.purchase.xiajia = '2';
        this.MyHaveReleased()
      }else if(this.activeName == '1'){
        this.purchase.guoqi = '2';
        this.purchase.xiajia = '1';
        this.MyHaveReleased()
      }else if(this.activeName == '2'){
        this.purchase.guoqi = '1';
        this.purchase.xiajia = '0';
        this.MyHaveReleased()
      }else if(this.activeName == '3'){
        this.purchaseCollect()
      }
    },
    currentChange($event){
      this.purchase.publish.page = $event;
      if(this.activeName == '0'){
        this.purchase.guoqi = '2';
        this.purchase.xiajia = '2';
        this.MyHaveReleased()
      }else if(this.activeName == '1'){
        this.purchase.guoqi = '2';
        this.purchase.xiajia = '1';
        this.MyHaveReleased()
      }else if(this.activeName == '2'){
        this.purchase.guoqi = '1';
        this.purchase.xiajia = '0';
        this.MyHaveReleased()
      }else if(this.activeName == '3'){
        this.purchaseCollect()
      }
    },
    caiCurrentChange($event){
      this.caitable.page = $event;
      this.getmycaigouinfo()
    },
    handleChange(){}
  },
};
</script>
<style lang="scss" scoped>
  .myPurchase{
    padding: 10px 20px;
    position: relative;
    .myPurchase_but{
      position: absolute;
      top: 10px;
      right: 20px;
      width: 140px;
      height: 34px;
      background: #0089EF;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      border: 0;
      outline: none;
      cursor: pointer;
    }
    .institution_val{
      .institution_val_but{
        display: block;
        margin: auto;
        padding: 6px 0;
      }
    }
  }
  .myPurchase_list{
    background: #FFFFFF;
    border: 1px solid #0089EF;
    margin-top: 20px;
    padding: 18px;
    .item_list_inlink{
      display: inline-block;
      vertical-align: middle;
      .item_list_tit{
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #0089EF;
        line-height: 25px;
        span{
          margin-left: 35px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #EF0000;
          line-height: 20px;
        }
      }
      .item_list_text{
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #828282;
        line-height: 20px;
        i{
          display: inline-block;
          vertical-align: middle;
          width: 6px;
          height: 6px;
          background: #EEEEEE;
          border-radius: 50%;
          margin-right: 7px;
        }
      }
    }
    .item_list_right{
      float: right;
      // text-align: right;
      .item_list_r{
        display: inline-block;
        vertical-align: middle;
        img{
          width: 40px;
          height: 40px;
        }
        p{
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #828282;
          line-height: 20px;
          margin: 10px 0 10px;
        }
        span{
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #C3C3C3;
          line-height: 17px;
        }
      }
      .item_list_l{
        display: inline-block;
        vertical-align: middle;
        padding: 0 48px;
        cursor: pointer;
      }
    }
    .myPurchase_message1{
      width: 40%;
      border-right: 1px solid #E7E7E7;
    }
    .myPurchase_message2{
      width: calc(60% - 1px);
      display: inline-block;
      vertical-align: top;
      font-size: 0;
      line-height: 140px;
      .myPurchase_message2_in{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100%;
      }
      .messagedel1{
        width: 70px;
        height: 70px;
        background: rgba(0,137,239,0.2);
        margin: 0 46px;
        div{
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          p{
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #005492;
            width: 42px;
            line-height: 20px;
          }
        }
      }
      .messagedel2{
        flex: 1;
        .item_list_text{
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #828282;
          line-height: 20px;
          i{
            display: inline-block;
            vertical-align: middle;
            width: 6px;
            height: 6px;
            background: #EEEEEE;
            border-radius: 50%;
            margin-right: 7px;
          }
        }
      }
      .messagedel3{
        width: 58px;
        background: #F2F2F2;
        line-height: 20px;
        text-align: center;
        height: 140px;
        .messagedel3_div{
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          .institution_val_but{
            display: block;
            margin: auto;
            padding: 4px 0;
          }
        }

      }
    }
  }
  .myPurchase_message{
    line-height: 140px;
    padding: 0;
    padding-left: 18px;
  }
  .publishMessage{
    background: #FFFFFF;
    padding: 20px;
    .publishMessage_top{
      height: 60px;
      line-height: 60px;
      padding:0 20px;
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #050505;
      background-color: #f0f0f0;
      margin-bottom: 20px;
      span{
        font-size: 20px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #0089EF;
        margin-right: 30px;
      }
    }

    .publishMessage_but{
      width: 120px;
      display: inline-block;
      margin: auto;
      background-color: #0089EF;
      border-color: #0089EF;
      border: 0;
      margin-right: 30px;
    }
  }
  ::v-deep{
    .el-table th,.el-table td{text-align: center;}
    .el-table th{background-color: #F5F5F5 !important;}
    .institution_val_pagination{
      text-align: center;
      background-color: #fff;
      padding: 0 20px 30px;
      margin-top: 20px;
      .el-pagination{padding: 0;}
      .btn-next,.btn-prev{
        padding: 0 10px !important;
      }
      .el-pager li.active{background-color: #0089EF !important;}
    }
    .el-pagination.is-background .el-pager li:not(.disabled).active{
      background-color: #0089EF;
    }
    .el-pagination.is-background .el-pager li:not(.disabled):hover{color: #0089EF;}
  }
</style>

<template>
  <div class="Industrial">
    <el-breadcrumb separator-class="el-icon-arrow-right" class="DetailsConsultation_breadcrumb">
      <el-breadcrumb-item :to="{ path: '/' }">
        <i class="el-icon-s-home"></i>首页
      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: title.path }">{{ title.val }}</el-breadcrumb-item>
      <el-breadcrumb-item> {{ valDetails.title }}</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="Industrial_in" v-if='showLoading'>
      <div class="Industrial_name">
        <div>
          <img :src="valDetails.thumb" alt="" />
        </div>
        <div class="Industrial_name_right">
          <h4 style="margin: 5px 0;">{{ valDetails.title }}</h4>
          <div>
            <div>
              <p class="Industrial_name_right_text" style="width: 100%;"><span>{{ valDetails.fubiaoti }}</span></p>
            </div>
            <div>
              <p class="Industrial_name_right_text" style="width: 100%;"><span>专业领域：{{
                valDetails.zyly }}</span></p>
            </div>
            <div>
              <p class="Industrial_name_right_text" style="width: 100%;"><span>单位：{{
                valDetails.danwei }}</span></p>
            </div>
            <div>
              <p class="Industrial_name_right_text" style="width: 100%;"><span>区域：{{
                valDetails.quyu }}</span></p>
            </div>
            <div>
              <p class="Industrial_name_right_text" style="width: 100%;"><span>职称：{{
                valDetails.zhicheng }}</span></p>
            </div>
            <div>
              <p class="Industrial_name_right_text" style="width: 100%;"><span>职业信息：{{
                valDetails.zhiyexinxi }}</span></p>
            </div>
            <div>
              <p class="Industrial_name_right_text" style="width: 100%;"><span>投资领域：{{
                valDetails.touzilingyu }}</span></p>
            </div>
            <div>
              <p class="Industrial_name_right_text" style="width: 100%;" v-if="typeName != '专家人'">
                <span>当前任职：{{ valDetails.dqrz }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- <el-tabs v-model="activeName" @tab-click="handleClick" class="Industrial_label">
        <el-tab-pane v-for="(item, index) in label" :key="index" :label="item" :name="item"></el-tab-pane>
      </el-tabs> -->
      <!-- <div class="ydd_Industrial_name">
        <el-row :gutter="20">
          <el-col :span="8"><img class="ydd_Industrial_img" :src="valDetails.thumb" alt=""></el-col>
          <el-col :span="16">
            <h4 class="ydd_Industrial_h4">{{ valDetails.title }}</h4>
            <el-row>
              <el-col :span="24">
                <div class="ydd_Industrial_label_lab">{{ valDetails.fubiaoti }}</div>
              </el-col>
              <el-col :span="12">
                <div class="ydd_Industrial_label_lab">
                  <p>职业信息</p>{{ valDetails.zhiyexinxi }}
                </div>
              </el-col>
              <el-col :span="12">
                <div class="ydd_Industrial_label_lab">
                  <p>投资领域</p>{{ valDetails.touzilingyu }}
                </div>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </div> -->
      <div id="AnOverview">
        <h3 class="Industrial_title"><img src="../../public/img/img17.png" alt="" /><span>个人介绍</span></h3>
        <div class="AnOverview" v-html="valDetails.description"></div>
      </div>
      <div id="BusinessExpertise" v-if="typeName != '专家人'">
        <h3 class="Industrial_title"><img src="../../public/img/img17.png"
            alt="" /><span>业务专长</span></h3>
        <div class="style">
          <el-table stripe border :cell-style="tableStyle" :data="typeList.BusinessExpertise" style="width: 100%">
            <el-table-column :label="change[0].fields.title" prop="title"></el-table-column>
            <el-table-column :label="change[0].fields.description" prop="description"></el-table-column>
            <el-table-column label="状态" width="120">
              <template slot-scope="scope">
                <el-button type="text" @click="ccasdawz(scope.row)">查看</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination class="pagination" background :current-page="change[0].page" :page-size="change[0].pagesize"
            layout="total, sizes, prev, pager, next, jumper" @size-change="sizeChange($event, 0)"
            @current-change="currentChange($event, 0)" :total="change[0].total" prev-text="上一页" next-text="下一页">
          </el-pagination>
        </div>
      </div>
      <div id="PastExperience1">
        <h3 class="Industrial_title"><img src="../../public/img/img17.png"
            alt="" /><span>教育经历</span></h3>
        <div class="direction">
          <el-table stripe border :cell-style="tableStyle" :data="typeList.PastExperience1" style="width: 100%">
            <el-table-column :label="change[7].fields.xuexiao" prop="xuexiao"></el-table-column>
            <el-table-column :label="change[7].fields.xuewei" prop="xuewei"></el-table-column>
            <el-table-column :label="change[7].fields.title" prop="title" width="197"></el-table-column>
            <el-table-column label="状态" width="120">
              <template slot-scope="scope">
                <el-button type="text" @click="ccasdawz(scope.row)">查看</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination class="pagination" background :current-page="change[7].page" :page-size="change[7].pagesize"
            layout="total, sizes, prev, pager, next, jumper" @size-change="sizeChange($event, 7)"
            @current-change="currentChange($event, 7)" :total="change[7].total" prev-text="上一页" next-text="下一页">
          </el-pagination>
        </div>
      </div>
      <div id="PastExperience">
        <h3 class="Industrial_title"><img src="../../public/img/img17.png"
            alt="" /><span>过往经历</span></h3>
        <div class="direction">
          <el-table stripe border :cell-style="tableStyle" :data="typeList.PastExperience" style="width: 100%">
            <el-table-column :label="change[1].fields.title" prop="title"></el-table-column>
            <el-table-column :label="change[1].fields.author" prop="author" width="197"></el-table-column>
            <el-table-column :label="change[1].fields.create_time" prop="create_time"></el-table-column>
            <el-table-column :label="change[1].fields.zhuangtai" prop="zhuangtai" width="197"></el-table-column>
            <el-table-column label="状态" width="120">
              <template slot-scope="scope">
                <el-button type="text" @click="ccasdawz(scope.row)">查看</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination class="pagination" background :current-page="change[1].page" :page-size="change[1].pagesize"
            layout="total, sizes, prev, pager, next, jumper" @size-change="sizeChange($event, 1)"
            @current-change="currentChange($event, 1)" :total="change[1].total" prev-text="上一页" next-text="下一页">
          </el-pagination>
        </div>
      </div>
      <!-- <div id="PastPerformance" v-if="typeName != '专家人'">
        <h3 class="Industrial_title"><img src="../../public/img/img17.png" alt="" /><span>过往业绩</span></h3>
        <div class="direction">
          <el-table stripe border :cell-style="tableStyle" :data="typeList.PastPerformance" style="width: 100%">
            <el-table-column label="序号" width="78" type="index"></el-table-column>
            <el-table-column :label="change[2].fields.title" prop="title"></el-table-column>
            <el-table-column :label="change[2].fields.hits" prop="hits" width="80"></el-table-column>
            <el-table-column :label="change[2].fields.rongzijine" width="197" prop="rongzijine"></el-table-column>
            <el-table-column :label="change[2].fields.touzifang" width="197" prop="touzifang"></el-table-column>
            <el-table-column :label="change[2].fields.zongbishu" width="197" prop="zongbishu"></el-table-column>
            <el-table-column label="状态" width="120">
              <template slot-scope="scope">
                <el-button type="text" @click="ccasdawz(scope.row)">查看</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination class="pagination" background :current-page="change[2].page" :page-size="change[2].pagesize"
            layout="total, sizes, prev, pager, next, jumper" @size-change="sizeChange($event, 2)"
            @current-change="currentChange($event, 2)" :total="change[2].total" prev-text="上一页" next-text="下一页">
          </el-pagination>
        </div>
      </div> -->
      <!-- <div id="MainStyle" v-if="typeName != '专家人'">
        <h3 class="Industrial_title"><img src="../../public/img/img17.png" alt="" /><span>主要风格</span></h3>
        <div class="direction">
          <el-table stripe border :cell-style="tableStyle" :data="typeList.MainStyle" style="width: 100%">
            <el-table-column :label="change[3].fields.title" prop="title" width="295"></el-table-column>
            <el-table-column :label="change[3].fields.description" prop="description"></el-table-column>
            <el-table-column label="状态" width="120">
              <template slot-scope="scope">
                <el-button type="text" @click="ccasdawz(scope.row)">查看</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination class="pagination" background :current-page="change[3].page" :page-size="change[3].pagesize"
            layout="total, sizes, prev, pager, next, jumper" @size-change="sizeChange($event, 3)"
            @current-change="currentChange($event, 3)" :total="change[3].total" prev-text="上一页" next-text="下一页">
          </el-pagination>
        </div>
      </div> -->
      <div id="MainPerformance" v-if="typeName == '专家人'">
        <h3 class="Industrial_title"><img src="../../public/img/img17.png" alt="" /><span>科技成果</span></h3>
        <div class="direction">
          <el-table stripe border :cell-style="tableStyle" :data="typeList.newList" style="width: 100%">
            <el-table-column label="序号" width="78" type="index"></el-table-column>
            <el-table-column :label="change[8].fields.title" prop="title"></el-table-column>
            <el-table-column :label="change[8].fields.description" prop="description"></el-table-column>
            <el-table-column label="状态" width="120">
              <template slot-scope="scope">
                <el-button type="text" @click="ccasdawz(scope.row)">查看</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination class="pagination" background :current-page="change[8].page" :page-size="change[8].pagesize"
            layout="total, sizes, prev, pager, next, jumper" @size-change="sizeChange($event, 8)"
            @current-change="currentChange($event, 8)" :total="change[8].total" prev-text="上一页" next-text="下一页">
          </el-pagination>
        </div>
      </div>
      <div id="MainPerformance" v-if="typeName == '专家人'">
        <h3 class="Industrial_title"><img src="../../public/img/img17.png" alt="" /><span>相关专利</span></h3>
        <div class="direction">
          <el-table stripe border :cell-style="tableStyle" :data="typeList.newList1" style="width: 100%">
            <el-table-column label="序号" width="78" type="index"></el-table-column>
            <el-table-column :label="change[9].fields.title" prop="title"></el-table-column>
            <el-table-column :label="change[9].fields.keywords" prop="keywords"></el-table-column>
            <el-table-column :label="change[9].fields.description" prop="description"></el-table-column>
            <el-table-column label="状态" width="120">
              <template slot-scope="scope">
                <el-button type="text" @click="ccasdawz(scope.row)">查看</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination class="pagination" background :current-page="change[9].page" :page-size="change[9].pagesize"
            layout="total, sizes, prev, pager, next, jumper" @size-change="sizeChange($event, 9)"
            @current-change="currentChange($event, 9)" :total="change[9].total" prev-text="上一页" next-text="下一页">
          </el-pagination>
        </div>
      </div>
      <div id="MainPerformance" v-if="typeName == '专家人'">
        <h3 class="Industrial_title"><img src="../../public/img/img17.png" alt="" /><span>著作/论文</span></h3>
        <div class="direction">
          <el-table stripe border :cell-style="tableStyle" :data="typeList.newList2" style="width: 100%">
            <el-table-column label="序号" width="78" type="index"></el-table-column>
            <el-table-column :label="change[10].fields.title" prop="title"></el-table-column>
            <el-table-column :label="change[10].fields.keywords" prop="keywords"></el-table-column>
            <el-table-column :label="change[10].fields.description" prop="description"></el-table-column>
            <el-table-column label="状态" width="120">
              <template slot-scope="scope">
                <el-button type="text" @click="ccasdawz(scope.row)">查看</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination class="pagination" background :current-page="change[10].page" :page-size="change[10].pagesize"
            layout="total, sizes, prev, pager, next, jumper" @size-change="sizeChange($event, 10)"
            @current-change="currentChange($event, 10)" :total="change[10].total" prev-text="上一页" next-text="下一页">
          </el-pagination>
        </div>
      </div>
      <div id="MainPerformance" v-if="typeName == '专家人'">
        <h3 class="Industrial_title"><img src="../../public/img/img17.png" alt="" /><span>研究报告</span></h3>
        <div class="direction">
          <el-table stripe border :cell-style="tableStyle" :data="typeList.newList3" style="width: 100%">
            <el-table-column label="序号" width="78" type="index"></el-table-column>
            <el-table-column :label="change[11].fields.title" prop="title"></el-table-column>
            <el-table-column :label="change[11].fields.keywords" prop="keywords"></el-table-column>
            <el-table-column :label="change[11].fields.description" prop="description"></el-table-column>
            <el-table-column label="状态" width="120">
              <template slot-scope="scope">
                <el-button type="text" @click="ccasdawz(scope.row)">查看</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination class="pagination" background :current-page="change[11].page" :page-size="change[11].pagesize"
            layout="total, sizes, prev, pager, next, jumper" @size-change="sizeChange($event, 11)"
            @current-change="currentChange($event, 11)" :total="change[11].total" prev-text="上一页" next-text="下一页">
          </el-pagination>
        </div>
      </div>
      <div id="MainPerformance" v-if="typeName != '专家人'">
        <h3 class="Industrial_title"><img src="../../public/img/img17.png" alt="" /><span>主要业绩</span></h3>
        <div class="direction">
          <el-table stripe border :cell-style="tableStyle" :data="typeList.MainPerformance" style="width: 100%">
            <el-table-column label="序号" width="78" type="index"></el-table-column>
            <el-table-column :label="change[4].fields.title" prop="title"></el-table-column>
            <el-table-column :label="change[4].fields.hits" prop="hits" width="80"></el-table-column>
            <el-table-column :label="change[4].fields.rongzijine" prop="rongzijine"></el-table-column>
            <el-table-column :label="change[4].fields.touzifang" prop="touzifang"></el-table-column>
            <el-table-column :label="change[4].fields.zongbishu" prop="zongbishu"></el-table-column>
            <el-table-column label="状态" width="120">
              <template slot-scope="scope">
                <el-button type="text" @click="ccasdawz(scope.row)">查看</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination class="pagination" background :current-page="change[4].page" :page-size="change[4].pagesize"
            layout="total, sizes, prev, pager, next, jumper" @size-change="sizeChange($event, 4)"
            @current-change="currentChange($event, 4)" :total="change[4].total" prev-text="上一页" next-text="下一页">
          </el-pagination>
        </div>
      </div>
      <!-- <div id="project" v-if="typeName != '专家人'">
        <h3 class="Industrial_title"><img src="../../public/img/img17.png" alt="" /><span>项目</span></h3>
        <div class="direction">
          <el-table stripe border :cell-style="tableStyle" :data="typeList.project" style="width: 100%">
            <el-table-column label="序号" width="78" type="index"></el-table-column>
            <el-table-column :label="change[5].fields.title" prop="title" width="295"></el-table-column>
            <el-table-column :label="change[5].fields.description" prop="description" width="197"></el-table-column>
            <el-table-column :label="change[5].fields.cgslg" prop="cgslg"></el-table-column>
            <el-table-column label="状态" width="120">
              <template slot-scope="scope">
                <el-button type="text" @click="ccasdawz(scope.row)">查看</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination class="pagination" background :current-page="change[5].page" :page-size="change[5].pagesize"
            layout="total, sizes, prev, pager, next, jumper" @size-change="sizeChange($event, 5)"
            @current-change="currentChange($event, 5)" :total="change[5].total" prev-text="上一页" next-text="下一页">
          </el-pagination>
        </div>
      </div> -->
      <div id="project" v-if="typeName != '专家人'">
        <h3 class="Industrial_title"><img src="../../public/img/img17.png" alt="" /><span>成果产出</span></h3>
        <div class="AnOverview" v-html="valDetails.cgcc"></div>
      </div>
      <div id="InterpersonalRelationship">
        <h3 class="Industrial_title"><img src="../../public/img/img17.png" alt="" /><span>人脉关系</span></h3>
        <div class="project">
          <div class="project_list" v-for="(d, i) in typeList.InterpersonalRelationship" :key="i" @click="ccasdawz(d)">
            <img :src="d.thumb" alt="" />
            <h4>{{ d.title }}</h4>
            <div><i class="el-icon-arrow-right"></i>{{ d.description }}</div>
          </div>
        </div>
        <el-pagination class="pagination" background :current-page="change[6].page" :page-size="change[6].pagesize"
          layout="total, sizes, prev, pager, next, jumper" @size-change="sizeChange($event, 6)"
          @current-change="currentChange($event, 6)" :total="change[6].total" prev-text="上一页" next-text="下一页">
        </el-pagination>
      </div>
      <div id="way">
        <h3 class="Industrial_title"><img src="../../public/img/img17.png" alt="" /><span>联系方式</span></h3>
        <div class="way registered">
          <el-row>
            <!-- <el-col :span="24">
              <div class="registered_div">
                <div class="registered_left way_list_left"><div><p>公司名</p></div></div>
                <div class="registered_right way_list_right"><p>{{valDetails.title?valDetails.title:'无'}}</p></div>
              </div>
            </el-col> -->
            <el-col :span="24">
              <div class="registered_div">
                <div class="registered_left way_list_left">
                  <div>
                    <p>手机号</p>
                  </div>
                </div>
                <div class="registered_right way_list_right">
                  <p v-if="typeName!='专家人'">{{ valDetails.shoujihao ? valDetails.shoujihao : '无' }}</p>
                  <p v-else>***</p>
                </div>
              </div>
            </el-col>
            <el-col :span="24">
              <div class="registered_div">
                <div class="registered_left way_list_left">
                  <div>
                    <p>微信</p>
                  </div>
                </div>
                <div class="registered_right way_list_right">
                  <p v-if="typeName!='专家人'">{{ valDetails.weixin ? valDetails.weixin : '无' }}</p>
                  <p v-else>***</p>
                </div>
              </div>
            </el-col>
            <el-col :span="24">
              <div class="registered_div">
                <div class="registered_left way_list_left">
                  <div>
                    <p>邮箱</p>
                  </div>
                </div>
                <div class="registered_right way_list_right">
                  <p v-if="typeName!='专家人'">{{ valDetails.youxiang ? valDetails.youxiang : '无' }}</p>
                  <p v-else>***</p>
                </div>
              </div>
            </el-col>
            <el-col :span="24">
              <div class="registered_div">
                <div class="registered_left way_list_left">
                  <div>
                    <p>微博</p>
                  </div>
                </div>
                <div class="registered_right way_list_right">
                  <p v-if="typeName!='专家人'">{{ valDetails.weibo ? valDetails.weibo : '无' }}</p>
                  <p v-else>***</p>
                </div>
              </div>
            </el-col>
            <el-col :span="24">
              <div class="registered_div">
                <div class="registered_left way_list_left">
                  <div>
                    <p>地址</p>
                  </div>
                </div>
                <div class="registered_right way_list_right">
                  <p>{{ valDetails.dizhi ? valDetails.dizhi : '无' }}</p>
                </div>
              </div>
            </el-col>
            <el-col :span="24">
              <div class="registered_div">
                <div class="registered_left way_list_left">
                  <div>
                    <p>QQ</p>
                  </div>
                </div>
                <div class="registered_right way_list_right">
                  <p v-if="typeName!='专家人'">{{ valDetails.qq ? valDetails.qq : '无' }}</p>
                  <p v-else>***</p>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div v-else v-loading="!showLoading" element-loading-text="拼命加载中" style="height:67vh"></div>
  </div>
</template>

<script>
import request from '@/api/request.js'
import commonUtils from '@/utils/CommonUtils.js'
export default {
  data() {
    return {
      showLoading: false,
      activeName: '企业概况',
      label: ['企业概况', '工商注册', '股权结构', '主要团队', '主要产品', '专利技术', '融资状况', '经营状况', '法律', 'BP', '对外投资', '相关资讯', '配套合作'],
      tableData: [],
      directionData: [],
      performanceData: [],
      titIndex: 0,
      list: [],
      type: 0,
      title: { path: "", val: "" },
      valDetails: {},
      change: [
        { page: 1, pagesize: 10, total: 0 },
        { page: 1, pagesize: 10, total: 0 },
        { page: 1, pagesize: 10, total: 0 },
        { page: 1, pagesize: 10, total: 0 },
        { page: 1, pagesize: 10, total: 0 },
        { page: 1, pagesize: 10, total: 0 },
        { page: 1, pagesize: 10, total: 0 },
        { page: 1, pagesize: 10, total: 0 },
        { page: 1, pagesize: 10, total: 0 },
        { page: 1, pagesize: 10, total: 0 },
        { page: 1, pagesize: 10, total: 0 },
        { page: 1, pagesize: 10, total: 0 },
        { page: 1, pagesize: 10, total: 0 },
        { page: 1, pagesize: 10, total: 0 },
        { page: 1, pagesize: 10, total: 0 },
      ],
      typeList: {
        BusinessExpertise: [],
        PastExperience: [],
        PastPerformance: [],
        MainStyle: [],
        MainPerformance: [],
        project: [],
        InterpersonalRelationship: [],
        PastExperience1: [],
        newList: [],
        newList1: [],
        newList2: [],
        newList3: [],
      },
      typeName: ''
    }
  },
  mounted() {
    this.type = this.$route.query.type
    this.typeName = this.$route.query.typeName
    if (this.$route.query.type == 7) {
      this.title = {
        path: '/ListIndustries?type=' + this.$route.query.type,
        val: "找社群"
      };
      this.CommunityDetails(this.$route.query.id);
      this.CommunityDetailsType(this.$route.query.id, 'yewuzhuanc', 'ywzc', 0)
      this.CommunityDetailsType(this.$route.query.id, 'gwjl', 'gwjl', 1)
      // this.CommunityDetailsType(this.$route.query.id, 'gwyj', 'guowangyeji', 2)
      // this.CommunityDetailsType(this.$route.query.id, 'zyfg', 'zhuyaofengge', 3)
      this.CommunityDetailsType(this.$route.query.id, 'zyyj', 'zhuyaoyeji', 4)
      this.CommunityDetailsType(this.$route.query.id, 'xiangmu', 'xiangmu', 5)
      this.CommunityDetailsType(this.$route.query.id, 'zhaoshequn', 'renmaiguanxi', 6)

      this.CommunityDetailsType(this.$route.query.id, 'jiaoyujingli', 'jiaoyujingli', 7)
      if (this.typeName == '专家人') {
        this.CommunityDetailsType1(this.$route.query.id, 'kjcg', 'kejichengguo', 8)
        this.CommunityDetailsType1(this.$route.query.id, 'zhuanlijishu', 'xgzl', 9)
        this.CommunityDetailsType1(this.$route.query.id, 'xiangguanwenxian', 'xgwx', 10)
        this.CommunityDetailsType1(this.$route.query.id, 'yanjiubaogao', 'yanjiubaogao', 11)
      }
    }
    setTimeout(() => {
      this.showLoading = true
    }, 2000)
  },
  methods: {
    tableStyle() { return "height:80px;" },
    CommunityDetails(id) {
      request.CommunityDetails(id).then((res) => {
        if (res.data.data != null) {
          this.valDetails = res.data.data;
        }
      })
    },
    CommunityDetailsType1(id, table, field, type) {
      let pagesize = this.change[type].pagesize;
      let page = this.change[type].page;
      request.CommunityDetailsTyp2(id, table, field, pagesize, page).then((res) => {
        if (res.data.data != null) {
          if (type == 8) {
            this.typeList.newList = res.data.data.list
          }else if (type == 9) {
            this.typeList.newList1 = res.data.data.list
          }else if (type == 10) {
            this.typeList.newList2 = res.data.data.list
          }else if (type == 11) {
            this.typeList.newList3 = res.data.data.list
          }
          let newobj = this.change[type]
          newobj.total = res.data.data.total
          newobj.fields = res.data.data.fields
            this.$set(this.change,type,newobj)
        }
      })
    },
    CommunityDetailsType(id, table, field, type) {
      let pagesize = this.change[type].pagesize;
      let page = this.change[type].page;
      if (type == 7) {
        request.CommunityDetailsTyp1(id, table, field, pagesize, page).then((res) => {
          if (res.data.data != null) {
            this.typeList.PastExperience1 = res.data.data.list
            
          let newobj = this.change[type]
          newobj.total = res.data.data.total
          newobj.fields = res.data.data.fields
            this.$set(this.change,type,newobj)
          }
        })
      } else {
        request.CommunityDetailsType(id, table, field, pagesize, page).then((res) => {
          if (res.data.data != null) {
            if (type == 0) {
              this.typeList.BusinessExpertise = res.data.data.list
            } else if (type == 1) {
              this.typeList.PastExperience = res.data.data.list;
            } else if (type == 2) {
              this.typeList.PastPerformance = res.data.data.list;
            } else if (type == 3) {
              this.typeList.MainStyle = res.data.data.list;
            } else if (type == 4) {
              this.typeList.MainPerformance = res.data.data.list;
            } else if (type == 5) {
              this.typeList.project = res.data.data.list;
            } else if (type == 6) {
              this.typeList.InterpersonalRelationship = res.data.data.list;
            }
            
          let newobj = this.change[type]
          newobj.total = res.data.data.total
          newobj.fields = res.data.data.fields
            this.$set(this.change,type,newobj)
          }
        })
      }
    },
    sizeChange(event, type) {
      this.change[type].pagesize = $event;
      this.change[type].page = 1;
      if (type == 0) {
        this.CommunityDetailsType(this.$route.query.id, 'yewuzhuanc', 'ywzc', type)
      } else if (type == 1) {
        this.CommunityDetailsType(this.$route.query.id, 'gwjl', 'gwjl', type)
      } else if (type == 2) {
        this.CommunityDetailsType(this.$route.query.id, 'gwyj', 'guowangyeji', type)
      } else if (type == 3) {
        this.CommunityDetailsType(this.$route.query.id, 'zyfg', 'zhuyaofengge', type)
      } else if (type == 4) {
        this.CommunityDetailsType(this.$route.query.id, 'zyyj', 'zhuyaoyeji', type)
      } else if (type == 5) {
        this.CommunityDetailsType(this.$route.query.id, 'xiangmu', 'xiangmu', type)
      } else if (type == 6) {
        this.CommunityDetailsType(this.$route.query.id, 'zhaoshequn', 'renmaiguanxi', type)
      } else if (type == 7) {
        this.CommunityDetailsType(this.$route.query.id, 'jiaoyujingli', 'jiaoyujingli', type)
      } else if (type == 8) {
        this.CommunityDetailsType1(this.$route.query.id, 'kjcg', 'kejichengguo', type)
      } else if (type == 9) {
        this.CommunityDetailsType1(this.$route.query.id, 'zhuanlijishu', 'xgzl', type)
      } else if (type == 10) {
        this.CommunityDetailsType1(this.$route.query.id, 'xiangguangwenxian', 'xgwx', type)
      } else if (type == 11) {
        this.CommunityDetailsType1(this.$route.query.id, 'yanjiubaogao', 'yanjiubaogao', type)
      }
    },
    currentChange(event, type) {
      this.change[type].page = $event;
      if (type == 0) {
        this.CommunityDetailsType(this.$route.query.id, 'yewuzhuanc', 'ywzc', type)
      } else if (type == 1) {
        this.CommunityDetailsType(this.$route.query.id, 'gwjl', 'gwjl', type)
      } else if (type == 2) {
        this.CommunityDetailsType(this.$route.query.id, 'gwjl', 'guowangyeji', type)
      } else if (type == 3) {
        this.CommunityDetailsType(this.$route.query.id, 'zyfg', 'zhuyaofengge', type)
      } else if (type == 4) {
        this.CommunityDetailsType(this.$route.query.id, 'zyyj', 'zhuyaoyeji', type)
      } else if (type == 5) {
        this.CommunityDetailsType(this.$route.query.id, 'xiangmu', 'xiangmu', type)
      } else if (type == 6) {
        this.CommunityDetailsType(this.$route.query.id, 'zhaoshequn', 'renmaiguanxi', type)
      } else if (type == 7) {
        this.CommunityDetailsType(this.$route.query.id, 'jiaoyujingli', 'jiaoyujingli', 7)
      } else if (type == 8) {
        this.CommunityDetailsType1(this.$route.query.id, 'kjcg', 'kejichengguo', type)
      } else if (type == 9) {
        this.CommunityDetailsType1(this.$route.query.id, 'zhuanlijishu', 'xgzl', type)
      } else if (type == 10) {
        this.CommunityDetailsType1(this.$route.query.id, 'xiangguangwenxian', 'xgwx', type)
      } else if (type == 11) {
        this.CommunityDetailsType1(this.$route.query.id, 'yanjiubaogao', 'yanjiubaogao', type)
      }
    },
    ccasdawz(item) {
      this.$router.push({
        path: '/ggDetails',
        query: { ...this.$route.query, id: item.id }
      })
    },
    handleClick(event) {
      let ar = '';
      this.label.forEach(item => { if (item.label == event.name) { ar = item.name } })
      document.getElementById(ar).scrollIntoView({ behavior: "smooth" });
    },
  }
}
</script>
<style lang="scss">
.el-table{
  .el-table__cell{
    text-align: center !important;
  }
}
</style>
<style lang="scss" scoped>
.DetailsConsultation_breadcrumb {
  line-height: 44px;
}

.registered_right_img {
  font-size: 0;
  margin: 23px 0px 0px 26px;

  img {
    display: inline-block;
    vertical-align: top;
    width: 168px;
    height: 150px;
    margin: 0 20px 20px 0;
  }
}

.Industrial {
  min-width: 1200px;
  width: 73%;
  margin: 30px auto 0;
  overflow: hidden;
  background: #fff;

  #AnOverview,
  #registered,
  #style,
  #team,
  #way,
  #park {
    margin-bottom: 48px;
  }

  .Industrial_in {
    width: 90%;
    margin: 50px auto 0;
    overflow: hidden;

    .Industrial_name {
      display: flex;
      margin-bottom: 60px;

      .Industrial_name_h4 {
        font-size: 24px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #0F0F0F;
        line-height: 33px;
        margin-bottom: 20px;
      }

      img {
        display: block;
        width: 200px;
        // object-fit: scale-down;
        margin-right: 44px;
      }

      .Industrial_name_right {
        h4 {
          font-size: 24px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #0F0F0F;
          line-height: 33px;
          margin: 30px 0;
        }

        .Industrial_name_right_text {
          display: inline-block;
          vertical-align: top;
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #757575;
          line-height: 44px;
          width: 50%;

          span {
            color: #0F0F0F;
          }
        }
      }
    }

    .Industrial_title {
      font-size: 22px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #0F0F0F;
      line-height: 44px;

      img,
      span {
        vertical-align: middle;
        margin-right: 10px;
      }
    }

    .AnOverview {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #555555;
      line-height: 31px;
    }

    .registered {
      border: 1px solid #E3EDFB;
      border-left: 0;
      border-bottom: 0;

      .registered_div {
        display: flex;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #0F0F0F;
        border: 1px solid #E3EDFB;
        border-top: 0;
        border-right: 0;

        .registered_left {
          border-top: 0;
          background: #F6FAFF;
          text-align: center;
          width: 225px;
          position: relative;
          border-right: 1px solid #E3EDFB;

          div {
            width: 225px;

            p {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              margin: auto;
              height: 58px;
              line-height: 58px;
            }
          }
        }

        .registered_right {
          display: flex;
          line-height: 58px;

          align-items: center;

          p {
            margin-left: 30px;
            margin-right: 12px;
          }
        }

        .way_list_left {
          width: 339px;
        }

        .way_list_right {
          margin-left: 137px;
        }
      }
    }

    .style,
    .way {
      margin-top: 40px;
    }

    .project {
      margin-top: 30px;
      font-size: 0;
      line-height: 0;

      .project_list {
        width: calc(20% - 22px);
        margin-right: 27px;
        margin-bottom: 22px;
        display: inline-block;
        vertical-align: top;

        img {
          display: block;
          width: 100%;
        }

        h4 {
          font-size: 20px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #111111;
          line-height: 28px;
          margin-top: 8px;
        }

        div {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ACACAC;
          line-height: 20px;
          display: -webkit-box;
          line-height: 20px;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;

          i {
            float: right;
          }
        }
      }

      .project_list:nth-child(5n) {
        margin-right: 0;
      }
    }

    .consulting {
      font-size: 0;
    }

    .List_Information {
      display: inline-block;
      vertical-align: top;
      width: calc(50% - 27px);
      margin-right: 54px;
      height: 100px;
      margin-bottom: 20px;

      .List_Information_left {
        width: 200px;
        height: 100%;
        display: inline-block;
        vertical-align: top;

        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }

      .List_Information_right {
        display: inline-block;
        vertical-align: top;
        height: 100%;
        width: calc(100% - 12px - 200px);
        margin-left: 12px;
        position: relative;

        h6 {
          display: -webkit-box;
          font-size: 20px;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }

        p {
          position: absolute;
          left: 0;
          bottom: 0;
          font-size: 12px;
          color: #757575;
        }
      }
    }

    .List_Information:nth-child(2n) {
      margin-right: 0;
    }

    .financial {
      height: 236px;
      font-size: 0;
      margin-bottom: 30px;

      .financial_left {
        display: inline-block;
        vertical-align: top;

        img {
          display: block;
          width: 277px;
          height: 100%;
          object-fit: scale-down;
        }
      }

      .financial_right {
        display: inline-block;
        vertical-align: top;
        width: calc(100% - 315px);
        background: #FFFFFF;
        border: 1px solid #E9E9E9;
        padding-left: 36px;
        padding-bottom: 12px;

        h3 {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #605F5F;
          line-height: 53px;
        }

        .financial_text {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #605F5F;
          line-height: 24px;
        }
      }
    }
  }
}

.ydd_Industrial_name {
  // display: none;
}

@media screen and (max-width:1200px) {
  .Industrial {
    width: 90%;
    min-width: auto;

    .Industrial_in {
      .Industrial_title {
        text-align: center;
        line-height: 64px;
        font-size: 24px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #0F0F0F;

        img {
          display: none;
        }
      }
    }
  }

  .Industrial_name,
  .Industrial_label {
    display: none !important;
  }

  .ydd_Industrial_name {
    display: block;
    overflow: hidden;

    .ydd_Industrial_h4 {
      font-size: 30px;
      color: #0F0F0F;
      line-height: 42px;
    }

    .ydd_Industrial_img {
      object-fit: scale-down;
      width: 100%;
    }

    .ydd_Industrial_label_lab {
      border-bottom: 1px solid #D4D4D4;
      line-height: 40px;
      font-size: 20px;
      padding: 10px 0;

      p {
        color: #757575;
      }
    }
  }

  .project_list,
  .project_list:nth-child(5n) {
    width: calc(50% - 27px) !important;
    margin-right: 27px !important;

    div {
      font-size: 18px !important;
    }
  }

  .project_list:nth-child(2n) {
    margin-right: 0;
  }
}

::v-deep {

  .el-tabs__item:hover,
  .el-tabs__item.is-active {
    color: #2C7CD7;
  }

  .el-tabs__active-bar {
    background-color: #2C7CD7;
  }

  .el-table th {
    background: #F6FAFF;
  }

  .el-table td {
    text-align: center;
  }

  .pagination {
    text-align: center;
    display: block;
    margin: 50px auto 50px;
  }

  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background: #2C7CD7;
  }

  .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #2C7CD7;
  }
}</style>

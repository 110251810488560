<template>
  <div>
    <el-row :gutter="20" class="Graphiclist_but">
      <el-col :span="6" v-for="(d,num) in list" :key="num">
        <div class="Graphiclist" @click="zxczxc(d)">
          <div class="img">
            <img :src="d.thumb" alt="">
          </div>
          <h4>
            <div>{{d.title}}</div>
          </h4>
          <div>{{d.desc}}</div>
        </div>
      </el-col>
    </el-row>
    <!-- <a href="#"><img class="Advertising" src="../../../public/img/img27.png" alt=""></a> -->
  </div>
</template>

<script>
export default {
  data(){
    return{
      users:String,
      users:Array,
    }
  },
  props:{
    users:{           //这个就是父组件中子标签自定义名字
      type:String,
      required:true
    },
    list:{           //这个就是父组件中子标签自定义名字
      type:Array,
      required:true
    }
  },
  methods:{
    zxczxc(item){
      if (this.$route.query.type == 2) {
        this.$router.push({
          path:'/DetailsConsultation',
          query:{...this.$route.query,id:item.id}
        })
      }else if (this.$route.query.type == 7) {
        this.$router.push({
          path:'/communityDetails',
          query:{type:this.$route.query.type}
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .Graphiclist{
    margin-top: 20px;
    border-bottom: 1px solid #D7D7D7;
    cursor: pointer;
    .img{
      width: 100%;
      height: 144px;
      overflow: hidden;
      margin-bottom: 0;
      img{
        width: 100%;
        transition: all .5s ease .1s;
      }
    }
    .img:hover > img{
      transform: scale(1.3);
    }
    h4{
      height: 52px;
      display: flex;
      align-items: center;
      div{
        margin: 0;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #060606;
      }
    }
    div{
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #A8A8A8;
      line-height: 17px;
      margin: 0px auto 8px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      height: 34px;
      p{
        float: right;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #F10000;
        line-height: 22px;
        span{
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          line-height: 17px;
          font-size: 12px;
        }
      }
    }
  }
  .Advertising{
    display: block;
    width: 100%;
    height: 63px;
    margin: 20px auto 40px;
    background: burlywood;
  }
  @media screen and (max-width:1200px) {
    .Advertising{display: none;}
    .el-col-6{width: 50%;}
    .Graphiclist{
      .img{height: auto;}
      div{
        font-size: 16px;
      }
      h4 div{font-size: 20px;}
    }
  }
</style>

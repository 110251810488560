<template>
  <div class="Industrial">
    <div class="Industrial_in">
      <div id="swikperitem">
        <div class="swiper-wrapper">
            <div class="swiper-slide">
              <img src="../../public/img/img8.png" class="swikper-img" alt="">
            </div>
            <div class="swiper-slide">
              <img src="../../public/img/img8.png" class="swikper-img" alt="">
            </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
      <div class="activity_label">
        <div class="swiper-wrapper" style="width: 100%;">
              <div v-for="(item, index) in labelll" :key="index" :class="labelIndex == index?'activity_label_div':''" class="swiper-slide" @click="bvcx(index)">{{item.name}}</div>
        </div>
      </div>
      <div>
        <div class="activity_tit">
          <i></i>
          <p>{{labelll[labelIndex].name}}</p>
        </div>
        <div class="Search_label_on">
          <div class="swiper-wrapper" style="width: 100%;">
                <div v-for="(item, index) in Searchlabel" :key="index" :class="SearchlabelIndex == index?'Search_label_color':''" class="swiper-slide Search_label" @click="mnb(index,item)">{{item.name}}</div>
          </div>
        </div>
        <div style="margin-top: 58px;">
          <div v-if="label[labelIndex] && qufenType">
            <el-row :gutter="56" v-if="label[labelIndex].type == 1">
              <el-col :span="12" v-for="(i,num) in list" :key="num">
                <div class="Search_list_1" @click="azscasdfasd(i)">
                  <img class="Search_list_1_left" :src="i.thumb" alt="">
                  <div class="Search_list_1_right">
                    <h4>{{i.title}}</h4>
                    <div class="Search_list_1_text">
                      <i class="el-icon-map-location"></i>
                      <span>{{i.hdcs}}</span>
                      <i class="el-icon-time"></i>
                      <span>{{i.start_time}}</span>
                      <button>{{i.st}}</button>
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="42" v-if="label[labelIndex].type == 2">
              <el-col :span="8" v-for="(i,num) in list" :key="num">
                <div class="Search_list_2" @click="azscasdfasd(i)">
                  <img class="Search_list_2_img" :src="i.thumb" alt="">
                  <div class="Search_list_2_val">
                    <h4>{{i.title}}</h4>
                    <div class="Search_list_2_text">
                      <i class="el-icon-map-location"></i>
                      <span>{{i.hdcs}}</span>
                      <i class="el-icon-time"></i>
                      <span>{{i.start_time}}</span>
                      <button>{{i.st}}</button>
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="15" v-if="label[labelIndex].type == 0">
              <el-col :span="6" v-for="(i,num) in list" :key="num">
                <div class="Search_list" @click="azscasdfasd(i)">
                  <img :src="i.thumb" alt="" />
                  <div>
                    <p>{{i.title}}</p>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
          <div v-else>
            <el-row :gutter="15">
              <el-col :span="6" v-for="(i,num) in list" :key="num">
                <div class="Search_list" @click="azscasdfasd(i)">
                  <img :src="i.thumb" alt="" />
                  <div>
                    <p>{{i.title}}1</p>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
          <el-pagination
            class="pagination"
            background
            :current-page="page"
            :page-size="pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @size-change="sizeChange"
            @current-change="currentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CommonUtils from "@/utils/CommonUtils";
import 'swiper/css/swiper.css'  //引入swiper样式
import Swiper from 'swiper';	//引入swiper
import request from '@/api/request.js'
export default {
  data(){
    return{
      name:'',
      label:[],
      labelll:[],
      labelIndex:0,
      Searchlabel:[],
      SearchlabelIndex:0,
      list:[],
      pagesize:20,
      page:1,
      total:0,
      qufenType:false
    }
  },
  mounted(){
    this.label = CommonUtils.Searchlabel;
    setTimeout(() => {
      var mySwiper = new Swiper('.activity_label',{
        //配置分页器内容
        setWrapperSize :true,
        slidesPerView: 'auto',
        slideToClickedSlide: true,//设置为true则点击slide会过渡到这个slide。
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 0,
      })
      var mySwiper1 = new Swiper('.Search_label_on',{
        //配置分页器内容
        setWrapperSize :true,
        slidesPerView: 'auto',
        slideToClickedSlide: true,//设置为true则点击slide会过渡到这个slide。
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 0,
      })
      var mySwiper2 = new Swiper('#swikperitem',{
        setWrapperSize :true,
        loop: true, // 循环模式选项
        pagination:{
            el:".swiper-pagination",//换页器与哪个标签关联
            clickable:true//分页器是否可以点击
        },
        //如果需要滚动条
        scrollbar: {
            el: '.swiper-scrollbar',
        },
        autoplay:true,
      })
    },400)
    this.column(this.$route.query.typeId,0)
    this.advertisingSpace()
  },
  methods:{
    column(idx,i){
      request.column(idx).then((res) => {
        if(i == 0){
          this.labelll = res.data.data;
          if(this.$route.query.twotypeId){
            this.column(this.$route.query.twotypeId,1)
          }else{
            this.column(this.labelll[0].id,1)
          }
          res.data.data.forEach((item,index) => {
            if(item.id == this.$route.query.twotypeId){
              this.labelIndex = index;
            }
          })
          console.log('column', res.data.data, 'this.labelIndex',this.labelIndex)
        }else{
          this.Searchlabel = res.data.data;
          this.page = 1;
          this.pagesize = 20;
          if(this.Searchlabel.length > 0){
            this.activityList(this.Searchlabel[0].id)
          }else if(this.label[this.labelIdx]){
            this.activityList(this.label[this.labelIdx].id)
          }else{
            this.activityList(this.labelll
            [this.SearchlabelIndex].id)
          }
        }
      })
    },
    activityList(catid){
      request.activityList(catid,this.pagesize,this.page).then((res) => {
        if(res.data.data != null){
          this.list = res.data.data.list;
          this.total = res.data.data.total
        }else{
          this.list = [];
          this.total = 0;
        }
      })
    },
    bvcx(idx){
      if(this.labelll[idx].name == '走进主机厂'|| this.labelll[idx].name == '采供对接会'|| this.labelll[idx].name == '行业直播'){
        this.$message({
          message: '暂无功能',
          type: 'warning'
        });
      }else{
        this.qufenType = false;
        this.label.forEach(d => {
          if(d.name == this.labelll[idx].name){
            this.qufenType = true
            return true;
          }
        })
        this.labelIndex = idx;
        this.SearchlabelIndex = 0;
        this.column(this.labelll[idx].id,1)
      }
    },
    mnb(idx,item){
      this.SearchlabelIndex = idx;
      this.page = 1;
      this.activityList(item.id)
    },
    sizeChange($event){
      this.pagesize = $event;
      this.page = 1;
      if(this.Searchlabel.length > 0){
        this.activityList(this.Searchlabel[this.SearchlabelIndex].id)
      }else{
        this.activityList(this.labelll[this.labelIndex].id)
      }
    },
    currentChange($event){
      this.page = $event;
      if(this.Searchlabel.length > 0){
        this.activityList(this.Searchlabel[this.SearchlabelIndex].id)
      }else{
        this.activityList(this.labelll[this.labelIndex].id)
      }
    },
    advertisingSpace(){
      request.advertisingSpace(2,1,this.$route.query.typeId).then((res) => {
        console.log(res);
      })
    },
    azscasdfasd(id){
      this.$router.push({
        path:'/collegeDetails',
        query:{...this.$route.query,id:id.id}
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.Industrial {
  min-width: 1200px;
  width: 73%;
  margin: 30px auto 0;
  overflow: hidden;
  background: #fff;
  .Industrial_in{
    width: 90%;
    margin: 50px auto 0;
    overflow: hidden;
    .activity_label{
      height: 40px;
      margin: 70px 0;
      display: block;
      div{
        width: 120px;
        flex: none;
        line-height: 40px;
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 400;
        text-align: center;
        color: #000;
        border-right: 1px solid #C8C8C8;
        cursor: pointer;
      }
      div:last-child{border-right: 0;}
      .activity_label_div{color: #2C7CD7;}
    }
    .activity_tit{
      font-size: 0;
      line-height: 0;
      margin-bottom: 30px;
      background: rgba(44, 124, 215, .13);
      i{
        display: inline-block;
        vertical-align: middle;
        width: 8px;
        height: 30px;
        background: #2C7CD7;
        border-radius: 8px;
        // margin-right: 18px;
      }
      p{
        display: inline-block;
        vertical-align: middle;
        margin-right: 0px;
        padding: 0 18px;
        background-color: #fff;
        font-size: 26px;
        font-family: PingFangSC-Semibold, PingFang SC;
        color: #2C7CD7;
        line-height: 37px;
      }
      // div{
      //   display: inline-block;
      //   vertical-align: middle;
      //   width: calc(100% - 94px);
      //   height: 27px;
      //   background: #2C7CD7;
      //   opacity: 0.13;
      // }
    }
    .Search_label{
      width: 132px;
      height: 40px;
      border-radius: 18px;
      border: 1px solid #D2D2D2;
      line-height: 40px;
      text-align: center;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #202020;
      margin-right: 20px;
      display: inline-block;
      vertical-align: middle;
      cursor: pointer;
    }
    .Search_label_color{
      background: #2C7CD7;
      border-color: #2C7CD7;
      color: #fff;
    }
    .Search_list{
      border: 1px solid #E0E0E0;
      margin-bottom: 20px;
      cursor: pointer;

      img{
        display: block;
        width: 100%;
        height: 152px;
      }
      div{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 95px;
        p{
          width: calc(100% - 40px);
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #030303;
          line-height: 25px;
          display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
        }
      }
    }
    .Search_list_1{
      display: flex;
      border-bottom: 1px solid #EAEAEA;
      margin-bottom: 16px;
      cursor: pointer;
      .Search_list_1_left{
        display: block;
        width: 202px;
        height: 104px;
        margin-right: 18px;
      }
      .Search_list_1_right{
        height: 104px;
        position: relative;
        padding-bottom: 20px;
        h4{
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #1E1E1E;
          line-height: 22px;
        }
        .Search_list_1_text{
          position: absolute;
          left: 0;
          bottom: 20px;
          width: 100%;
          span,i{vertical-align: middle;}
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #787878;
          line-height: 20px;
          span{margin-right: 10px;}
          button{
            float: right;
            width: 73px;
            height: 24px;
            border-radius: 4px;
            border: 1px solid #FF0000;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FF0000;
            cursor: pointer;
          }
        }
      }
    }
    .Search_list_2{
      height: 277px;
      border: 1px solid #E0E0E0;
      margin-bottom: 30px;
      cursor: pointer;

      .Search_list_2_img{
        display: block;
        width: 100%;
        height: 185px;
      }
      .Search_list_2_val{
        padding: 0 16px;
        h4{
          line-height: 56px;
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #030303;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
        }
        .Search_list_2_text{
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #787878;
          line-height: 20px;
          i,span{vertical-align: middle;}
          span{margin-right: 10px;}
          button{
            float: right;
            width: 73px;
            height: 24px;
            border-radius: 4px;
            border: 1px solid #FF0000;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FF0000;
            cursor: pointer;
          }
        }
      }
    }
  }
}
#swikperitem{
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
}
.swikper-img{display: block;width: 100%;}
@media screen and (max-width:1200px) {
  .Industrial{
    padding: 0;
    min-width: auto;
    width: 90%;
    .Industrial_in{
      .activity_label{
        display: block;
        div{
          width: 120px;
          flex: none;
        }
      }
      .Search_label_on{
        display: block;
        border: 0;
      }
    }
  }
}
::v-deep {
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background: #2c7cd7;
  }
  .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #2c7cd7;
  }
  .el-select .el-input.is-focus .el-input__inner,
  .el-select .el-input__inner:focus {
    border-color: #2c7cd7;
  }
  .el-select-dropdown__item.selected {
    color: #2c7cd7;
    font-weight: bold;
  }
  .el-button--primary:hover,
  .el-button--primary:focus,
  .el-button--primary {
    color: #ffffff;
    background: #66b1ff;
    border-color: #66b1ff;
  }
  .el-button--primary {
    color: #ffffff;
    background: #2c7cd7;
    border-color: #2c7cd7;
  }
  .pagination {
    text-align: center;
    display: block;
    margin: 50px auto 50px;
  }
  @media screen and (max-width:1200px) {
    .el-col-6,.el-col-8{width: 50%;}
    .el-col-12,.Search_list_1{width: 100% !important;}
    .Search_list_1_right{flex: auto;}
    .Search_list_2{
      height: auto !important;
      .Search_list_2_val{
        overflow: hidden;
        padding-bottom: 10px !important;
      }
    }
  }
}
</style>

<template>
  <div class="Purchase_list">
    <div class="Purchase_list_left">
      <div class="publishMessage">
        <div class="publishMessage_top">
          <span>免费发布采购信息</span>
          <div style="display: inline-block;vertical-align: top;cursor: pointer;" @click="asdassd()">找项目</div>
        </div>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="94px" class="demo-ruleForm"  size="small">
          <el-row>
            <el-col :span="24">
              <el-form-item label="	采购标题" prop="title">
                <el-input v-model="ruleForm.title" placeholder="请输入内容" style="width: 100%;"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="产品类别" prop="cplb">
                <el-cascader
                  style="width: 100%;"
                  placeholder="请选择产品类别"
                  v-model="ruleForm.cplb"
                  :props="xzqhoption"
                  :options="cplbList"
                  @change="handleChange">
                    <template slot="append">.com</template>
                  </el-cascader>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="截止时间" prop="jzsj">
                <el-date-picker
                style="width: 100%;"
                  v-model="ruleForm.jzsj"
                  type="date"
                  placeholder="请选择">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="紧急程度" style="width: 100%;">
               <el-radio-group v-model="ruleForm.jinjichengdu">
                   <el-radio :label="3">特别紧急</el-radio>
                   <el-radio :label="2">紧急</el-radio>
                   <el-radio :label="1">一般</el-radio>
                 </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="适用车型" prop="sycx">
                <el-select v-model="ruleForm.sycx" placeholder="请选择" style="width: 100%;">
                  <el-option
                    v-for="item in chexing"
                    :key="item.value"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="金额" prop="jine">
                <el-input v-model="ruleForm.jine" placeholder="请输入内容" style="width: 100%;"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="数量" prop="shuliang">
                <el-input v-model="ruleForm.shuliang" placeholder="请输入内容" style="width: 100%;"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="地址" prop="diqu">
                <el-cascader
                  style="width: 100%;"
                  placeholder="请选择产品类别"
                  v-model="ruleForm.diqu"
                  :options="dizhi"
                  value="val"
                  label="type"
                  :props="defaultParams"
                  @change="handleChange">
                    <template slot="append">.com</template>
                  </el-cascader>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <div class="publishMessage_border"></div>
            </el-col>
            <el-col :span="12">
              <el-form-item label="公司名称" prop="gsmc">
                <el-input v-model="ruleForm.gsmc" placeholder="请输入" style="width: 100%;"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="姓名" prop="xingming">
                <el-input v-model="ruleForm.xingming" placeholder="请输入" style="width: 100%;"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="手机号码" prop="shoujihaoma">
                <el-input v-model="ruleForm.shoujihaoma" placeholder="请输入" style="width: 100%;" maxlength="11"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="邮箱" prop="youxiang">
                <el-input v-model="ruleForm.youxiang" placeholder="请输入" style="width: 100%;"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="备注" prop="content">
                <el-input v-model="ruleForm.content" placeholder="请输入" style="width: 100%;" type="textarea" maxlength="300" show-word-limit></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-button type="primary" size="small" class="publishMessage_but" @click="addcaigouSignUp()">发布</el-button>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="itemSearch" id="zxczxx">
        <div class="itemSearch_sarch">
          <p>搜项目</p>
          <input placeholder="按照产品类别搜索" v-model="keyword" @keyup.enter.native="search">
          <button @click="search()">搜索</button>
        </div>
        <div class="product_list">
          <div class="product_list_tit">热门产品：</div>
          <div class="product_list_in" style="padding-left: 0;">
            <p v-for="(a,i) in hotcplb" :key="i" v-if="i < 6" @click="horutProduct(a,i)">{{a.name}}</p>
          </div>
          <button @click="show2 = !show2">更多<i :class="!show2?'el-icon-arrow-down':'el-icon-arrow-up'"></i></button>
          <transition name="el-zoom-in-top">
            <div class="product_list_show" v-show="show2 && hotcplb.length > 6">
              <div class="product_list_in">
                <p v-for="(a,i) in hotcplb" :key="i" v-if="i+6 >= 6" @click="horutProduct(a,i+6)">{{a.mpflname}}</p>
              </div>
            </div>
          </transition>
        </div>
        <div class="publishMessage_border" style="width: 100%;"></div>
        <div class="itemType">
          <div class="itemType_top"><div class="tag_title"><i></i><span>项目分类</span></div></div>
          <div>您还可以选择：</div>
          <el-row>
            <el-col :span="6" v-for="(a,i) in cplbnum" :key="i">
              <p class="itemType_p" @click="product(a)" :class="a.name == cplb.name?'product_list_in_p':''">{{a.name}}（{{a.num}}）</p>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="itemSearch_backurd" v-if="cplb.length > 0">您已选择 >
        <span v-for="(d, i) in cplb" :key="i" @click="productA(d,i)">{{d.name}}（{{d.num}}）</span>
      </div>
      <div class="item_list">
        <div class="item_list_top">
          <p v-for="(d, i) in hotName" :class="hot == i?'item_list_top_p':''" @click="hotClock(i)">{{d}}</p>
        </div>
        <div class="item_list_in" v-for="(d,i) in purchaseList" :key="i" @click="listClisk(d)">
          <div class="item_list_inlink">
            <div class="item_list_tit">{{d.title}}
              <span>{{d.jjcdname}}</span>
            </div>
            <div class="item_list_text"><i></i>适用车型: {{d.sycxname}}</div>
            <div class="item_list_text"><i></i>认证要求: {{d.rzyqname}}</div>
            <div class="item_list_text"><i></i>采购量: {{d.shuliang}}</div>
          </div>
          <div class="item_list_right">
            <img :src="'https://admin.aiauto.shop/'+d.touxiang" alt="">
            <p>{{d.gsmc}}</p>
            <span>{{d.inputtimename}}</span>
          </div>
        </div>
      </div>
      <div class="Purchase_list_left_pagination" v-if="total > 0">
        <el-pagination
          background
          layout="prev, pager, next"
          :current-page="page"
          :page-size="pagesize"
          @current-change="currentChange"
          prev-text="上一页"
          next-text="下一页"
          :total="total">
        </el-pagination>
      </div>
    </div>
    <div class="Purchase_list_right">
      <div class="Purchase_list_right_in">
        <div class="Purchase_list_right_top">优质项目推荐</div>
        <div class="ProjectRecommendation" v-for="(item,idx) in highlist" :key="idx" @click="listClisk(item)">
          <div class="ProjectRecommendation_tit">{{item.title}}<span>{{item.jjcdname}}</span></div>
          <div class="item_list_text"><i></i>适用车型: {{item.sycxname}}</div>
          <div class="item_list_text"><i></i>认证要求: {{item.rzyqname}}</div>
          <div class="item_list_text"><i></i>采购量: {{item.company}}</div>
          <span style="display: block;">{{item.inputtimename}}</span>
        </div>
      </div>
      <!-- <div class="Purchase_list_right_in">
        <div class="Purchase_list_right_top">采购专场</div>
        <div class="PurchasingSession">
          <a href="#"><img src="../../public/img/img21.png" alt=""></a>
          <span> 冲压件采购项目汇总</span>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
import request from '@/api/request.js'
export default {
  data() {
    return {
      ruleForm: {
        title:'',
        cplb:'',
        jzsj:'',
        jinjichengdu:3,
        sycx:'',
        jine:'',
        shuliang:'',
        diqu:'',
        gsmc:'',
        xingming: '',
        shoujihaoma: '',
        youxiang: '',
        content: '',
      },
      rules: {
        title: [{ required: true, message: '请输入	采购标题', trigger: 'blur' }],
        cplb: [{ required: true, message: '请选择产品类别', trigger: 'blur' }],
        jzsj: [{ required: true, message: '请输入截止时间', trigger: 'blur' }],
        sycx: [{ required: true, message: '请输入适用车型', trigger: 'blur' }],
        jine: [
          { required: true, message: '请输入金额', trigger: 'blur' },
          { validator: (rule, value, callback) => {
            if (/^([0]|[1-9][0-9]*)$/.test(value) === false) {
                callback(new Error('请输入正确的数值'));
              } else {
                callback();
              }
          }, trigger: "blur" },
        ],
        shuliang: [{ required: true, message: '请输入数量', trigger: 'blur' },
          { validator: (rule, value, callback) => {
            if (/^([0]|[1-9][0-9]*)$/.test(value) === false) {
                callback(new Error('请输入正确的数值'));
              } else {
                callback();
              }
          }, trigger: "blur" },],
        diqu: [{ required: true, message: '请输入地址', trigger: 'blur' }],
        gsmc: [{ required: true, message: '请输入公司名称', trigger: 'blur' }],
        xingming: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        shoujihaoma: [
          {  required: true, min: 11, message: "手机号不足11位", trigger: "blur" },
          { validator: (rule, value, callback) => {
            if (!value) { return callback(new Error("手机号不能为空"))} else { const reg = /^1[3-9][0-9]\d{8}$/;
              if (reg.test(value)) {callback()} else {
                return callback(new Error("请输入正确的手机号"));
              }
            }
          }, trigger: "blur" },
        ],
        youxiang: [{ required: true, message: '请输入邮箱', trigger: 'blur' }],
      },
      show2:true,
      input:'',
      value:'',
      purchaseList:[],
      cplb:[],
      keyword:'',
      pagesize:10,
      page:1,
      total:0,
      chexing:[],
      cplbnum:[],
      hotcplb:[],
      cplbList:[],
      dizhi:[],
      highlist:[],
      hot:0,
      pcplb:'',
      hotName:['最新采购项目', '热门采购项目'],
      defaultParams: {
        label: "val",
        value: "type",
      },
      xzqhoption: {
        // checkStrictly: true,
        value: 'type',
        label: 'val',
      },
    }
  },
  mounted() {this.PurchaseChannelList()},
  methods:{
    hotClock(i){
      this.hot = i;
      this.PurchaseChannelList();
    },
    PurchaseChannelList(){
      request.PurchaseChannelList(this.page-1,this.cplb.cplb,this.keyword,this.hot,this.pcplb).then((res) => {
        if(res.data.code == 1){
          this.purchaseList = res.data.data.list;
          this.chexing = res.data.data.chexing;
          this.cplbnum = res.data.data.cplbnum;
          this.hotcplb = res.data.data.hotcplb;
          this.highlist = res.data.data.highlist;
          this.total = parseInt(res.data.data.maxnum);
          let cplb = [];
          if (res.data.data.cplb) {
            cplb = Object.values(res.data.data.cplb);
            for (var i = 0; i < cplb.length;i++) {
              if(cplb[i].children){
                cplb[i].children = Object.values(cplb[i].children);
                for (var a = 0; a < cplb[i].children.length;a++) {
                  if(cplb[i].children[a].children){
                    cplb[i].children[a].children = Object.values(cplb[i].children[a].children)
                  }
                }
              }
            }
          };
          this.cplbList = this.changeNodes(cplb);
          this.dizhi = this.changeNodes(res.data.data.dizhi);
        }
      })
    },
    search(){
      this.page = 1;
      this.PurchaseChannelList();
    },
    product(a){
      this.pcplb = a.id
      this.cplb.push(a);
      this.page = 1;
      this.PurchaseChannelList()
    },
    productA(d,i){
      let num = i-1
      if(i-1 == '-1'){
        num = null
      }
      if(num != null){
        this.pcplb = this.cplb[num].id;
        let arr = [];
        this.cplb.forEach((item, index) => {
          if(index < i){arr.push(item)}
        })
        this.cplb = arr;
      }else{
        this.pcplb = '';
        this.cplb = [];
      };
      this.page = 1;
      this.PurchaseChannelList()
    },
    listClisk(item){
      this.$router.push({
        path:'/purchaseDetail',
        query:{...this.$route.query,id:item.id}
      })
    },
    horutProduct(a,i){ this.keyword = a.mpflname },
    changeNodes (data) {
      if (data.length > 0) {
        for (var i = 0; i < data.length;i++) {
          data[i].val = data[i].lable == ''?undefined:data[i].lable;
          data[i].type = data[i].value == ''?undefined:data[i].value;
          if(data[i].children){
            for (var a = 0; a < data[i].children.length;a++) {
              data[i].children[a].val = data[i].children[a].lable == undefined?'':data[i].children[a].lable
              data[i].children[a].type = data[i].children[a].value == undefined?'':data[i].children[a].value
              if(data[i].children[a].children){
                for (var z = 0; z < data[i].children[a].children.length;z++) {
                  data[i].children[a].children[z].val = data[i].children[a].children[z].lable == undefined?'':data[i].children[a].children[z].lable
                  data[i].children[a].children[z].type = data[i].children[a].children[z].value == undefined?'':data[i].children[a].children[z].value
                }
              }
            }
          }
        }
      }
      return data;
    },
    addcaigouSignUp(){
      if(window.localStorage.getItem('code')){
        this.$refs['ruleForm'].validate((valid) => {
          if (valid) {
            let val = this.ruleForm;
            val.cplb = this.ruleForm.cplb[this.ruleForm.cplb.length - 1];
            val.diqu = this.ruleForm.diqu[this.ruleForm.diqu.length - 1];
            val.jzsj = new Date(this.ruleForm.jzsj).getTime();
            request.addcaigouSignUp(val).then((res) => {
              if(res.data.code == 1){
                this.$message({
                  message: res.data.msg,
                  type: 'success'
                });
                this.ruleForm = this.$options.data().ruleForm;
                this.hot = this.$options.data().hot;
                this.PurchaseChannelList()
              }else{
                that.$message.error(res.data.msg);
              }
            })
          }
        })
      }else{
        this.$message.error('请先登录');
      }

    },
    handleChange(){},
    currentChange($event){
      this.page = $event;
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
      this.PurchaseChannelList()
    },
    asdassd(){
      document.getElementById("zxczxx").scrollIntoView({
          behavior: 'smooth'
      });
    }
  }
}
</script>
<style lang="scss" scoped>
  .publishMessage_border{
    display: block;
    width: 90%;
    margin:0 auto 16px;
    border-bottom: 1px dashed #E9E9E9;
  }
  .tag_title{
    display: inline-block;
    vertical-align: middle;
    line-height: 50px;
    font-size: 0;
    span,i{
      display: inline-block;
      vertical-align: middle;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #4A4A4A;
    }
    i{
      width: 4px;
      height: 14px;
      background: #0089EF;
      border-radius: 4px;
      margin-right: 5px;
    }
  }
  .Purchase_list{
    min-width: 1200px;
    width: 74%;
    margin: auto;
    font-size: 0;
    .Purchase_list_left{
      display: inline-block;
      vertical-align: top;
      width: calc(100% - 20px - 340px);
      margin-right: 20px;
      .publishMessage{
        background: #FFFFFF;
        padding: 20px;
        .publishMessage_top{
          height: 60px;
          line-height: 60px;
          padding:0 20px;
          font-size: 20px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #050505;
          background-color: #f0f0f0;
          margin-bottom: 20px;
          span{
            font-size: 20px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #0089EF;
            margin-right: 30px;
          }
        }

        .publishMessage_but{
          width: 120px;
          display: block;
          margin: auto;
          background-color: #0089EF;
          border-color: #0089EF;
        }
      }
      .itemSearch{
        background-color: #fff;
        margin-top: 20px;
        padding-top: 20px;
        overflow: hidden;
        .itemSearch_sarch{
          width: 95%;
          border: 1px solid #0089EF;
          height: 46px;
          line-height: 46px;
          font-size: 0;
          margin: auto;
          p{
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #0089EF;
            padding: 0 16px;
            line-height: 24px;
            display: inline-block;
            vertical-align: middle;
            border-right: 1px solid #0089EF;
          }
          input{
            display: inline-block;
            vertical-align: top;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            width: 70%;
            border: 0;
            line-height: 46px;
            margin-left: 10px;
            outline: none;
          }
          button{
            width: 120px;
            line-height: 46px;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            background-color: #0089EF;
            border: 0;
            float: right;
            cursor: pointer;
          }
        }
        .product_list{
          width: 95%;
          margin: auto;
          font-size: 0;
          line-height: 48px;
          position: relative;
          .product_list_tit{
            display: inline-block;
            vertical-align: top;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #B8B8B8;
          }
          .product_list_in{
            display: inline-block;
            vertical-align: top;
            width: calc(100% - 70px - 40px);
            font-size: 0;
            padding-left: 20px;
            p{
              display: inline-block;
              vertical-align: top;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              margin-right: 20px;
              cursor: pointer;
            }
            .product_list_in_p{color: #0089EF;}
          }
          button{
            font-size: 12px;
            color: #0089EF;
            display: inline-block;
            vertical-align: top;
            border: 0;
            background-color: #fff;
            line-height: 48px;
            cursor: pointer;
          }
          .product_list_show{
            position: absolute;
            top: 48px;
            left: 0;
            width: 100%;
            background-color: #fff;
            box-shadow: 0 10px 10px 0 #F0F0F0;
            font-size: 0;
            z-index: 11;
            div{
              margin-left: 70px;
            }
          }
        }
        .itemType{
          width: 95%;
          margin: auto;
          font-size: 0;
          div{
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #6C6C6C;
            line-height: 20px;
            margin-bottom: 10px;
          }
          .itemType_p{
            display: inline-block;
            vertical-align: top;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #515151;
            line-height: 26px;
            cursor: pointer;
          }
          .product_list_in_p{color: #0089EF;}
        }
      }
      .itemSearch_backurd{
        height: 46px;
        background: #D8D8D8;
        line-height: 46px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #828282;
        padding: 0 20px;
        span{cursor: pointer;}
      }
      .item_list{
        padding: 20px;
        background-color: #fff;
        margin-bottom: 20px;
        .item_list_top{
          height: 60px;
          line-height: 60px;
          padding: 0 18px;
          background: #F0F0F0;
          font-size: 0;
          p{
            display: inline-block;
            vertical-align: top;
            font-size: 20px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #313131;
            margin-right: 40px;
            cursor: pointer;
          }
          .item_list_top_p{color: #0089EF;}
        }
        .item_list_in{
          font-size: 0;
          background: #FFFFFF;
          border: 1px solid #0089EF;
          padding: 20px;
          margin-top: 20px;
          .item_list_inlink{
            display: inline-block;
            vertical-align: top;
            .item_list_tit{
              font-size: 18px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #0089EF;
              line-height: 25px;
              span{
                margin-left: 35px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #EF0000;
                line-height: 20px;
              }
            }
            .item_list_text{
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #828282;
              line-height: 20px;
              i{
                display: inline-block;
                vertical-align: middle;
                width: 6px;
                height: 6px;
                background: #EEEEEE;
                border-radius: 50%;
                margin-right: 7px;
              }
            }
          }
          .item_list_right{
            float: right;
            text-align: right;
            img{
              width: 40px;
              height: 40px;
            }
            p{
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #828282;
              line-height: 20px;
              margin: 10px 0 10px;
            }
            span{
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #C3C3C3;
              line-height: 17px;
            }
          }
        }
      }
    }
    .Purchase_list_right{
      display: inline-block;
      vertical-align: top;
      font-size: 0;
      .Purchase_list_right_in{
        width: 300px;
        padding: 20px;
        background-color: #fff;
        margin-bottom: 20px;
        .Purchase_list_right_top{
          background-color: #F0F0F0;
          line-height: 60px;
          padding-left: 27px;
          font-size: 20px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #595959;
        }
        .ProjectRecommendation{
          background: #FFFFFF;
          border: 1px solid #0089EF;
          padding: 20px;
          margin-top: 20px;
          cursor: pointer;
          .ProjectRecommendation_tit{
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #0089EF;
            line-height: 25px;
            span{
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #EF0000;
              line-height: 20px;
              vertical-align: middle;
              margin-left: 25px;
            }
          }
          p{
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #828282;
            line-height: 20px;
            margin: 4px 0 ;
          }
          .ProjectRecommendation_text{
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #828282;
            line-height: 20px;
            i{
              display: inline-block;
              vertical-align: middle;
              width: 6px;
              height: 6px;
              background: #EEEEEE;
              border-radius: 50%;
              margin-right: 7px;
            }
          }
          span{
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #C3C3C3;
            line-height: 17px;
            margin-top: 8px;
          }
        }
        .PurchasingSession{
          a{
            display: block;
            margin-top: 20px;
            img{
              display: block;
              width: 100%;
            }
          }
          span{
            display: block;
            margin: auto;
            text-align: center;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #828282;
            line-height: 20px;
            margin-top: 10px;
          }
        }
      }
    }
  }
  ::v-deep{
    .Purchase_list_left_pagination{
      text-align: right;
      background-color: #fff;
      padding: 20px 30px;
      .el-pagination{padding: 0;}
      .btn-next,.btn-prev{
        padding: 0 10px !important;
      }
    }
    // .publishMessage{
    //   .el-cascader .el-input.is-focus .el-input__inner{
    //     border-color: #0089EF;
    //   }
    //   .el-cascader-node.in-active-path, .el-cascader-node.is-selectable.in-checked-path, .el-cascader-node.is-active,
    //   .el-radio__input.is-checked + .el-radio__label{
    //     color: #0089EF;
    //   }
    //   .el-radio__input.is-checked .el-radio__inner{
    //     background-color: #0089EF;
    //     border-color: #0089EF;
    //   }
    // }
    .item_list_text{
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #828282;
      line-height: 20px;
      i{
        display: inline-block;
        vertical-align: middle;
        width: 6px;
        height: 6px;
        background: #EEEEEE;
        border-radius: 50%;
        margin-right: 7px;
      }
    }
  }
</style>

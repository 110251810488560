<template>
  <div class="videoDetails">
    <el-button type="primary" class="videoDetails_but" size="small" @click="fanhui()">返回</el-button>
    <div class="consulting_text">
      <p v-html="val.content"></p>
    </div>
  </div>
</template>

<script>
  import request from '@/api/request.js'
  export default {
    data() {
      return{
        val:{}
      }
    },
    mounted() {
      this.informationDetails(this.$route.query.wzid)
    },
    methods:{
      fanhui(){
        // this.$router.push({ path:'/' })
        this.$router.go(-1);
      },
      informationDetails(id){
        request.informationDetails(id).then((res) => {
          this.val = res.data.data;
        })
      },
    }
  }
</script>

<style lang="scss" scoped>
  .videoDetails{
    min-width: calc(1200px - 40px);
    width: calc(74% - 40px);
    margin: auto;
    background: #FFFFFF;
    padding: 22px 20px;
    min-height: 65vh;
  }
  .consulting_text{
    p{
      font-size: 14px;
      font-weight: 400;
        color: #4E4E4E;
        line-height: 20px;
    }
  }
  .videoDetails_but,.videoDetails_but:hover{
    background: #2C7CD7;
  }
  .videoDetails_but{
    margin-bottom: 20px;
  }
</style>

<template>
    <div class="flot_div">
        <div class="button_div" @click="dialogVisibleGPT = true">
          <div>
            <img src="../../public/NewImage/backtop.png" alt="" srcset="">
            <span>车找采GPT</span>
          </div>
        </div>
        <el-dialog :visible.sync="dialogVisibleGPT" :modal="false" width="30%" class="chat_dialog" title="车找采GPT">
            <div class="chat_div">
                <div class="main_cont_div" ref="msgbox">
                    <div v-for="(item, i) in chatList" :key="i" class="message_div">
                        <div v-if="item.type == 'mine'" class="message_1">
                            <div class="message_cont">{{ item.content }}</div>
                            <el-avatar :size="50" :src="circleUrl"></el-avatar>
                        </div>
                        <div v-else class="message_2">
                            <el-avatar :size="50" :src="circleUrl1"></el-avatar>
                            <div class="message_cont">{{ item.content }}</div>
                        </div>
                    </div>
                </div>
                <div class="main_bot_div" v-loading="loading">
                    <el-input v-model="input" placeholder="请输入内容" @keyup.enter.native="sendMessage"></el-input>
                    <el-button type="primary" @click="sendMessage" style="margin-left: 10px;" icon="el-icon-position"
                        circle></el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import request from '@/api/request.js'
export default {
    data() {
        return {
            dialogVisibleGPT: false,
            input: '',
            chatList: [],
            loading: false,
            circleUrl: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
            circleUrl1: 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png'
        }
    },
    methods: {
        sendMessage() {
            if (this.input) {
                this.loading = true
                this.chatList.push({
                    type: 'mine', content: this.input
                })
                request.chatAi(this.input).then(res => {
                    this.chatList.push({
                        type: 'ai', content: JSON.parse(res.data.data)[1][0]
                    })

                    this.$nextTick(() => {
                        let scrollElem = this.$refs.msgbox;
                        scrollElem.scrollTo({
                            top: scrollElem.scrollHeight,
                            behavior: 'smooth'
                        });
                    });


                    this.input = ""
                    this.loading = false
                })
            } else {
                this.$message.error('请输入内容！');
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.flot_div {
    .button_div {
      // background: #2c7cd7;
      // color: #fff;
      cursor: pointer;
      height: 110px;
      width: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      div{
        width: 45px;
        img{
          display: block;
          width: 100%;
        }
        span{
          display: block;
          text-align: center;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #1971C2;
          line-height: 18px;
          margin-top: 6px;
        }
      }
    }

    .chat_div {
        .main_bot_div {
            display: flex;
            align-items: center;
        }

        .main_cont_div {
            height: 30vh;
            overflow-y: auto;
            margin-bottom: 15px;

            .message_div {
                width: 100%;
                margin-top: 10px;

                .message_1 {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    width: 100%;

                    .message_cont {
                        background: #fff;
                        padding: 10px;
                        border-radius: 10px;
                        margin-right: 6px;
                        max-width: 80%;
                    }
                }

                .message_2 {
                    display: flex;
                    align-items: center;
                    width: 100%;

                    .message_cont {
                        background: #fff;
                        padding: 10px;
                        border-radius: 10px;
                        margin-left: 6px;
                        max-width: 80%;
                    }
                }
            }
        }
    }

}
::v-deep{
  .chat_dialog {
      .el-dialog {
          background: #f5f5f6 !important;
      }
  }
  .v-modal{display: none;}
}
</style>

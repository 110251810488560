<template>
  <div class="DetailsConsultation">
    <el-breadcrumb separator-class="el-icon-arrow-right" class="DetailsConsultation_breadcrumb">
      <el-breadcrumb-item :to="{ path: '/' }">
      	<i class="el-icon-s-home"></i>首页
      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: label.path}">{{label.name}}</el-breadcrumb-item>
      <el-breadcrumb-item>{{val.title}}</el-breadcrumb-item>
    </el-breadcrumb>
    <div style="background:#fff;overflow: hidden;margin-bottom: 48px;">
      <div class="DetailsConsultation_text">
        <h4>{{val.title}}</h4>
        <p>{{val.description}}</p>
        <div class="consulting_text">
          <div>{{val.create_time}}</div>
          <p v-html="val.content"></p>
        </div>
        <!-- <div class="share">
          <el-button type="danger" plain round>分享到微博</el-button>
          <el-button type="success" plain round>分享到微信</el-button>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import CommonUtils from "@/utils/CommonUtils";
import request from '@/api/request.js'
export default {
  data() {
    return{
      label:{
        name:'',
        path:''
      },
      val:{}
    }
  },
  mounted() {
    let paramStr = CommonUtils.getParam(this.$route.query);
    if(this.$route.query.type == 0){
      this.label = {
        name:'找资讯',
        path:'/FindListInformation'+paramStr
      }
      this.informationDetails(this.$route.query.id)
    }else if(this.$route.query.type == 1){
      this.label = {
        name:'找产业',
        path:'/ListIndustries'+paramStr
      }
      console.log(this.$route.query.id)
      this.industryDetails(this.$route.query.id)
    }else if(this.$route.query.type == 2){
      this.label = {
        name:'找金能',
        path:'/FindListInformation'+paramStr
      }
      this.JinNengDetails()
    }else if(this.$route.query.type == 5){
      this.label = {
        name:'找投行',
        path:'/FindListInformation'+paramStr
      }
    }else if(this.$route.query.type == 6){
      this.label = {
        name:'找园区',
        path:'/FindListInformation'+paramStr
      }
    }
  },
  methods:{
    JinNengDetails(){
      request.JinNengDetails(this.$route.query.id).then((res) => {
        if(res.data.data != null){
          console.log('activityDetails',res.data.data)
          this.val = res.data.data;
        }else{
          this.val = {};
        }
      })
    },
    informationDetails(id){
      request.informationDetails(id).then((res) => {
        this.val = res.data.data
      })
    },
    industryDetails(id){
      request.industryDetails(id).then((res) => {
        console.log(123)
        this.val = res.data.data
      })
    },
  }
}
</script>

<style lang="scss" scoped>
  .DetailsConsultation{
    min-width: 1200px;
    width: 73%;
    margin:30px auto 0;
    min-height: 67vh;
    .DetailsConsultation_breadcrumb{
      line-height: 44px;
    }
    .DetailsConsultation_text{
      width: 90%;
      margin:50px auto 27px;
      h4{
        font-size: 22px;
        color: #141414;
        font-weight: normal;
        margin-bottom: 10px;
      }
      p{
        font-weight: 400;
        color: #828282;
        line-height: 20px;
        padding-bottom: 12px;
        border-bottom: 1px solid #EAEAEA;
      }
    }
    .consulting_text{
      div{
        font-weight: 400;
        color: #828282;
        line-height: 20px;
        margin: 23px auto 32px;
      }
      p{
        font-size: 14px;
        font-weight: 400;
          color: #4E4E4E;
          line-height: 20px;
      }
    }
  }
  .share{
    margin:26px auto 0;
    text-align: center;
  }
  @media screen and (max-width:1200px) {
    .DetailsConsultation{
      min-width: auto !important;
      width: 95% !important;
      .consulting_text,p{
        font-size: 16px !important;
      }
    }

  }
</style>

<template>
  <div class="Industrial">
    <el-breadcrumb separator-class="el-icon-arrow-right" class="DetailsConsultation_breadcrumb">
      <el-breadcrumb-item :to="{ path: '/' }">
		  <i class="el-icon-s-home"></i>首页
	  </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: title.path }">{{ title.val }}</el-breadcrumb-item>
      <el-breadcrumb-item>{{valDetails.title}}</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="Industrial_in">
      <div class="Industrial_name">
        <div>
          <h4 class="Industrial_name_h4"  v-if="type == 6">{{valDetails.title}}</h4>
          <img :src="valDetails.thumb" alt=""/>
        </div>
        <div class="Industrial_name_right">
          <h4 >{{valDetails.qymc?valDetails.qymc:'暂无'}}</h4>
          <div>
            <p class="Industrial_name_right_text">机构性质：<span>{{valDetails.jigouxingzhi}}</span></p>
            <p class="Industrial_name_right_text">注册地点：<span>{{valDetails.zhucedidian}}</span></p>
            <p class="Industrial_name_right_text">成立时间：<span>{{valDetails.chengliriqi?valDetails.chengliriqi:valDetails.clsj}}</span></p>
            <p class="Industrial_name_right_text">投资总部：<span>{{valDetails.fddbr}}</span></p>
            <p class="Industrial_name_right_text">官方网站：<span>{{valDetails.gfwz}}/</span></p>
            <p class="Industrial_name_right_text">投资阶段：<span>{{valDetails.touzijieduan}}</span></p>
          </div>
        </div>
      </div>
      <el-tabs v-model="activeName" @tab-click="handleClick" class="Industrial_label">
        <el-tab-pane v-for="(item, index) in label" :key="index" :label="item.label" :name="item.label"></el-tab-pane>
      </el-tabs>
      <div class="ydd_Industrial_name" style="display: none;">
        <el-row :gutter="20">
          <el-col :span="8"><img class="ydd_Industrial_img" src="../../public/img/img16.png" alt=""></el-col>
          <el-col :span="16">
            <h4 class="ydd_Industrial_h4" v-if="type != 6">宁德时代</h4>
            <h4 class="ydd_Industrial_h4" v-if="type == 6">{{valDetails.qymc?valDetails.qymc:'暂无'}}</h4>
            <el-row>
              <el-col :span="24"><div class="ydd_Industrial_label_lab"><p>机构性质</p>{{valDetails.jigouxingzhi}}</div></el-col>
              <el-col :span="24"><div class="ydd_Industrial_label_lab"><p>注册地点</p>{{valDetails.zhucedidian}}</div></el-col>
            </el-row>
            <el-row>
              <el-col :span="24"><div class="ydd_Industrial_label_lab"><p>成立时间</p>{{valDetails.chengliriqi?valDetails.chengliriqi:valDetails.clsj}}</div></el-col>
              <el-col :span="24"><div class="ydd_Industrial_label_lab"><p>投资总部</p>{{valDetails.fddbr}}</div></el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12"><div class="ydd_Industrial_label_lab"><p>官方网站</p>{{valDetails.gfwz}}</div></el-col>
          <el-col :span="12"><div class="ydd_Industrial_label_lab"><p>投资阶段</p>{{valDetails.touzijieduan}}</div></el-col>
        </el-row>
      </div>
      <div id="AnOverview" v-if="type != 6">
        <h3 class="Industrial_title"><img src="../../public/img/img17.png" alt=""/><span>企业概况</span></h3>
        <div class="AnOverview" v-html="valDetails.content"></div>
      </div>
      <div id="park"  v-if="type == 6">
        <h3 class="Industrial_title"><img src="../../public/img/img17.png" alt=""/><span>园区状况</span></h3>
        <div class="registered">
          <el-row>
            <el-col :span="24">
              <div class="registered_div">
                <div class="registered_left"><div><p>园区概况</p></div></div>
                <div class="registered_right"><p>{{valDetails.title?valDetails.title:'暂无'}}</p></div>
              </div>
            </el-col>
            <el-col :span="24">
              <div class="registered_div">
                <div class="registered_left"><div><p>园区图片</p></div></div>
                <div class="registered_right" style="min-height: 58px;">
                  <div class="registered_right_img">
                    <img v-for="(d,i) in valDetails.yuanqutupian" :src="d.url" alt="" />
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :span="24">
              <div class="registered_div">
                <div class="registered_left"><div><p>园区产业</p></div></div>
                <div class="registered_right"><p>{{valDetails.yuanquchanye == ''?'暂无':valDetails.yuanquchanye}}</p></div>
              </div>
            </el-col>
            <el-col :span="24">
              <div class="registered_div">
                <div class="registered_left"><div><p>经营范围</p></div></div>
                <div class="registered_right"><p>{{valDetails.jyfw?valDetails.jyfw:'暂无'}}</p></div>
              </div>
            </el-col>
            <el-col :span="24">
              <div class="registered_div">
                <div class="registered_left"><div><p>备注</p></div></div>
                <div class="registered_right"><p>备注</p></div>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="ydd_registered" style="display: none;">
          <el-row>
            <el-col :span="24" class="ydd_registered_label">
              <div>园区概况：</div><p>{{valDetails.title?valDetails.title:'暂无'}}</p>
            </el-col>
            <el-col :span="24" class="ydd_registered_label">
              <div>园区图片：</div>
              <div class="registered_right_img">
                <img v-for="(d,i) in valDetails.yuanqutupian" :src="d.url" />
              </div>
            </el-col>
            <el-col :span="24" class="ydd_registered_label">
              <div>园区产业：</div><p>{{valDetails.yuanquchanye == ''?'暂无':valDetails.yuanquchanye}}</p>
            </el-col>
            <el-col :span="24" class="ydd_registered_label">
              <div>经营范围：</div><p>{{valDetails.jyfw?valDetails.jyfw:'暂无'}}</p>
            </el-col>
            <el-col :span="12" class="ydd_registered_label">
              <div>备注：</div><p>备注</p>
            </el-col>
          </el-row>
        </div>
      </div>
      <div id="registered">
        <h3 class="Industrial_title"><img src="../../public/img/img17.png" alt=""/><span>工商注册</span></h3>
        <div class="registered">
          <el-row>
            <el-col :span="12">
              <div class="registered_div">
                <div class="registered_left"><div><p>企业名称</p></div></div>
                <div class="registered_right">
                  <p v-if="type == 6">{{valDetails.gongsiming?valDetails.gongsiming:'暂无'}}</p>
                  <p v-if="type != 6 && type != 3">{{valDetails.qymc?valDetails.qymc:'暂无'}}</p>
                  <p v-if="type == 3">{{valDetails.title?valDetails.title:'暂无'}}</p>
                </div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="registered_div">
                <div class="registered_left"><div><p>统一社会信用代码</p></div></div>
                <div class="registered_right"><p>{{valDetails.tyshxydm?valDetails.tyshxydm:'暂无'}}</p></div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="registered_div">
                <div class="registered_left"><div><p>注册资本</p></div></div>
                <div class="registered_right"><p>{{valDetails.zhuceziben?valDetails.zhuceziben:'暂无'}}元</p></div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="registered_div">
                <div class="registered_left"><div><p>成立日期</p></div></div>
                <div class="registered_right"><p>{{valDetails.clsj?valDetails.clsj:'暂无'}}</p></div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="registered_div">
                <div class="registered_left"><div><p>公司类型</p></div></div>
                <div class="registered_right"><p>{{valDetails.gslx?valDetails.gslx:'暂无'}}</p></div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="registered_div">
                <div class="registered_left"><div><p>法定代表人</p></div></div>
                <div class="registered_right"><p>{{valDetails.fddbr?valDetails.fddbr:'暂无'}}</p></div>
              </div>
            </el-col>
            <el-col :span="24">
              <div class="registered_div">
                <div class="registered_left"><div><p>经营状态</p></div></div>
                <div class="registered_right"><p>{{valDetails.jyzt?valDetails.jyzt:'暂无'}}</p></div>
              </div>
            </el-col>
            <el-col :span="24">
              <div class="registered_div">
                <div class="registered_left"><div><p>行业</p></div></div>
                <div class="registered_right"><p>{{valDetails.xingye?valDetails.xingye:'暂无'}}</p></div>
              </div>
            </el-col>
            <el-col :span="24">
              <div class="registered_div">
                <div class="registered_left"><div><p>经营范围</p></div></div>
                <div class="registered_right"><p>{{valDetails.jyfw?valDetails.jyfw:'暂无'}}</p></div>
              </div>
            </el-col>
            <el-col :span="24">
              <div class="registered_div">
                <div class="registered_left"><div><p>联系方式</p></div></div>
                <div class="registered_right">
                  <p v-if="type == 6">{{valDetails.dianhua?valDetails.dianhua:'暂无'}}</p>
                  <p v-if="type != 6">{{valDetails.lxfs?valDetails.lxfs:'暂无'}}</p>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="ydd_registered" style="display: none;">
          <el-row>
            <el-col :span="24" class="ydd_registered_label">
              <div>企业名称：</div><p v-if="type == 6">{{valDetails.gongsiming?valDetails.gongsiming:'暂无'}}</p><p v-if="type != 6">{{valDetails.qymc?valDetails.qymc:'暂无'}}</p>
            </el-col>
            <el-col :span="24" class="ydd_registered_label">
              <div>统一社会信用代码：</div><p>{{valDetails.tyshxydm?valDetails.tyshxydm:'暂无'}}</p>
            </el-col>
            <el-col :span="24" class="ydd_registered_label">
              <div>注册资本：</div><p>{{valDetails.zhuceziben?valDetails.zhuceziben:'暂无'}}元</p>
            </el-col>
            <el-col :span="12" class="ydd_registered_label">
              <div>成立日期：</div><p>{{valDetails.clsj?valDetails.clsj:'暂无'}}</p>
            </el-col>
            <el-col :span="12" class="ydd_registered_label">
              <div>公司类型：</div><p>{{valDetails.gslx?valDetails.gslx:'暂无'}}</p>
            </el-col>
            <el-col :span="24" class="ydd_registered_label">
              <div>法定代表人：</div><p>{{valDetails.fddbr?valDetails.fddbr:'暂无'}}</p>
            </el-col>
            <el-col :span="12" class="ydd_registered_label">
              <div>经营状态：</div><p>{{valDetails.jyzt?valDetails.jyzt:'暂无'}}</p>
            </el-col>
            <el-col :span="12" class="ydd_registered_label">
              <div>行业：</div><p>{{valDetails.xingye?valDetails.xingye:'暂无'}}</p>
            </el-col>
            <el-col :span="24" class="ydd_registered_label">
              <div>经营范围：</div><p>{{valDetails.jyfw?valDetails.jyfw:'暂无'}}</p>
            </el-col>
            <el-col :span="24" class="ydd_registered_label">
              <div>联系方式：</div><p v-if="type == 6">{{valDetails.dianhua?valDetails.dianhua:'暂无'}}</p><p v-if="type != 6">{{valDetails.lxfs?valDetails.lxfs:'暂无'}}</p>
            </el-col>
          </el-row>
        </div>
      </div>
      <div id="OwnershipStructure" v-if="type == 3">
        <h3 class="Industrial_title"><img src="../../public/img/img17.png" alt=""/><span>股权结构</span></h3>
        <div class="OwnershipStructure_val" style="height: 600px;">
          <!-- <div class="OwnershipStructure_val_but">
            <i></i>
            <el-button class="OwnershipStructure_val_but_el" type="primary">{{valDetails.title}}</el-button>
            <i></i>
          </div>
          <div class="OwnershipStructure_val_in" v-for="(zm, idx) in valDetails.guquanjiegou" :key="idx">
            <div>{{zm}} <span></span>
              <i :class="idx == 0?'div_i_top':''" v-if="idx == 0"></i>
              <i :class="idx == valDetails.guquanjiegou.length - 1?'div_i_bottom':''" v-if="idx != 0"></i></div>
              <p style="width: 346px;"></p>
            <div>
              <i :class="idx == 0?'div_i_top':''" v-if="idx == 0"></i>
              <i :class="idx == valDetails.guquanjiegou.length - 1?'div_i_bottom':''" v-if="idx != 0"></i>
              <span></span> {{zm}}</div>
          </div> -->
          <RelationGraph ref="seeksRelationGraph" :options="graphOptions"/>
        </div>
      </div>
      <div id="consulting" v-if="type === '6'">
        <h3 class="Industrial_title"><img src="../../public/img/img17.png" alt=""/><span>相关资讯</span></h3>
        <div class="consulting">
          <div class="List_Information" v-for="(item, index) in InformationList" :key="index" @click="zcasdasdas(item)">
            <div class="List_Information_left"><img :src="item.thumb" alt=""></div>
            <div class="List_Information_right">
              <h6>{{item.title}}</h6>
              <p>{{item.description}}</p>
            </div>
          </div>
          <el-pagination
            class="pagination"
            background
            :current-page="relatedInformation.page"
            :page-size="relatedInformation.pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="relateDsizeChange"
            prev-text="上一页"
            next-text="下一页"
            @current-change="relateDcurrentChange"
            :total="relatedInformation.total">
          </el-pagination>
        </div>
      </div>
      <div id="parkPolicy" v-if="type != 4 && type != 3">
        <h3 class="Industrial_title"><img src="../../public/img/img17.png" alt=""/><span>园区政策</span></h3>
        <div class="style condition">
          <el-table
            stripe
            border
            :cell-style="tableStyle"
            :data="parkPolicyData"
            style="width: 100%">
            <el-table-column
              label="序号" width="78" type="index"></el-table-column>
            <el-table-column
              label="股东名称"
              prop="title">
            </el-table-column>
            <el-table-column
              fixed="right"
              label="操作"
              width="100">
              <template slot-scope="scope">
                <el-button type="text" @click="ccasdawz(scope.row.id)">查看</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            class="pagination"
            background
            :current-page="relatedParkpolicy.page"
            :page-size="relatedParkpolicy.pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="ParkpolicyDsizeChange"
            @current-change="ParkpolicyDcurrentChange"
            prev-text="上一页"
            next-text="下一页"
            :total="relatedParkpolicy.total">
          </el-pagination>
        </div>
      </div>
      <div id="AttractInvestment" v-if="type != 4 && type != 3">
        <h3 class="Industrial_title"><img src="../../public/img/img17.png" alt=""/><span>招商引资</span></h3>
        <div class="style condition">
          <el-table
            stripe
            border
            :cell-style="tableStyle"
            :data="parkCapital"
            style="width: 100%">
            <el-table-column
              label="序号" width="78" type="index"></el-table-column>
            <el-table-column
              label="日期"
              prop="create_time"
              width="295">
            </el-table-column>
            <el-table-column
              label="标题"
              prop="title">
            </el-table-column>
            <el-table-column
              fixed="right"
              label="操作"
              width="100">
              <template slot-scope="scope">
                <el-button type="text" @click="ccasdawz(scope.row.id)">查看</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            class="pagination"
            background
            :current-page="relatedCapital.page"
            :page-size="relatedCapital.pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="CapitalDsizeChange"
            @current-change="CapitalDcurrentChange"
            prev-text="上一页"
            next-text="下一页"
            :total="relatedCapital.total">
          </el-pagination>
        </div>
      </div>
      <div id="style" v-if="type != 3">
        <h3 class="Industrial_title" v-if="type != '6'"><img src="../../public/img/img17.png" alt=""/><span>投资风格</span></h3>
        <h3 class="Industrial_title" v-if="type == '6'"><img src="../../public/img/img17.png" alt=""/><span>投融资服务</span></h3>
        <div class="style">
          <el-table
            stripe
            border
            :cell-style="tableStyle"
            :data="parkServiceList"
            style="width: 100%">
            <el-table-column
              label="序号" width="78" type="index"></el-table-column>
            <el-table-column
              label="名称"
              prop="title"
              width="295">
            </el-table-column>
            <el-table-column
              v-if="type != 4"
              label="时间"
              prop="create_time">
            </el-table-column>
            <el-table-column
              v-if="type == 4"
              label="股份类型"
              prop="gufenleixing"
              width="295">
            </el-table-column>
            <el-table-column
              v-if="type == 4"
              label="股份风格"
              prop="fengge">
            </el-table-column>
            <el-table-column
              fixed="right"
              label="操作"
              width="100">
              <template slot-scope="scope">
                <el-button type="text" @click="ccasdawz(scope.row.id)">查看</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            class="pagination"
            background
            :current-page="relatedService.page"
            :page-size="relatedService.pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="ServicesizeChange"
            @current-change="ServicecurrentChange"
            prev-text="上一页"
            next-text="下一页"
            :total="relatedService.total">
          </el-pagination>
        </div>
      </div>
      <div id="direction" v-if="type == '6' && type != 3">
        <h3 class="Industrial_title"><img src="../../public/img/img17.png" alt=""/><span>对外投资</span></h3>
        <div class="direction">
          <el-table
            stripe
            border
            :cell-style="tableStyle"
            :data="parkExternallyList"
            style="width: 100%">
            <el-table-column label="序号" width="78" type="index"></el-table-column>
            <el-table-column
              label="企业名称"
              prop="title"
              width="340">
            </el-table-column>
            <el-table-column
              label="成立日期"
              prop="create_time">
            </el-table-column>
            <el-table-column label="状态" width="120">
              <template slot-scope="scope">
                <el-button type="text" @click="ccasdawz(scope.row.id)">查看</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            class="pagination"
            background
            :current-page="relatedExternally.page"
            :page-size="relatedExternally.pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="relatedExternallysizeChange"
            @current-change="relatedExternallycurrentChange"
            prev-text="上一页"
            next-text="下一页"
            :total="relatedExternally.total">
          </el-pagination>
        </div>
      </div>
      <div id="direction" v-if="type != '6' && type != 3">
        <h3 class="Industrial_title"><img src="../../public/img/img17.png" alt=""/><span>投资方向/项目</span></h3>
        <div class="direction">
          <el-table
            stripe
            border
            :cell-style="tableStyle"
            :data="directionData"
            style="width: 100%">
            <el-table-column
              label="序号" width="78" type="index"></el-table-column>
            <el-table-column
              label="投资方向"
              prop="description"
              width="295">
            </el-table-column>
            <el-table-column
              label="投资比例"
              prop="status"
              width="197">
            </el-table-column>
            <el-table-column
              label="投资项目"
              prop="title">
            </el-table-column>
            <el-table-column label="状态" width="120">
              <template slot-scope="scope">
                <el-button type="text" @click="ccasdawz(scope.row.id)">查看</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            class="pagination"
            background
            :current-page="investment.page"
            :page-size="investment.pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="investmentsizeChange"
            @current-change="investmentcurrentChange"
            prev-text="上一页"
            next-text="下一页"
            :total="investment.total">
          </el-pagination>
        </div>
      </div>
      <div id="project" v-if="type != '6' && type != 3">
        <h3 class="Industrial_title"><img src="../../public/img/img17.png" alt=""/><span>投资项目</span></h3>
        <div class="project">
          <div class="project_list" v-for="(d,i) in investmentProject" :key="i">
            <img :src="d.thumb" alt="" />
            <h4>{{d.title}}</h4>
            <div><i class="el-icon-arrow-right"></i> {{d.gsmc}}</div>
          </div>
        </div>
        <el-pagination
          class="pagination"
          background
          :current-page="project.page"
          :page-size="project.pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="projectsizeChange"
          @current-change="projectcurrentChange"
          prev-text="上一页"
          next-text="下一页"
          :total="project.total">
        </el-pagination>
      </div>
      <div id="consulting" v-if="type != '6' && type != 3">
        <h3 class="Industrial_title"><img src="../../public/img/img17.png" alt=""/><span>相关资讯</span></h3>
        <div class="consulting">
          <div class="List_Information" v-for="(item, index) in correlationList" :key="index" @click="zcasdasdas(item)">
            <div class="List_Information_left"><img :src="item.thumb" alt=""></div>
            <div class="List_Information_right">
              <h6>{{item.tit}}</h6>
              <p>{{item.val}}</p>
            </div>
          </div>
          <el-pagination
              class="pagination"
              background
              :current-page="correlation.page"
              :page-size="correlation.pagesize"
              layout="total, sizes, prev, pager, next, jumper"
              @size-change="correlationsizeChange"
              @current-change="correlationcurrentChange"
              prev-text="上一页"
              next-text="下一页"
              :total="correlation.total">
          </el-pagination>
        </div>
      </div>
      <div id="team">
        <h3 class="Industrial_title"><img src="../../public/img/img17.png" alt=""/><span>主要团队</span></h3>
        <div class="project">
          <div class="project_list" v-for="(d,i) in PrimaryTeamList" :key="i" @click="ccasdawz(d)">
            <img :src="d.thumb" alt="" />
            <h4>{{d.title}}</h4>
            <div><i class="el-icon-arrow-right"></i> {{d.gsmc}}</div>
          </div>
        </div>
        <el-pagination
          class="pagination"
          background
          :current-page="PrimaryTeam.page"
          :page-size="PrimaryTeam.pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="PrimaryTeamSize"
          @current-change="PrimaryTeamChange"
          prev-text="上一页"
          next-text="下一页"
          :total="PrimaryTeam.total">
        </el-pagination>
      </div>
      <div id="zxczxx" v-if="type == '6'">
        <h3 class="Industrial_title"><img src="../../public/img/img17.png" alt=""/><span>园区企业</span></h3>
        <div class="FindIndustry condition">
          <el-table
            stripe
            :cell-style="tableStyle"
            :data="zxcsstableData"
            style="width: 100%">
            <el-table-column
              label="企业名称"
              prop="title"
              width="340">
            </el-table-column>
            <el-table-column
              label="成立日期"
              prop="create_time">
            </el-table-column>
            <el-table-column label="详情" width="90">
              <template slot-scope="scope">
                <el-button @click="zxczcasd(scope.row)" type="primary" plain size="mini" >查看</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            ackground
            :current-page="zxcsstable.page"
            :page-size="zxcsstable.pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="zxcsstableSize"
            @current-change="zxcsstableChange"
            prev-text="上一页"
            next-text="下一页"
            :total="zxcsstable.total">
          </el-pagination>
        </div>
      </div>
      <div id="establishFund" v-if="type != '6' && type != 3">
        <h3 class="Industrial_title"><img src="../../public/img/img17.png" alt=""/><span>设立基金</span></h3>
        <div class="direction condition">
          <el-table
            stripe
            border
            :cell-style="tableStyle"
            :data="establishFund"
            style="width: 100%">
            <el-table-column
              label="序号" width="78" type="index"></el-table-column>
            <el-table-column
              label="基金名称"
              prop="title">
            </el-table-column>
            <el-table-column
              label="投资方向"
              prop="tzfx"
              width="197">
            </el-table-column>
            <el-table-column
              label="介绍"
              prop="description">
            </el-table-column>
            <el-table-column
              label="持股比例"
              prop="chigubili">
            </el-table-column>
            <el-table-column label="状态" width="120">
              <template slot-scope="scope">
                <el-button type="text" @click="ccasdawz(scope.row.id)">查看</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-pagination
          class="pagination"
          background
          :current-page="fund.page"
          :page-size="fund.pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="fundSize"
          @current-change="fundChange"
          prev-text="上一页"
          next-text="下一页"
          :total="fund.total">
        </el-pagination>
      </div>
      <div id="financial" v-if="type == '6' || type == 3">
        <h3 class="Industrial_title"><img src="../../public/img/img17.png" alt=""/><span>主要产品</span></h3>
        <div class="pc_financial" style="min-height: 70px;">
          <div class="financial" v-for="(i,t) in financeList" :key="t">
            <div class="financial_left" style="height: 100%;"><img :src="i.thumb" alt=""></div>
            <div class="financial_right">
              <h3>{{i.title}}</h3>
              <el-row>
                <el-col :span="9">
                  <p class="financial_text">发电机组型号 {{i.fdjzxh}}</p>
                  <p class="financial_text">柴油机 型号 {{i.cyjxh}}</p>
                  <p class="financial_text">行程MM {{i.xingchengmm}}</p>
                  <p class="financial_text">额定功率KW {{i.edglkw}}</p>
                  <p class="financial_text">额定电流A {{i.eddla}}</p>
                  <p class="financial_text">额定功率KW {{i.eddla}}</p>
                  <p class="financial_text">额定转速r/min {{i.edingzhuansu}}</p>
                </el-col>
                <el-col :span="8">
                  <p class="financial_text">额定频率Hz {{i.edingpinlu}}</p>
                  <p class="financial_text">额定电压V {{i.edingdianyav}}</p>
                  <p class="financial_text">相数及接法 {{i.xsjjf}}</p>
                  <p class="financial_text">输出路数 {{i.shuchulushu}}</p>
                  <p class="financial_text">额定功率因数 {{i.edglys}}</p>
                  <p class="financial_text">燃油消耗率g/kwh {{i.ryxh}}</p>
                  <p class="financial_text">机油消耗率g/kwh {{i.jyxhl}}</p>
                </el-col>
                <el-col :span="7">
                  <p class="financial_text">控制方法 {{i.kzff}}</p>
                  <p class="financial_text">电压 {{i.dianya}}</p>
                  <p class="financial_text">频态调整率% {{i.pinlu}}</p>
                  <p class="financial_text">稳定时间S 1 </p>
                  <p class="financial_text">波动率 0.5 </p>
                  <p class="financial_text">频率 稳态调整率% {{i.wdsjs}}</p>
                  <p class="financial_text">频态调整率% {{i.pintai}}</p>
                </el-col>
              </el-row>
            </div>
          </div>
          <el-pagination
              class="pagination"
              background
              :current-page="productOrganization.page"
              :page-size="productOrganization.pagesize"
              layout="total, sizes, prev, pager, next, jumper"
              @size-change="productOrganizationSize"
              @current-change="productOrganizationChange"
              prev-text="上一页"
              next-text="下一页"
              :total="productOrganization.total">
            </el-pagination>
        </div>
        <div class="ydd_financial" style="display: none;">
          <div class="ydd_financial_list" v-for="(i,t) in financeList" :key="t">
            <el-row :gutter="30">
              <el-col :span="12"><img class="ydd_financial_list_img" :src="i.thumb" alt=""></el-col>
              <el-col :span="12"><h3 class="ydd_financial_list_h3">{{i.title}}</h3></el-col>
            </el-row>
            <el-row style="padding: 50px 0;">
              <el-col :span="14">
                <p class="financial_text">发电机组型号 {{i.fdjzxh}}</p>
                <p class="financial_text">柴油机 型号 {{i.cyjxh}}</p>
                <p class="financial_text">行程MM {{i.xingchengmm}}</p>
                <p class="financial_text">额定功率KW {{i.edglkw}}</p>
                <p class="financial_text">额定电流A {{i.eddla}}</p>
                <p class="financial_text">额定功率KW {{i.eddla}}</p>
                <p class="financial_text">额定转速r/min {{i.edingzhuansu}}</p>
              </el-col>
              <el-col :span="10">
                <p class="financial_text">额定频率Hz {{i.edingpinlu}}</p>
                <p class="financial_text">额定电压V {{i.edingdianyav}}</p>
                <p class="financial_text">相数及接法 {{i.xsjjf}}</p>
                <p class="financial_text">输出路数 {{i.shuchulushu}}</p>
                <p class="financial_text">额定功率因数 {{i.edglys}}</p>
                <p class="financial_text">燃油消耗率g/kwh {{i.ryxh}}</p>
                <p class="financial_text">机油消耗率g/kwh {{i.jyxhl}}</p>
              </el-col>
            </el-row>
          </div>
          <el-pagination
              class="pagination"
              background
              :current-page="productOrganization.page"
              :page-size="productOrganization.pagesize"
              layout="total, sizes, prev, pager, next, jumper"
              @size-change="productOrganizationSize"
              @current-change="productOrganizationChange"
              prev-text="上一页"
              next-text="下一页"
              :total="productOrganization.total">
            </el-pagination>
        </div>
      </div>
      <div id="PatentedTechnology" v-if="type == '6' || type == 3">
        <h3 class="Industrial_title"><img src="../../public/img/img17.png" alt=""/><span>{{type == 6?'园区企业专利技术':'专利技术'}}</span></h3>
        <el-row :gutter="57">
          <el-col :span="4" v-for="(d,num) in zhuanlijishuList" :key="num">
            <div class="PatentedTechnology_in" @click="ccasdawz(d.id)">
              <div><img src="../../public/img/img15.png" alt=""></div>
              <h4>{{d.title}}</h4>
              <p>{{d.description}}</p>
            </div>
          </el-col>
        </el-row>
        <el-pagination
            class="pagination"
            background
            :current-page="zhuanlijishu.page"
            :page-size="zhuanlijishu.pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="zhuanlijishuSize"
            @current-change="zhuanlijishuChange"
            prev-text="上一页"
            next-text="下一页"
            :total="zhuanlijishu.total">
          </el-pagination>
      </div>
      <div id="bp" v-if="type == '6' && type != 3">
        <h3 class="Industrial_title"><img src="../../public/img/img17.png" alt=""/><span>BP</span></h3>
        <el-row :gutter="190">
          <el-col :span="8" v-for="(d,num) in BPlist" :key="num">
            <div class="bp_val" @click="bpclick(d)">
              <img src="../../public/img/img7.png" alt="">
              <div>
                <p>{{d.title}}</p>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-pagination
          class="pagination"
          background
          :current-page="BPd.page"
          :page-size="BPd.pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="BPdSize"
          @current-change="BPdChange"
          prev-text="上一页"
          next-text="下一页"
          :total="BPd.total">
        </el-pagination>
      </div>
      <div id="cooperation" v-if="type == '6' && type != 3" class="condition">
        <h3 class="Industrial_title"><img src="../../public/img/img17.png" alt=""/><span>配套合作</span></h3>
        <el-table
          stripe
          border
          :cell-style="tableStyle"
          :data="parkSupportingCooperationData"
          style="width: 100%">
          <el-table-column
            label="序号" width="78" type="index"></el-table-column>
          <el-table-column
            label="公司名称"
            prop="title">
          </el-table-column>
          <el-table-column
            label="产品"
            prop="title">
          </el-table-column>
          <el-table-column
            fixed="right"
            label="操作"
            width="100">
            <template slot-scope="scope">
              <el-button type="text" @click="ccasdawz(scope.row.id)">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          :current-page="parkSupportingCooperation.page"
          :page-size="parkSupportingCooperation.pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="parkSupportingCooperationSize"
          @current-change="parkSupportingCooperationCurrent"
          prev-text="上一页"
          next-text="下一页"
          :total="parkSupportingCooperation.total">
        </el-pagination>
      </div>
      <div id="financial" v-if="type != '6' && type != 3">
        <h3 class="Industrial_title"><img src="../../public/img/img17.png" alt=""/>
          <span v-if="type != 6">金融产品</span>
          <span v-if="type == 6">机构业务</span>
        </h3>
        <div class="pc_financial">
          <div class="financial" v-for="(i,t) in financeList" :key="t">
            <div class="financial_left" style="height: 100%;"><img :src="i.thumb" alt=""></div>
            <div class="financial_right">
              <h3>{{i.title}}</h3>
              <el-row>
                <el-col :span="9">
                  <p class="financial_text">发电机组型号 {{i.fdjzxh}}</p>
                  <p class="financial_text">柴油机 型号 {{i.cyjxh}}</p>
                  <p class="financial_text">行程MM {{i.xingchengmm}}</p>
                  <p class="financial_text">额定功率KW {{i.edglkw}}</p>
                  <p class="financial_text">额定电流A {{i.eddla}}</p>
                  <p class="financial_text">额定功率KW {{i.eddla}}</p>
                  <p class="financial_text">额定转速r/min {{i.edingzhuansu}}</p>
                </el-col>
                <el-col :span="8">
                  <p class="financial_text">额定频率Hz {{i.edingpinlu}}</p>
                  <p class="financial_text">额定电压V {{i.edingdianyav}}</p>
                  <p class="financial_text">相数及接法 {{i.xsjjf}}</p>
                  <p class="financial_text">输出路数 {{i.shuchulushu}}</p>
                  <p class="financial_text">额定功率因数 {{i.edglys}}</p>
                  <p class="financial_text">燃油消耗率g/kwh {{i.ryxh}}</p>
                  <p class="financial_text">机油消耗率g/kwh {{i.jyxhl}}</p>
                </el-col>
                <el-col :span="7">
                  <p class="financial_text">控制方法 {{i.kzff}}</p>
                  <p class="financial_text">电压 {{i.dianya}}</p>
                  <p class="financial_text">频态调整率% {{i.pinlu}}</p>
                  <p class="financial_text">稳定时间S 1 </p>
                  <p class="financial_text">波动率 0.5 </p>
                  <p class="financial_text">频率 稳态调整率% {{i.wdsjs}}</p>
                  <p class="financial_text">频态调整率% {{i.pintai}}</p>
                </el-col>
              </el-row>
            </div>
          </div>
          <el-pagination
              class="pagination"
              background
              :current-page="productOrganization.page"
              :page-size="productOrganization.pagesize"
              layout="total, sizes, prev, pager, next, jumper"
              @size-change="productOrganizationSize"
              @current-change="productOrganizationChange"
              prev-text="上一页"
              next-text="下一页"
              :total="productOrganization.total">
            </el-pagination>
        </div>
        <div class="ydd_financial" style="display: none;">
          <div class="ydd_financial_list" v-for="(i,t) in financeList" :key="t">
            <el-row :gutter="30">
              <el-col :span="12"><img class="ydd_financial_list_img" :src="i.thumb" alt=""></el-col>
              <el-col :span="12"><h3 class="ydd_financial_list_h3">{{i.title}}</h3></el-col>
            </el-row>
            <el-row style="padding: 50px 0;">
              <el-col :span="14">
                <p class="financial_text">发电机组型号 {{i.fdjzxh}}</p>
                <p class="financial_text">柴油机 型号 {{i.cyjxh}}</p>
                <p class="financial_text">行程MM {{i.xingchengmm}}</p>
                <p class="financial_text">额定功率KW {{i.edglkw}}</p>
                <p class="financial_text">额定电流A {{i.eddla}}</p>
                <p class="financial_text">额定功率KW {{i.eddla}}</p>
                <p class="financial_text">额定转速r/min {{i.edingzhuansu}}</p>
              </el-col>
              <el-col :span="10">
                <p class="financial_text">额定频率Hz {{i.edingpinlu}}</p>
                <p class="financial_text">额定电压V {{i.edingdianyav}}</p>
                <p class="financial_text">相数及接法 {{i.xsjjf}}</p>
                <p class="financial_text">输出路数 {{i.shuchulushu}}</p>
                <p class="financial_text">额定功率因数 {{i.edglys}}</p>
                <p class="financial_text">燃油消耗率g/kwh {{i.ryxh}}</p>
                <p class="financial_text">机油消耗率g/kwh {{i.jyxhl}}</p>
              </el-col>
            </el-row>
          </div>
          <el-pagination
              class="pagination"
              background
              :current-page="productOrganization.page"
              :page-size="productOrganization.pagesize"
              layout="total, sizes, prev, pager, next, jumper"
              @size-change="productOrganizationSize"
              @current-change="productOrganizationChange"
              prev-text="上一页"
              next-text="下一页"
              :total="productOrganization.total">
            </el-pagination>
        </div>
      </div>
      <div id="toraise" v-if="type != '6' && type != 3">
        <h3 class="Industrial_title"><img src="../../public/img/img17.png" alt=""/>
          <span v-if="type < 5">募资状况</span>
          <span v-if="type >=5">机构状况</span>
        </h3>
        <div class="direction condition">
          <el-table
            stripe
            border
            :cell-style="tableStyle"
            :data="fundraisingStatusData"
            style="width: 100%">
            <el-table-column
              label="序号" width="78" type="index"></el-table-column>
            <el-table-column
              label="股东名称"
              prop="title"
              width="295">
            </el-table-column>
            <el-table-column
              label="股份类型"
              prop="gufenleixing"
              width="197">
            </el-table-column>
            <el-table-column
              label="持股数量（股）"
              prop="cgsl">
            </el-table-column>
            <el-table-column
              label="持股比例"
              prop="chigubili">
            </el-table-column>
            <el-table-column label="状态" width="120">
              <template slot-scope="scope">
                <el-button type="text" @click="ccasdawz(scope.row.id)">查看</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-pagination
            class="pagination"
            background
            :current-page="fundraisingStatus.page"
            :page-size="fundraisingStatus.pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="fundraisingStatusSize"
            @current-change="fundraisingStatusChange"
            prev-text="上一页"
            next-text="下一页"
            :total="fundraisingStatus.total">
          </el-pagination>
      </div>
      <div id="enterprise" v-if="type != '6' && type != 3">
        <h3 class="Industrial_title"><img src="../../public/img/img17.png" alt=""/><span>同类企业</span></h3>
        <div class="project">
          <div class="project_list" v-for="(a,i) in similarEnterprise" :key="i" @click="TLccasdawz(a.id)">
            <img :src="a.thumb" alt="" />
            <h4>{{a.title}}</h4>
            <div><i class="el-icon-arrow-right"></i>{{a.description}}</div>
          </div>
        </div>
        <el-pagination
          class="pagination"
          background
          :current-page="similar.page"
          :page-size="similar.pagesize"
          prev-text="上一页"
          next-text="下一页"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="similarSize"
          @current-change="similarChange"
          :total="similar.total">
        </el-pagination>
      </div>
      <div id="establishFundzzzz" v-if="type == 3">
        <h3 class="Industrial_title"><img src="../../public/img/img17.png" alt=""/><span>融资状况</span></h3>
        <div class="direction condition">
          <el-table
            stripe
            border
            :cell-style="tableStyle"
            :data="FinancingSituation"
            style="width: 100%">
            <el-table-column
              label="序号" width="78" type="index"></el-table-column>
            <el-table-column
              label="股东名称"
              prop="title">
            </el-table-column>
            <el-table-column
              label="股份类型"
              prop="gufenleixing"
              width="197">
            </el-table-column>
            <el-table-column
              label="持股数量（股）"
              prop="description">
            </el-table-column>
            <el-table-column
              label="持股比例"
              prop="chigubili">
            </el-table-column>
            <el-table-column label="状态" width="120">
              <template slot-scope="scope">
                <el-button type="text" @click="ccasdawz(scope.row.id)">查看</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-pagination
          class="pagination"
          background
          :current-page="FinancingSituationll.page"
          :page-size="FinancingSituationll.pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="FinancingSituationSize"
          @current-change="FinancingSituationChange"
          prev-text="上一页"
          next-text="下一页"
          :total="FinancingSituationll.total">
        </el-pagination>
      </div>
      <div id="parkPolicy" v-if="type == 3">
        <h3 class="Industrial_title"><img src="../../public/img/img17.png" alt=""/><span>经营状况</span></h3>
        <div class="style condition">
          <el-table
            stripe
            border
            :cell-style="tableStyle"
            :data="OperatingConditionData"
            style="width: 100%">
            <el-table-column
              label="序号" width="78" type="index"></el-table-column>
            <el-table-column
              label="年报"
              prop="title">
            </el-table-column>
            <el-table-column
              fixed="right"
              label="操作"
              width="100">
              <template slot-scope="scope">
                <el-button type="text" @click="ccasdawz(scope.row.id)">查看</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            class="pagination"
            background
            :current-page="OperatingCondition.page"
            :page-size="OperatingCondition.pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="OperatingConditionsize"
            @current-change="OperatingConditioncurrent"
            prev-text="上一页"
            next-text="下一页"
            :total="OperatingCondition.total">
          </el-pagination>
        </div>
      </div>
      <div id="law" v-if="type == 3">
        <h3 class="Industrial_title"><img src="../../public/img/img17.png" alt=""/><span>法律</span></h3>
        <div class="style condition">
          <el-table
            stripe
            border
            :cell-style="tableStyle"
            :data="lawData"
            style="width: 100%">
            <el-table-column
              label="序号" width="78" type="index"></el-table-column>
            <el-table-column
              label="日期"
              prop="create_time"
              width="295">
            </el-table-column>
            <el-table-column
              label="案件名称"
              prop="title">
            </el-table-column>
            <el-table-column
              label="案由"
              prop="anyou">
            </el-table-column>
            <el-table-column
              label="案件身份"
              prop="ajsf">
            </el-table-column>
            <el-table-column
              fixed="right"
              label="操作"
              width="100">
              <template slot-scope="scope">
                <el-button type="text" @click="ccasdawz(scope.row.id)">查看</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            class="pagination"
            background
            :current-page="lawll.page"
            :page-size="lawll.pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="lawllSize"
            @current-change="lawllCurrent"
            prev-text="上一页"
            next-text="下一页"
            :total="lawll.total">
          </el-pagination>
        </div>
      </div>
      <div id="enterpriseBP" v-if="type == 3">
        <h3 class="Industrial_title"><img src="../../public/img/img17.png" alt=""/><span>BP</span></h3>
        <el-row :gutter="190">
          <el-col :span="8" v-for="(d,num) in enterpriseBPlist" :key="num">
            <div class="bp_val" @click="bpclick(d)">
              <img src="../../public/img/img7.png" alt="">
              <div>
                <p>{{d.title}}</p>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-pagination
          class="pagination"
          background
          :current-page="enterpriseBPll.page"
          :page-size="enterpriseBPll.pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="enterpriseBPllSize"
          @current-change="enterpriseBPllCurrent"
          prev-text="上一页"
          next-text="下一页"
          :total="enterpriseBPll.total">
        </el-pagination>
      </div>
      <div id="Outward" v-if="type == 3">
        <h3 class="Industrial_title"><img src="../../public/img/img17.png" alt=""/><span>对外投资</span></h3>
        <el-table
          stripe
          border
          :cell-style="tableStyle"
          :data="OutwardInvestmentData"
          style="width: 100%">
          <el-table-column
            label="序号" width="78" type="index"></el-table-column>
          <el-table-column
            label="被投资企业"
            prop="title">
          </el-table-column>
          <el-table-column
            label="被投资企业法定代表人/负责人"
            prop="btzqyfddbrfzr">
          </el-table-column>
          <el-table-column
            label="成立日期"
            prop="create_time">
          </el-table-column>
          <el-table-column
            label="投资占比"
            prop="touzizhanbi">
          </el-table-column>
          <el-table-column
            label="认缴金额"
            prop="renjiaojine">
          </el-table-column>
          <el-table-column
            label="状态"
            prop="zhuangtai">
          </el-table-column>
          <el-table-column
            fixed="right"
            label="操作"
            width="100">
            <template slot-scope="scope">
              <el-button type="text" @click="ccasdawz(scope.row.id)">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="pagination"
          background
          :current-page="OutwardInvestment.page"
          :page-size="OutwardInvestment.pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="OutwardInvestmentSize"
          @current-change="OutwardInvestmentCurrent"
          prev-text="上一页"
          next-text="下一页"
          :total="OutwardInvestment.total">
        </el-pagination>
      </div>
      <div id="consulting3" v-if="type == 3">
        <h3 class="Industrial_title"><img src="../../public/img/img17.png" alt=""/><span>相关资讯</span></h3>
        <div class="consulting">
          <div class="List_Information" v-for="(item, index) in correlationList" :key="index" @click="zcasdasdas()">
            <div class="List_Information_left"><img :src="item.thumb" alt=""></div>
            <div class="List_Information_right">
              <h6>{{item.tit}}</h6>
              <p>{{item.val}}</p>
            </div>
          </div>
          <el-pagination
              class="pagination"
              background
              :current-page="correlation.page"
              :page-size="correlation.pagesize"
              layout="total, sizes, prev, pager, next, jumper"
              @size-change="correlationsizeChange"
              @current-change="correlationcurrentChange"
              prev-text="上一页"
              next-text="下一页"
              :total="correlation.total">
          </el-pagination>
        </div>
      </div>
      <div id="matching3" v-if="type == 3">
        <h3 class="Industrial_title"><img src="../../public/img/img17.png" alt=""/><span>配套合作</span></h3>
        <el-table
          stripe
          border
          :cell-style="tableStyle"
          :data="matchingCooperationData"
          style="width: 100%">
          <el-table-column
            label="序号" width="78" type="index"></el-table-column>
          <el-table-column
            label="公司名称"
            prop="title">
          </el-table-column>
          <el-table-column
            label="产品"
            prop="title">
          </el-table-column>
          <el-table-column
            fixed="right"
            label="操作"
            width="100">
            <template slot-scope="scope">
              <el-button type="text" @click="ccasdawz(scope.row.id)">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="pagination"
          background
          :current-page="matchingCooperation.page"
          :page-size="matchingCooperation.pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="matchingCooperationSize"
          @current-change="matchingCooperationCurrent"
          prev-text="上一页"
          next-text="下一页"
          :total="matchingCooperation.total">
        </el-pagination>
      </div>
      <div id="way">
        <h3 class="Industrial_title"><img src="../../public/img/img17.png" alt=""/><span>联系方式</span></h3>
        <div class="way registered">
          <el-row>
            <el-col :span="24">
              <div class="registered_div">
                <div class="registered_left way_list_left"><div><p>公司名</p></div></div>
                <div class="registered_right way_list_right"><p>{{valDetails.title?valDetails.title:'无'}}</p></div>
              </div>
            </el-col>
            <el-col :span="24">
              <div class="registered_div">
                <div class="registered_left way_list_left"><div><p>电话</p></div></div>
                <div class="registered_right way_list_right"><p>{{valDetails.lxfs?valDetails.lxfs:'无'}}</p></div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from '@/api/request.js'
import commonUtils from '@/utils/CommonUtils.js'
import RelationGraph from 'relation-graph'
export default {
  data(){
    return{
      oldScrollTop:0,
      activeName: '企业概况',
      label:[],
      zxcsstableData:[],
      tableData: [],
      parkPolicyData:[],
      AttractInvestmentData:[],
      titIndex:0,
      InformationList:[],
      title:{path:'',val:""},
      type:0,
      list:[],
      valDetails:{},
      parkCapital:[],
      parkServiceList:[],
      establishFundData:[],
      directionData:[],
      investmentProject:[],
      correlationList:[],
      PrimaryTeamList:[],
      establishFund:[],
      financeList:[],
      fundraisingStatusData:[],
      similarEnterprise:[],
      parkExternallyList:[],
      MainProductList:[],
      zhuanlijishuList:[],
      FinancingSituation:[],
      OperatingConditionData:[],
      lawData:[],
      BPlist:[],
      enterpriseBPlist:[],
      OutwardInvestmentData:[],
      matchingCooperationData:[],
      parkSupportingCooperationData:[],
      relatedInformation:{page:1,pagesize:10,total:0},
      relatedParkpolicy:{page:1,pagesize:10,total:0},
      relatedCapital:{page:1,pagesize:10,total:0},
      relatedService:{page:1,pagesize:10,total:0},
      investment:{page:1,pagesize:10,total:0},
      project:{page:1,pagesize:10,total:0},
      correlation:{page:1,pagesize:10,total:0},
      PrimaryTeam:{page:1,pagesize:10,total:0},
      fund:{page:1,pagesize:10,total:0},
      productOrganization:{page:1,pagesize:10,total:0},
      fundraisingStatus:{page:1,pagesize:10,total:0},
      similar:{page:1,pagesize:10,total:0},
      MainProduct:{page:1,pagesize:10,total:0},
      zhuanlijishu:{page:1,pagesize:10,total:0},
      relatedExternally:{page:1,pagesize:10,total:0},
      FinancingSituationll:{page:1,pagesize:10,total:0},
      OperatingCondition:{page:1,pagesize:10,total:0},
      lawll:{page:1,pagesize:10,total:0},
      enterpriseBPll:{page:1,pagesize:10,total:0},
      OutwardInvestment:{page:1,pagesize:10,total:0},
      matchingCooperation:{page:1,pagesize:10,total:0},
      zxcsstable:{page:1,pagesize:10,total:0},
      BPd:{page:1,pagesize:10,total:0},
      parkSupportingCooperation:{page:1,pagesize:10,total:0},


      g_loading: true,
      demoname: '---',
      graphOptions: {
        "backgrounImage": "http://ai-mark.cn/images/ai-mark-desc.png",
        "backgrounImageNoRepeat": false,
        "layouts": [
          {
            "label": "中心",
            "layoutName": "tree",
            "layoutClassName": "seeks-layout-center",
            "defaultJunctionPoint": "border",
            "defaultNodeShape": 0,
            "defaultLineShape": 5,  // 1直线
            "min_per_width": "200",
            "max_per_width": "300",
            "min_per_height": "150",
            "max_per_height": "300",
            "centerOffset_x": "0",
            "centerOffset_y": "0",
          }
        ],
        "defaultLineMarker": {  // 箭头样式
          "markerWidth": 22,
          "markerHeight": 22,
          "refX":15,
          "refY": 6,
          "data": "M2,2 L10,6 L2,10 L6,6 L2,2"
        },
        "allowShowMiniNameFilter": true,
        "allowShowMiniToolBar": true,
        "allowSwitchLineShape": true,
        "allowSwitchJunctionPoint": true,
        "defaultNodeShape": 1,
        "defaultNodeWidth": "150",
        "defaultNodeHeight": "50",
        "defaultLineShape": 4,
        "defaultJunctionPoint": "tb",
        "defaultShowLineLabel": true,
        "disableZoom": false,
        "disableDragNode": false,
        "defaultExpandHolderPosition": "top",
        "defaultNodeBorderWidth": 2,
        "defaultNodeColor": "rgba(144, 238, 144, 1)",
        "isMoveByParentNode": false,
        isHideArrow: false,
      },
    }
  },
  components:{ RelationGraph },
  mounted(){
    this.type = this.$route.query.type;
    if(this.$route.query.type == 4){
      this.title = {
        path:'/FindListInformation' + commonUtils.getParam(this.$route.query),
        val:"找投资"
      };
      this.label = [
        {label:'企业概况',name:'AnOverview'},
        {label:'工商注册',name:'registered'},
        {label:'投资风格',name:'style'},
        {label:'投资方向/项目',name:'direction'},
        {label:'投资项目',name:'project'},
        {label:'相关资讯',name:'consulting'},
        {label:'主要团队',name:'team'},
        {label:'设立基金',name:'establishFund'},
        {label:'金融产品',name:'financial'},
        {label:'募资状况',name:'toraise'},
        {label:'同类企业',name:'enterprise'},
        {label:'联系方式',name:'way'},
      ];
      window.addEventListener('scroll', this.handleScroll)
      this.findInvestmentDetails(this.$route.query.id)
      this.findInvestmentDetailsInvestment(this.$route.query.id)
      this.findInvestmentDetailsType(this.$route.query.id,'jigfxxm','tzfxxm')
      this.findInvestmentDetailsType(this.$route.query.id,'touzixiangmu','touzixiangmu')
      this.findInvestmentDetailsType(this.$route.query.id,'touzixiangmu','touzixiangmu')
      this.relatedInformationList(this.$route.query.id)
      this.findInvestmentDetailsType(this.$route.query.id,'zhaoshequn','zytd')
      this.findInvestmentDetailsType(this.$route.query.id,'shelijijin','shelijijin')
      this.findInvestmentDetailsType(this.$route.query.id,'zhuyaochanpin','jrcp')
      this.findInvestmentDetailsType(this.$route.query.id,'rzzk','mzzk')
      this.similarEnterpriseC(this.$route.query.id)
    }else if(this.$route.query.type == 6){
      this.title = {
        path:'/FindListInformation' + commonUtils.getParam(this.$route.query),
        val:"找园区"
      }
      this.parkDetails(this.$route.query.id)
      this.parkDetailsInformation(this.$route.query.id)
      this.parkDetailsParkpolicy(this.$route.query.id)
      this.parkDetailCapital(this.$route.query.id)
      this.parkService(this.$route.query.id)
      this.parkExternally(this.$route.query.id)
      this.ParkTeam(this.$route.query.id)
      this.parkDetailsType(this.$route.query.id,'zhuyaochanpin','zycp')
      this.parkDetailsType(this.$route.query.id,'zhuanlijishu','zhuanlijishu')
      this.parkDetailsType(this.$route.query.id,'rzzk','rzzk')
      this.parkDetailsType(this.$route.query.id,'peitaohezuo','peitaohezuo')
    }else if(this.$route.query.type == 3){
      this.title = {
        path:'/enterpriseList' + commonUtils.getParam(this.$route.query),
        val:"找企业"
      }
      this.enterpriseDetails(this.$route.query.id)
      this.enterprisePrimaryTeam(this.$route.query.id)
      this.enterpriseDetailsType(this.$route.query.id,'zhuyaochanpin','zycp')
      this.enterpriseDetailsType(this.$route.query.id,'zhuanlijishu','zhuanlijishu')
      this.enterpriseDetailsType(this.$route.query.id,'rzzk','rzzk')
      this.enterpriseDetailsType(this.$route.query.id,'jingyingzhuangk','jyzk')
      this.enterpriseDetailsType(this.$route.query.id,'falv','falu')
      this.enterpriseDetailsType(this.$route.query.id,'bpwj','bp')
      this.enterpriseDetailsType(this.$route.query.id,'duiwaitouzi','duiwaitouzi')
      this.relatedInformationList(this.$route.query.id)
      this.enterpriseDetailsType(this.$route.query.id,'peitaohezuo','peitaohezuo')
      this.demoname = this.$route.params.demoname
      this.getInitData()
    }
  },
  methods:{
    getInitData(){
      var  __graph_json_data={
        "rootId": "a",
        "nodes":[
          {
            "id": "a",
            "text": "a公司",
          },
          {
            "id": "b",
            "id2": "b2222",
            "text": "b公司",
            hasChildren: true,
            'data': { 'num': '11%' }
          },
          {
            "id": "c",
            "text": "c公司"
          },
        ],
        "links": [
          {
            "from": "b",
            "to": "a",
            // text: '控股份111',
            styleClass: "link-class"
          },
          {
            "from": "c",
            "to": "a",
            // text: '控股份222'
          },
        ]
      }
      if(__graph_json_data.nodes.length==2){
        __graph_json_data.nodes[1].fixed=true
        __graph_json_data.nodes[1].x=0
      }
      if(__graph_json_data.nodes.length==3){
        __graph_json_data.nodes[1].fixed=true
        __graph_json_data.nodes[1].x=-250
        __graph_json_data.nodes[2].fixed=true
        __graph_json_data.nodes[2].x=250
      }
      this.setGraphData(__graph_json_data)
    },
    setGraphData(__graph_json_data) {
      // __graph_json_data.nodes.forEach(thisNode => {
      //   if (thisNode.text === '深圳市腾讯计算机系统有限公司') {
      //     thisNode.width = 300
      //     thisNode.height = 100
      //     // thisNode.offset_x = -80
      //   }
      //   if (thisNode.data.hasChildren ) {
      //     // thisNode.data = { asyncChild: true, loaded: false, id: thisNode.id }; // 这是一个自定义属性，用来在后续判断如果点击了这个节点，则动态获取数据
      //     thisNode.data.asyncChild = true
      //     thisNode.data.loaded = false
      //     thisNode.expandHolderPosition = "top";
      //     thisNode.expanded = false;
      //   }
      // })
      setTimeout(function() {
        this.g_loading = false
        this.$refs.seeksRelationGraph.setJsonData(__graph_json_data, (seeksRGGraph) => {
          // 这些写上当图谱初始化完成后需要执行的代码
           this.$nextTick(()=>{
                this.$refs.seeksRelationGraph.focusRootNode()
            })
        })
      }.bind(this), 1000)
    },
    tableStyle() { return "height:80px;"},
    handleScroll() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if(scrollTop <= '100'){ this.activeName = 'AnOverview' }
    },
    casdasdas(id){
      window.localStorage.setItem('type',0);
      this.$router.push({
          path:'/DetailsConsultation',
          query:{type:0,sublevel:this.$route.query.sublevel,typeId:this.$route.query.typeId,id:id.id}
      })
    },
    parkDetails(id){
      request.parkDetails(id).then((res) => {
        if(res.data.data != null){
          this.valDetails = res.data.data;
        }
      })
    },
    enterpriseDetails(id){
      request.enterpriseDetails(id).then((res) => {
        if(res.data.data != null){
          this.valDetails = res.data.data;
        }
      })
    },
    parkDetailsInformation(id){
      request.parkDetailsInformation(id,this.relatedInformation.pagesize,this.relatedInformation.page).then((res) => {
        if(res.data.data != null){
          console.log('找园区',res.data.data)
          this.InformationList = res.data.data.list;
          this.relatedInformation.total = res.data.data.total;
        }
      })
    },
    findInvestmentDetails(id){
      request.findInvestmentDetails(id).then((res) => {
        if(res.data.data != null){
          this.valDetails = res.data.data;
        }
      })
    },
    parkDetailsParkpolicy(id){
      request.parkDetailsParkpolicy(id,this.relatedInformation.pagesize,this.relatedInformation.page).then((res) => {
        if(res.data.data != null){
          if(this.type == 6){
            this.zxcsstableData = res.data.data.list;
            this.zxcsstable.total = res.data.data.total;
          }else{
            this.parkPolicyData = res.data.data.list;
            this.relatedParkpolicy.total = res.data.data.total;
          }
        }
      })
    },
    findInvestmentDetailsInvestment(id){
      request.findInvestmentDetailsInvestment(id,this.relatedService.pagesize,this.relatedService.page).then((res) => {
        if(res.data.data != null){
          this.parkServiceList = res.data.data.list;
          this.relatedService.total = res.data.data.total;
        }
      })
    },
    findInvestmentDetailsType(id,table,field){
      let pagesize = 0;
      let page = 0;
      if(table == 'jigfxxm'){
        pagesize = this.investment.pagesize;
        page = this.investment.page;
      }else if(table == 'touzixiangmu'){
        pagesize = this.project.pagesize;
        page = this.project.page;
      }else if(table == 'zhaoshequn'){
        pagesize = this.PrimaryTeam.pagesize;
        page = this.PrimaryTeam.page;
      }else if(table == 'shelijijin'){
        pagesize = this.fund.pagesize;
        page = this.fund.page;
      }else if(table == 'zhuyaochanpin'){
        pagesize = this.productOrganization.pagesize;
        page = this.productOrganization.page;
      }else if(table == 'rzzk'){
        pagesize = this.fundraisingStatus.pagesize;
        page = this.fundraisingStatus.page;
      }
      request.findInvestmentDetailsType(id,table,field,pagesize,page).then((res) => {
        if(res.data.data != null){
          if(table == 'jigfxxm'){
            this.directionData = res.data.data.list;
            this.investment.total = res.data.data.total;
          }else if(table == 'touzixiangmu'){
            this.investmentProject = res.data.data.list;
            this.project.total = res.data.data.total;
          }else if(table == 'zhaoshequn'){
            this.PrimaryTeamList = res.data.data.list;
            this.PrimaryTeam.total = res.data.data.total;
          }else if(table == 'shelijijin'){
            this.establishFund = res.data.data.list;
            this.fund.total = res.data.data.total;
          }else if(table == 'zhuyaochanpin'){
            this.financeList = res.data.data.list;
            this.productOrganization.total = res.data.data.total;
          }else if(table == 'rzzk'){
            this.fundraisingStatusData = res.data.data.list;
            this.fundraisingStatus.total = res.data.data.total;
          }
        }
      })
    },
    enterprisePrimaryTeam(id){
      request.enterprisePrimaryTeam(id,this.MainProduct.pagesize,this.MainProduct.page).then((res) => {
        if(res.data.data != null){
          this.PrimaryTeamList = res.data.data.list;
          this.PrimaryTeam.total = res.data.data.total;
        }
      })
    },
    enterpriseDetailsType(id,table,field){
      let pagesize = 0;
      let page = 0;
      if(table == 'zhuyaochanpin'){
        pagesize = this.productOrganization.pagesize;
        page = this.productOrganization.page;
      }else if(table == 'zhuanlijishu'){
        pagesize = this.zhuanlijishu.pagesize;
        page = this.zhuanlijishu.page;
      }else if(table == 'rzzk'){
        pagesize = this.FinancingSituationll.pagesize;
        page = this.FinancingSituationll.page;
      }else if(table == 'jingyingzhuangk'){
        pagesize = this.OperatingCondition.pagesize;
        page = this.OperatingCondition.page;
      }else if(table == 'falv'){
        pagesize = this.lawll.pagesize;
        page = this.lawll.page;
      }else if(table == 'bpwj'){
        pagesize = this.enterpriseBPll.pagesize;
        page = this.enterpriseBPll.page;
      }else if(table == 'duiwaitouzi'){
        pagesize = this.OutwardInvestment.pagesize;
        page = this.OutwardInvestment.page;
      }else if(table == 'peitaohezuo'){
        pagesize = this.matchingCooperation.pagesize;
        page = this.matchingCooperation.page;
      }
      request.enterpriseDetailsType(id,table,field,pagesize,page).then((res) => {
        if(res.data.data != null){
          if(table == 'zhuyaochanpin'){
            this.financeList = res.data.data.list;
            this.productOrganization.total = res.data.data.total;
          }else if(table == 'zhuanlijishu'){
            this.zhuanlijishuList = res.data.data.list;
            this.zhuanlijishu.total = res.data.data.total;
          }else if(table == 'rzzk'){
            this.FinancingSituation = res.data.data.list;
            this.FinancingSituationll.total = res.data.data.total;
          }else if(table == 'jingyingzhuangk'){
            this.OperatingConditionData = res.data.data.list;
            this.OperatingCondition.total = res.data.data.total;
          }else if(table == 'falv'){
            this.lawData = res.data.data.list;
            this.lawll.total = res.data.data.total;
          }else if(table == 'bpwj'){
            this.enterpriseBPlist = res.data.data.list;
            this.enterpriseBPll.total = res.data.data.total;
          }else if(table == 'duiwaitouzi'){
            this.OutwardInvestmentData = res.data.data.list;
            this.OutwardInvestment.total = res.data.data.total;
          }else if(table == 'peitaohezuo'){
            this.matchingCooperationData = res.data.data.list;
            this.matchingCooperation.total = res.data.data.total;
          }
        }
      })
    },
    parkDetailsType(id,table,field){
      let pagesize = 0;
      let page = 0;
      if(table == 'zhuyaochanpin'){
        pagesize = this.productOrganization.pagesize;
        page = this.productOrganization.page;
      }else if(table == 'zhuanlijishu'){
        pagesize = this.zhuanlijishu.pagesize;
        page = this.zhuanlijishu.page;
      }else if(table == 'rzzk'){
        pagesize = this.BPd.pagesize;
        page = this.BPd.page;
      }else if(table == 'peitaohezuo'){
        pagesize = this.parkSupportingCooperation.pagesize;
        page = this.parkSupportingCooperation.page;
      }
      request.parkDetailsType(id,table,field,pagesize,page).then((res) => {
        if(res.data.data != null){
          if(table == 'zhuyaochanpin'){
            this.financeList = res.data.data.list;
            this.productOrganization.total = res.data.data.total;
          }else if(table == 'zhuanlijishu'){
            this.zhuanlijishuList = res.data.data.list;
            this.zhuanlijishu.total = res.data.data.total;
          }else if(table == 'rzzk'){
            this.BPlist = res.data.data.list;
            this.BPd.total = res.data.data.total;
          }else if(table == 'peitaohezuo'){
            this.parkSupportingCooperationData = res.data.data.list;
            this.parkSupportingCooperation.total = res.data.data.total;
          }
        }
      })
    },
    similarEnterpriseC(id){
      request.similarEnterprise(id,this.similar.pagesize,this.similar.page).then((res) => {
        if(res.data.data != null){
          this.similarEnterprise = res.data.data.list;
          this.similar.total = res.data.data.total;
        }
      })
    },
    relatedInformationList(id){
      request.relatedInformation(id,this.correlation.pagesize,this.correlation.page).then((res) => {
        if(res.data.data != null){
          this.correlationList = res.data.data.list;
          this.correlation.total = res.data.data.total;
        }
      })
    },
    parkDetailCapital(id){
      request.parkDetailCapital(id,this.relatedCapital.pagesize,this.relatedCapital.page).then((res) => {
        if(res.data.data != null){
          this.parkCapital = res.data.data.list;
          this.relatedParkpolicy.total = res.data.data.total;
        }
      })
    },
    parkService(id){
      request.parkService(id,this.relatedService.pagesize,this.relatedService.page).then((res) => {
        if(res.data.data != null){
          this.parkServiceList = res.data.data.list;
          this.relatedService.total = res.data.data.total;
        }
      })
    },
    parkExternally(id){
      request.parkExternally(id,this.relatedExternally.pagesize,this.relatedExternally.page).then((res) => {
        if(res.data.data != null){
          this.parkExternallyList = res.data.data.list;
          this.relatedExternally.total = res.data.data.total;
        }
      })
    },
    ParkTeam(id){
      request.ParkTeam(id,this.relatedExternally.pagesize,this.relatedExternally.page).then((res) => {
        if(res.data.data != null){
          this.PrimaryTeamList = res.data.data.list;
          this.PrimaryTeam.total = res.data.data.total;
        }
      })
    },
    relateDsizeChange($event){
      this.correlationList.pagesize = $event;
      this.correlationList.page = 1;
      this.parkDetailsInformation(this.$route.query.id)
    },
    relateDcurrentChange($event){
      this.relatedInformation.page = $event;
      this.parkDetailsInformation(this.$route.query.id)
    },
    ParkpolicyDsizeChange($event){
      this.relatedInformation.pagesize = $event;
      this.relatedInformation.page = 1;
      this.parkDetailsParkpolicy(this.$route.query.id)
    },
    ParkpolicyDcurrentChange($event){
      this.relatedInformation.page = $event;
      this.parkDetailsParkpolicy(this.$route.query.id)
    },
    CapitalDsizeChange($event){
      this.relatedService.pagesize = $event;
      this.relatedService.page = 1;
      this.parkDetailCapital(this.$route.query.id)
    },
    CapitalDcurrentChange($event){
      this.relatedService.page = $event;
      this.parkDetailCapital(this.$route.query.id)
    },
    ServicesizeChange($event){
      this.relatedService.pagesize = $event;
      this.relatedService.page = 1;
      this.parkService(this.$route.query.id)
    },
    ServicecurrentChange($event){
      this.relatedService.page = $event;
      this.parkService(this.$route.query.id)
    },
    investmentsizeChange($event){
      this.investment.pagesize = $event;
      this.investment.page = 1;
      if(type == 4){
        this.findInvestmentDetailsType(this.$route.query.id,'jigfxxm','tzfxxm')
      }
    },
    investmentcurrentChange($event){
      this.investment.page = $event;
      if(type == 4){
        this.findInvestmentDetailsType(this.$route.query.id,'jigfxxm','tzfxxm')
      }
    },
    projectsizeChange($event){
      this.project.pagesize = $event;
      this.project.page = 1;
      if(type == 4){
        this.findInvestmentDetailsType(this.$route.query.id,'touzixiangmu','touzixiangmu')
      }
    },
    projectcurrentChange($event){
      this.project.page = $event;
      if(type == 4){
        this.findInvestmentDetailsType(this.$route.query.id,'touzixiangmu','touzixiangmu')
      }
    },
    correlationsizeChange($event){
      this.project.pagesize = $event;
      this.project.page = 1;
      if(type == 4){
        this.relatedInformationList(this.$route.query.id)
      }
    },
    correlationcurrentChange($event){
      this.project.page = $event;
      if(type == 4){
        this.relatedInformationList(this.$route.query.id)
      }
    },
    PrimaryTeamSize($event){
      this.PrimaryTeam.pagesize = $event;
      this.PrimaryTeam.page = 1;
      if(type == 4){
        this.findInvestmentDetailsType(this.$route.query.id,'zhaoshequn','zytd')
      }else if(type == 3){
        this.enterprisePrimaryTeam(this.$route.query.id)
      }else if(type == 6){
        this.ParkTeam(this.$route.query.id)
      }
    },
    PrimaryTeamChange($event){
      this.PrimaryTeam.page = $event;
      if(type == 4){
        this.findInvestmentDetailsType(this.$route.query.id,'zhaoshequn','zytd')
      }else if(type == 3){
        this.enterprisePrimaryTeam(this.$route.query.id)
      }else if(type == 6){
        this.ParkTeam(this.$route.query.id)
      }
    },
    fundSize($event){
      this.fund.pagesize = $event;
      this.fund.page = 1;
      if(type == 4){
        this.findInvestmentDetailsType(this.$route.query.id,'shelijijin','shelijijin')
      }
    },
    fundChange($event){
      this.fund.page = $event;
      if(type == 4){
        this.findInvestmentDetailsType(this.$route.query.id,'shelijijin','shelijijin')
      }
    },
    productOrganizationSize($event){
      this.productOrganization.pagesize = $event;
      this.productOrganization.page = 1;
      if(type == 4){
        this.findInvestmentDetailsType(this.$route.query.id,'zhuyaochanpin','jrcp')
      }else if(type == 3){
        this.enterpriseDetailsType(this.$route.query.id,'zhuyaochanpin','zycp')
      }else if(type == 6){
        this.parkDetailsType(this.$route.query.id,'zhuyaochanpin','zycp')
      }
    },
    productOrganizationChange($event){
      this.productOrganization.page = $event;
      if(type == 4){
        this.findInvestmentDetailsType(this.$route.query.id,'zhuyaochanpin','jrcp')
      }else if(type == 3){
        this.enterpriseDetailsType(this.$route.query.id,'zhuyaochanpin','zycp')
      }else if(type == 6){
        this.parkDetailsType(this.$route.query.id,'zhuyaochanpin','zycp')
      }
    },
    fundraisingStatusSize($event){
      this.productOrganization.pagesize = $event;
      this.productOrganization.page = 1;
      if(type == 4){
        this.findInvestmentDetailsType(this.$route.query.id,'rzzk','mzzk')
      }
    },
    fundraisingStatusChange($event){
      this.productOrganization.page = $event;
      if(type == 4){
        this.findInvestmentDetailsType(this.$route.query.id,'rzzk','mzzk')
      }
    },
    similarSize($event){
      this.productOrganization.pagesize = $event;
      this.productOrganization.page = 1;
      if(type == 4){
        this.similarEnterpriseC(this.$route.query.id,'rzzk','mzzk')
      }
    },
    similarChange($event){
      this.productOrganization.page = $event;
      if(type == 4){
        this.similarEnterpriseC(this.$route.query.id,'rzzk','mzzk')
      }
    },
    zhuanlijishuSize($event){
      this.relatedService.pagesize = $event;
      this.relatedService.page = 1;
      if(type == 6){
        this.parkDetailsType(this.$route.query.id,'zhuanlijishu','zhuanlijishu')
      }else if(type == 3){
        this.enterpriseDetailsType(this.$route.query.id,'zhuanlijishu','zhuanlijishu')
      }
    },
    zhuanlijishuChange($event){
      this.zhuanlijishu.page = $event;
      if(type == 6){
        this.parkDetailsType(this.$route.query.id,'zhuanlijishu','zhuanlijishu')
      }else if(type == 3){
        this.enterpriseDetailsType(this.$route.query.id,'zhuanlijishu','zhuanlijishu')
      }
    },
    FinancingSituationSize($event){
      this.FinancingSituationll.pagesize = $event;
      this.FinancingSituationll.page = 1;
      this.enterpriseDetailsType(this.$route.query.id,'rzzk','rzzk')
    },
    FinancingSituationChange($event){
      this.FinancingSituationll.page = $event;
      this.enterpriseDetailsType(this.$route.query.id,'rzzk','rzzk')
    },
    OperatingConditionsize($event){
      this.OperatingCondition.pagesize = $event;
      this.OperatingCondition.page = 1;
      this.enterpriseDetailsType(this.$route.query.id,'jingyingzhuangk','jyzk')
    },
    OperatingConditioncurrent($event){
      this.OperatingCondition.page = $event;
      this.enterpriseDetailsType(this.$route.query.id,'jingyingzhuangk','jyzk')
    },
    lawllSize($event){
      this.lawll.pagesize = $event;
      this.lawll.page = 1;
      this.enterpriseDetailsType(this.$route.query.id,'falv','falu')
    },
    lawllCurrent($event){
      this.lawll.page = $event;
      this.enterpriseDetailsType(this.$route.query.id,'falv','falu')
    },
    enterpriseBPllSize($event){
      this.enterpriseBPll.pagesize = $event;
      this.enterpriseBPll.page = 1;
      this.enterpriseDetailsType(this.$route.query.id,'bpwj','bp')
    },
    enterpriseBPllCurrent($event){
      this.enterpriseBPll.page = $event;
      this.enterpriseDetailsType(this.$route.query.id,'bpwj','bp')
    },
    OutwardInvestmentSize($event){
      this.OutwardInvestment.pagesize = $event;
      this.OutwardInvestment.page = 1;
      this.enterpriseDetailsType(this.$route.query.id,'duiwaitouzi','duiwaitouzi')
    },
    OutwardInvestmentCurrent($event){
      this.OutwardInvestment.page = $event;
      this.enterpriseDetailsType(this.$route.query.id,'duiwaitouzi','duiwaitouzi')
    },
    matchingCooperationSize($event){
      this.matchingCooperation.pagesize = $event;
      this.matchingCooperation.page = 1;
      this.enterpriseDetailsType(this.$route.query.id,'peitaohezuo','peitaohezuo')
    },
    matchingCooperationCurrent($event){
      this.matchingCooperation.page = $event;
      this.enterpriseDetailsType(this.$route.query.id,'peitaohezuo','peitaohezuo')
    },
    relatedExternallysizeChange($event){
      this.relatedExternally.pagesize = $event;
      this.relatedExternally.page = 1;
      this.enterpriseDetailsType(this.$route.query.id,'peitaohezuo','peitaohezuo')
    },
    relatedExternallycurrentChange($event){
      this.relatedExternally.page = $event;
      this.enterpriseDetailsType(this.$route.query.id,'peitaohezuo','peitaohezuo')
    },
    zxcsstableSize($event){
      this.zxcsstable.pagesize = $event;
      this.zxcsstable.page = 1;
      this.parkDetailsParkpolicy(this.$route.query.id)
    },
    zxcsstableChange($event){
      this.zxcsstable.page = $event;
      this.parkDetailsParkpolicy(this.$route.query.id)
    },
    BPdSize($event){
      this.BPd.pagesize = $event;
      this.BPd.page = 1;
      this.parkDetailsType(this.$route.query.id,'rzzk','rzzk')
    },
    BPdChange($event){
      this.BPd.page = $event;
      this.parkDetailsType(this.$route.query.id,'rzzk','rzzk')
    },
    parkSupportingCooperationSize($event){
      this.parkSupportingCooperation.pagesize = $event;
      this.parkSupportingCooperation.page = 1;
      this.parkDetailsType(this.$route.query.id,'peitaohezuo','peitaohezuo')
    },
    parkSupportingCooperationCurrent($event){
      this.parkSupportingCooperation.page = $event;
      this.parkDetailsType(this.$route.query.id,'peitaohezuo','peitaohezuo')
    },
    handleClick(event) {
      let ar = '';
      this.label.forEach(item => {if(item.label == event.name){ar = item.name}})
      document.getElementById(ar).scrollIntoView({ behavior: "smooth" });
    },
    zxczcasd(item){
      this.$router.push({
          path:'/InvestmentDetails',
          query:{...this.$route.query,id:item.id}
      })
    },
    ccasdawz(item){
      this.$router.push({
          path:'/ggDetails',
          query:{...this.$route.query,id:item.id}
      })
    },
    TLccasdawz(id){
      this.$router.push({
          path:'/InvestmentDetails',
          query:{...this.$route.query,id:id}
      })
    },
    bpclick(item){
      window.open(item.bpwenjian,"_blank");
    },
  }
}
</script>

<style lang="scss" scoped>
.DetailsConsultation_breadcrumb{
      line-height: 44px;
    }
    .registered_right_img{
      font-size: 0;
      margin: 23px 0px 0px 26px;
      img{
        display: inline-block;
        vertical-align: top;
        width: 168px;
        height: 150px;
        margin: 0 20px 20px 0;
      }
    }
.Industrial {
  min-width: 1200px;
  width: 73%;
  margin: 30px auto 0;
  overflow: hidden;
  background: #fff;
  #AnOverview,#registered,#style,#team,#way,#park{margin-bottom: 48px;}
  .Industrial_in{
    width: 90%;
    margin: 50px auto 0;
    overflow: hidden;
    .Industrial_name{
      display: flex;
      margin-bottom: 60px;
      .Industrial_name_h4{
        font-size: 24px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #0F0F0F;
        line-height: 33px;
        margin-bottom: 20px;
      }
      img{
        display: block;
        width: 200px;
        margin-right: 44px;
      }
      .Industrial_name_right{
        h4{
          font-size: 24px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #0F0F0F;
          line-height: 33px;
          margin: 30px 0;
        }
        .Industrial_name_right_text{
          display: inline-block;
          vertical-align: top;
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #757575;
          line-height: 44px;
          width: 50%;
          span{color: #0F0F0F;}
        }
      }
    }
    .Industrial_title{
      font-size: 22px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #0F0F0F;
      line-height: 44px;
      img,span{
        vertical-align: middle;
        margin-right: 10px;
      }
    }
    .AnOverview{
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #555555;
      line-height: 31px;
    }
    .registered{
      border: 1px solid #E3EDFB;
      border-left: 0;
      border-bottom: 0;
      .registered_div{
        display: flex;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #0F0F0F;
        border: 1px solid #E3EDFB;
        border-top: 0;
        border-right: 0;
        .registered_left{
          border-top: 0;
          background: #F6FAFF;
          text-align: center;
          width: 225px;
          position: relative;
          border-right: 1px solid #E3EDFB;
          div{
            width: 225px;
            p{
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              margin: auto;
              height: 58px;
              line-height: 58px;
            }
          }
        }
        .registered_right{
          display: flex;
          line-height: 58px;
          align-items: center;
          p{
            margin-left: 30px;margin-right: 12px;
          }
        }
        .way_list_left{
          width: 339px;
        }
        .way_list_right{
          margin-left: 137px;
        }
      }
    }
    .style,.way{margin-top: 40px;}
    .project{
      margin-top: 30px;
      font-size: 0;
      line-height: 0;
      .project_list{
        width: calc(20% - 22px);
        margin-right: 27px;
        margin-bottom: 22px;
        display: inline-block;
        vertical-align: top;
        img{
          display: block;
          width: 100%;
        }
        h4{
          font-size: 20px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #111111;
          line-height: 28px;
          margin-top: 8px;
        }
        div{
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ACACAC;
          line-height: 20px;
          i{float: right;}
        }
      }
      .project_list:nth-child(5n){margin-right: 0;}
    }
    .consulting{
      font-size: 0;
    }
    .List_Information{
      display: inline-block;
      vertical-align: top;
      width: calc(50% - 27px);
      margin-right: 54px;
      height: 100px;
      margin-bottom: 20px;
      .List_Information_left{
        width: 200px;
        height: 100%;
        display: inline-block;
        vertical-align: top;
        img{
          display: block;
          width: 100%;
          height: 100%;
        }
      }
      .List_Information_right{
        display: inline-block;
        vertical-align: top;
        height: 100%;
        width: calc(100% - 12px - 200px);
        margin-left: 12px;
        position: relative;
        cursor: pointer;
        h6{
          display: -webkit-box;
          font-size: 20px;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
        p{
          position: absolute;
          left: 0;
          bottom: 0;
          font-size: 12px;
          color: #757575;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
      }
    }
    .List_Information:nth-child(2n){margin-right: 0;}
    .financial{
      height: 236px;
      font-size: 0;
      margin-bottom: 30px;
      .financial_left{
        display: inline-block;
        vertical-align: top;
        img{
          display: block;
          width: 277px;
          height: 100%;
          object-fit: scale-down;
        }
      }
      .financial_right{
        display: inline-block;
        vertical-align: top;
        width: calc(100% - 315px);
        background: #FFFFFF;
        border: 1px solid #E9E9E9;
        padding-left: 36px;
        padding-bottom: 12px;
        h3{
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #605F5F;
          line-height: 53px;
        }
        .financial_text{
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #605F5F;
          line-height: 24px;
        }
      }
    }
  }
  .OwnershipStructure_val{
    width: 84%;
    margin: auto;
    position: relative;
    .OwnershipStructure_val_in{
      display: flex;
      justify-content: center;
      div{
        font-size: 18px;
        font-weight: 400;
        color: #101010;
        line-height: 84px;
        span{
          display: inline-block;
          vertical-align: middle;
          width: 100px;
          height: 1px;
          background-color: #D6D6D6;
        }
        i{
          display: inline-block;
          width: 1px;
          height:84px;
          vertical-align: bottom;
          background-color: #D6D6D6;
        }
        .div_i_top{height: 40px;}
        .div_i_bottom{height: 45px;vertical-align: top;}
      }
    }
    .OwnershipStructure_val_but{
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      height: 74px;
      width: 346px;
      text-align: center;
      line-height: 74px;
      i{
        display: inline-block;
        vertical-align: middle;
        width: 40px;
        height: 1px;
        background-color: #D6D6D6;
      }
      .OwnershipStructure_val_but_el{
        width: 266px;
        height: 74px;
        background: #2C7CD7;
        border-radius: 8px;
        font-size: 24px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
      }
    }
  }

}
.List_Information:hover{
  .List_Information_right h6{
    color: #2C7CD7;
  }
}
.FindIndustry_sapn{
  display: inline-block;
  vertical-align: top;
  margin-left: 24px;
  height: 65px;
  position: relative;
  width: calc(100% - 65px - 24px);
  text-align: left;
  h4{
    width: 100%;
    font-size: 18px;margin-top: 10px;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
  }
  span{
    font-size: 12px;
    line-height: 16px;
    position: absolute;
    bottom: 0;
    width: 100%;
    color: #A7A7A7;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
}
.PatentedTechnology_in{
  width: 100%;
  div{
    width: 100%;
    img{
      display: block;
      width: 100%;
    }
  }
  h4{
    font-size: 20px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 400;
    color: #111111;
    line-height: 28px;
  }
  p{
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ACACAC;
    line-height: 20px;
    margin-bottom: 17px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
}
.bp_val{
  font-size: 0;
  margin-top: 30px;
  img{
    height: 40px;
  }
  div{
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 68px);
    margin-left: 23px;
    p{
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #030303;
      line-height: 20px;
      display: -webkit-box;   /*必须结合的属性,将对象作为弹性伸缩盒子模型显示*/
            -webkit-line-clamp: 3;  /*设置多少行*/
            -webkit-box-orient: vertical;   /*必须结合的属性,设置或检索伸缩盒对象的子元素的排列方式*/
            overflow: hidden;   /*文本会被修剪，并且剩余的部分不可见*/
            text-overflow: ellipsis;   /*显示省略号来代表被修剪的文本*/
    }
  }
}
@media screen and (max-width:1200px) {
  .Industrial{
    width: 90%;
    min-width: auto;
    .Industrial_in{
      .Industrial_title{
        text-align: center;
        line-height: 64px;
        font-size: 24px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #0F0F0F;
        img{display: none;}
      }
    }
  }
  .way_list_right{margin-left: 0 !important;}
  .way_list_left{
    width: initial !important;
    div{width: 160px !important;}
  }
  .Industrial_name,.Industrial_label{display: none !important;}
  .ydd_Industrial_name{
    display: block !important;
    overflow: hidden;
    .ydd_Industrial_h4{
      font-size: 30px;
      color: #0F0F0F;
      line-height: 42px;
    }
    .ydd_Industrial_img{object-fit: scale-down;width: 100%;}
    .ydd_Industrial_label_lab{
      border-bottom: 1px solid #D4D4D4;
      line-height: 40px;
      font-size: 20px;
      padding: 10px 0;
      p{
        color: #757575;
      }
    }
  }
  .registered{display: none;}
  .ydd_registered{
    display: block !important;
    .ydd_registered_label{
      font-size: 20px;
      line-height: 44px;
      border-bottom: 1px solid #D4D4D4;
      padding: 10px 0;
      div{display: inline-block;vertical-align: top;color: #757575;font-size: 24px;}
      p{display: inline-block;vertical-align: top;}
    }
  }
  .OwnershipStructure_val{
    .OwnershipStructure_val_in{
      p{width: 17% !important;flex: auto;}
      div{
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        span{    width: auto;flex: 1;}
        .div_i_top{margin-top: 42px;}
        .div_i_bottom{margin-bottom: 42px;}
      }
    }
    .OwnershipStructure_val_but{
      width: 45% !important;
      i{width: 14% !important;}
      .OwnershipStructure_val_but_el{width: 72% !important;    font-size: 21px;height: 64px;}
    }
  }
  .project_list,.project_list:nth-child(5n){
    width: calc(50% - 27px) !important;
    margin-right: 27px !important;
  }
  .project_list:nth-child(2n){margin-right: 0;}
  .pc_financial{display: none;}
  .ydd_financial{display: block !important;}
  .ydd_financial_list_img{width: 100%;}
  .ydd_financial_list_h3{
    font-size: 24px;
    color: #0F0F0F;
    line-height: 44px;
    font-weight: 400;
  }
  .financial_text{
    font-size: 22px;
    color: #605F5F;
    line-height: 34px;
  }
  .List_Information{width: 100% !important;}
  .way{display: block !important;}
}
::v-deep {
  .el-pagination{text-align: center;}
  .el-table th.el-table__cell > .cell{text-align: center;}
  .el-tabs__item:hover,.el-tabs__item.is-active{
      color: #2C7CD7;
  }
  .el-tabs__active-bar{background-color: #2C7CD7;}
  .el-table th{
    background: #F6FAFF;
  }
  .el-table td{
    text-align: center;
  }
  .pagination{
    text-align: center;
    display: block;
    margin:50px auto 50px;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active{
    background: #2C7CD7;
  }
  .el-pagination.is-background .el-pager li:not(.disabled):hover{
    color: #2C7CD7;
  }
  .condition{
    .el-table .el-table__cell{text-align: center;}
  }
  .el-button--text{color: #2C7CD7;}
  .c-mini-toolbar{display: none;}
  @media screen and (max-width:1200px) {
    .el-col-4{width: 33.33333333333333333333333333%;}
    #bp{
      .el-col-8,.el-row{padding: 0 !important;margin: 0 !important;}
      .el-col-8{
        width:calc(50% - 20px);margin-right: 20px !important;
      }
      .el-col-8:nth-child(2n){margin-right: 0 !important;}
    }
  }
}
</style>

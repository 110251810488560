<template>
  <div class="mySupply">
    <el-tabs v-model="activeName">
      <el-tab-pane label="供应信息" name="0">
        <div class="myPurchase_list myPurchase_message" v-for="(d, index) in gongyin.list" :key="index">
          <div class="item_list_inlink myPurchase_message1">
            <div class="item_list_tit">
              {{d.title}}
              <span>{{d.jjcdname}}</span>
            </div>
            <div class="item_list_text"><i></i>适用车型: {{d.sycxname}}</div>
            <div class="item_list_text"><i></i>认证要求: {{d.rzyqname}}</div>
            <div class="item_list_text"><i></i>采购量: {{d.shuliang}}</div>
          </div>
          <div class="myPurchase_message2">
            <div class="myPurchase_message2_in">
              <div class="messagedel1">
                <div>
                  <p>供应商信息</p>
                </div>
              </div>
              <div class="messagedel2">
                <el-row :gutter="20">
                  <el-col :span="12">
                    <div class="item_list_text"><i></i>公司名称: {{d.gsmc}}</div>
                    <div class="item_list_text"><i></i>姓名: {{d.xingming}}</div>
                    <div class="item_list_text"><i></i>邮箱：{{d.youxiang}}</div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item_list_text"><i></i>手机号: {{d.shoujihaoma}}</div>
                    <div class="item_list_text"><i></i>金额: {{d.jine}}</div>
                    <div class="item_list_text"><i></i>数量：{{d.shuliang}}</div>
                  </el-col>
                </el-row>
              </div>
              <div class="messagedel3">
                <div class="messagedel3_div">
                  <div>
                    <el-button class="institution_val_but" type="text" style="color: #0089EF;" @click="listClisk(d)">查看</el-button>
                    <el-button class="institution_val_but" type="text" style="color: #6C6C6C;" @click="unmount(d,index)">{{d.xiajia == '2'?'上架':'下架'}}</el-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="institution_val_pagination">
          <el-pagination
            background
            prev-text="上一页"
            next-text="下一页"
            layout="prev, pager, next"
            :current-page="gongyinPage"
             @current-change="sizeChange($event)"
            :total="gongyin.total">
          </el-pagination>
        </div>
      </el-tab-pane>
      <!-- <el-tab-pane label="我的收藏" name="1">
        <div class="myPurchase_list">
          <div class="item_list_inlink">
            <div class="item_list_tit">
              驻车手柄
              <span>紧急采购</span>
            </div>
            <div class="item_list_text"><i></i>适用车型: 不限</div>
            <div class="item_list_text"><i></i>适用车型: 不限</div>
            <div class="item_list_text"><i></i>适用车型: 不限</div>
          </div>
          <div class="item_list_right">
            <div class="item_list_r">
              <img src="" alt="">
              <p>某新能源主机厂</p>
              <span>30分钟前发布</span>
            </div>
            <div class="item_list_l"><img src="../../../public/NewImage/hot.png" alt="">收藏</div>
          </div>
        </div>
        <div class="institution_val_pagination">
          <el-pagination
            background
            prev-text="上一页"
            next-text="下一页"
            layout="prev, pager, next"
            :total="1000">
          </el-pagination>
        </div>
      </el-tab-pane> -->
    </el-tabs>
  </div>
</template>

<script>
import request from '@/api/request.js';
export default {
  data() {
    return {
      activeName:'0',
      dialogVisible:false,
      ruleForm: {
        title:'',
        cplb:'',
        jzsj:'',
        jinjichengdu:3,
        sycx:'',
        jine:'',
        shuliang:'',
        diqu:'',
        gsmc:'',
        xingming: '',
        shoujihaoma: '',
        youxiang: '',
        content: '',
      },
      rules: {
        title: [{ required: true, message: '请输入	采购标题', trigger: 'blur' }],
        cplb: [{ required: true, message: '请选择产品类别', trigger: 'blur' }],
        jzsj: [{ required: true, message: '请输入截止时间', trigger: 'blur' }],
        sycx: [{ required: true, message: '请输入适用车型', trigger: 'blur' }],
        jine: [{ required: true, message: '请输入金额', trigger: 'blur' }],
        shuliang: [{ required: true, message: '请输入数量', trigger: 'blur' }],
        diqu: [{ required: true, message: '请输入地址', trigger: 'blur' }],
        gsmc: [{ required: true, message: '请输入公司名称', trigger: 'blur' }],
        xingming: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        shoujihaoma: [
          { min: 11, message: "手机号不足11位", trigger: "blur" },
          { validator: (rule, value, callback) => {
            if (!value) { return callback(new Error("手机号不能为空"))} else { const reg = /^1[3-9][0-9]\d{8}$/;
              if (reg.test(value)) {callback()} else {
                return callback(new Error("请输入正确的手机号"));
              }
            }
          }, trigger: "blur" },
        ],
        youxiang: [{ required: true, message: '请输入邮箱', trigger: 'blur' }],
      },
      cplbList:[],
      dizhi:[],
      defaultParams: {
        label: "val",
        value: "type",
      },
      xzqhoption: {
        value: 'type',
        label: 'val',
      },
      gongyinPage:1,
      gongyin:{total:null}
    }
  },
  mounted() { this.purchaseRegistration() },
  methods:{
    purchaseRegistration(){
      request.purchaseRegistration(this.gongyinPage).then((res) => {
        this.gongyin = res.data.data;
        this.gongyin.total = parseInt(res.data.data.maxnum)
      })
    },
    listClisk(item){
      this.$router.push({
        path:'/purchaseDetail',
        query:{...this.$route.query,id:item.id}
      })
    },
    sizeChange(idx){
      this.gongyinPage = idx;
      this.purchaseRegistration()
    },
    unmount(d,i){
      request.purchaseOffShelf(d.id).then((res) => {
        this.$message({
          message: res.data.msg,
          type: 'success'
        });
        this.purchaseRegistration()
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.mySupply{
  padding: 10px 20px;
  position: relative;
  .myPurchase_but{
    position: absolute;
    top: 10px;
    right: 20px;
    width: 140px;
    height: 34px;
    background: #0089EF;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    border: 0;
    outline: none;
    cursor: pointer;
  }
  .myPurchase_list{
    background: #FFFFFF;
    border: 1px solid #0089EF;
    margin-top: 20px;
    padding: 18px;
    .item_list_inlink{
      display: inline-block;
      vertical-align: middle;
      .item_list_tit{
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #0089EF;
        line-height: 25px;
        span{
          margin-left: 35px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #EF0000;
          line-height: 20px;
        }
      }
      .item_list_text{
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #828282;
        line-height: 20px;
        i{
          display: inline-block;
          vertical-align: middle;
          width: 6px;
          height: 6px;
          background: #EEEEEE;
          border-radius: 50%;
          margin-right: 7px;
        }
      }
    }
    .item_list_right{
      float: right;
      // text-align: right;
      .item_list_r{
        display: inline-block;
        vertical-align: middle;
        img{
          width: 40px;
          height: 40px;
        }
        p{
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #828282;
          line-height: 20px;
          margin: 10px 0 10px;
        }
        span{
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #C3C3C3;
          line-height: 17px;
        }
      }
      .item_list_l{
        display: inline-block;
        vertical-align: middle;
        padding: 0 48px;
        cursor: pointer;
      }
    }
    .myPurchase_message1{
      width: 40%;
      border-right: 1px solid #E7E7E7;
    }
    .myPurchase_message2{
      width: calc(60% - 1px);
      display: inline-block;
      vertical-align: top;
      font-size: 0;
      line-height: 140px;
      .myPurchase_message2_in{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100%;
      }
      .messagedel1{
        width: 70px;
        height: 70px;
        background: rgba(0,137,239,0.2);
        margin: 0 46px;
        div{
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          p{
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #005492;
            width: 42px;
            line-height: 20px;
          }
        }
      }
      .messagedel2{
        flex: 1;
        .item_list_text{
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #828282;
          line-height: 20px;
          i{
            display: inline-block;
            vertical-align: middle;
            width: 6px;
            height: 6px;
            background: #EEEEEE;
            border-radius: 50%;
            margin-right: 7px;
          }
        }
      }
      .messagedel3{
        width: 58px;
        background: #F2F2F2;
        line-height: 20px;
        text-align: center;
        height: 140px;
        .messagedel3_div{
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          .institution_val_but{
            display: block;
            margin: auto;
            padding: 4px 0;
          }
        }

      }
    }
  }
  .myPurchase_message{
    line-height: 140px;
    padding: 0;
    padding-left: 18px;
  }
}
::v-deep{
  .el-table th,.el-table td{text-align: center;}
  .el-table th{background-color: #F5F5F5 !important;}
  .institution_val_pagination{
    text-align: center;
    background-color: #fff;
    padding: 0 20px 30px;
    margin-top: 20px;
    .el-pagination{padding: 0;}
    .btn-next,.btn-prev{
      padding: 0 10px !important;
    }
    .el-pager li.active{background-color: #0089EF !important;}
  }
}
</style>

const http = 'https://admin.aiauto.shop/index.php?v=1&appid=2&appsecret=CHEZHUANGTOUE921805707FA3&';
let member = {};
let JSONmember = '';
if(window.localStorage.getItem('code')){
  member = {
    'api_auth_code':window.localStorage.getItem('code'),
    'api_auth_uid':JSON.parse(window.localStorage.getItem('individual')).id
  }
  JSONmember =`&api_auth_code=${window.localStorage.getItem('code')}&api_auth_uid=${JSON.parse(window.localStorage.getItem('individual')).id}`
}else{
  member = {
    'api_auth_code':0,
    'api_auth_uid':0
  };
  JSONmember =`&api_auth_code=${0}&api_auth_uid=${0}`
}

const request = {
  //推荐
  getrecommend() {
    return window.axios({
      url: `${http}api_call_function=rec_ent`+JSONmember,
      method: "get",
    });
  },
  //菜单
  columnnew() {
    return window.axios({
      url: `${http}s=httpapi&c=index`+JSONmember,
      method: "get",
    });
  },
  // logo
  logoIMG(){
    return window.axios({
      url: `${http}s=httpapi&c=index&m=logo&api_auth_code=0b0559a093bba2e3b1f2f2423d12e6b6`,
      method: "get",
    });
  },
  //百度文心一言接口
  chatAi(content) {
    return window.axios({
      url: `${http}api_call_function=baidu_chat&content=`+content+JSONmember,
      method: "get",
    });
  },
  //汽车领域法律服务接口
  falvfuwu(pid) {
    return window.axios({
      url: `${http}api_call_function=fawulvsuo&c=show&id=`+pid+JSONmember,
      method: "get",
    });
  },
  //3D 模型数据
  zhuyaochanpin3d(pid) {
    return window.axios({
      url: `${http}api_call_function=normal&c=show&id=`+pid+JSONmember,
      method: "get",
    });
  },
  /**
   * 栏目
   */
  column(pid) {
    return window.axios({
      url: `${http}api_call_function=category_data&s=httpapi&m=category&mid=share&pid=`+pid+JSONmember,
      method: "get",
    });
  },
  /**
   * 主栏目{{id}}
   */
  columnID(catid) {
    return window.axios({
      url: `${http}s=che&c=apis&catid=`+catid+JSONmember,
      method: "get",
    });
  },
  /**
   * 图片验证码
   */
  verificationCode() {
    return `${http}s=api&c=api&m=captcha`;
  },
  /**
   * 发送短信验证码
   */
  verificationCodeShort(mobile,code) {
    return window.axios({
      url: `${http}s=member&c=api&m=register_code&mobile=${mobile}&code=${code}`,
      method: "get",
    });
  },
  /**
   * 登录
   */
  logo(data) {
    var fd = window.qs.stringify(data);
    return window.axios({
      url: `${http}s=member&c=login`,
      method: "post",
      headers: {
        'Content-Type':'application/x-www-form-urlencoded'
      },
      data:fd
    });
  },
  /**
   * 注册
   */
  register(data) {
    var fd = window.qs.stringify(data);
    return window.axios({
      url: `${http}s=member&c=register&api_call_function=format_register`,
      method: "post",
      headers: {
        'Content-Type':'application/x-www-form-urlencoded'
      },
      data:fd
    });
  },
  /**
   * 网站信息
   */
  websiteInformation() {
    return window.axios({
      url: `${http}s=httpapi&m=site`+JSONmember,
      method: "get",
    });
  },
  /**
   * 首页轮播图
   */
  homeWheel() {
    return window.axios({
      url: `${http}s=che&c=data&m=getbanner&type=3`+JSONmember,
      method: "get",
    });
  },
  /**
   * 找资讯列表
   */
  informationList(catid,pagesize,page,input) {
    if(catid.Keyword){
      return window.axios({
        url: `${http}s=news&c=search&api_call_function=list_data&Keyword=${catid.Keyword}`+JSONmember,
        method: "get",
      });
    }else{
      return window.axios({
        url: `${http}s=news&c=search&api_call_function=list_data&catid=${catid}&pagesize=${pagesize}&page=${page}&keyword=${input}`+JSONmember,
        method: "get",
      });
    }
  },
  /**
   * 找资讯详情
   */
  informationDetails(id) {
    return window.axios({
      url: `${http}api_call_function=detail_data&c=show&id=${id}`+JSONmember,
      method: "get",
    });
  },
  informationDetailsN(id) {
    return window.axios({
      url: `${http}api_call_function=normal&c=show&s=xgzx&id=${id}`+JSONmember,
      method: "get",
    });
  },
  /**
   * 找活动列表
   */
  activityList(catid,pagesize,page) {
    if(catid.Keyword){
      return window.axios({
        url: `${http}s=zhaohuodong&c=search&api_call_function=huodong_data&Keyword=${catid.Keyword}`+JSONmember,
        method: "get",
      });
    }else{
      return window.axios({
        url: `${http}s=zhaohuodong&c=search&api_call_function=huodong_data&catid=${catid}&pagesize=${pagesize}&page=${page}`+JSONmember,
        method: "get",
      });
    }

  },
  /**
   * 找产业列表
   */
  industryList(catid,pagesize,page,input) {
    if(catid.Keyword){
      return window.axios({
        url: `${http}s=zhaochanye&c=search&api_call_function=list_data&Keyword=${catid.Keyword}`+JSONmember,
        method: "get",
      });
    }else{
      return window.axios({
        url: `${http}s=zhaochanye&c=search&api_call_function=list_data&catid=${catid}&pagesize=${pagesize}&page=${page}&keyword=${input}`+JSONmember,
        method: "get",
      });
    }
  },
  /**
   * 找产业详情
   */
  industryDetails(id) {
    return window.axios({
      url: `${http}api_call_function=detail_data&c=show&id=${id}`+JSONmember,
      method: "get",
    });
  },
  /**
   * 找投资列表
   */
  LookingInvestmentList(catid,pagesize,page,input) {
    if(catid.Keyword){
      return window.axios({
        url: `${http}s=zhaotouzi&c=search&api_call_function=list_data&Keyword=${catid.Keyword}`+JSONmember,
        method: "get",
      });
    }else{
      return window.axios({
        url: `${http}s=zhaotouzi&c=search&api_call_function=list_data&catid=${catid}&pagesize=${pagesize}&page=${page}&keyword=${input}`+JSONmember,
        method: "get",
      });
    }
  },
  /**
   * 找投资详情
   */
  findInvestmentDetails(id) {
    return window.axios({
      url: `${http}api_call_function=detail_ztz&c=show&id=${id}`+JSONmember,
      method: "get",
    });
  },
  /**
   * 找投资详情{{投资风格}}
   */
  findInvestmentDetailsInvestment(id,pagesize,page) {
    return window.axios({
      url: `${http}s=che&c=data&m=tzfg&id=${id}&pagesize=${pagesize}&page=${page}`+JSONmember,
      method: "get",
    });
  },
  findInvestmentDetailsInvestment1(id,pagesize,page) {
    return window.axios({
      url: `${http}&s=che&c=detail&m=zyqxqlst&id=${id}&table=tourongzifuwu&field=trzfw&pagesize=${pagesize}&page=${page}`+JSONmember,
      method: "get",
    });
  },
  /**
   * 找投资详情{{投资方向项目/主要团队/设立基金/金融产品/募资状况/投资项目}}
   */
  findInvestmentDetailsType(id,table,field,pagesize,page) {
    return window.axios({
      url: `${http}s=che&c=detail&m=ztzlist&id=${id}&table=${table}&field=${field}&pagesize=${pagesize}&page=${page}`+JSONmember,
      method: "get",
    });
  },
  /**
   * 相关资讯
   */
  relatedInformation(id,pagesize,page) {
    return window.axios({
      url: `${http}s=che&c=data&m=zyqxgzx&id=${id}&pagesize=${pagesize}&page=${page}`+JSONmember,
      method: "get",
    });
  },
  relatedInformation1(id,pagesize,page) {
    return window.axios({
      url: `${http}s=che&c=detail&m=ztzlist&id=${id}&table=news&field=xgzx&pagesize=${pagesize}&page=${page}`+JSONmember,
      method: "get",
    });
  },
  /**
   * 同类企业
   */
  similarEnterprise(id,pagesize,page) {
    return window.axios({
      url: `${http}s=che&c=detail&m=tlqy&id=${id}&pagesize=${pagesize}&page=${page}`+JSONmember,
      method: "get",
    });
  },
  /**
   * 找金能列表
   */
  JinNengList(catid,pagesize,page,input) {
    return window.axios({
      url: `${http}s=zhaojinneng&c=search&api_call_function=list_data&catid=${catid}&pagesize=${pagesize}&page=${page}&keyword=${input}`+JSONmember,
      method: "get",
    });
  },
  /**
   * 找金能详情
   */
  JinNengDetails(catid) {
    return window.axios({
      url: `${http}api_call_function=detail_data&c=show&id=${catid}`+JSONmember,
      method: "get",
    });
  },
  /**
   * 找企业列表
   */
  enterpriseList(catid, pagesize, page, kewObj, keyword) {
    return window.axios({
      url: `${http}s=zhaoqiye&c=search&api_call_function=zqylist&catid=${catid}&chanyeleibie=${catid}&pagesize=${pagesize}&page=${page}&keyword=${keyword||''}`+JSONmember,
      method: "get",
      params:kewObj
    });
  },
  /**
   * 找企业搜索
   */
  enterpriseListSearch(data) {
    var fd = window.qs.stringify({...data});
    return window.axios({
      url: `${http}s=zhaoqiye&c=search&api_call_function=zqylist`+JSONmember,
      method: "post",
      headers: {
        'Content-Type':'application/x-www-form-urlencoded'
      },
      data:fd
    });
  },
  /**
   * 找企业搜索
   */
  qgxxSearch(data) {
    var fd = window.qs.stringify({...data});
    return window.axios({
      url: `${http}s=qgxx&c=search`+JSONmember,
      method: "post",
      headers: {
        'Content-Type':'application/x-www-form-urlencoded'
      },
      data:fd
    });
  },
  /**
    * 找企业详情
    */
  enterpriseDetails(catid) {
    return window.axios({
      url: `${http}api_call_function=zqyxq&c=show&s=zhaoqiye&id=${catid}`+JSONmember,
      method: "get",
    });
  },
  /**
   * 找企业详情{{主要团队}}
   */
  enterprisePrimaryTeam(id,pagesize,page) {
    return window.axios({
      url: `${http}s=che&c=detail&m=zqyzytd&id=${id}&pagesize=${pagesize}&page=${page}`+JSONmember,
      method: "get",
    });
  },
  /**
   * 找企业详情{{主要产品/专利技术/融资状况/经营状况/法律/BP/对外投资/配套合作}}
   */
  enterpriseDetailsType(id,table,field,pagesize,page) {
    return window.axios({
      url: `${http}s=che&c=detail&m=zqyzycp&id=${id}&table=${table}&field=${field}&pagesize=${pagesize}&page=${page}`+JSONmember,
      method: "get",
    });
  },
  enterpriseDetailsType1(id,table,field,pagesize,page) {
    return window.axios({
      url: `${http}s=che&c=detail&m=zyqxqlst&id=${id}&table=${table}&field=${field}&pagesize=${pagesize}&page=${page}`+JSONmember,
      method: "get",
    });
  },
  enterpriseDetailsType2(id,table,field,pagesize,page) {
    return window.axios({
      url: `${http}s=che&c=detail&m=ztzlist&id=${id}&table=${table}&field=${field}&pagesize=${pagesize}&page=${page}`+JSONmember,
      method: "get",
    });
  },
  /**
   * 找投行列表
   */
  investmentBank(keyword,catid,pagesize,page) {
    return window.axios({
      url: `${http}s=zhaotouhang&c=search&api_call_function=zqylist&keyword=${keyword}&catid=${catid}&pagesize=${pagesize}&page=${page}`+JSONmember,
      method: "get",
    });
  },
  /**
   * 找投行详情
   */
  investmentBankDetails(id) {
    return window.axios({
      url: `${http}api_call_function=zqyxq&c=show&id=${id}`+JSONmember,
      method: "get",
    });
  },
  /**
   * 找投资详情{{投资方向项目/主要团队/设立基金/金融产品/募资状况/投资项目}}
   */
  investmentBankType(id,table,field,pagesize,page) {
    return window.axios({
      url: `${http}s=che&c=detail&m=ztzlist&id=${id}&table=${table}&field=${field}&pagesize=${pagesize}&page=${page}`+JSONmember,
      method: "get",
    });
  },
  /**
   * 找投行详情{{机构风格/机构方向项目/机构项目/主要团队/设立基金/机构状况/机构业务}}
   */
  investmentType(id,table,field,pagesize,page) {
    return window.axios({
      url: `${http}s=che&c=detail&m=zthlist&id=${id}&table=${table}&field=${field}&pagesize=${pagesize}&page=${page}`+JSONmember,
      method: "get",
    });
  },
  /**
   * 找投行详情{{相关资讯}}
   */
  investmentTypeCorrelation(id,pagesize,page) {
    return window.axios({
      url: `${http}s=che&c=data&m=zthxgzx&id=${id}&pagesize=${pagesize}&page=${page}`+JSONmember,
      method: "get",
    });
  },
  /**
   * 找投行详情{{同类企业}}
   */
  investmentSimilarEnterprise(id,pagesize,page) {
    return window.axios({
      url: `${http}s=che&c=detail&m=zthtlqy&id=${id}&pagesize=${pagesize}&page=${page}`+JSONmember,
      method: "get",
    });
  },
  /**
   * 找园区列表
   */
  parkList(keyword,catid,pagesize,page) {
    return window.axios({
      url: `${http}s=zhaoyuanqu&c=search&api_call_function=list_data&keyword=${keyword}&catid=${catid}&pagesize=${pagesize}&page=${page}`+JSONmember,
      method: "get",
    });
  },
  /**
   * 找园区详情
   */
  parkDetails(id) {
    return window.axios({
      url: `${http}api_call_function=detail_zyq&c=show&id=${id}`+JSONmember,
      method: "get",
    });
  },
  /**
    * 找园区详情{{相关资讯}}
    */
  parkDetailsInformation(id,pagesize,page) {
    return window.axios({
      url: `${http}s=che&c=data&m=zyqxgzx&id=${id}&pagesize=${pagesize}&page=${page}`+JSONmember,
      method: "get",
    });
  },
  /**
    * 找园区详情{{园区政策}}
    */
  parkDetailsParkpolicy(id,pagesize,page) {
    return window.axios({
      url: `${http}s=che&c=data&m=zyqyqzc&id=${id}&pagesize=${pagesize}&page=${page}`+JSONmember,
      method: "get",
    });
  },
  /**
    * 找园区详情{{招商引资}}
    */
  parkDetailCapital(id,pagesize,page) {
    return window.axios({
      url: `${http}s=che&c=data&m=zyqzsyz&id=${id}&pagesize=${pagesize}&page=${page}`+JSONmember,
      method: "get",
    });
  },
  /**
    * 找园区详情{{投融资服务}}
    */
  parkService(id,pagesize,page) {
    return window.axios({
      url: `${http}s=che&c=data&m=zyqtrzfw&id=${id}&pagesize=${pagesize}&page=${page}`+JSONmember,
      method: "get",
    });
  },
  /**
    * 找园区详情{{对外投资}}
    */
  parkExternally(id,pagesize,page) {
    return window.axios({
      url: `${http}s=che&c=data&m=zyqdwtz&id=${id}&pagesize=${pagesize}&page=${page}`+JSONmember,
      method: "get",
    });
  },
  parkExternally1(id,pagesize,page) {
    return window.axios({
      url: `${http}s=che&c=detail&m=zyqxqlst&id=${id}&table=duiwai&field=duiwaitouzi&pagesize=${pagesize}&page=${page}`+JSONmember,
      method: "get",
    });
  },
  /**
    * 找园区详情{{主要团队}}
    */
  ParkTeam(id,pagesize,page) {
    return window.axios({
      url: `${http}s=che&c=detail&m=zyqxqlst&id=${id}&pagesize=${pagesize}&page=${page}&table=zhaoshequn&field=zytd`+JSONmember,
      method: "get",
    });
  },
  /**
    * 找园区详情{{主要产品/园区专利技术/BP/配套合作}}
    */
  parkDetailsType(id,table,field,pagesize,page) {
    return window.axios({
      url: `${http}s=che&c=detail&m=zyqxqlst&id=${id}&table=${table}&field=${field}&pagesize=${pagesize}&page=${page}`+JSONmember,
      method: "get",
    });
  },
  /**
   * 找社群列表
   */
  investmentList(catid,pagesize,page,input) {
    if(catid.Keyword){
      return window.axios({
        url: `${http}s=zhaoshequn&c=search&api_call_function=list_data&Keyword=${catid.Keyword}`+JSONmember,
        method: "get",
      });
    }else{
      return window.axios({
        url: `${http}s=zhaoshequn&c=search&api_call_function=list_data&catid=${catid}&pagesize=${pagesize}&page=${page}&keyword=${input}`+JSONmember,
        method: "get",
      });
    }
  },
  /**
   * 找活动详情
   */
  activityDetails(id) {
    return window.axios({
      url: `${http}api_call_function=hdxq&c=show&id=${id}`+JSONmember,
      method: "get",
    });
  },
  /**
   * 找社群详情
   */
  CommunityDetails(id) {
    return window.axios({
      url: `${http}api_call_function=zqyxq&c=show&id=${id}`+JSONmember,
      method: "get",
    });
  },
  /**
   * 找社群详情{{业务专长/过往经历/过往业绩/主要风格/主要业绩/项目/人脉关系}}
   */
  CommunityDetailsType(id,table,field,pagesize,page) {
    return window.axios({
      url: `${http}s=che&c=detail&m=zsqlist&id=${id}&table=${table}&field=${field}&pagesize=${pagesize}&page=${page}`+JSONmember,
      method: "get",
    });
  },
  CommunityDetailsTyp1(id,table,field,pagesize,page) {
    return window.axios({
      url:`${http}s=che&c=data&m=moduleRelatedlist&id=${id}&pagesize=${pagesize}&page=${page}&relatedTable=${table}&relatedParame=${field}&table=zhaoshequn`+JSONmember,
      method: "get",
    });
  },
  /**
   * 报名
   */
  signUp(data) {
    var fd = window.qs.stringify(data);
    return window.axios({
      url: `${http}s=che&c=signup&m=signup`+JSONmember,
      method: "post",
      headers: {
        'Content-Type':'application/x-www-form-urlencoded'
      },
      data:fd
    });
  },
  /**
   * 网站注册协议
   */
  RegistrationAgreement() {
    return window.axios({
      url: `${http}s=httpapi&id=1`,
      method: "get",
    });
  },
  /**
   * 修改资料
   */
  modifyData(data,api_auth_code,api_auth_uid) {
    var fd = window.qs.stringify({...data,...member});
    return window.axios({
      url: `${http}s=member&c=account&m=index&api_auth_code=${api_auth_code}&api_auth_uid=${api_auth_uid}`,
      method: "post",
      headers: {
        'Content-Type':'application/x-www-form-urlencoded'
      },
      data:fd,
    });
  },
  /**
   * 修改密码
   */
  ChangePassword(data,api_auth_code,api_auth_uid) {
    var fd = window.qs.stringify({data:{...data,...member}});
    return window.axios({
      url: `${http}s=member&c=account&m=password&api_auth_code=${api_auth_code}&api_auth_uid=${api_auth_uid}`,
      method: "post",
      headers: {
        'Content-Type':'application/x-www-form-urlencoded'
      },
      data:fd,
    });
  },
  /**
   * 我的报名
   */
  myRegistration(cate_id,api_auth_code,api_auth_uid) {
    var fd = window.qs.stringify({'cate_id':cate_id,...member});
    return window.axios({
      url: `${http}s=che&c=data&m=mysign&api_auth_code=${api_auth_code}&api_auth_uid=${api_auth_uid}`,
      method: "post",
      headers: {
        'Content-Type':'application/x-www-form-urlencoded'
      },
      data:fd,
    });
  },
  /**
   * 我的会员
   */
  myMember(api_auth_code,api_auth_uid) {
    return window.axios({
      url: `${http}s=che&c=data&m=myvip`+JSONmember,
      method: "post",
      headers: {
        'Content-Type':'application/x-www-form-urlencoded'
      },
    });
  },
  /**
   * 我的广告
   */
  myAD() {
    return window.axios({
      url: `${http}s=che&c=data&m=myadvert`+JSONmember,
      method: "post",
      headers: {
        'Content-Type':'application/x-www-form-urlencoded'
      },
    });
  },
  /**
   * 我的下载
   */
  myCourse() {
    return window.axios({
      url: `${http}s=che&c=data&m=mydownload`+JSONmember,
      method: "get",
    });
  },
  /**
   * 我的课程
   */
  mydownload() {
    return window.axios({
      url: `${http}s=che&c=data&m=mycourse`+JSONmember,
      method: "get",
    });
  },
  /**
   * 我的资料{{主栏目}}
   */
  myDataColumn() {
    return window.axios({
      url: `${http}s=che&c=data&m=ziliaoType`+JSONmember,
      method: "get",
    });
  },
  /**
   * 开通会员
   */
  OpenMember(data,api_auth_code,api_auth_uid) {
    var fd = window.qs.stringify({...data,...member});
    return window.axios({
      url: `${http}s=che&c=data&m=openvip&api_auth_code=${api_auth_code}&api_auth_uid=${api_auth_uid}`,
      method: "post",
      headers: {
        'Content-Type':'application/x-www-form-urlencoded'
      },
      data:fd
    });
  },
  /**
   * 提交资料
   */
  tjzl(data,api_auth_code,api_auth_uid) {
    var fd = window.qs.stringify({...data,...member});
    return window.axios({
      url: `${http}s=che&c=data&m=ziliaosave&api_auth_code=${api_auth_code}&api_auth_uid=${api_auth_uid}`,
      method: "post",
      headers: {
        'Content-Type':'application/x-www-form-urlencoded'
      },
      data:fd
    });
  },
  /**
   * 开通会员-栏目
   */
  memberColumn(pid,ad,level) {
    let aa = ''
    if(ad == 0){aa = `&pid=${pid}`}else{aa = `&pid=${pid}&ad=1&level=${level}`}
    return window.axios({
      url: `${http}api_call_function=openvip_data&s=httpapi&m=category&mid=share${aa}`+JSONmember,
      method: "get",
    });
  },
  /**
   * 广告位列表
   */
  advertisingSpace(localtion,type,cate_id) {
    var arr = '';
    if(localtion == 1){
      arr = `&localtion=${localtion}&type=${type}`+JSONmember
    }else{
      arr = `&localtion=${localtion}&type=${type}&cate_id=${cate_id}`+JSONmember
    }
    return window.axios({
      url: `${http}s=che&c=data&m=advertlist`+arr,
      method: "get",
    });
  },
  /**
   * 购买广告
   */
  BuyAdvertising(data) {
    var fd = window.qs.stringify({...data,...member});
    return window.axios({
      url: `${http}s=che&c=data&m=openad&api_auth_code=${member.api_auth_code}&api_auth_uid=${member.api_auth_uid}`,
      method: "post",
      headers: {
        'Content-Type':'application/x-www-form-urlencoded'
      },
      data:fd
    });
  },
  /**
   * 我报名的采购信息(供应商)
   */
  purchaseRegistration(page) {
    return window.axios({
      url: `${http}s=member&c=user&m=getmycaigou&page=${page}`+JSONmember,
      method: "get",
    });
  },
  /**
   * 公共详情
   */
  publicParticulars(id) {
    return window.axios({
      url: `${http}api_call_function=zqyxq&c=show&id=${id}`+JSONmember,
      method: "get",
    });
  },
  /**
   * 个人中心-我的采购信息
   */
  MyHaveReleased(data){
    return window.axios({
      url: `${http}s=member&c=user&m=getmycaigou&page=${data.page}&guoqi=${data.guoqi}&xiajia=${data.xiajia}`+JSONmember,
      method: "get",
    });
  },
  /**
   * 个人中心-我的采购信息-查看报名
   */
  getmycaigouinfo(data){
    return window.axios({
      url: `${http}s=member&c=user&m=getmycaigouinfo&page=${data.page}&xxid=${data.id}`+JSONmember,
      method: "get",
    });
  },
  /**
   * 采购上下架
   */
  purchaseOffShelf(xxid){
    return window.axios({
      url: `${http}s=member&c=user&m=setcaigou&xxid=${xxid}`+JSONmember,
      method: "get",
    });
  },
  /**
   * 采购删除
   */
  purchaseDeletion(xxid){
    return window.axios({
      url: `${http}s=member&c=user&m=delcaigou&xxid=${xxid}`+JSONmember,
      method: "get",
    });
  },
  /**
   * 采购收藏
   */
  purchaseCollect(xxid){
    return window.axios({
      url: `${http}s=member&c=user&m=getmycollect`+JSONmember,
      method: "post",
    });
  },

  /**
   * 采购频道页
   */
  PurchaseChannelList(page,cplb,keyword,hot,pcplb) {
    return window.axios({
      url: `${http}s=qgxx&c=search&page=${page}&cplb=${cplb}&keyword=${keyword}&hot=${hot}&pcplb=${pcplb}`+JSONmember,
      method: "get",
    });
  },
  /**
   * 采购信息详情
   */
  PurchaseChannelDetile(id) {
    return window.axios({
      url: `${http}s=qgxx&c=show&id=${id}`+JSONmember,
      method: "get",
    });
  },
  /**
   * 供应商报名采购信息报名接口
   */
  supplierPurchaseSignUp(data) {
    var fd = window.qs.stringify({...data});
    return window.axios({
      url: `${http}s=member&c=user&m=setbaoming`+JSONmember,
      method: "post",
      headers: {
        'Content-Type':'application/x-www-form-urlencoded'
      },
      data:fd,
    });
  },
  /**
   * 收藏采购信息
   */
  setmyCollect(id) {
    return window.axios({
      url: `${http}s=member&c=user&m=setmycollect&xxid=${id}`+JSONmember,
      method: "get",
    });
  },
  /**
   * 添加新的采购信息
   */
  addcaigouSignUp(data) {
    var fd = window.qs.stringify({...data});
    return window.axios({
      url: `${http}s=member&c=user&m=addcaigou&api_auth_code=${member.api_auth_code}&api_auth_uid=${member.api_auth_uid}`,
      method: "post",
      headers: {
        'Content-Type':'application/x-www-form-urlencoded'
      },
      data:fd
    });
  },
  // 入驻(新增)
  ceshi(data){
    var fd = window.qs.stringify({...data});
    return window.axios({
      url: `${http}s=Member&c=user&m=savecompanyinfo`+JSONmember,
      method: "post",
      headers: {
        'Content-Type':'application/x-www-form-urlencoded'
      },
      data:fd
    });
  },
  enterInfo(data){
    var fd = window.qs.stringify({...data});
    return window.axios({
      url: `${http}s=Member&c=user&m=get_companypage`+JSONmember,
      method: "post",
      headers: {
        'Content-Type':'application/x-www-form-urlencoded'
      },
      data:fd
    });
  },
  /**
   * 获取用户信息
   */
  getUserInfo() {
    return window.axios({
      url: `${http}s=member&c=user&m=getUserInfo`+JSONmember,
      method: "get",
    });
  },
  /**
   * 会员收费列表
   */
  membershipFeeList() {
    return window.axios({
      url: `${http}s=payment&c=payment&m=viplist`+JSONmember,
      method: "get",
    });
  },
  /**
   * 开通会员
   */
  openMembership(data) {
    var fd = window.qs.stringify({...data});
    return window.axios({
      url: `${http}s=payment&c=payment&m=openvip`+JSONmember,
      method: "post",
      headers: {
        'Content-Type':'application/x-www-form-urlencoded'
      },
      data:fd
    });
  },
  /**
   * 续费会员
   */
  renew(data) {
    var fd = window.qs.stringify({...data});
    return window.axios({
      url: `${http}s=payment&c=payment&m=renewvip`+JSONmember,
      method: "post",
      headers: {
        'Content-Type':'application/x-www-form-urlencoded'
      },
      data:fd
    });
  },
  /**
   * 支付
   */
  payment(data) {
    var fd = window.qs.stringify({...data});
    return window.axios({
      url: `${http}s=payment&c=payment&m=prepay`+JSONmember,
      method: "post",
      headers: {
        'Content-Type':'application/x-www-form-urlencoded'
      },
      data:fd
    });
  },
  /**
   * 开发票
   */
  invoice(data) {
    var fd = window.qs.stringify({...data});
    return window.axios({
      url: `${http}s=che&c=data&m=invoice`+JSONmember,
      method: "post",
      headers: {
        'Content-Type':'application/x-www-form-urlencoded'
      },
      data:fd
    });
  },
  /**
   * 开通记录
   */
  openingRecord() {
    return window.axios({
      url: `${http}s=payment&c=payment&m=openrecord`+JSONmember,
      method: "get"
    });
  },
  /**
   * 检查是否支付【微信扫码支付】
   */
  checkPayment(data) {
    var fd = window.qs.stringify({...data});
    return window.axios({
      url: `${http}s=che&c=pay&m=checkpay`+JSONmember,
      method: "post",
      headers: {
        'Content-Type':'application/x-www-form-urlencoded'
      },
      data:fd
    });
  },
  /**
   * 首页
   */
  HomeIndex() {
    return window.axios({
      url: `${http}s=httpapi&c=index`+JSONmember,
      method: "get",
    });
  },


  column1(pid) {
    return window.axios({
      url: `${http}c=show&id=`+pid+JSONmember,
      method: "get",
    });
  },
  //找企业
  newget(s,catid,pagesize,page,input) {
    return window.axios({
      url: `${http}s=${s}&c=search&api_call_function=normal&catid=${catid}&pagesize=${pagesize}&page=${page}&keyword=${input}`+JSONmember,
      method: "get",
    });
  },
  newget1(s,s1,catid,pagesize,page) {
    return window.axios({
      url: `${http}s=che&c=data&m=moduleRelatedlist&id=${catid}&pagesize=${pagesize}&page=${page}&relatedTable=${s}&relatedParame=${s1}&table=zhaoqiye`+JSONmember,
      method: "get",
    });
  },
  newget2(s,s1,catid,pagesize,page,table) {
    return window.axios({
      url: `${http}s=che&c=data&m=moduleRelatedlist&id=${catid}&pagesize=${pagesize}&page=${page}&relatedTable=${s}&relatedParame=${s1}&table=${table}`+JSONmember,
      method: "get",
    });
  },
  newget3(catid,pagesize,page) {
    return window.axios({
      url: `${http}s=che&c=data&m=getChartsJson&api_call_function=getChartsJson&id=${catid}&pagesize=${pagesize}&page=${page}&relatedTable=jigfxxm&relatedParame=tzfxxm&table=zhaotouzi`+JSONmember,
      method: "get",
    });
  },
  investmentBank1(keyword,catid,pagesize,page,s,s1) {
    return window.axios({
      // url: `${http}s=${s}&c=search&api_call_function=${s1}&keyword=${keyword}&catid=${catid}&pagesize=${pagesize}&page=${page}`+JSONmember,
      url: `${http}s=${s}&c=search&api_call_function=${s1}&keyword=${keyword}&catid=${catid}&pagesize=${pagesize}&page=${page}`+JSONmember,
      method: "get",
    });
  },
  investmentBank11(keyword,catid,pagesize,page) {
    return window.axios({
      // url: `${http}s=${s}&c=search&api_call_function=${s1}&keyword=${keyword}&catid=${catid}&pagesize=${pagesize}&page=${page}`+JSONmember,
      url: `${http}s=zhuanjiaren&c=search&api_call_function=zsqlist&keyword=${keyword}&catid=${catid}&pagesize=${pagesize}&page=${page}`+JSONmember,
      method: "get",
    });
  },
  CommunityDetailsTyp2(id,table,field,pagesize,page) {
    return window.axios({
      url:`${http}s=che&c=data&m=moduleRelatedlist&id=${id}&pagesize=${pagesize}&page=${page}&relatedTable=${table}&relatedParame=${field}&table=zhuanjiaren`+JSONmember,
      method: "get",
    });
  },
  getsandList(catid,pagesize,page) {
    return window.axios({
      url: `${http}s=sandimoxing&c=search&api_call_function=normal&catid=${catid}&pagesize=${pagesize}&page=${page}`+JSONmember,
      method: "get",
    });
  },
  getCompanyInfo(data){
    var fd = window.qs.stringify({...data});
    return window.axios({
      url: `${http}s=Member&c=user&m=getCompanyInfo`+JSONmember,
      method: "post",
      headers: {
        'Content-Type':'application/x-www-form-urlencoded'
      },
      data:fd
    });
  },
  Deletecompanyinfo (data){
    var fd = window.qs.stringify({...data});
    return window.axios({
      url: `${http}s=Member&c=user&m=Deletecompanyinfo `+JSONmember,
      method: "post",
      headers: {
        'Content-Type':'application/x-www-form-urlencoded'
      },
      data:fd
    });
  }
}
export default request;

<template>
  <div>
    <div class="home">
      <div class="home_type">
        <div class="home_type_in">
          <div class="home_type_inlet type_main"><span>全部分类</span></div>
          <div class="home_type_inlet type_child">
            <ul class="type_child_in">
              <li v-for="(i,d) in typeClassification" :key="d" @click="listClick(i,d)">{{i.name}}</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="Special_subject">
        <div @mouseleave='mouseleaveON()' style="display: inline-block;vertical-align: top;width: calc(100% - 330px);">
          <div class="Special_li">
            <div v-for="(a,q) in HomeIndex.cplb" :key="q" @mouseenter="handleMouseover(q)" @click="childrenOn(a, 99)" :class="cplbIdx.idx == q?'Special_li_div_color':''">
              {{a.lable}}<i class="el-icon-arrow-right"></i>
            </div>
          </div>
          <div class="Special_swiper" style="position: relative;overflow: inherit;">
            <div
              class="Special_swiper_big"
              ref="mySwiper"
              @mouseenter="mySwiperL('big')"
              @mouseleave="mySwiperq('big')"
            >
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(item,index) in HomeIndex.banner1" :key="index">
                  <a :href="item.url">
                    <img class="Special_swiper_img" :src="'https://admin.aiauto.shop'+item.image" :alt="item.title" srcset="">
                  </a>
                </div>
              </div>
              <transition name="el-fade-in-linear">
                <div v-show="bigSwiperNavigation">
                  <div class="swiper-button-prev Special_swiper_big_prev"></div><!--左箭头。如果放置在swiper外面，需要自定义样式。-->
                  <div class="swiper-button-next Special_swiper_big_next"></div><!--右箭头。如果放置在swiper外面，需要自定义样式。-->
                </div>
              </transition>
            </div>
            <div class="Special_swiper_mySwiper"
              @mouseenter="mySwiperL('my')"
              @mouseleave="mySwiperq('my')">
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(item,index) in HomeIndex.banner11" :key="index">
                  <a :href="item.url">
                    <img class="Special_swiper_img" :src="'https://admin.aiauto.shop'+item.image" :alt="item.title" srcset="">
                  </a>
                </div>
              </div>
              <transition name="el-fade-in-linear">
                <div v-show="mySwiperSwiperNavigation">
                  <div class="swiper-button-prev Special_swiper_mySwiper_prev"></div><!--左箭头。如果放置在swiper外面，需要自定义样式。-->
                  <div class="swiper-button-next Special_swiper_mySwiper_next"></div><!--右箭头。如果放置在swiper外面，需要自定义样式。-->
                </div>
              </transition>
            </div>
            <div class="Special_li_block" v-if="cplbIdx.type">
              <!-- <p>{{HomeIndex.cplb[cplbIdx.idx].lable}} <i class="el-icon-arrow-right"></i></p> -->
              <el-row :gutter="20" v-if="HomeIndex.cplb[cplbIdx.idx].children">
                <el-col :span="12" v-for="(d,i) in HomeIndex.cplb[cplbIdx.idx].children" :key="i">
                  <div class="Special_li_block_in">
                    <div @click="childrenOn(i, 99)">{{d.lable}} <i class="el-icon-arrow-right"></i></div>
                    <ul v-if="d.children">
                      <li v-for="(item, index) in d.children" :key="index" @click="childrenOn(i, item.value)">{{item.lable}}</li>
                    </ul>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
        <div class="Special_news">
          <div class="Special_news_top">
            <div>
              <h4>
                <img src="../../public/NewImage/Special_news_1.png" alt="" srcset="">专属采购服务
              </h4>
              <img class="exclusive" src="../../public/NewImage/01223.jpg" alt="" srcset="">
            </div>
            <div>
              <h4><img src="../../public/NewImage/Special_news_2.png" alt="" srcset="">专属融资服务</h4>
              <img class="exclusive" src="../../public/NewImage/01223.jpg" alt="" srcset="">
            </div>
            <div @click="supplierPlacement()">
              <h4><img src="../../public/NewImage/Special_news_3.png" alt="" srcset="">企业入驻</h4>
            </div>
          </div>
          <div class="Special_news_bottom">
            <h3>热点新闻</h3>
            <p v-for="(d,i) in HomeIndex.news" :key="i" @click="NewDetile(d)">{{d.title}}</p>
          </div>
        </div>
      </div>
      <div class="Search_activity">
        <div class="Search_activity_tit">
          <div class="tag_title"><i></i><span>找活动</span></div>
          <div class="Search_activity_tabs">
            <div class="Search_activity_tabs_list">
              <div v-for="(d,i) in HomeIndex.huodong" :key="i" @click="activeName = i" :class="i == activeName?'Search_activity_tabs_list_del':''">{{d.name}}</div>
            </div>
          </div>
          <div @click="eventsMore()" class="more">更多<i class="el-icon-arrow-right"></i></div>
        </div>
        <div>
          <div class="events_list" v-if="HomeIndex.huodong[activeName]" v-for="(n,d) in HomeIndex.huodong[activeName]?.list" :key="d" @click="eventsMoreInfo(n)">
            <img :src="'https://admin.aiauto.shop/uploadfile/'+n.thumb" alt="">
            <div class="events_list_div">
              <h4>{{n.title}}</h4>
              <div style="margin-left: 10px;"><img src="../../public/NewImage/events_2.png" alt=""><span>{{n.didian}}</span></div>
              <div><img src="../../public/NewImage/events_1.png" alt=""><span>{{n.riqi}}</span></div>
              <button v-if="n.iezhi > 0">还有{{n.iezhi}}天</button>
              <button style="border-color: #D8D8D8;background-color: #D8D8D8;color: #6D6D6D;" v-if="n.iezhi <= 0">已结束</button>
            </div>
          </div>
        </div>
      </div>
      <div class="requirement">
        <div class="requirement_in">
          <div class="requirement_in_top">
            <div class="tag_title"><i></i><span>最新融资需求</span></div>
            <div class="requirement_in_top_border"></div>
            <div class="more" @click="InvestmentDetailsChange()">更多<i class="el-icon-arrow-right"></i></div>
          </div>
          <div class="requirement_list" v-for="(d, i) in HomeIndex.zxrz" :key="i">
            <!-- <button class="requirement_list_libel">天使轮</button> -->
            <div class="requirement_list_title" style="width: calc(26% + 60px);margin-right: 12px;">
              <p>{{d.title}}</p>
              <span>{{d.jianjie}}</span>
            </div>
            <div class="requirement_list_money">
              <p>{{d.money}}</p>
              <span>{{d.currency}}</span>
            </div>
            <p class="requirement_list_text" style="width: 82px;">{{d.jiezhi}}</p>
            <button class="requirement_list_but" @click="InvestmentDetailsChange(d.id)">我要投资</button>
          </div>
        </div>
        <div class="requirement_in">
          <div class="requirement_in_top">
            <div class="tag_title"><i></i><span>最新并购需求</span></div>
            <div class="requirement_in_top_border"></div>
            <div class="more" @click="InvestmentDetailsChange()">更多<i class="el-icon-arrow-right"></i></div>
          </div>
          <div class="requirement_list" v-for="(d, i) in HomeIndex.zxbg" :key="i">
            <!-- <button class="requirement_list_libel">天使轮</button> -->
            <div class="requirement_list_title" style="width: calc(26% + 60px);margin-right: 12px;">
              <p>{{d.title}}</p>
              <span>{{d.jianjie}}</span>
            </div>
            <div class="requirement_list_money">
              <p>{{d.money}}</p>
              <span>{{d.currency}}</span>
            </div>
            <p class="requirement_list_text" style="width: 82px;">{{d.jiezhi}}</p>
            <button class="requirement_list_but" @click="InvestmentDetailsChange(d.id)">我要投资</button>
          </div>
        </div>
      </div>
      <a v-if="HomeIndex.guanggao[0]" :href="HomeIndex.guanggao[0].url" class="guanggao"><img :src="'https://admin.aiauto.shop'+ HomeIndex.guanggao[0].image" alt="" style="width: 100%;display: block;"></a>
      <div class="firm">
        <el-row :gutter="26">
          <el-col :span="6">
            <div class="firm_back1 firm_val">
              <h4>优质整车企业推荐</h4>
              <div v-for="(d,i) in HomeIndex.youzhi1" :key="i" @click="enterpriseList1(d.id)">·  {{d.title}}</div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="firm_back2 firm_val">
              <h4>优质零配件企业推荐</h4>
              <div v-for="(d,i) in HomeIndex.youzhi2" :key="i" @click="enterpriseList1(d.id)">·  {{d.title}}</div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="firm_back3 firm_val">
              <h4>优质设备及其他企业</h4>
              <div v-for="(d,i) in HomeIndex.youzhi3" :key="i" @click="enterpriseList1(d.id)">·  {{d.title}}</div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="firm_back4 firm_val">
              <h4>优质MRO企业推荐</h4>
              <div v-for="(d,i) in HomeIndex.youzhi4" :key="i" @click="enterpriseList1(d.id)">·  {{d.title}}</div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="Purchase">
        <div class="Purchase_tit">
          <div class="tag_title"><i></i><span>最新采购项目</span></div>
          <div class="more Purchase_tit_more" @click="Purchase()">更多<i class="el-icon-arrow-right"></i></div>
        </div>
        <div class="FindIndustry condition">
          <el-table
            stripe
            :cell-style="tableStyle"
            :data="HomeIndex.zxcg"
            style="width: 100%">
            <el-table-column label="采购名称" prop="title"></el-table-column>
            <el-table-column label="紧急程度">
              <template slot-scope="scope">
                <span v-if="scope.row.jinjichengdu == '3'">特别紧急</span>
                <span v-if="scope.row.jinjichengdu == '2'">紧急</span>
                <span v-if="scope.row.jinjichengdu == '1'">一般</span>
              </template>
            </el-table-column>
            <el-table-column label="发布日期" prop="addtimestr"></el-table-column>
            <el-table-column label="截止日期" prop="jzsjstr"></el-table-column>
            <el-table-column label="地区" prop="dizhiname"></el-table-column>
            <el-table-column label="适用车型" prop="sycxname"></el-table-column>
            <el-table-column label="公司名称" prop="gsmc"></el-table-column>
            <el-table-column label="详情" width="90">
              <template slot-scope="scope">
                <el-button type="primary" plain size="mini" @click="PurchaseItem(scope.row)">详情</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <a v-if="HomeIndex.guanggao[1]" :href="HomeIndex.guanggao[1].url" class="guanggao"><img :src="'https://admin.aiauto.shop'+ HomeIndex.guanggao[1].image" alt="" style="width: 100%;display: block;"></a>
      <div class="subject requirement" style="height: auto;">
        <div class="requirement_in">
          <div class="requirement_in_top">
            <div class="tag_title"><i></i><span>专题</span></div>
            <div class="requirement_in_top_border" style="width: calc(100% - 20px - 53px - 36px) !important;"></div>
            <div class="more" @click="FindListInformation(8)">更多<i class="el-icon-arrow-right"></i></div>
          </div>
          <div class="subject_list" v-for="(item, d) in HomeIndex.zhuanti" :key="d" @click="DetailsConsultation(8, item.id)">
            <img :src="'https://admin.aiauto.shop/uploadfile/'+item.attachment" alt="">
            <div>
              <h4>{{item.title}}</h4>
              <p><img src="../../public/NewImage/events_1.png" alt="">{{item.times}}</p>
            </div>
          </div>
        </div>
        <div class="requirement_in">
          <div class="requirement_in_top">
            <div class="tag_title"><i></i><span>高端访谈</span></div>
            <div class="requirement_in_top_border" style="width: calc(100% - 20px - 93px - 36px) !important;"></div>
            <div class="more" @click="FindListInformation(9)">更多<i class="el-icon-arrow-right"></i></div>
          </div>
          <div class="subject_list" v-for="(item, d) in HomeIndex.fangtan" :key="d" @click="DetailsConsultation(9, item.id)">
            <img :src="'https://admin.aiauto.shop/uploadfile/'+item.attachment" alt="">
            <div>
              <h4>{{item.title}}</h4>
              <p><img src="../../public/NewImage/events_1.png" alt="">{{item.times}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="institution">
        <div class="institution_top">
          <el-tabs v-model="aaactiveName" class="institution_top_tabs">
            <el-tab-pane label="创业项目融资" name="0">创业项目融资</el-tab-pane>
            <el-tab-pane label="投资活跃机构" name="1">投资活跃机构</el-tab-pane>
          </el-tabs>
          <div class="institution_top_right" @click="enterpriseList()"><div class="more">更多<i class="el-icon-arrow-right"></i></div></div>
        </div>
        <div class="institution_val">
          <el-table
            stripe
            :cell-style="tableStyle"
            :data="aaactiveName == '0'?HomeIndex.rzxx:HomeIndex.tzxx"
            style="width: 100%">
            <el-table-column width="280" label="机构名称">
              <template slot-scope="scope">
                <img style="height: 65px;" :src="'https://admin.aiauto.shop'+scope.row.gongsilog" alt="">
                <div class="FindIndustry_sapn">
                  <h4>{{scope.row.cname}}</h4>
                  <span>{{scope.row.company}}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="融资轮次" prop="lunci"></el-table-column>
            <el-table-column label="行业" prop="hangye"></el-table-column>
            <el-table-column label="融资金额" prop="money"></el-table-column>
            <el-table-column label="详情" width="90">
              <template slot-scope="scope">
                <el-button type="primary" plain size="mini" @click="enterpriseList(scope.row.companyid)">详情</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <a v-if="HomeIndex.guanggao[2]" :href="HomeIndex.guanggao[2].url" class="guanggao"><img :src="'https://admin.aiauto.shop'+ HomeIndex.guanggao[2].image" alt="" style="width: 100%;display: block;"></a>
      <div class="corporation">
        <div class="corporation_left">
          <div class="corporation_side">
            <p>汽车上市公司</p>
            <div class="corporation_swiper corporation_swiper1" style="height: calc(100% - 140px);">
              <div class="swiper-wrapper">
                  <div class="swiper-slide" v-for="(d, i) in HomeIndex.banner01" :key="i">
                    <a :href="d.url" style="display: block;width: 100%;height: 100%;">
                      <!-- <p>{{d.title}}</p> -->
                      <img :src="'https://admin.aiauto.shop'+ d.image" alt="" style="height: calc(50% + 70px);">
                    </a>
                  </div>
              </div>
              <div class="swiper-pagination"></div>
            </div>
          </div>
        </div>
        <div class="corporation_right">
          <el-table
            stripe
            height="580"
            :cell-style="tableStyle"
            :data="HomeIndex.ssqy"
            style="width: 95%;margin: auto;padding-top: 10px;">
            <el-table-column label="公司名称" prop="company">
              <template slot-scope="scope">
                <el-tooltip class="item" effect="dark" :content="scope.row.company" placement="bottom-start">
                  <div class="ONshengluehao">
                      {{ scope.row.company }}
                  </div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column label="业务类别" prop="yewu">
              <template slot-scope="scope">
                <el-tooltip class="item" effect="dark" :content="scope.row.yewu" placement="bottom-start">
                  <div class="ONshengluehao">
                      {{ scope.row.yewu }}
                  </div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column label="证券所" prop="zjs" width="100"></el-table-column>
            <el-table-column label="上市时间" prop="shangshi" width="120"></el-table-column>
            <el-table-column label="市值" prop="市值"></el-table-column>
            <el-table-column label="营业收入 (季度/年度)" width="160" prop="money"></el-table-column>
            <el-table-column label="" width="70">
              <template slot-scope="scope">
                <span class="corporation_right_span" @click="enterpriseList1(scope.row.companyid)">详情</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="corporation">
        <div class="corporation_left">
          <div class="corporation_side corporation_side1">
            <p>专精特新</p>
            <div class="corporation_swiper corporation_swiper2" style="height: calc(100% - 140px);">
              <div class="swiper-wrapper">
                  <div class="swiper-slide" v-for="(d, i) in HomeIndex.banner02" :key="i">
                    <a :href="d.url" style="display: block;width: 100%;height: 100%;">
                      <img :src="'https://admin.aiauto.shop'+ d.image" alt="" style="height: calc(50% + 70px);">
                    </a>
                  </div>
              </div>
              <div class="swiper-pagination"></div>
            </div>
          </div>
        </div>
        <div class="corporation_right">
          <el-table
            stripe
            height="580"
            :cell-style="tableStyle"
            :data="HomeIndex.zjqy"
            style="width: 95%;margin: auto;padding-top: 10px;">
            <el-table-column label="公司名称" prop="company">
              <template slot-scope="scope">
                <el-tooltip class="item" effect="dark" :content="scope.row.company" placement="bottom-start">
                  <div class="ONshengluehao">
                      {{ scope.row.company }}
                  </div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column label="业务类别" prop="yewu">
              <template slot-scope="scope">
                <el-tooltip class="item" effect="dark" :content="scope.row.yewu" placement="bottom-start">
                  <div class="ONshengluehao">
                      {{ scope.row.yewu }}
                  </div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column label="级别(国家级/省级)" width="140" prop="jibie"></el-table-column>
            <el-table-column label="地域" prop="didia" width="100"></el-table-column>
            <el-table-column label="营业收入" prop="money"></el-table-column>
            <el-table-column label="投资方" width="160" prop="touzi">
              <template slot-scope="scope">
                <el-tooltip class="item" effect="dark" :content="scope.row.touzi" placement="bottom-start">
                  <div class="ONshengluehao">
                      {{ scope.row.touzi }}
                  </div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column label="" width="70">
              <template slot-scope="scope">
                <span class="corporation_right_span" @click="enterpriseList1(scope.row.companyid)">详情</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="link">
        <div class="link_top"><div class="tag_title"><i></i><span>友情链接</span></div></div>
        <div class="link_div" v-for="(d, i) in HomeIndex.links" :key="i"><a :href="d.tzwz">{{d.title}}</a></div>
      </div>
    </div>
    <transition name="el-fade-in-linear">
      <div class="elBacktop" v-show="backtopType">
        <chatAi></chatAi>
        <el-button @click="backtopClick" type="primary"><img src="../../public/NewImage/backtop1.png" alt="" srcset=""></el-button>
      </div>
    </transition>
  </div>
</template>
<script>
import 'swiper/css/swiper.css'  //引入swiper样式
import Swiper from 'swiper';	//引入swiper
import request from '@/api/request.js'
import chatAi from '@/views/chatAi.vue';
let mySwiper;
let mySwiper2;
let mySwiper3;
let mySwiper4;
export default {
  components: {chatAi},
  data(){
    return{
      tyepList:['采购商','供应商','产业园区','创业人','投资人','专家人','采购人'],
      typeClassification:[],
      bigSwiperNavigation:false,
      mySwiperSwiperNavigation:false,
      activeName: 0,
      aaactiveName: '0',
      input2:'',
      backtopType:false,
      HomeIndex:{banner1:[],cplb:[],huodong:[]},
      exclusive:{val1:false,val2:false,val3:false},
      cplbIdx:{type:false,idx:0}
    }
  },
  mounted() {
    this.screenWidth = document.body.clientWidth
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth
      })()
    };
    window.addEventListener("scroll", this.scrolling);
    this.HomeIndexClick();
    this.column()
  },
  watch: {
    screenWidth: {
      handler: function (val, oldVal) {
        if (val < 1230) {
          this.dialogWidth = false
          //此刻屏幕宽度小于800
        } else {
          this.dialogWidth = true
          //此刻屏幕宽度大于800
        }
      },
    },
  },
  methods: {
    tableStyle() { return "height:60px;"},
    scrolling() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      let scrollStep = scrollTop - this.oldScrollTop;
      if(scrollTop >= 600){
        this.backtopType = true;
      }else{
        this.backtopType = false;
      }
    },
    column(){
      let louter = [
        {name:'找企业', louter:'/enterpriseList', type:3},
        {name:'找采购', louter:'/purchaseList', type:9},
        {name:'找活动', louter:'/SearchActivity', type:8},
        {name:'找园区', louter:'/FindListInformation', type:6},
        {name:'找资讯', louter:'/FindListInformation', type:0},
        {name:'找产业', louter:'/ListIndustries', type:1},
        {name:'找投资', louter:'/FindListInformation', type:4},
        {name:'找投行', louter:'/FindListInformation', type:5},
        {name:'找社群', louter:'/ListIndustries', type:7},
        {name:'找数智'},
      ];
      let arr = [];
      request.column(0).then((res) => {
        // louter.forEach(d => {
          res.data.data.forEach((item, index) => {
            // if(item.name == d.name){
              arr.push({...item,louter:louter[index].louter, type:louter[index].type})
            // }
          })
        // });
        this.typeClassification = arr;
      })
    },
    listClick(item,e){
      if(item.louter != undefined){
        this.listIndex = e;
        this.$router.push({
            path:item.louter,
            query:{
              type:item.type,
              sublevel:0,
              typeId:item?item.id:0
            }
        });
        window.localStorage.setItem('type',e);
        window.localStorage.setItem('sublevel',i);
        this.show = true;
      }else{
        this.$router.push({
            path:'/',
        });
      }
    },
    enterpriseList(){
      if(this.aaactiveName == 0){
        this.$router.push({ path:'/enterpriseList',query:{type:3,sublevel:0,typeId:21}})
      }else{
        this.$router.push({ path:'/FindListInformation',query:{type:4,sublevel:0,typeId:49}})
      }
    },
    FindListInformation(id){this.$router.push({ path:'/FindListInformation',query:{type:0,sublevel:id,typeId:9}})},
    DetailsConsultation(idx, id){this.$router.push({ path:'/DetailsConsultation',query:{type:0,sublevel:0,typeId:idx, id:id}})},
    supplierPlacement(){
      if(window.localStorage.getItem('code')){
        this.$router.push({ path:'/settlingProcess'})
      }else{
        this.$message.error('请先登录');
      }
    },
    HomeIndexClick(){
      request.HomeIndex().then((res) => {
        if(res.data.code == 1){
          this.HomeIndex = res.data.data;
          this.HomeIndex.cplb = this.changeNodes(Object.values(res.data.data.cplb));
          setTimeout(() => {
            mySwiper = new Swiper('.Special_swiper_big',{
              spaceBetween: 10,
              loop: true,
              autoplay: {
                delay: 5400,
                stopOnLastSlide: false,
                autoplayDisableOnInteraction:false,
                disableOnInteraction: false,
              },
              direction: 'horizontal',
              navigation: {
                nextEl: '.Special_swiper_big_next',
                prevEl: '.Special_swiper_big_prev'
              }
            })
            mySwiper2 = new Swiper('.Special_swiper_mySwiper',{
              spaceBetween: 10,
              slidesPerView: 3,
              loop: true,
              autoplay: {
                delay: 5000,
                stopOnLastSlide: false,
                disableOnInteraction: false,
              },
              direction: 'horizontal',
              navigation: {
                nextEl: '.Special_swiper_mySwiper_next',
                prevEl: '.Special_swiper_mySwiper_prev'
              },
              freeMode: true,
              watchSlidesProgress: true,
            })
            mySwiper3 = new Swiper('.corporation_swiper1',{
              loop: true,
              autoplay: {
                delay: 1400,
                stopOnLastSlide: false,
                disableOnInteraction: false,
              },
              pagination: {
                el: ".swiper-pagination",
              },
            })
            mySwiper4 = new Swiper('.corporation_swiper2',{
              loop: true,
              autoplay: {
                delay: 1400,
                stopOnLastSlide: false,
                disableOnInteraction: false,
              },
              pagination: {
                el: ".swiper-pagination",
              },
            })

          },400)
          console.log(this.HomeIndex)
        }
      })
    },
    mySwiperL(val) {
      if(val == 'big'){
        this.bigSwiperNavigation = true;
        mySwiper.autoplay.stop();
      }else if(val == 'my'){
        this.mySwiperSwiperNavigation = true;
        mySwiper2.autoplay.stop();
      }
    },
    mySwiperq(val) {
      if(val == 'big'){
        this.bigSwiperNavigation = false;
        mySwiper.autoplay.start();
      }else if(val == 'my'){
        this.bigSwiperNavigation = true;
        mySwiper2.autoplay.start();
      }

    },
    handleMouseover(idx){
      this.cplbIdx.type = true;
      this.cplbIdx.idx = idx;
    },
    childrenOn(g, f){
      let val = {}
      // let val = [{idx:this.cplbIdx.idx, didx:g, qidx:f}]
      // window.localStorage.setItem('xuanz', JSON.stringify(val))
      if(f == 99){
        val = {type:3,sublevel:0,typeId:9}
      }else{
        val = {type:3,sublevel:0,typeId:9,checktab:f}
      }
      this.$router.push({ path:'/enterpriseList',query:val})
    },
    mouseleaveON(){ this.cplbIdx.type = false },
    NewDetile(e){ this.$router.push({ path:'/DetailsConsultation',query:{type:0,sublevel:0,typeId:9,id:e.id}}) },
    eventsMore(){ this.$router.push({ path:'/SearchActivity',query:{type:8, sublevel:0, typeId:77 }}) },
    eventsMoreInfo(e){
      this.$router.push({ path:'/collegeDetails',query:{type:8, sublevel:0, typeId:77, id:e.id }})
    },
    enterpriseList1(e){
      if(e){
        this.$router.push({ path:'/InvestmentDetailsNew',query:{type:3, sublevel:0, typeId:21, id:e }})
      }
    },
    backtopClick(){
      let top = document.documentElement.scrollTop || document.body.scrollTop// 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
        if (top <= 0) {clearInterval(timeTop)}
      }, 10)
    },
    Purchase(e){ this.$router.push({ path:'/purchaseList'})},
    PurchaseItem(e){ this.$router.push({ path:'/purchaseDetail',query:{id:e.id}})},
    InvestmentDetailsChange(e){
      if(e){
        this.$router.push({ path:'/InvestmentDetailsNew',query:{ type:3, sublevel:0, typeId:21, id:e }})
      }else{
        this.$router.push({ path:'/enterpriseList',query:{ type:3, sublevel:0, typeId:21 }})
      }
    },
    changeNodes (data) {
      if (data.length > 0) {
        data.forEach(d => {
          if(d.children){
            d.children = Object.values(d.children)
            d.children.forEach(dn => {
              if(dn.children){
                dn.children = Object.values(dn.children)
                dn.children.forEach(dni => {
                  if(dni.children){
                    dni.children = Object.values(dni.children)
                    dni.children.forEach(dnic => {
                      if(dnic.children){
                        dnic.children = Object.values(dnic.children)
                      }
                    })
                  }
                })
              }
            })
          }
        })
      }
      return data;
    }
  },
};
</script>
<style lang="scss" scoped>
@import url("../../node_modules/swiper/css/swiper.css");
.guanggao{
  min-width: 1200px;
  width: 74%;
  margin: auto;
  display: block;
}
.home{
  // height: 50px;
  // line-height: 50px;
  background: #F5F5F5;
  .tag_title{
    display: inline-block;
    vertical-align: middle;
    line-height: 50px;
    font-size: 0;
    span,i{
      display: inline-block;
      vertical-align: middle;
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #4A4A4A;
    }
    i{
      width: 8px;
      height: 24px;
      background: #0089EF;
      border-radius: 4px;
      margin-right: 5px;
    }
  }
  .more{
    display: inline-block;
    vertical-align: top;
    line-height: 50px;
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #999999;
    cursor: pointer;
  }
  .more:hover{color: #006CBD;}
  .top_type{
    min-width: 1200px;
    width: 74%;
    margin: auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .top_type_text{
      li{
        display: inline-block;
        padding: 0 14px;
        border-right: 1px solid #DCDCDC;
        height: 14px;
        line-height: 14px;
        vertical-align: middle;
        cursor: pointer;
        color: #999;
      }
      li:last-child{
        border: 0;
      }
      li:hover{color: #000;}
    }
    .top_type_right{
      position: absolute;
      top: 0;
      right: 0;
      font-size: 16px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #008DF0;
      img{
        vertical-align: middle;
        margin-right: 10px;
      }
    }
  }

  .home_type{
    height: 60px;
    line-height: 60px;
    background: #0089EF;
    .home_type_in{
      min-width: 1200px;
      width: 74%;
      margin: auto;
      .home_type_inlet{
        display: inline-block;
        vertical-align: top;
      }
      .type_main{
        width: 130px;
        background: #006CBD;
        margin-right: 14px;
        height: 60px;
        span{
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
          margin-left: 17px;
        }
      }
      .type_child{
        width: calc(100% - 14px - 130px);
        height: 60px;
        .type_child_in{
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          li{
            font-size: 20px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            text-align: center;
            cursor: pointer;
          }
          li:hover{
            color: #45b0ff;
          }
        }
      }
    }
  }
  .Special_subject{
    min-width: 1200px;
    width: 74%;
    margin: auto;
    font-size: 0;
    .Special_li{
      display: inline-block;
      vertical-align: top;
      padding: 14px 0;
      background: #fff;
      width: 130px;
      margin-right: 14px;
      height: 484px;
      position: relative;
      div{
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #4A4A4A;
        line-height: 15px;
        text-align: center;
        padding: 11px 0;
        cursor: pointer;
      }
    }
    .Special_news{
      display: inline-block;
      vertical-align: top;
      width: 330px;
      .Special_news_top{
        height: 150px;
        background-color: #fff;
        border-radius: 8px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        div{position: relative;}
        div:hover>.exclusive{
          opacity: 1;
          z-index: 1000;
        }
        div{cursor: pointer;}
        .exclusive{
          opacity: 0;
          position: absolute;
          top: 100%;
          width: 200px;
          right: -60px;
          z-index: -1000;
          transition: all 0.5s;
        }
        h4{
          img{
            display: block;
            margin: auto;
            margin-bottom: 16px;
          }
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #0E0E0E;
          line-height: 20px;
        }
      }
      .Special_news_bottom{
        display: inline-block;
        vertical-align: top;
        margin-top: 12px;
        width: 288px;
        height: 350px;
        background: #FFFFFF;
        border-radius: 8px;
        padding: 0 21px;
        h3{
          font-size: 20px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #0089EF;
          line-height: 28px;
          padding: 8px 0;
          border-bottom: 1px solid #d2d2d2;
        }
        p{
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #4A4A4A;
          line-height: 18px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          margin-top: 11px;
          cursor: pointer;
        }
        p:hover{color: #0089EF;}
      }
    }
    .Special_swiper{
      display: inline-block;
      vertical-align: top;
      width: calc(100% - 130px - 10px - 14px);
      height: 510px;
      overflow: hidden;
      margin-right: 10px;
      position: relative;
      .Special_swiper_img{width: 100%;height: 100%;}
      .Special_swiper_big{
        width: 100%;
        height: 354px;
        overflow: hidden;
        position: relative;
      }
      .Special_swiper_mySwiper{
        width: 100%;
        height: 140px;
        overflow: hidden;
        margin-top: 16px;
        position: relative;
      }
    }
  }
  .Search_activity{
    min-width: calc(1200px - 40px);
    width: calc(74% - 40px);
    margin: auto;
    background: #FFFFFF;
    border-radius: 8px;
    padding:22px 20px;
    margin-top: 20px;
    font-size: 0;
    .Search_activity_tabs{
      display: inline-block;
      vertical-align: top;
      width: calc(100% - 73px - 36px - 38px);
      margin:0 18px 0 20px;
      height: 50px;
      background: #EEEEEE;
      border-radius: 25px;
    }
    .events_list{
      display: inline-block;
      vertical-align: top;
      width: calc(25% - 15px);
      background: rgba(245,245,245,0.53);
      margin: 24px 20px 0 0;
      cursor: pointer;
      img{width: 100%;height: 165px;display: block;}
      .events_list_div{
        border-left: 1px solid #E0E0E0;
        border-bottom: 1px solid #E0E0E0;
        border-right: 1px solid #E0E0E0;
        overflow: hidden;
        h4{
          margin: 17px 10px 0 10px;
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #030303;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          line-height: 18px;
        }
        div{
          display: inline-block;
          vertical-align: top;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #787878;
          line-height: 20px;
          margin-top: 10px;
          margin-right: 10px;
          img{
            display: inline-block;
            vertical-align: middle;
            width: auto;
            height: 13px;
          }
        }
        button{
          float: right;
          width: 62px;
          height: 20px;
          border-radius: 4px;
          border: 1px solid #FF0000;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FF0000;
          line-height: 18px;
          background-color: #fff;
          margin-top: 10px;
          margin-right: 8px;
        }
      }
    }
    .events_list:nth-child(4n){margin-right: 0;}
  }
  .requirement{
    min-width: 1200px;
    width: 74%;
    margin:20px auto 26px;
    height: 460px;
    background: #FFFFFF;
    border-radius: 8px;
    font-size: 0;
    .requirement_in{
      width: calc(50% - 40px);
      display: inline-block;
      vertical-align: top;
      padding: 0 20px;
      .requirement_in_top_border{
        display: inline-block;
        vertical-align: middle;
        background-color: #CACACA;
        height: 1px;
        width: calc(100% - 20px - 133px - 36px);
        margin: 0 10px;
        margin-top: 15px;
      }
      .requirement_list{
        width: 95%;
        font-size: 0;
        margin: 26px auto 0;
        padding-bottom: 19px;
        border-bottom: 1px solid #C5C5C5;
        .requirement_list_libel{
          display: inline-block;
          vertical-align: top;
          width: 58px;
          height: 24px;
          background: #E6F7FF;
          border-radius: 4px;
          border: 1px solid #97D7FF;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #1E93FF;
          margin-right: 10px;
        }
        .requirement_list_title{
          width: 26%;
          display: inline-block;
          vertical-align: top;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 22px;
          p{
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
          }
          span{
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 20px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
          }
        }
        .requirement_list_money{
          width: 30%;
          display: inline-block;
          vertical-align: top;
          font-size: 22px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #E00000;
          line-height: 30px;
          p{
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
          }
          span{
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FF9A01;
            line-height: 20px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
          }
        }
        .requirement_list_text{
          display: inline-block;
          vertical-align: top;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          line-height: 30px;
          margin-right: 6px;
        }
        .requirement_list_but{
          display: inline-block;
          vertical-align: top;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #1E93FF;
          background-color: #fff;
          width: 73px;
          height: 29px;
          border-radius: 4px;
          border: 1px solid #007EE4;
          cursor: pointer;
        }
      }
    }
  }
  .firm{
    min-width: 1200px;
    width: 74%;
    margin:30px auto 16px;
    .firm_val{
      border-radius: 8px;
      max-height: 457px;
      overflow: hidden;
      padding: 30px 0;
      h4{
        font-size: 24px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #4A4A4A;
        line-height: 30px;
        margin-left: 32px;
      }
      div{
        margin-left: 32px;
        margin-top: 17px;
        font-size: 20px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 28px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        cursor: pointer;
      }
      div:hover{color: #0089EF;}
    }
    .firm_back1{background: #E1F6F1;}
    .firm_back2{background: #FEEBF5;}
    .firm_back3{background: #DBF4FE;}
    .firm_back4{background: #E1F6F1;}
  }
  .Purchase{
    min-width: 1200px;
    width: 74%;
    margin: auto;
    background-color: #fff;
    .Purchase_tit{
      line-height: 78px;
      height: 78px;
      padding: 0 24px;
      .Purchase_tit_more{float: right;      line-height: 78px;}
    }
  }
  .subject{
    height: 448px;
    margin-bottom: 27px;
    .subject_list{
      font-size: 0;
      height: 103px;
      margin-bottom: 17px;
      cursor: pointer;
      img{
        display: inline-block;
        vertical-align: top;
        width: 202px;
        height: 100%;
        margin-right: 19px;
      }
      div{
        position: relative;
        width: calc(100% - 202px - 19px);
        height: 100%;
        display: inline-block;
        vertical-align: top;
        h4{
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #1E1E1E;
          line-height: 22px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
        p{
          position: absolute;
          bottom: 0;
          left: 0;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #787878;
          line-height: 20px;
          img{
            width: auto;
            height: 14px;
            vertical-align: bottom;
            margin-right: 6px;
          }
        }
      }
    }
    .subject_list:hover{
      div{
        h4{color: #006CBD;}
      }
    }
  }
  .institution{
    min-width: 1200px;
    width: 74%;
    margin:0 auto 20px;
    background-color: #fff;
    border-radius: 8px;
    .institution_top{
      padding: 0 20px;
      line-height: 77px;
      height: 77px;
      font-size: 24px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #0089EF;
      .institution_top_tabs{
        display: inline-block;
        vertical-align: top;
      }
      .institution_top_right{
        float: right;
        line-height: 77px;
        .more{line-height: 77px;}
      }
    }
  }
  .corporation{
    min-width: 1200px;
    width: 74%;
    margin:16px auto 20px;
    background-color: #fff;
    border-radius: 8px;
    font-size: 0;
    .corporation_left{
      display: inline-block;
      vertical-align: top;
      width: 300px;
      height: 600px;
      overflow: hidden;
      position: relative;
      .corporation_side{
        width: 300px;
        height: 600px;
        background: url(../../public/NewImage/corporation_1.png);
        position: relative;
        overflow: hidden;
        p{
          margin: 98px 0 0 38px;
          font-size: 30px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #014A80;
          line-height: 42px;
        }
        img{width: 100%;height:50%;;position: absolute;bottom: 0;left: 0;}
      }
      .corporation_side1{background: url(../../public/NewImage/corporation_3.png);}
      .swiper-pagination{
        bottom: 20px;
        width: calc(100% - 10px);
        text-align: right;
      }
    }

  }
  .link{
    min-width: 1200px;
    width: 74%;
    margin:0 auto 25px;
    overflow: hidden;
    background: #FFFFFF;
    border-radius: 8px;
    font-size: 0;
    padding: 20px 0 10px 0;
    overflow: hidden;
    padding-right: 20px;
    .link_top{margin-left: 20px;}
    .link_div{
      float: left;
      font-size: 14px;
      margin: 0px 0 10px 20px;
      line-height: 20px;
      cursor: pointer;
    }
    .link_div:hover,.link_div>a:hover{color: #006CBD;}
  }
  .tobottom{
    .tobottom_top{
      height: 210px;
      background: #0089EF;
      .tobottom_top_in{
        min-width: 1200px;
        width: 74%;
        margin:auto;
        overflow: hidden;
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 100%;
        .tobottom_top_span{
          h4{
            font-size: 24px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 33px;
            margin-bottom: 15px;
          }
          p{
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 22px;
          }
        }
      }
    }
    .tobottom_bottom{
      height: 66px;
      line-height: 66px;
      background: #035EA2;
      text-align: center;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      span{margin-right: 22px;}
    }
  }
}
.Special_li_block{
  position: absolute;
  top: 0;
  left: -14px;
  height: calc(100% - 40px);
  width: calc(100% + 14px - 40px);
  background-color: #fff;
  z-index: 11111;
  padding: 20px;
  overflow-y: auto;
  p{
    font-size: 16px;
    padding: 4px 10px;
    color: #fff;
    cursor: pointer;
    background-color: #006CBD;
  }
  .Special_li_block_in{
    margin-top: 10px;
    div{
      font-size: 14px;
      line-height: 20px;
      margin-top: 0;
      cursor: pointer;
      border-bottom: 1px solid #006CBD;
      color: #fff;
      background-color: #006CBD;
      padding: 4px 10px;
      margin-bottom: 12px;
    }
    // div:hover{color: #006CBD;background-color: #fff;}
    ul{
      display: inline-block;
      vertical-align: top;
      margin: 0 -10px;
      overflow: hidden;
      li{
        display: inline-block;
        vertical-align: top;
        margin: 6px 0;
        font-size: 14px;
        padding: 0 10px;
        height: 16px;
        line-height: 16px;
        white-space: nowrap;
        cursor: pointer;
      }
      li:hover{color: #006CBD;}
    }
  }
}
.Special_li_div_color{
  background-color: #008DF0;
  color: #fff !important;
}
.ONshengluehao{
  white-space:nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.elBacktop{
  position: fixed;
  right: 2%;
  bottom:4%;
  width: 80px;
  height: 190px;
  border: 1px solid #006CBD;
  border-radius: 10px;
  background-color: #fff;
  border-radius: 6px;
  overflow: hidden;
  z-index: 10;
  button{
    width: 80px;
    background-color: #006CBD;
    border-color: #006CBD;
    border-radius: 0;
    text-align: center;
    height: 80px;
    line-height: 80px;
  }
}
@media screen and (max-width:1200px) {
  .Advertising{display: none;}
}
.Search_activity_tabs_list{
  display: flex;
  line-height: 50px;
  div{
    flex: auto;
    font-size: 16px;
    text-align: center;
    border-radius: 50px;
    cursor: pointer;
  }
  .Search_activity_tabs_list_del{
    background-color: #006CBD;
    color: #fff;
  }
}

::v-deep{
  .swiper-slide{img{width: 100%;display: block;}}
  .el-backtop{width: 80px;}
  // .Special_swiper_mySwiper .swiper-slide {
  //   width: 30%;
  //   height: 100%;
  //   cursor: pointer;
  //   margin-right: 3.333333333%;
  // }
  .Search_activity_tabs{
    .el-tabs__nav-wrap.is-scrollable{padding: 0;}
    .el-tabs__item{
      line-height: 50px;
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #4A4A4A;
      padding: 0 24px;
    }
    .el-tabs__nav-wrap::after{
      height: 0 !important;
    }
    .el-tabs--top .el-tabs__item.is-top:nth-child(2),.el-tabs--top .el-tabs__item.is-top:last-child{
      padding: 0 34px;
    }
    .el-tabs__item.is-active{
      color: #fff;
      background: #0089EF;
          background: #0089EF;
          height: 50px;
          border-radius: 50px;
    }
    .el-tabs__active-bar{
      height: 0px;
    }
    .el-tabs__nav-next, .el-tabs__nav-prev{display: none;}
  }
  .Special_swiper_mySwiper .swiper-slide-thumb-active {
    opacity: 1;
    cursor: auto;
  }
  .condition{
    margin-bottom: 20px;
    .el-table th{
      background: #F6FAFF;
      padding:4px 0;
    }
    .el-table .el-table__cell{text-align: center;}
    .el-button--primary{
      border-color: #003672;
      background-color: #fff;
      color: #003672;
    }
    .el-button--primary:hover{
      color: #fff;
      background-color: #003672;
    }
  }
  .swiper-pagination-bullet{width: 20px;height: 20px;background-color: #fff;opacity: 1;}
  .swiper-pagination-bullet-active{background: #289BF1;}
  .institution{
    .institution_top{
      .el-tabs__nav-wrap::after{height: 0 !important;}
      .el-tabs__content{display: none;}
      .el-tabs__item{
        font-size: 24px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #4A4A4A;
      }
      .el-tabs__item.is-active{
        color: #0089EF;
      }
      .el-tabs__active-bar{
        height: 8px;
        background: #0089EF;
        border-radius: 4px;
      }
    }
    .FindIndustry_sapn{
      display: inline-block;
      vertical-align: top;
      margin-left: 24px;
      height: 65px;
      position: relative;
      width: calc(100% - 65px - 24px);
      text-align: left;
      h4{
        width: 100%;
        font-size: 18px;margin-top: 10px;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
      }
      span{
        font-size: 12px;
        line-height: 16px;
        position: absolute;
        bottom: 0;
        width: 100%;
        color: #A7A7A7;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
    }
    .institution_val{
      padding: 0 20px 20px;
      .el-table th {
          background: #F6FAFF;
          padding: 4px 0;
      }
      .el-table .el-table__cell{text-align: center;}
      .el-button--primary{
        border-color: #003672;
        background-color: #fff;
        color: #003672;
      }
      .el-button--primary:hover{
        color: #fff;
        background-color: #003672;
      }
    }
  }
  .corporation_right{
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 300px);
    .el-table th{
      padding:4px 0;
    }
    .corporation_right_span{color: #0089EF;cursor: pointer;}
  }
  @media screen and (max-width:1200px) {
    .component-label{
      width: 96%;
    }
  }
}
</style>

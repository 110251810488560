<template>
  <div class="Industrial">
    <!-- 左侧悬浮导航栏 -->
    <div class="float_div">
      <div v-for="(item, index) in tabDatas" :key="index"
        :class="['menu_items', nowCheckedTab.name == item.name ? 'checked_tab' : '']" @click="scollEvent(item)">
        <div>{{ item.name }}</div>
      </div>
    </div>
    <!-- 面包屑 -->
    <el-breadcrumb class="DetailsConsultation_breadcrumb" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <i class="el-icon-s-home"></i>首页
      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: title.path }">{{ title.val }}</el-breadcrumb-item>
      <el-breadcrumb-item>{{ valDetails.title }}</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="Industrial_in" v-if='showLoading'>
      <div class="Industrial_name">
        <div style="display: flex;align-items: center;justify-content: center">
          <!--          <h4 class="Industrial_name_h4">{{ valDetails.title }}</h4>-->
          <img :src="valDetails.gongsilog" alt="" />
        </div>
        <div class="Industrial_name_right">
          <!--          <h4>{{ valDetails.qymc ? valDetails.qymc : '暂无' }}</h4>-->
          <h4 class="Industrial_name_h4">{{ valDetails.title }}</h4>
          <div style="width:100%">
            <p class="Industrial_name_right_text" :title="valDetails.ywmc">{{ valDetails.fields.ywmc }}：<span>{{
              valDetails.ywmc || '暂无' }}</span>
            </p>
            <p class="Industrial_name_right_text" :title="valDetails.gsjc">
              {{ valDetails.fields.gsjc }}：<span>{{ valDetails.gsjc || '暂无' }}</span></p>
            <p class="Industrial_name_right_text"
              :title="valDetails.zongbudizhi ? arrToString(valDetails.zongbudizhi, ',') : '暂无'">
              {{ valDetails.fields.zongbudizhi }}：<span>{{ valDetails.zongbudizhi ?
                arrToString(valDetails.zongbudizhi, ',') : '暂无' }}</span></p>
            <p class="Industrial_name_right_text"
              :title="valDetails.chanyeleibie ? arrToString(valDetails.chanyeleibie, ',') : ''">
              {{ valDetails.fields.chanyeleibie }}：<span>{{
                valDetails.chanyeleibie ?
                arrToString(valDetails.chanyeleibie, ',')
                : '暂无' }}</span></p>
            <p class="Industrial_name_right_text" :title="valDetails.zwgs ? arrToString(valDetails.zwgs, ',') : '暂无'">{{
              valDetails.fields.zwgs }}：<span>{{ valDetails.zwgs ? arrToString(valDetails.zwgs, ',') : '暂无' }}</span>
            </p>
            <p class="Industrial_name_right_text"
              :title="valDetails.zbjieduan ? arrToString(valDetails.zbjieduan, ',') : '暂无'">
              {{ valDetails.fields.zbjieduan }}：<span>{{
                valDetails.zbjieduan && valDetails.zbjieduan.length > 0 ? arrToString(valDetails.zbjieduan, ",") : '暂无'
              }}</span>
            </p>
            <p class="Industrial_name_right_text" :title="valDetails.gsch ? arrToString(valDetails.gsch, ',') : '暂无'">
              {{ valDetails.fields.gsch }}：<span>{{
                valDetails.gsch && valDetails.gsch.length > 0 ? arrToString(valDetails.gsch, ",") : '暂无' }}</span></p>
            <p class="Industrial_name_right_text" :title="valDetails.jishuleibie">
              {{ valDetails.fields.jishuleibie }}：<span>{{ valDetails.jishuleibie ||
                '暂无' }}</span></p>
            <!-- <p class="Industrial_name_right_text">证券所：<span>{{ '暂无' }}</span></p> -->
            <p class="Industrial_name_right_text" :title="valDetails.clsj">{{ valDetails.fields.clsj }}：<span>{{
              valDetails.chengliriqi ||
              valDetails.clsj }}</span></p>
            <p class="Industrial_name_right_text" @click="dialogVisible = true">{{ valDetails.fields.gsspwj }}：<span
                class="video_span">{{ valDetails.gsspwj
                  || '暂无' }}</span></p>
            <p class="Industrial_name_right_text" :title="valDetails.zhucedidian">
              {{ valDetails.fields.zhucedidian }}：<span>{{ valDetails.zhucedidian ||
                '暂无' }}</span></p>
            <p class="Industrial_name_right_text" :title="valDetails.gfwz">{{ valDetails.fields.gfwz }}：<span>{{
              valDetails.gfwz || '暂无' }}</span>
            </p>
            <p class="Industrial_name_right_text" :title="valDetails.fddbr">{{ valDetails.fields.fddbr }}：<span>{{
              valDetails.fddbr || '暂无'
            }}</span></p>
            <p class="Industrial_name_right_text">{{ valDetails.fields.hyxj }}：<span>{{ valDetails.hyxj || '暂无' }}</span>
            </p>
            <p class="Industrial_name_right_text" :title="valDetails.zhuyaokehu">{{ valDetails.fields.zhuyaokehu
            }}：<span>{{
  valDetails.zhuyaokehu ||
  '暂无' }}</span></p>
            <p class="Industrial_name_right_text" :title="valDetails.zhengjuansuo">{{ valDetails.fields.zhengjuansuo
            }}：<span>{{
  valDetails.zhengjuansuo ||
  '暂无' }}</span></p>
            <p class="Industrial_name_right_text1" style="width:99%">
              {{ valDetails.fields.qiyerongyu }}：<span v-html='valDetails.qiyerongyu'></span></p>

          </div>
          <div>
            <el-popover placement="right" width="400" trigger="click" class="wx_ck_div">
              <img :src="valDetails.ckxcx" alt="">
              <el-button class="ckxcx_div" disabled slot="reference">查看小程序</el-button>
            </el-popover>
          </div>
        </div>
      </div>
      <!-- <el-tabs v-model="activeName" class="Industrial_label" @tab-click="handleClick">
        <el-tab-pane v-for="(item, index) in label" :key="index" :label="item.label" :name="item.label"></el-tab-pane>
      </el-tabs> -->
      <!-- 导航栏 -->
      <div class="top_tabs_div">
        <div v-for="(item, index) in tabDatas" :key="index"
          :class="['tabs_items', nowCheckedTab.name == item.name ? 'checked_tab' : '']" @click="scollEvent(item)">
          <div>{{ item.name }}</div>
        </div>
      </div>

      <!-- 基本情况 -->
      <div id="basicInformation" style="padding: 0 5%;padding-top:20px;">
        <div class="li_tab_check">
          <div v-if="type != 6" @click="scollTo('AnOverview')">{{ valDetails.fields.qiyegaikuang }}</div>
          <div v-if="type == 6" @click="scollTo('park')">园区状况</div>
          <div @click="scollTo('registered')">工商注册</div>
          <div v-if="type == 3" @click="scollTo('OwnershipStructure')">{{ valDetails.fields.guquanjiegou }}</div>
          <div v-if="type != 4 && type != 3" @click="scollTo('parkPolicy')">园区政策</div>
          <div v-if="type == 6" @click="scollTo('zxczxx')">园区企业</div>
          <div v-if="type != '6' && type != 3" @click="scollTo('toraise')">{{ type < 5 ? '募资状况' : '机构状况' }}</div>
              <div @click="scollTo('team')">{{ valDetails.fields.zytd }}</div>
          </div>
          <div v-if="type != 6" id="AnOverview">
            <h3 class="Industrial_title"><img alt="" src="../../public/img/img17.png" /><span>{{
              valDetails.fields.qiyegaikuang }}</span></h3>
            <div class="AnOverview" style="text-indent:2em">{{ valDetails.qiyegaikuang }}</div>
          </div>
          <div v-if="type == 6" id="park">
            <h3 class="Industrial_title"><img alt="" src="../../public/img/img17.png" /><span>园区状况</span></h3>
            <div class="registered">
              <el-row>
                <el-col :span="24">
                  <div class="registered_div">
                    <div class="registered_left">
                      <div>
                        <p>园区概况</p>
                      </div>
                    </div>
                    <div class="registered_right">
                      <p>{{ valDetails.title ? valDetails.title : '暂无' }}</p>
                    </div>
                  </div>
                </el-col>
                <el-col :span="24">
                  <div class="registered_div">
                    <div class="registered_left">
                      <div>
                        <p>园区图片</p>
                      </div>
                    </div>
                    <div class="registered_right" style="min-height: 58px;">
                      <div class="registered_right_img">
                        <img v-for="(d, i) in valDetails.yuanqutupian" :src="d.url" alt="" />
                      </div>
                    </div>
                  </div>
                </el-col>
                <el-col :span="24">
                  <div class="registered_div">
                    <div class="registered_left">
                      <div>
                        <p>园区产业</p>
                      </div>
                    </div>
                    <div class="registered_right">
                      <p>
                        {{ valDetails.yuanquchanye == '' ? '暂无' : valDetails.yuanquchanye }}</p>
                    </div>
                  </div>
                </el-col>
                <el-col :span="24">
                  <div class="registered_div">
                    <div class="registered_left">
                      <div>
                        <p>经营范围</p>
                      </div>
                    </div>
                    <div class="registered_right">
                      <p>{{ valDetails.jyfw ? valDetails.jyfw : '暂无' }}</p>
                    </div>
                  </div>
                </el-col>
                <el-col :span="24">
                  <div class="registered_div">
                    <div class="registered_left">
                      <div>
                        <p>备注</p>
                      </div>
                    </div>
                    <div class="registered_right">
                      <p>备注</p>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
            <div class="ydd_registered" style="display: none;">
              <el-row>
                <el-col :span="24" class="ydd_registered_label">
                  <div>园区概况：</div>
                  <p>{{ valDetails.title ? valDetails.title : '暂无' }}</p>
                </el-col>
                <el-col :span="24" class="ydd_registered_label">
                  <div>园区图片：</div>
                  <div class="registered_right_img">
                    <img v-for="(d, i) in valDetails.yuanqutupian" :src="d.url" />
                  </div>
                </el-col>
                <el-col :span="24" class="ydd_registered_label">
                  <div>园区产业：</div>
                  <p>{{ valDetails.yuanquchanye == '' ? '暂无' : valDetails.yuanquchanye }}</p>
                </el-col>
                <el-col :span="24" class="ydd_registered_label">
                  <div>经营范围：</div>
                  <p>{{ valDetails.jyfw ? valDetails.jyfw : '暂无' }}</p>
                </el-col>
                <el-col :span="12" class="ydd_registered_label">
                  <div>备注：</div>
                  <p>备注</p>
                </el-col>
              </el-row>
            </div>
          </div>
          <div id="registered">
            <h3 class="Industrial_title"><img alt="" src="../../public/img/img17.png" /><span>工商注册</span></h3>
            <div class="registered">
              <el-row>
                <el-col :span="12">
                  <div class="registered_div">
                    <div class="registered_left">
                      <div>
                        <p>{{ type != 6 ? (type !=
                          3 ? valDetails.fields.qymc : valDetails.fields.title) : valDetails.fields.gongsiming }}</p>
                      </div>
                    </div>
                    <div class="registered_right">
                      <p v-if="type == 6">{{ valDetails.gongsiming ? valDetails.gongsiming : '暂无' }}</p>
                      <p v-if="type != 6 && type != 3">{{ valDetails.qymc ? valDetails.qymc : '暂无' }}</p>
                      <p v-if="type == 3">{{ valDetails.title ? valDetails.title : '暂无' }}</p>
                    </div>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="registered_div">
                    <div class="registered_left">
                      <div>
                        <p>{{ valDetails.fields.ywmc }}</p>
                      </div>
                    </div>
                    <div class="registered_right">
                      <p>{{ valDetails.ywmc || '暂无' }}</p>
                    </div>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="registered_div">
                    <div class="registered_left">
                      <div>
                        <p>{{ valDetails.fields.zhuceziben }}</p>
                      </div>
                    </div>
                    <div class="registered_right">
                      <p>{{ valDetails.zhuceziben ? valDetails.zhuceziben : '暂无' }}元</p>
                    </div>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="registered_div">
                    <div class="registered_left">
                      <div>
                        <p>{{ valDetails.fields.shijiaoziben }}</p>
                      </div>
                    </div>
                    <div class="registered_right">
                      <p>{{ valDetails.shijiaoziben ? valDetails.shijiaoziben + '元' : '暂无' }}</p>
                    </div>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="registered_div">
                    <div class="registered_left">
                      <div>
                        <p>{{ valDetails.fields.gslx }}</p>
                      </div>
                    </div>
                    <div class="registered_right">
                      <p>{{ valDetails.gslx ? valDetails.gslx : '暂无' }}</p>
                    </div>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="registered_div">
                    <div class="registered_left">
                      <div>
                        <p>{{ valDetails.fields.clsj }}</p>
                      </div>
                    </div>
                    <div class="registered_right">
                      <p>{{ valDetails.clsj ? valDetails.clsj : '暂无' }}</p>
                    </div>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="registered_div">
                    <div class="registered_left">
                      <div>
                        <p>{{ valDetails.fields.fddbr }}</p>
                      </div>
                    </div>
                    <div class="registered_right">
                      <p>{{ valDetails.fddbr ? valDetails.fddbr : '暂无' }}</p>
                    </div>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="registered_div">
                    <div class="registered_left">
                      <div>
                        <p>{{ valDetails.fields.yingyeqixian }}</p>
                      </div>
                    </div>
                    <div class="registered_right">
                      <p>{{ valDetails.yingyeqixian ? valDetails.yingyeqixian : '暂无' }}</p>
                    </div>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="registered_div">
                    <div class="registered_left">
                      <div>
                        <p>{{ valDetails.fields.tyshxydm }}</p>
                      </div>
                    </div>
                    <div class="registered_right">
                      <p>{{ valDetails.tyshxydm ? valDetails.tyshxydm : '暂无' }}</p>
                    </div>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="registered_div">
                    <div class="registered_left">
                      <div>
                        <p>{{ valDetails.fields.jyzt }}</p>
                      </div>
                    </div>
                    <div class="registered_right">
                      <p>{{ valDetails.jyzt ? valDetails.jyzt : '暂无' }}</p>
                    </div>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="registered_div">
                    <div class="registered_left">
                      <div>
                        <p>{{ valDetails.fields.xingye }}</p>
                      </div>
                    </div>
                    <div class="registered_right">
                      <p>{{ valDetails.xingye ? valDetails.xingye : '暂无' }}</p>
                    </div>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="registered_div">
                    <div class="registered_left">
                      <div>
                        <p>{{ valDetails.fields.ssss }}</p>
                      </div>
                    </div>
                    <div class="registered_right">
                      <p>{{ valDetails.ssss ? valDetails.ssss : '暂无' }}</p>
                    </div>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="registered_div">
                    <div class="registered_left">
                      <div>
                        <p>{{ valDetails.fields.dengjijiguan }}</p>
                      </div>
                    </div>
                    <div class="registered_right">
                      <p>{{ valDetails.dengjijiguan ? valDetails.dengjijiguan : '暂无' }}</p>
                    </div>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="registered_div">
                    <div class="registered_left">
                      <div>
                        <p>{{ valDetails.fields.djsj }}</p>
                      </div>
                    </div>
                    <div class="registered_right">
                      <p>{{ valDetails.djsj ? valDetails.djsj : '暂无' }}</p>
                    </div>
                  </div>
                </el-col>
                <el-col :span="24">
                  <div class="registered_div">
                    <div class="registered_left">
                      <div>
                        <p>{{ valDetails.fields.zhucedidian }}</p>
                      </div>
                    </div>
                    <div class="registered_right">
                      <p>{{ valDetails.zhucedidian ? valDetails.zhucedidian : '暂无' }}</p>
                    </div>
                  </div>
                </el-col>
                <el-col :span="24">
                  <div class="registered_div">
                    <div class="registered_left">
                      <div>
                        <p>{{ valDetails.fields.jyfw }}</p>
                      </div>
                    </div>
                    <div class="registered_right">
                      <p>{{ valDetails.jyfw ? valDetails.jyfw : '暂无' }}</p>
                    </div>
                  </div>
                </el-col>
                <!-- <el-col :span="24">
                <div class="registered_div">
                  <div class="registered_left">
                    <div>
                      <p>联系方式</p>
                    </div>
                  </div>
                  <div class="registered_right">
                    <p v-if="type == 6">{{ valDetails.dianhua ? valDetails.dianhua : '暂无' }}</p>
                    <p v-if="type != 6">{{ valDetails.lxfs ? valDetails.lxfs : '暂无' }}</p>
                  </div>
                </div>
              </el-col> -->
              </el-row>
            </div>
            <div class="ydd_registered" style="display: none;">
              <el-row>
                <el-col :span="24" class="ydd_registered_label">
                  <div>企业名称：</div>
                  <p v-if="type == 6">{{ valDetails.gongsiming ? valDetails.gongsiming : '暂无' }}</p>
                  <p v-if="type != 6">{{ valDetails.qymc ? valDetails.qymc : '暂无' }}</p>
                </el-col>
                <el-col :span="24" class="ydd_registered_label">
                  <div>统一社会信用代码：</div>
                  <p>{{ valDetails.tyshxydm ? valDetails.tyshxydm : '暂无' }}</p>
                </el-col>
                <el-col :span="24" class="ydd_registered_label">
                  <div>注册资本：</div>
                  <p>{{ valDetails.zhuceziben ? valDetails.zhuceziben : '暂无' }}元</p>
                </el-col>
                <el-col :span="12" class="ydd_registered_label">
                  <div>成立日期：</div>
                  <p>{{ valDetails.clsj ? valDetails.clsj : '暂无' }}</p>
                </el-col>
                <el-col :span="12" class="ydd_registered_label">
                  <div>公司类型：</div>
                  <p>{{ valDetails.gslx ? valDetails.gslx : '暂无' }}</p>
                </el-col>
                <el-col :span="24" class="ydd_registered_label">
                  <div>法定代表人：</div>
                  <p>{{ valDetails.fddbr ? valDetails.fddbr : '暂无' }}</p>
                </el-col>
                <el-col :span="12" class="ydd_registered_label">
                  <div>经营状态：</div>
                  <p>{{ valDetails.jyzt ? valDetails.jyzt : '暂无' }}</p>
                </el-col>
                <el-col :span="12" class="ydd_registered_label">
                  <div>行业：</div>
                  <p>{{ valDetails.xingye ? valDetails.xingye : '暂无' }}</p>
                </el-col>
                <el-col :span="24" class="ydd_registered_label">
                  <div>经营范围：</div>
                  <p>{{ valDetails.jyfw ? valDetails.jyfw : '暂无' }}</p>
                </el-col>
                <el-col :span="24" class="ydd_registered_label">
                  <div>联系方式：</div>
                  <p v-if="type == 6">{{ valDetails.dianhua ? valDetails.dianhua : '暂无' }}</p>
                  <p v-if="type != 6">{{ valDetails.lxfs ? valDetails.lxfs : '暂无' }}</p>
                </el-col>
              </el-row>
            </div>
          </div>
          <div v-if="type == 3" id="OwnershipStructure">
            <h3 class="Industrial_title"><img alt="" src="../../public/img/img17.png" /><span>{{
              valDetails.fields.guquanjiegou }}</span></h3>
            <div class="direction condition">
              <el-table :cell-style="tableStyle" :data="FinancingSituation1" border stripe style="width: 100%">
                <el-table-column label="序号" type="index" width="78"></el-table-column>
                <el-table-column :label="FinancingSituationll33.fields.title" prop="title">
                </el-table-column>
                <el-table-column :label="FinancingSituationll33.fields.touzijine" prop="touzijine">
                </el-table-column>
                <!-- <el-table-column :label="FinancingSituationll33.fields.suozhangufen" prop="suozhangufen">
                </el-table-column> -->
                <el-table-column :label="FinancingSituationll33.fields.suozhangubi" prop="suozhangubi">
                </el-table-column>
                <el-table-column :label="FinancingSituationll33.fields.jiegoutu" prop="jiegoutu">
                  <template slot-scope="scope">
                    <img :src="scope.row.jiegoutu" style="height:80px;" />
                  </template>
                </el-table-column>
                <el-table-column :label="FinancingSituationll33.fields.bingzhuangtu" prop="bingzhuangtu">
                  <template slot-scope="scope">
                    <img :src="scope.row.bingzhuangtu" style="height:80px;" />
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <el-pagination :current-page="FinancingSituationll33.page" :page-size="FinancingSituationll33.pagesize"
              :total="FinancingSituationll33.total" background class="pagination"
              layout="total, sizes, prev, pager, next, jumper" next-text="下一页" prev-text="上一页"
              @size-change="FinancingSituationSize22" @current-change="FinancingSituationChange22">
            </el-pagination>
          </div>
          <div v-if="type != 4 && type != 3" id="parkPolicy">
            <h3 class="Industrial_title"><img alt="" src="../../public/img/img17.png" /><span>园区政策</span></h3>
            <div class="style condition">
              <el-table :cell-style="tableStyle" :data="parkPolicyData" border stripe style="width: 100%">
                <el-table-column label="序号" type="index" width="78"></el-table-column>
                <el-table-column label="股东名称" prop="title">
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="100">
                  <template slot-scope="scope">
                    <el-button type="text" @click="ccasdawz(scope.row.id)">查看</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination :current-page="relatedParkpolicy.page" :page-size="relatedParkpolicy.pagesize"
                :total="relatedParkpolicy.total" background class="pagination"
                layout="total, sizes, prev, pager, next, jumper" next-text="下一页" prev-text="上一页"
                @size-change="ParkpolicyDsizeChange" @current-change="ParkpolicyDcurrentChange">
              </el-pagination>
            </div>
          </div>
          <div v-if="type == '6'" id="zxczxx">
            <h3 class="Industrial_title"><img alt="" src="../../public/img/img17.png" /><span>园区企业</span></h3>
            <div class="FindIndustry condition">
              <el-table :cell-style="tableStyle" :data="zxcsstableData" stripe style="width: 100%">
                <el-table-column label="企业名称" prop="title" width="340">
                </el-table-column>
                <el-table-column label="成立日期" prop="create_time">
                </el-table-column>
                <el-table-column label="详情" width="90">
                  <template slot-scope="scope">
                    <el-button plain size="mini" type="primary" @click="zxczcasd(scope.row)">查看</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination :current-page="zxcsstable.page" :page-size="zxcsstable.pagesize" :total="zxcsstable.total"
                ackground layout="total, sizes, prev, pager, next, jumper" next-text="下一页" prev-text="上一页"
                @size-change="zxcsstableSize" @current-change="zxcsstableChange">
              </el-pagination>
            </div>
          </div>
          <div v-if="type != '6' && type != 3" id="toraise">
            <h3 class="Industrial_title"><img alt="" src="../../public/img/img17.png" />
              <span v-if="type < 5">募资状况</span>
              <span v-if="type >= 5">机构状况</span>
            </h3>
            <div class="direction condition">
              <el-table :cell-style="tableStyle" :data="fundraisingStatusData" border stripe style="width: 100%">
                <el-table-column label="序号" type="index" width="78"></el-table-column>
                <el-table-column label="股东名称" prop="title" width="295">
                </el-table-column>
                <el-table-column label="股份类型" prop="gufenleixing" width="197">
                </el-table-column>
                <el-table-column label="持股数量（股）" prop="cgsl">
                </el-table-column>
                <el-table-column label="持股比例" prop="chigubili">
                </el-table-column>
                <el-table-column label="状态" width="120">
                  <template slot-scope="scope">
                    <el-button type="text" @click="ccasdawz(scope.row.id)">查看</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <el-pagination :current-page="fundraisingStatus.page" :page-size="fundraisingStatus.pagesize"
              :total="fundraisingStatus.total" background class="pagination"
              layout="total, sizes, prev, pager, next, jumper" next-text="下一页" prev-text="上一页"
              @size-change="fundraisingStatusSize" @current-change="fundraisingStatusChange">
            </el-pagination>
          </div>
          <div id="team">
            <h3 class="Industrial_title"><img alt="" src="../../public/img/img17.png" /><span>{{ valDetails.fields.zytd
            }}</span></h3>
            <div class="project">
              <div v-for="(d, i) in PrimaryTeamList" :key="i" class="project_list">
                <div class="team_img_div">
                  <img class="team_img" :src="d.thumb" alt="" />
                </div>
                <div style="width:100%;text-align: center;margin-top: 10px;">
                  <h4>{{ d.title }}</h4>
                  <div>
                    {{ d.zhiwu }}
<!--                    <i class="el-icon-arrow-right"></i>-->
                  </div>
                </div>
              </div>
            </div>
            <el-pagination :current-page="PrimaryTeam.page" :page-size="PrimaryTeam.pagesize" :total="PrimaryTeam.total"
              background class="pagination" layout="total, sizes, prev, pager, next, jumper" next-text="下一页"
              prev-text="上一页" @size-change="PrimaryTeamSize" @current-change="PrimaryTeamChange">
            </el-pagination>
          </div>
        </div>
        <!-- 生产经营 -->
        <div id="productionOperation" style="padding: 0 5%;">
          <div class="li_tab_check">
            <!-- <div v-if="type == 3" @click="scollTo('financial1')">{{ valDetails.fields.jbxx }}</div> -->
            <div v-if="type == '6' || type == 3" @click="scollTo('financial1')">{{ valDetails.fields.gcsb }}</div>
            <div v-if="type == '6' || type == 3" @click="scollTo('financial')">{{ valDetails.fields.hexinchanpin }}</div>
            <div v-if="type != 6 && type != 3" @click="scollTo('financial2')">{{ type == 6 ? '机构业务' : '金融产品' }}</div>
            <div v-if="type == 3" @click="scollTo('financial11')">3D产品模型展示</div>
          </div>
          <div v-if="type == '6' || type == 3" id="financial1">
            <h3 class="Industrial_title"><img alt="" src="../../public/img/img17.png" /><span>{{ valDetails.fields.jbxx
            }}</span></h3>
            <div class="condition">
              <el-table :cell-style="tableStyle" :data="gongchangshebei"
                :tree-props="{children: 'chejianxinxis', hasChildren: 'chejianxinxis'}"
                border stripe style="width: 100%">
                <el-table-column :label="gongchangshebei1.fields.title" prop="title" />
                <el-table-column :label="gongchangshebei1.fields.jiditupian" prop="jiditupian">
                  <template slot-scope="scope">
                    <img :src="scope.row.jiditupian" alt="" style="height:80px;" />
                  </template>
                </el-table-column>
                <el-table-column :label="gongchangshebei1.fields.zhuyaoshebei" prop="zhuyaoshebei">
                  <template slot-scope="scope">
                    <img :src="scope.row.zhuyaoshebei" alt="" style="height:80px;" />
                  </template>
                </el-table-column>
                <el-table-column :label="gongchangshebei1.fields.zysbsm" prop="zysbsm" />
              </el-table>
              <el-pagination :current-page="gongchangshebei1.page" :page-size="gongchangshebei1.pagesize"
                :total="gongchangshebei1.total" background class="pagination"
                layout="total, sizes, prev, pager, next, jumper" next-text="下一页" prev-text="上一页"
                @size-change="gongchangshebei1Size1" @current-change="gongchangshebei1Change1">
              </el-pagination>
            </div>
          </div>
          <!-- <div v-if="type == '6' || type == 3" id="financial1">
            <h3 class="Industrial_title"><img alt="" src="../../public/img/img17.png" /><span>{{ valDetails.fields.gcsb
            }}</span></h3>
            <div class="condition">
              <el-table :cell-style="tableStyle" :show-header='false' :data="financeList" :border="false"
                style="width: 100%">
                <el-table-column>
                  <template slot-scope="scope"><el-table :cell-style="tableStyle" :data="scope.row.chejianxinxisArr"
                      border stripe style="width: 100%">
                      <el-table-column label="图片" prop="1">
                        <template slot-scope="scope">
                          <img :src="scope.row[1]" alt="" style="height:80px;" />
                        </template>
                      </el-table-column>
                      <el-table-column label="车间说明" prop="2">
                      </el-table-column>
                      <el-table-column label="主要设备图片" prop="3">
                        <template slot-scope="scope">
                          <img :src="scope.row[3]" alt="" style="height:80px;" />
                        </template>
                      </el-table-column>
                      <el-table-column label="主要设备说明" prop="4">
                      </el-table-column>
                    </el-table>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination :current-page="productOrganization.page" :page-size="productOrganization.pagesize"
                :total="productOrganization.total" background class="pagination"
                layout="total, sizes, prev, pager, next, jumper" next-text="下一页" prev-text="上一页"
                @size-change="productOrganizationSize1" @current-change="productOrganizationChange1">
              </el-pagination>
            </div>
          </div> -->
          <div v-if="type == '6' || type == 3" id="financial">
            <h3 class="Industrial_title"><img alt="" src="../../public/img/img17.png" /><span>{{
              valDetails.fields.hexinchanpin
            }}</span></h3>
            <div class="condition">
              <el-table :cell-style="tableStyle" :data="financeList1" border stripe style="width: 100%">
                <el-table-column :label="productOrganization1.fields.title" prop="title" width="130">
                </el-table-column>
                <el-table-column :label="productOrganization1.fields.thumb" prop="thumb">
                  <template slot-scope="scope">
                    <el-image style="height: 110px"
                      :src="hxcpImg(scope.row.thumb)" :preview-src-list="[hxcpImg(scope.row.thumb)]">
                    </el-image>
                  </template>
                </el-table-column>
                <el-table-column :label="productOrganization1.fields.jishucanshu" prop="canshu">
                  <template slot-scope="scope">
                    <div v-html='scope.row.canshu' class="overflow_txt"></div>
                  </template>
                </el-table-column>
                <el-table-column :label="productOrganization1.fields.cpsm" prop="cpsm" width="250">
                  <template slot-scope="scope">
                    <div :title="scope.row.cpsm"
                      style="display: -webkit-box;-webkit-box-orient: vertical;overflow: hidden;-webkit-line-clamp: 4;">
                      {{ scope.row.cpsm }}</div>
                  </template>
                </el-table-column>
                <el-table-column :label="productOrganization1.fields.cplb1" prop="cplb1" width="90">
                </el-table-column>
                <el-table-column :label="productOrganization1.fields.cplb2" prop="cplb2" width="90">
                </el-table-column>
                <el-table-column :label="productOrganization1.fields.cplb3" prop="cplb3" width="90">
                </el-table-column>
              </el-table>
              <el-pagination :current-page="productOrganization1.page" :page-size="productOrganization1.pagesize"
                :total="productOrganization1.total" background class="pagination"
                layout="total, sizes, prev, pager, next, jumper" next-text="下一页" prev-text="上一页"
                @size-change="productOrganizationSize11" @current-change="productOrganizationChange11">
              </el-pagination>
            </div>
          </div>
          <div v-if="type != '6' && type != 3" id="financial2">
            <h3 class="Industrial_title"><img alt="" src="../../public/img/img17.png" />
              <span v-if="type != 6">金融产品</span>
              <span v-if="type == 6">机构业务</span>
            </h3>
            <div class="pc_financial">
              <div v-for="(i, t) in financeList" :key="t" class="financial">
                <div class="financial_left" style="height: 100%;"><img :src="i.thumb" alt=""></div>
                <div class="financial_right">
                  <h3>{{ i.title }}</h3>
                  <el-row>
                    <el-col :span="9">
                      <p class="financial_text">发电机组型号 {{ i.fdjzxh }}</p>
                      <p class="financial_text">柴油机 型号 {{ i.cyjxh }}</p>
                      <p class="financial_text">行程MM {{ i.xingchengmm }}</p>
                      <p class="financial_text">额定功率KW {{ i.edglkw }}</p>
                      <p class="financial_text">额定电流A {{ i.eddla }}</p>
                      <p class="financial_text">额定功率KW {{ i.eddla }}</p>
                      <p class="financial_text">额定转速r/min {{ i.edingzhuansu }}</p>
                    </el-col>
                    <el-col :span="8">
                      <p class="financial_text">额定频率Hz {{ i.edingpinlu }}</p>
                      <p class="financial_text">额定电压V {{ i.edingdianyav }}</p>
                      <p class="financial_text">相数及接法 {{ i.xsjjf }}</p>
                      <p class="financial_text">输出路数 {{ i.shuchulushu }}</p>
                      <p class="financial_text">额定功率因数 {{ i.edglys }}</p>
                      <p class="financial_text">燃油消耗率g/kwh {{ i.ryxh }}</p>
                      <p class="financial_text">机油消耗率g/kwh {{ i.jyxhl }}</p>
                    </el-col>
                    <el-col :span="7">
                      <p class="financial_text">控制方法 {{ i.kzff }}</p>
                      <p class="financial_text">电压 {{ i.dianya }}</p>
                      <p class="financial_text">频态调整率% {{ i.pinlu }}</p>
                      <p class="financial_text">稳定时间S 1 </p>
                      <p class="financial_text">波动率 0.5 </p>
                      <p class="financial_text">频率 稳态调整率% {{ i.wdsjs }}</p>
                      <p class="financial_text">频态调整率% {{ i.pintai }}</p>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <el-pagination :current-page="productOrganization.page" :page-size="productOrganization.pagesize"
                :total="productOrganization.total" background class="pagination"
                layout="total, sizes, prev, pager, next, jumper" next-text="下一页" prev-text="上一页"
                @size-change="productOrganizationSize" @current-change="productOrganizationChange">
              </el-pagination>
            </div>
            <div class="ydd_financial" style="display: none;">
              <div v-for="(i, t) in financeList" :key="t" class="ydd_financial_list">
                <el-row :gutter="30">
                  <el-col :span="12"><img :src="i.thumb" alt="" class="ydd_financial_list_img"></el-col>
                  <el-col :span="12">
                    <h3 class="ydd_financial_list_h3">{{ i.title }}</h3>
                  </el-col>
                </el-row>
                <el-row style="padding: 50px 0;">
                  <el-col :span="14">
                    <p class="financial_text">发电机组型号 {{ i.fdjzxh }}</p>
                    <p class="financial_text">柴油机 型号 {{ i.cyjxh }}</p>
                    <p class="financial_text">行程MM {{ i.xingchengmm }}</p>
                    <p class="financial_text">额定功率KW {{ i.edglkw }}</p>
                    <p class="financial_text">额定电流A {{ i.eddla }}</p>
                    <p class="financial_text">额定功率KW {{ i.eddla }}</p>
                    <p class="financial_text">额定转速r/min {{ i.edingzhuansu }}</p>
                  </el-col>
                  <el-col :span="10">
                    <p class="financial_text">额定频率Hz {{ i.edingpinlu }}</p>
                    <p class="financial_text">额定电压V {{ i.edingdianyav }}</p>
                    <p class="financial_text">相数及接法 {{ i.xsjjf }}</p>
                    <p class="financial_text">输出路数 {{ i.shuchulushu }}</p>
                    <p class="financial_text">额定功率因数 {{ i.edglys }}</p>
                    <p class="financial_text">燃油消耗率g/kwh {{ i.ryxh }}</p>
                    <p class="financial_text">机油消耗率g/kwh {{ i.jyxhl }}</p>
                  </el-col>
                </el-row>
              </div>
              <el-pagination :current-page="productOrganization.page" :page-size="productOrganization.pagesize"
                :total="productOrganization.total" background class="pagination"
                layout="total, sizes, prev, pager, next, jumper" next-text="下一页" prev-text="上一页"
                @size-change="productOrganizationSize" @current-change="productOrganizationChange">
              </el-pagination>
            </div>
          </div>
          <div v-if="type == 3" id="financial11">
            <h3 class="Industrial_title"><img alt="" src="../../public/img/img17.png" /><span>3D产品模型展示</span></h3>
            <div class="condition" style="margin-bottom:20px">
              <el-table :cell-style="tableStyle" :data="cp3dList" border stripe style="width: 100%">
                <el-table-column :label="cp3dListPage.fields.title" prop="title">
                </el-table-column>
                <el-table-column :label="cp3dListPage.fields.thumb">
                  <template slot-scope="scope">
                    <img :src="scope.row.thumb" style="height:80px;" />
                  </template>
                </el-table-column>
                <el-table-column :label="cp3dListPage.fields.cpjs" prop="cpjs">
                </el-table-column>
                <el-table-column :label="cp3dListPage.fields.cpxq" prop="cpxq">
                </el-table-column>
                <el-table-column>
                  <template slot-scope="scope">
                    <el-button type="text" @click="ckmodel(scope.row)">查看模型</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <el-pagination :current-page="cp3dListPage.page" :page-size="cp3dListPage.pagesize"
              :total="cp3dListPage.total" background class="pagination" layout="total, sizes, prev, pager, next, jumper"
              next-text="下一页" prev-text="上一页" @size-change="productddOrganizationSize"
              @current-change="productddOrganizationChange">
            </el-pagination>
          </div>
        </div>
        <!-- 研发状况 -->
        <div id="developmentStatus" style="padding: 0 5%;">
          <div class="li_tab_check">
            <div v-if="type == 3" @click="scollTo('developmentComp')">{{ valDetails.fields.yanfajigou }}</div>
            <div v-if="type == 3" @click="scollTo('intellectualProperty')">{{ valDetails.fields.zscq }}</div>
            <div v-if="type == 3" @click="scollTo('qualityCertification')">{{ valDetails.fields.zlrztx }}</div>
            <div v-if="type == 6" @click="scollTo('PatentedTechnology')">园区企业专利技术</div>
          </div>
          <div id="developmentComp" v-if="type == 3">
            <h3 class="Industrial_title"><img alt="" src="../../public/img/img17.png" /><span>{{
              valDetails.fields.yanfajigou }}</span></h3>
            <div class="condition style">
              <el-table :cell-style="tableStyle" :data="institutionsData" border stripe style="width: 100%">
                <el-table-column :label="institutionsDataPage.fields.title" prop="title">
                </el-table-column>
                <el-table-column :label="institutionsDataPage.fields.xiangmu" prop="xiangmu">
                </el-table-column>
                <el-table-column :label="institutionsDataPage.fields.renyuan" prop="renyuan">
                </el-table-column>
                <el-table-column :label="institutionsDataPage.fields.yanfajigou" prop="yanfajigou">
                </el-table-column>
                <el-table-column :label="institutionsDataPage.fields.chengguo" prop="chengguo">
                </el-table-column>
              </el-table>
            </div>
            <el-pagination :current-page="institutionsDataPage.page" :page-size="FinancingSituationll.pagesize"
              :total="institutionsDataPage.total" background class="pagination"
              layout="total, sizes, prev, pager, next, jumper" next-text="下一页" prev-text="上一页"
              @size-change="FinancingSituationSize1" @current-change="FinancingSituationChange1">
            </el-pagination>
          </div>
          <div id="intellectualProperty" v-if="type == 3">
            <h3 class="Industrial_title"><img alt="" src="../../public/img/img17.png" /><span>{{ valDetails.fields.zscq
            }}</span></h3>
            <div class="condition style">
              <el-table :cell-style="tableStyle" :data="FinancingSituation2" border stripe style="width: 100%">
                <el-table-column :label="FinancingSituationll2.fields.title" prop="title" width='320'>
                </el-table-column>
                <el-table-column :label="FinancingSituationll2.fields.cqlx" prop="cqlx">
                </el-table-column>
                <el-table-column :label="FinancingSituationll2.fields.shouquanhao" prop="shouquanhao" width='320'>
                </el-table-column>
                <el-table-column :label="FinancingSituationll2.fields.jynr" prop="jynr" width='300'>
                  <template slot-scope="scope">
                    <span v-if="scope.row" v-html='scope.row.jynr'></span>
                  </template>
                </el-table-column>
                <el-table-column fixed="right" label="查询" width="100">
                  <template slot-scope="scope">
                    <el-button disabled type="text" @click="viewEvent(scope.row)">查询</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <el-pagination :current-page="FinancingSituationll2.page" :page-size="FinancingSituationll2.pagesize"
              :total="FinancingSituationll2.total" background class="pagination"
              layout="total, sizes, prev, pager, next, jumper" next-text="下一页" prev-text="上一页"
              @size-change="FinancingSituationSize2" @current-change="FinancingSituationChange2">
            </el-pagination>
          </div>
          <div id="qualityCertification" v-if="type == 3">
            <h3 class="Industrial_title"><img alt="" src="../../public/img/img17.png" /><span>{{ valDetails.fields.zlrztx
            }}</span></h3>
            <div class="condition style">
              <el-table :cell-style="tableStyle" :data="FinancingSituation3" border stripe style="width: 100%">
                <el-table-column :label="FinancingSituationll3.fields.title" prop="title">
                </el-table-column>
                <el-table-column :label="FinancingSituationll3.fields.rzjg" prop="rzjg">
                </el-table-column>
                <el-table-column :label="FinancingSituationll3.fields.rzbh" prop="rzbh">
                </el-table-column>
                <el-table-column :label="FinancingSituationll3.fields.rzsj" prop="rzsj">
                </el-table-column>
                <el-table-column fixed="right" :label="FinancingSituationll3.fields.zscx" width="100">
                  <template slot-scope="scope">
                    <el-button disabled type="text" @click="viewEvent(scope.row)">查询</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <el-pagination :current-page="FinancingSituationll3.page" :page-size="FinancingSituationll3.pagesize"
              :total="FinancingSituationll3.total" background class="pagination"
              layout="total, sizes, prev, pager, next, jumper" next-text="下一页" prev-text="上一页"
              @size-change="FinancingSituationSize3" @current-change="FinancingSituationChange3">
            </el-pagination>
          </div>
          <div v-if="type == '6'" id="PatentedTechnology">
            <h3 class="Industrial_title"><img alt="" src="../../public/img/img17.png" />
              <span>园区企业专利技术</span>
            </h3>
            <el-row :gutter="57">
              <el-col v-for="(d, num) in zhuanlijishuList" :key="num" :span="4">
                <div class="PatentedTechnology_in" @click="ccasdawz(d.id)">
                  <div><img alt="" src="../../public/img/img15.png"></div>
                  <h4>{{ d.title }}</h4>
                  <p>{{ d.description }}</p>
                </div>
              </el-col>
            </el-row>
            <el-pagination :current-page="zhuanlijishu.page" :page-size="zhuanlijishu.pagesize"
              :total="zhuanlijishu.total" background class="pagination" layout="total, sizes, prev, pager, next, jumper"
              next-text="下一页" prev-text="上一页" @size-change="zhuanlijishuSize" @current-change="zhuanlijishuChange">
            </el-pagination>
          </div>
        </div>
        <!-- 供应链管理 -->
        <div id="supplyManagement" style="padding: 0 5%;">
          <div class="li_tab_check">
            <div v-if="type == 6" @click="scollTo('cooperation')">配套合作</div>
            <div v-if="type == 3" @click="scollTo('cooperation1')">{{ valDetails.fields.zjptkh }}</div>
            <div v-if="type == 3" @click="scollTo('cooperation2')">{{ valDetails.fields.jjptkh }}</div>
            <div v-if="type == 3" @click="scollTo('cooperation3')">{{ valDetails.fields.cgcp }}</div>
            <div v-if="type == 3" @click="scollTo('cooperation4')">{{ valDetails.fields.cangchu }}</div>
            <div v-if="type == 3" @click="scollTo('cooperation5')">{{ valDetails.fields.wuliu }}</div>
            <div v-if="type == 3" @click="scollTo('cooperation6')">{{ valDetails.fields.jckqk }}</div>
          </div>
          <div v-if="type == '6'" id="cooperation" class="condition">
            <h3 class="Industrial_title"><img alt="" src="../../public/img/img17.png" /><span>配套合作</span></h3>
            <div class="consulting" v-if="type == '6'">
              <el-table :cell-style="tableStyle" :data="parkSupportingCooperationData" border stripe style="width: 100%">
                <el-table-column label="序号" type="index" width="78"></el-table-column>
                <el-table-column label="公司名称" prop="title">
                </el-table-column>
                <el-table-column label="产品" prop="title">
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="100">
                  <template slot-scope="scope">
                    <el-button type="text" @click="ccasdawz(scope.row.id)">查看</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination :current-page="parkSupportingCooperation.page"
                :page-size="parkSupportingCooperation.pagesize" :total="parkSupportingCooperation.total"
                layout="total, sizes, prev, pager, next, jumper" next-text="下一页" prev-text="上一页"
                @size-change="parkSupportingCooperationSize" @current-change="parkSupportingCooperationCurrent">
              </el-pagination>
            </div>
          </div>
          <div v-if="type == 3" id="cooperation1" class="condition">
            <h3 class="Industrial_title"><img alt="" src="../../public/img/img17.png" /><span>{{ valDetails.fields.zjptkh
            }}</span></h3>
            <div class="consulting" v-if="type == '3'">
              <el-table :cell-style="tableStyle" :data="matchingCooperationData" border stripe style="width: 100%">
                <el-table-column :label="matchingCooperation.fields.title" prop="title">
                  <template slot-scope="scope">
                    <span v-html='scope.row.title'></span>
                  </template>
                </el-table-column>
                <el-table-column fixed="right" :label="matchingCooperation.fields.qiatan" width="100">
                  <template slot-scope="scope">
                    <el-button disabled type="text" @click="ccasdawz(scope.row.id)">洽谈</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination :current-page="matchingCooperation.page" :page-size="matchingCooperation.pagesize"
                :total="matchingCooperation.total" background class="pagination"
                layout="total, sizes, prev, pager, next, jumper" next-text="下一页" prev-text="上一页"
                @size-change="matchingCooperationSize" @current-change="matchingCooperationCurrent">
              </el-pagination>
            </div>
          </div>
          <div v-if="type == 3" id="cooperation2" class="condition">
            <h3 class="Industrial_title"><img alt="" src="../../public/img/img17.png" /><span>{{ valDetails.fields.jjptkh
            }}</span></h3>
            <div class="consulting" v-if="type == '3'">
              <el-table :cell-style="tableStyle" :data="matchingCooperationData1" border stripe style="width: 100%">
                <el-table-column :label="matchingCooperation1.fields.kehugaishu" prop="kehugaishu">
                  <template slot-scope="scope">
                    <span v-html='scope.row.kehugaishu'></span>
                  </template>
                </el-table-column>
                <el-table-column fixed="right" :label="matchingCooperation1.fields.qiatan" width="100">
                  <template slot-scope="scope">
                    <el-button disabled type="text" @click="ccasdawz(scope.row.id)">洽谈</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination :current-page="matchingCooperation1.page" :page-size="matchingCooperation1.pagesize"
                :total="matchingCooperation1.total" background class="pagination"
                layout="total, sizes, prev, pager, next, jumper" next-text="下一页" prev-text="上一页"
                @size-change="matchingCooperationSize1" @current-change="matchingCooperationCurrent1">
              </el-pagination>
            </div>
          </div>
          <div v-if="type == 3" id="cooperation3" class="condition">
            <h3 class="Industrial_title"><img alt="" src="../../public/img/img17.png" /><span>{{ valDetails.fields.cgcp
            }}</span></h3>
            <div class="consulting" v-if="type == '3'">
              <el-table :cell-style="tableStyle" :data="matchingCooperationData2" border stripe style="width: 100%">
                <el-table-column label="序号" type="index" width="78"></el-table-column>
                <el-table-column :label="matchingCooperation2.fields.cpmc || '产品名称'" prop="cpmc"></el-table-column>
                <el-table-column :label="matchingCooperation2.fields.cgsl" prop="cgsl"></el-table-column>
                <el-table-column :label="matchingCooperation2.fields.cgsj" prop="cgsj"></el-table-column>
                <el-table-column :label="matchingCooperation2.fields.cgfs" prop="cgfs"></el-table-column>
                <el-table-column fixed="right" :label="matchingCooperation2.fields.qiatan" width="100">
                  <template slot-scope="scope">
                    <el-button disabled type="text" @click="ccasdawz(scope.row.id)">洽谈</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination :current-page="matchingCooperation2.page" :page-size="matchingCooperation2.pagesize"
                :total="matchingCooperation2.total" background class="pagination"
                layout="total, sizes, prev, pager, next, jumper" next-text="下一页" prev-text="上一页"
                @size-change="matchingCooperationSize2" @current-change="matchingCooperationCurrent2">
              </el-pagination>
            </div>
          </div>
          <div v-if="type == 3" id="cooperation4" class="condition">
            <h3 class="Industrial_title"><img alt="" src="../../public/img/img17.png" /><span>{{ valDetails.fields.cangchu
            }}</span></h3>
            <div class="consulting">
              <el-table :cell-style="tableStyle" :data="matchingCooperationData3" border stripe style="width: 100%">
                <el-table-column label="序号" type="index" width="78"></el-table-column>
                <el-table-column :label="matchingCooperation3.fields.ccdd" prop="ccdd"></el-table-column>
                <el-table-column :label="matchingCooperation3.fields.cangchuguimo" prop="cangchuguimo"></el-table-column>
                <el-table-column :label="matchingCooperation3.fields.ccsl" prop="ccsl"></el-table-column>
                <el-table-column :label="matchingCooperation3.fields.ccfs" prop="ccfs"></el-table-column>
                <el-table-column fixed="right" :label="matchingCooperation3.fields.qiatan" width="100">
                  <template slot-scope="scope">
                    <el-button disabled type="text" @click="ccasdawz(scope.row.id)">洽谈</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination :current-page="matchingCooperation3.page" :page-size="matchingCooperation3.pagesize"
                :total="matchingCooperation3.total" background class="pagination"
                layout="total, sizes, prev, pager, next, jumper" next-text="下一页" prev-text="上一页"
                @size-change="matchingCooperationSize3" @current-change="matchingCooperationCurrent3">
              </el-pagination>
            </div>
          </div>
          <div v-if="type == 3" id="cooperation5" class="condition">
            <h3 class="Industrial_title"><img alt="" src="../../public/img/img17.png" /><span>{{ valDetails.fields.wuliu
            }}</span></h3>
            <div class="consulting">
              <el-table :cell-style="tableStyle" :data="matchingCooperationData4" border stripe style="width: 100%">
                <el-table-column label="序号" type="index" width="78"></el-table-column>
                <el-table-column :label="matchingCooperation4.fields.fdhdd" prop="fdhdd"></el-table-column>
                <el-table-column :label="matchingCooperation4.fields.wlsl" prop="wlsl"></el-table-column>
                <el-table-column :label="matchingCooperation4.fields.wuliushijian" prop="wuliushijian"></el-table-column>
                <el-table-column :label="matchingCooperation4.fields.wuliufangshi" prop="wuliufangshi"></el-table-column>
                <el-table-column fixed="right" :label="matchingCooperation4.fields.qiatan" width="100">
                  <template slot-scope="scope">
                    <el-button disabled type="text" @click="ccasdawz(scope.row.id)">洽谈</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination :current-page="matchingCooperation4.page" :page-size="matchingCooperation4.pagesize"
                :total="matchingCooperation4.total" background class="pagination"
                layout="total, sizes, prev, pager, next, jumper" next-text="下一页" prev-text="上一页"
                @size-change="matchingCooperationSize4" @current-change="matchingCooperationCurrent4">
              </el-pagination>
            </div>
          </div>
          <div v-if="type == 3" id="cooperation6" class="condition">
            <h3 class="Industrial_title"><img alt="" src="../../public/img/img17.png" /><span>{{ valDetails.fields.jckqk
            }}</span></h3>
            <div class="consulting">
              <el-table :cell-style="tableStyle" :data="matchingCooperationData5" border stripe style="width: 100%">
                <el-table-column label="序号" type="index" width="78"></el-table-column>
                <el-table-column :label="matchingCooperation5.fields.title" prop="title"></el-table-column>
                <el-table-column :label="matchingCooperation5.fields.cpmc" prop="cpmc"></el-table-column>
                <el-table-column :label="matchingCooperation5.fields.shuliang" prop="shuliang"></el-table-column>
                <el-table-column :label="matchingCooperation5.fields.jine" prop="jine"></el-table-column>
                <el-table-column fixed="right" label="查询" width="100">
                  <template slot-scope="scope">
                    <el-button disabled type="text" @click="viewEvent(scope.row)">查询</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <!-- <el-table :cell-style="tableStyle" :data="matchingCooperationData5" border stripe style="width: 100%">
              <el-table-column label="序号" type="index" width="78"></el-table-column>
              <el-table-column label="出口" prop="name"></el-table-column>
              <el-table-column label="出口产品" prop="name"></el-table-column>
              <el-table-column label="出口数量" prop="name"></el-table-column>
              <el-table-column label="金额" prop="name"></el-table-column>
              <el-table-column fixed="right" label="查询" width="100">
                <template slot-scope="scope">
                  <el-button type="text" @click="ccasdawz(scope.row.id)">查询</el-button>
                </template>
              </el-table-column>
            </el-table> -->
              <el-pagination :current-page="matchingCooperation5.page" :page-size="matchingCooperation5.pagesize"
                :total="matchingCooperation5.total" background class="pagination"
                layout="total, sizes, prev, pager, next, jumper" next-text="下一页" prev-text="上一页"
                @size-change="matchingCooperationSize5" @current-change="matchingCooperationCurrent5">
              </el-pagination>
            </div>
          </div>
        </div>
        <!-- 经营状况 -->
        <div id="performance" style="padding: 0 5%;">
          <div class="li_tab_check">
            <div v-if="type == 3" @click="scollTo('parkPolicy')">经营状况</div>
            <div v-if="type == 3" @click="scollTo('cooperation7')">{{ valDetails.fields.caiwushouzhi }}</div>
            <div v-if="type == 3" @click="scollTo('law')">{{ valDetails.fields.falu }}</div>
          </div>
          <div v-if="type == 3" id="parkPolicy">
            <h3 class="Industrial_title"><img alt="" src="../../public/img/img17.png" /><span>经营状况</span></h3>
            <div class="style condition">
              <el-table :cell-style="tableStyle" :data="OperatingConditionData" border stripe style="width: 100%">
                <el-table-column label="序号" type="index" width="78"></el-table-column>
                <el-table-column label="文件类型" prop="title"></el-table-column>
                <el-table-column :label="OperatingCondition.fields.wenjianming" prop="wenjianming" >
                  <template slot-scope="scope">
                    <span @click="ckbb(scope.row,'ziliao')">{{ scope.row.wenjianming }}</span>
                  </template>
                </el-table-column>
                <el-table-column fixed="right" label="查询" width="100">
                  <template slot-scope="scope">
                    <el-button disabled type="text" @click="viewEvent(scope.row)">查询</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination :current-page="OperatingCondition.page" :page-size="OperatingCondition.pagesize"
                :total="OperatingCondition.total" background class="pagination"
                layout="total, sizes, prev, pager, next, jumper" next-text="下一页" prev-text="上一页"
                @size-change="OperatingConditionsize" @current-change="OperatingConditioncurrent">
              </el-pagination>
            </div>
          </div>
          <div v-if="type == 3" id="cooperation7" class="condition">
            <h3 class="Industrial_title"><img alt="" src="../../public/img/img17.png" /><span>{{
              valDetails.fields.caiwushouzhi }}</span></h3>
            <div class="consulting">
              <el-table :cell-style="tableStyle" :data="matchingCooperationData6" border stripe style="width: 100%">
                <el-table-column label="序号" type="index" width="78"></el-table-column>
                <el-table-column :label="matchingCooperation6.fields.nianfen" prop="nianfen" width="128">
                  <template slot-scope="scope">
                    {{ scope.row.nianfen && scope.row.nianfen.includes("T") ? scope.row.nianfen.split("T")[0] : scope.row.nianfen }}
                  </template>
                </el-table-column>
                <el-table-column :label="matchingCooperation6.fields.yingyeshouru" prop="yingyeshouru"></el-table-column>
                <el-table-column :label="matchingCooperation6.fields.cbfy" prop="cbfy"></el-table-column>
                <el-table-column :label="matchingCooperation6.fields.yingkuijine" prop="yingkuijine"></el-table-column>
                <el-table-column :label="matchingCooperation6.fields.lirunlu" prop="lirunlu"></el-table-column>
              </el-table>
              <el-pagination :current-page="matchingCooperation6.page" :page-size="matchingCooperation6.pagesize"
                :total="matchingCooperation6.total" background class="pagination"
                layout="total, sizes, prev, pager, next, jumper" next-text="下一页" prev-text="上一页"
                @size-change="matchingCooperationSize6" @current-change="matchingCooperationCurrent6">
              </el-pagination>
              <el-table :cell-style="tableStyle" :data="matchingCooperationData66" border stripe style="width: 100%">
                <el-table-column label="序号" type="index" width="78"></el-table-column>
                <el-table-column :label="matchingCooperation66.fields.niandu" prop="niandu" width="100">
                  <template slot-scope="scope">
                    {{ scope.row.niandu && scope.row.niandu.includes("T") ? scope.row.niandu.split("T")[0] : scope.row.niandu }}
                  </template>
                </el-table-column>
                <el-table-column :label="matchingCooperation66.fields.title">
                  <template slot-scope="scope">
                    <span>{{scope.row.title}}</span>
                  </template>
                </el-table-column>
                <el-table-column :label="matchingCooperation66.fields.zcfzb">
                  <template slot-scope="scope">
                    <!-- <span v-html='scope.row.zcfzb'></span> -->
                    <a :href="scope.row.zcfzb">{{scope.row.zcfzb.match(/\/([^/]+)$/)[1]}}</a>
                    <!-- <img :src="scope.row.zcfzb" style="height:80px;" /> -->
                  </template>
                </el-table-column>
                <el-table-column :label="matchingCooperation66.fields.sunyibiao">
                  <template slot-scope="scope">
                    <!-- <span v-html='scope.row.sunyibiao'></span> -->
                    <a :href="scope.row.sunyibiao">{{scope.row.sunyibiao.match(/\/([^/]+)$/)[1]}}</a>
                    <!-- <img :src="scope.row.sunyibiao" style="height:80px;" /> -->
                  </template>
                </el-table-column>
                <el-table-column :label="matchingCooperation66.fields.xjllb">
                  <template slot-scope="scope">
                    <!-- <span v-html='scope.row.xjllb'></span> -->
                    <a :href="scope.row.xjllb">{{scope.row.xjllb.match(/\/([^/]+)$/)[1]}}</a>
                    <!-- <img :src="scope.row.xjllb" style="height:80px;" /> -->
                  </template>
                </el-table-column>
                <el-table-column label="查询" width="80">
                  <template slot-scope="scope">
                    <el-button type="text" @click="viewEvent(scope.row)" disabled>查询</el-button>
                  </template></el-table-column>
              </el-table>
              <el-pagination :current-page="matchingCooperation66.page" :page-size="matchingCooperation66.pagesize"
                :total="matchingCooperation66.total" background class="pagination"
                layout="total, sizes, prev, pager, next, jumper" next-text="下一页" prev-text="上一页"
                @size-change="matchingCooperationSize66" @current-change="matchingCooperationCurrent66">
              </el-pagination>
            </div>
          </div>
          <div v-if="type == 3" id="law">
            <h3 class="Industrial_title"><img alt="" src="../../public/img/img17.png" /><span>{{ valDetails.fields.falu
            }}</span></h3>
            <div class="style condition">
              <el-table :cell-style="tableStyle" :data="lawData" border stripe style="width: 100%">
                <el-table-column label="序号" type="index" width="78"></el-table-column>
                <el-table-column :label="lawll.fields.title" prop="title">
                </el-table-column>
                <el-table-column :label="lawll.fields.riqi" prop="riqi" width="295">
                </el-table-column>
                <el-table-column :label="lawll.fields.anyou" prop="anyou">
                </el-table-column>
                <el-table-column :label="lawll.fields.ajsf" prop="ajsf">
                </el-table-column>
                <el-table-column fixed="right" label="详情" width="100">
                  <template slot-scope="scope">
                    <el-button type="text" @click="ccasdawz(scope.row.id)" disabled>查看</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination :current-page="lawll.page" :page-size="lawll.pagesize" :total="lawll.total" background
                class="pagination" layout="total, sizes, prev, pager, next, jumper" next-text="下一页" prev-text="上一页"
                @size-change="lawllSize" @current-change="lawllCurrent">
              </el-pagination>
            </div>
          </div>
        </div>
        <!-- 投融资状况 -->
        <div id="investmentStatus" style="padding: 0 5%;">
          <div class="li_tab_check">
            <div v-if="type == 3" @click="scollTo('establishFundzzzz')">{{ valDetails.fields.rzzk }}</div>
            <div v-if="type == 3 || type == 6" @click="scollTo('direction')">{{ valDetails.fields.duiwaitouzi }}</div>
            <div v-if="type == 3 || type == 6" @click="scollTo('bp')">{{ valDetails.fields.tourongzibp }}</div>
            <div v-if="type != 4 && type != 3" @click="scollTo('AttractInvestment')">招商引资</div>
            <div v-if="type != 3" @click="scollTo('style')">{{ type == '6' ? '投融资服务' : '投资偏好' }}</div>
            <div v-if="type != 6 && type != 3" @click="scollTo('direction1')">投资方向/项目</div>
            <div v-if="type != 6 && type != 3" @click="scollTo('project')">投资项目</div>
            <div v-if="type != 6 && type != 3" @click="scollTo('establishFund')">设立基金</div>
          </div>
          <div v-if="type == 3" id="establishFundzzzz">
            <h3 class="Industrial_title"><img alt="" src="../../public/img/img17.png" /><span>{{ valDetails.fields.rzzk
            }}</span></h3>
            <div class="direction condition">
              <el-table :cell-style="tableStyle" :data="FinancingSituation" border stripe style="width: 100%">
                <el-table-column label="序号" type="index" width="78"></el-table-column>
                <el-table-column :label="FinancingSituationll.fields.rzny" prop="rzny">
                </el-table-column>
                <el-table-column :label="FinancingSituationll.fields.rongzilunci" prop="rongzilunci">
                </el-table-column>
                <el-table-column :label="FinancingSituationll.fields.rongzijine" prop="rongzijine">
                </el-table-column>
                <el-table-column :label="FinancingSituationll.fields.touzifang" prop="touzifang">
                </el-table-column>
                <el-table-column label="查询" width="120">
                  <template slot-scope="scope">
                    <el-button disabled type="text" @click="viewEvent(scope.row)">查看</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <el-pagination :current-page="FinancingSituationll.page" :page-size="FinancingSituationll.pagesize"
              :total="FinancingSituationll.total" background class="pagination"
              layout="total, sizes, prev, pager, next, jumper" next-text="下一页" prev-text="上一页"
              @size-change="FinancingSituationSize" @current-change="FinancingSituationChange">
            </el-pagination>
          </div>
          <div id="direction" v-if="type == 3 || type == 6">
            <h3 class="Industrial_title"><img alt="" src="../../public/img/img17.png" /><span>{{
              valDetails.fields.duiwaitouzi }}</span></h3>
            <div class="direction" v-if="type == 3">
              <el-table :cell-style="tableStyle" :data="OutwardInvestmentData" border stripe style="width: 100%">
                <el-table-column label="序号" type="index" width="78"></el-table-column>
                <el-table-column :label="OutwardInvestment.fields.touzixiangmu" prop="touzixiangmu">
                </el-table-column>
                <el-table-column :label="OutwardInvestment.fields.touzilunci" prop="touzilunci">
                </el-table-column>
                <el-table-column :label="OutwardInvestment.fields.touzijine" prop="touzijine">
                </el-table-column>
                <el-table-column :label="OutwardInvestment.fields.beitouzifang" prop="beitouzifang">
                </el-table-column>
              </el-table>
              <el-pagination :current-page="OutwardInvestment.page" :page-size="OutwardInvestment.pagesize"
                :total="OutwardInvestment.total" background class="pagination"
                layout="total, sizes, prev, pager, next, jumper" next-text="下一页" prev-text="上一页"
                @size-change="OutwardInvestmentSize" @current-change="OutwardInvestmentCurrent">
              </el-pagination>
            </div>
            <div class="direction" v-if="type == '6'">
              <el-table :cell-style="tableStyle" :data="parkExternallyList" border stripe style="width: 100%">
                <el-table-column label="序号" type="index" width="78"></el-table-column>
                <el-table-column label="relatedExternally.fields.title" prop="title" width="340">
                </el-table-column>
                <el-table-column label="relatedExternally.fields.create_time" prop="create_time">
                </el-table-column>
                <el-table-column label="状态" width="120">
                  <template slot-scope="scope">
                    <el-button type="text" @click="ccasdawz(scope.row.id)">查看</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination :current-page="relatedExternally.page" :page-size="relatedExternally.pagesize"
                :total="relatedExternally.total" background class="pagination"
                layout="total, sizes, prev, pager, next, jumper" next-text="下一页" prev-text="上一页"
                @size-change="relatedExternallysizeChange" @current-change="relatedExternallycurrentChange">
              </el-pagination>
            </div>
          </div>
          <div id="bp" v-if="type == '6' || type == '3'">
            <h3 class="Industrial_title"><img alt="" src="../../public/img/img17.png" /><span>{{
              valDetails.fields.tourongzibp }}</span>
            </h3>
            <div class="style condition" v-if="type == '6'">
              <el-row :gutter="190">
                <el-col v-for="(d, num) in BPlist" :key="num" :span="8">
                  <div class="bp_val" @click="bpclick(d)">
                    <img alt="" src="../../public/img/img7.png">
                    <div>
                      <p>{{ d.title }}</p>
                    </div>
                  </div>
                </el-col>
              </el-row>
              <el-pagination :current-page="BPd.page" :page-size="BPd.pagesize" :total="BPd.total" background
                class="pagination" layout="total, sizes, prev, pager, next, jumper" next-text="下一页" prev-text="上一页"
                @size-change="BPdSize" @current-change="BPdChange">
              </el-pagination>
            </div>
            <div class="style condition" v-if="type == '3'">
              <el-table :cell-style="tableStyle" :data="enterpriseBPlist" border stripe style="width: 100%">
                <el-table-column label="序号" type="index"></el-table-column>
                <el-table-column :label="enterpriseBPll.fields.title" prop="title"></el-table-column>
                <el-table-column :label="enterpriseBPll.fields.xiangmubp" width="600">
                  <template slot-scope="scope">
                    <span v-html='scope.row.xiangmubp'></span>
                  </template>
                </el-table-column>
                <el-table-column :label="enterpriseBPll.fields.xiangmulunci" prop="xiangmulunci"
                  width="140"></el-table-column>
                <el-table-column :label="enterpriseBPll.fields.bpshijian" prop="bpshijian"></el-table-column>
                <el-table-column label="详情" width="100">
                  <template slot-scope="scope">
                    <el-button type="text" @click="ccasdawz(scope.row.id)" disabled>查看</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination :current-page="enterpriseBPll.page" :page-size="enterpriseBPll.pagesize"
                :total="enterpriseBPll.total" background class="pagination"
                layout="total, sizes, prev, pager, next, jumper" next-text="下一页" prev-text="上一页"
                @size-change="enterpriseBPllSize" @current-change="enterpriseBPllCurrent">
              </el-pagination>
            </div>
          </div>
          <div v-if="type != 4 && type != 3" id="AttractInvestment">
            <h3 class="Industrial_title"><img alt="" src="../../public/img/img17.png" /><span>招商引资</span></h3>
            <div class="style condition">
              <el-table :cell-style="tableStyle" :data="parkCapital" border stripe style="width: 100%">
                <el-table-column label="序号" type="index" width="78"></el-table-column>
                <el-table-column label="日期" prop="create_time" width="295">
                </el-table-column>
                <el-table-column label="标题" prop="title">
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="100">
                  <template slot-scope="scope">
                    <el-button type="text" @click="ccasdawz(scope.row.id)">查看</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination :current-page="relatedCapital.page" :page-size="relatedCapital.pagesize"
                :total="relatedCapital.total" background class="pagination"
                layout="total, sizes, prev, pager, next, jumper" next-text="下一页" prev-text="上一页"
                @size-change="CapitalDsizeChange" @current-change="CapitalDcurrentChange">
              </el-pagination>
            </div>
          </div>
          <div v-if="type != 3" id="style">
            <h3 class="Industrial_title"><img alt="" src="../../public/img/img17.png" /><span>{{ type ==
              '6' ? '投融资服务' : '投资偏好' }}</span>
            </h3>
            <div class="style">
              <el-table :cell-style="tableStyle" :data="parkServiceList" border stripe style="width: 100%">
                <el-table-column label="序号" type="index" width="78"></el-table-column>
                <el-table-column label="名称" prop="title" width="295">
                </el-table-column>
                <el-table-column v-if="type != 4" label="时间" prop="create_time">
                </el-table-column>
                <el-table-column v-if="type == 4" label="股份类型" prop="gufenleixing" width="295">
                </el-table-column>
                <el-table-column v-if="type == 4" label="股份风格" prop="fengge">
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="100">
                  <template slot-scope="scope">
                    <el-button type="text" @click="ccasdawz(scope.row.id)">查看</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination :current-page="relatedService.page" :page-size="relatedService.pagesize"
                :total="relatedService.total" background class="pagination"
                layout="total, sizes, prev, pager, next, jumper" next-text="下一页" prev-text="上一页"
                @size-change="ServicesizeChange" @current-change="ServicecurrentChange">
              </el-pagination>
            </div>
          </div>
          <div v-if="type != '6' && type != 3" id="direction1">
            <h3 class="Industrial_title"><img alt="" src="../../public/img/img17.png" /><span>投资方向/项目</span></h3>
            <div class="direction">
              <el-table :cell-style="tableStyle" :data="directionData" border stripe style="width: 100%">
                <el-table-column label="序号" type="index" width="78"></el-table-column>
                <el-table-column label="投资方向" prop="content" width="295">
                </el-table-column>
                <el-table-column label="投资比例" prop="status" width="197">
                </el-table-column>
                <el-table-column label="投资项目" prop="title">
                </el-table-column>
                <el-table-column label="状态" width="120">
                  <template slot-scope="scope">
                    <el-button type="text" @click="ccasdawz(scope.row.id)">查看</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination :current-page="investment.page" :page-size="investment.pagesize" :total="investment.total"
                background class="pagination" layout="total, sizes, prev, pager, next, jumper" next-text="下一页"
                prev-text="上一页" @size-change="investmentsizeChange" @current-change="investmentcurrentChange">
              </el-pagination>
            </div>
          </div>
          <div v-if="type != '6' && type != 3" id="project">
            <h3 class="Industrial_title"><img alt="" src="../../public/img/img17.png" /><span>投资项目</span></h3>
            <div class="project">
              <div v-for="(d, i) in investmentProject" :key="i" class="project_list">
                <img :src="d.thumb" alt="" />
                <h4>{{ d.title }}</h4>
                <div><i class="el-icon-arrow-right"></i> {{ d.gsmc }}</div>
              </div>
            </div>
            <el-pagination :current-page="project.page" :page-size="project.pagesize" :total="project.total" background
              class="pagination" layout="total, sizes, prev, pager, next, jumper" next-text="下一页" prev-text="上一页"
              @size-change="projectsizeChange" @current-change="projectcurrentChange">
            </el-pagination>
          </div>
          <div v-if="type != '6' && type != 3" id="establishFund">
            <h3 class="Industrial_title"><img alt="" src="../../public/img/img17.png" /><span>设立基金</span></h3>
            <div class="direction condition">
              <el-table :cell-style="tableStyle" :data="establishFund" border stripe style="width: 100%">
                <el-table-column label="序号" type="index" width="78"></el-table-column>
                <el-table-column label="基金名称" prop="title">
                </el-table-column>
                <el-table-column label="投资方向" prop="tzfx" width="197">
                </el-table-column>
                <el-table-column label="介绍" prop="content">
                </el-table-column>
                <el-table-column label="持股比例" prop="chigubili">
                </el-table-column>
                <el-table-column label="状态" width="120">
                  <template slot-scope="scope">
                    <el-button type="text" @click="ccasdawz(scope.row.id)">查看</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <el-pagination :current-page="fund.page" :page-size="fund.pagesize" :total="fund.total" background
              class="pagination" layout="total, sizes, prev, pager, next, jumper" next-text="下一页" prev-text="上一页"
              @size-change="fundSize" @current-change="fundChange">
            </el-pagination>
          </div>
        </div>
        <!-- 相关资讯 -->
        <div id="relatedInformation" style="padding: 0 5%;">
          <div class="li_tab_check">
            <div v-if="type == 3" @click="scollTo('consulting')">{{ valDetails.fields.xgzx }}</div>
            <div v-if="type != 3 && type != 6" @click="scollTo('enterprise')">同类企业</div>
            <div v-if="type == 3" @click="scollTo('enterprise1')">{{ valDetails.fields.leisiqiye }}</div>
          </div>
          <div id="consulting">
            <h3 class="Industrial_title"><img alt="" src="../../public/img/img17.png" /><span>{{ valDetails.fields.xgzx
            }}</span></h3>
            <div class="consulting" v-if="type == 3">
              <div v-for="(item, index) in correlationList" :key="index" class="List_Information"
                @click="zcasdasdas(item)">
                <div class="List_Information_left"><img alt="" :src="item.thumb"></div>
                <div class="List_Information_right" v-if="item">
                  <span>
                    <h6>{{ item.title }}</h6>
                  </span>
                  <div>
                    <p>{{ item.fabumeiti }}</p>
                    <p>{{ item.nysj }}</p>
                  </div>
                </div>
              </div>
              <el-pagination :current-page="correlation.page" :page-size="correlation.pagesize" :total="correlation.total"
                background class="pagination" layout="total, sizes, prev, pager, next, jumper" next-text="下一页"
                prev-text="上一页" @size-change="correlationsizeChange" @current-change="correlationcurrentChange">
              </el-pagination>
            </div>
            <div class="consulting" v-if="type != '6' && type != 3">
              <div v-for="(item, index) in correlationList" :key="index" class="List_Information"
                @click="zcasdasdas(item)">
                <div class="List_Information_left"><img alt="" :src="item.thumb"></div>
                <div class="List_Information_right">
                  <span>
                    <h6>{{ item.title }}</h6>
                  </span>
                  <div>
                    <p>{{ item.fabumeiti }}</p>
                    <p>{{ item.nysj }}</p>
                  </div>
                </div>
              </div>
              <el-pagination :current-page="correlation.page" :page-size="correlation.pagesize" :total="correlation.total"
                background class="pagination" layout="total, sizes, prev, pager, next, jumper" next-text="下一页"
                prev-text="上一页" @size-change="correlationsizeChange" @current-change="correlationcurrentChange">
              </el-pagination>
            </div>
            <div class="consulting" v-if="type === '6'">
              <div v-for="(item, index) in InformationList" :key="index" class="List_Information"
                @click="zcasdasdas(item)">
                <div class="List_Information_left"><img :src="item.thumb" alt=""></div>
                <div class="List_Information_right">
                  <h6>{{ item.title }}</h6>
                  <p>{{ item.description }}</p>
                </div>
              </div>
              <el-pagination :current-page="relatedInformation.page" :page-size="relatedInformation.pagesize"
                :total="relatedInformation.total" background class="pagination"
                layout="total, sizes, prev, pager, next, jumper" next-text="下一页" prev-text="上一页"
                @size-change="relateDsizeChange" @current-change="relateDcurrentChange">
              </el-pagination>
            </div>
          </div>
          <div v-if="type != '6' && type != 3" id="enterprise">
            <h3 class="Industrial_title"><img alt="" src="../../public/img/img17.png" /><span>同类企业</span></h3>
            <div class="project">
              <div v-for="(a, i) in similarEnterprise" :key="i" class="project_list" @click="TLccasdawz(a.id)">
                <img :src="a.thumb" alt="" />
                <h4>{{ a.title }}</h4>
                <div><i class="el-icon-arrow-right"></i>{{ a.description }}</div>
              </div>
            </div>
            <el-pagination :current-page="similar.page" :page-size="similar.pagesize" :total="similar.total" background
              class="pagination" layout="total, sizes, prev, pager, next, jumper" next-text="下一页" prev-text="上一页"
              @size-change="similarSize" @current-change="similarChange">
            </el-pagination>
          </div>
          <div v-if="type == 3" id="enterprise1">
            <h3 class="Industrial_title"><img alt="" src="../../public/img/img17.png" /><span>{{
              valDetails.fields.leisiqiye }}</span></h3>

            <el-table :cell-style="tableStyle" :data="matchingCooperationData7" border stripe style="width: 100%">
              <el-table-column label="序号" type="index" width="78"></el-table-column>
              <el-table-column :label="matchingCooperation7.fields.title" prop="title" width="295">
              </el-table-column>
              <el-table-column :label="matchingCooperation7.fields.gscp" prop="gscp">
              </el-table-column>
              <el-table-column :label="matchingCooperation7.fields.content" prop="content">
              </el-table-column>
              <el-table-column :label="matchingCooperation7.fields.peitaokehu" prop="peitaokehu">
              </el-table-column>
              <el-table-column fixed="right" label="查询" width="100">
                <template slot-scope="scope">
                  <el-button disabled type="text" @click="viewEvent(scope.row)">查询</el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination :current-page="matchingCooperation7.page" :page-size="matchingCooperation7.pagesize"
              :total="matchingCooperation7.total" background class="pagination"
              layout="total, sizes, prev, pager, next, jumper" next-text="下一页" prev-text="上一页"
              @size-change="matchingCooperationSize7" @current-change="matchingCooperationCurrent7">
            </el-pagination>
          </div>
        </div>
        <!-- 联系方式 -->
        <div id="contactInformation" style="padding: 0 5%;">
          <div id="way">
            <h3 class="Industrial_title"><img alt="" src="../../public/img/img17.png" /><span>{{ valDetails.fields.lxfs
            }}</span></h3>
            <div class="way registered" v-for="(item, index) in lxfzList" :key='index'>
              <el-row>
                <el-col :span="24">
                  <div class="registered_div">
                    <div class="registered_left way_list_left">
                      <div>
                        <!-- <p>{{ lxfzListTitle.title }}</p> -->
                        <p>机构名称</p>
                      </div>
                    </div>
                    <div class="registered_right way_list_right" style="background: #f6faff;flex:1;">
                      <p>{{ lxfzListTitle.dizhi }}</p>
                    </div>
                  </div>
                </el-col>
                <el-col :span="24">
                  <div class="registered_div" v-if="item">
                    <div class="registered_left way_list_left" style="background: #ffffff;">
                      <div>
                        <p>{{ item.title ? item.title : '无' }}</p>
                      </div>
                    </div>
                    <div class="registered_right way_list_right">
                      <p>{{ item.dizhi ? item.dizhi : '无' }}</p>
                    </div>
                  </div>
                </el-col>
                <el-col :span="24">
                  <div class="registered_div">
                    <div
                      style="background: #f6faff;flex:1;line-height: 58px;border-right: 1px solid #e3edfb;padding-left: 40px;">
                      <p>{{ lxfzListTitle.youbian }}</p>
                    </div>
                    <div
                      style="background: #f6faff;flex:1;line-height: 58px;border-right: 1px solid #e3edfb;padding-left: 40px;">
                      <p>{{ lxfzListTitle.dianhua }}</p>
                    </div>
                    <div style="background: #f6faff;flex:1;line-height: 58px;padding-left: 40px;">
                      <p>{{ lxfzListTitle.wangzhi }}</p>
                    </div>
                  </div>
                </el-col>
                <el-col :span="24">
                  <div class="registered_div" v-if="item">
                    <div style="flex:1;line-height: 58px;border-right: 1px solid #e3edfb;padding-left: 40px;">
                      <p>{{ item.youbian ? item.youbian : '无' }}</p>
                    </div>
                    <div style="flex:1;line-height: 58px;border-right: 1px solid #e3edfb;padding-left: 40px;">
                      <p>{{ item.dianhua ? item.dianhua : '无' }}</p>
                    </div>
                    <div style="flex:1;line-height: 58px;padding-left: 40px;">
                      <p>{{ item.wangzhan ? item.wangzhan : '无' }}</p>
                    </div>
                  </div>
                </el-col>
                <el-col :span="24">
                  <div class="registered_div">
                    <div
                      style="background: #f6faff;flex:1;line-height: 58px;border-right: 1px solid #e3edfb;padding-left: 40px;">
                      <p>{{ lxfzListTitle.lianxiren }}</p>
                    </div>
                    <div
                      style="background: #f6faff;flex:1;line-height: 58px;border-right: 1px solid #e3edfb;padding-left: 40px;">
                      <p>{{ lxfzListTitle.shouji }}</p>
                    </div>
                    <div
                      style="background: #f6faff;flex:1;line-height: 58px;border-right: 1px solid #e3edfb;padding-left: 40px;">
                      <p>{{ lxfzListTitle.weixin }}</p>
                    </div>
                    <div
                      style="background: #f6faff;flex:1;line-height: 58px;border-right: 1px solid #e3edfb;padding-left: 40px;">
                      <p>{{ lxfzListTitle.youxiang }}</p>
                    </div>
                    <div style="background: #f6faff;flex:1;line-height: 58px;padding-left: 40px;">
                      <p>{{ lxfzListTitle.douyin }}</p>
                    </div>
                  </div>
                </el-col>
                <el-col :span="24">
                  <div class="registered_div" v-if="item">
                    <div style="flex:1;line-height: 58px;border-right: 1px solid #e3edfb;padding-left: 40px;">
                      <p>{{ item.lianxiren ? item.lianxiren : '无' }}</p>
                    </div>
                    <div style="flex:1;line-height: 58px;border-right: 1px solid #e3edfb;padding-left: 40px;">
                      <p>{{ item.shouji ? item.shouji : '无' }}</p>
                    </div>
                    <div style="flex:1;line-height: 58px;border-right: 1px solid #e3edfb;padding-left: 40px;">
                      <p>{{ item.weixin ? item.weixin : '无' }}</p>
                    </div>
                    <div style="flex:1;line-height: 58px;border-right: 1px solid #e3edfb;padding-left: 40px;">
                      <p>{{ item.youxiang ? item.youxiang : '无' }}</p>
                    </div>
                    <div style="flex:1;line-height: 58px;padding-left: 40px;">
                      <p>{{ item.douyin ? item.douyin : '无' }}</p>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>

            <div class="way registered" v-for="(item, index) in lxfzList1" :key='index'>
              <el-row>
                <el-col :span="24">
                  <div class="registered_div">
                    <div class="registered_left way_list_left">
                      <div>
                        <!-- <p>{{ lxfzListTitle1.title }}</p> -->
                        <p>分支机构名称</p>
                      </div>
                    </div>
                    <div class="registered_right way_list_right" style="background: #f6faff;flex:1;">
                      <p>{{ lxfzListTitle1.dizhi }}</p>
                    </div>
                  </div>
                </el-col>
                <el-col :span="24">
                  <div class="registered_div" v-if="item">
                    <div class="registered_left way_list_left" style="background: #ffffff;">
                      <div>
                        <p>{{ item.title ? item.title : '无' }}</p>
                      </div>
                    </div>
                    <div class="registered_right way_list_right">
                      <p>{{ item.dizhi ? item.dizhi : '无' }}</p>
                    </div>
                  </div>
                </el-col>
                <el-col :span="24">
                  <div class="registered_div">
                    <div
                      style="background: #f6faff;flex:1;line-height: 58px;border-right: 1px solid #e3edfb;padding-left: 40px;">
                      <p>{{ lxfzListTitle1.youbian }}</p>
                    </div>
                    <div
                      style="background: #f6faff;flex:1;line-height: 58px;border-right: 1px solid #e3edfb;padding-left: 40px;">
                      <p>{{ lxfzListTitle1.dianhua }}</p>
                    </div>
                    <div style="background: #f6faff;flex:1;line-height: 58px;padding-left: 40px;">
                      <p>{{ lxfzListTitle1.wangzhi }}</p>
                    </div>
                  </div>
                </el-col>
                <el-col :span="24">
                  <div class="registered_div" v-if="item">
                    <div style="flex:1;line-height: 58px;border-right: 1px solid #e3edfb;padding-left: 40px;">
                      <p>{{ item.youbian ? item.youbian : '无' }}</p>
                    </div>
                    <div style="flex:1;line-height: 58px;border-right: 1px solid #e3edfb;padding-left: 40px;">
                      <p>{{ item.dianhua ? item.dianhua : '无' }}</p>
                    </div>
                    <div style="flex:1;line-height: 58px;padding-left: 40px;">
                      <p>{{ item.wangzhan ? item.wangzhan : '无' }}</p>
                    </div>
                  </div>
                </el-col>
                <el-col :span="24">
                  <div class="registered_div">
                    <div
                      style="background: #f6faff;flex:1;line-height: 58px;border-right: 1px solid #e3edfb;padding-left: 40px;">
                      <p>{{ lxfzListTitle1.lianxiren }}</p>
                    </div>
                    <div
                      style="background: #f6faff;flex:1;line-height: 58px;border-right: 1px solid #e3edfb;padding-left: 40px;">
                      <p>{{ lxfzListTitle1.shouji }}</p>
                    </div>
                    <div
                      style="background: #f6faff;flex:1;line-height: 58px;border-right: 1px solid #e3edfb;padding-left: 40px;">
                      <p>{{ lxfzListTitle1.weixin }}</p>
                    </div>
                    <div
                      style="background: #f6faff;flex:1;line-height: 58px;border-right: 1px solid #e3edfb;padding-left: 40px;">
                      <p>{{ lxfzListTitle1.youxiang }}</p>
                    </div>
                    <div style="background: #f6faff;flex:1;line-height: 58px;padding-left: 40px;">
                      <p>{{ lxfzListTitle1.douyin }}</p>
                    </div>
                  </div>
                </el-col>
                <el-col :span="24">
                  <div class="registered_div" v-if="item">
                    <div style="flex:1;line-height: 58px;border-right: 1px solid #e3edfb;padding-left: 40px;">
                      <p>{{ item.lianxiren ? item.lianxiren : '无' }}</p>
                    </div>
                    <div style="flex:1;line-height: 58px;border-right: 1px solid #e3edfb;padding-left: 40px;">
                      <p>{{ item.shouji ? item.shouji : '无' }}</p>
                    </div>
                    <div style="flex:1;line-height: 58px;border-right: 1px solid #e3edfb;padding-left: 40px;">
                      <p>{{ item.weixin ? item.weixin : '无' }}</p>
                    </div>
                    <div style="flex:1;line-height: 58px;border-right: 1px solid #e3edfb;padding-left: 40px;">
                      <p>{{ item.youxiang ? item.youxiang : '无' }}</p>
                    </div>
                    <div style="flex:1;line-height: 58px;padding-left: 40px;">
                      <p>{{ item.douyin ? item.douyin : '无' }}</p>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>

      </div>
      <div v-else v-loading="!showLoading" element-loading-text="拼命加载中" style="height:67vh"></div>
      <!-- 公司视频展示弹窗 -->
      <el-dialog :visible.sync="dialogVisible"
        :title="valDetails.fields && valDetails.fields.gssp ? valDetails.fields.gssp : ''">
        <video muted="muted" class="video" width="100%" :src="valDetails.gssp ? valDetails.gssp : ''" type="video/mp4"
          autoplay="autoplay" controls="controls" loop="-1">
          <p>你的浏览器不支持video标签.</p>
        </video>
      </el-dialog>
      <!-- 3D模型展示弹窗 -->
      <el-dialog :visible.sync="dialogVisible1" title="3D模型">
        <div style="position: relative;height:500px;">

          <div id="sview_1"
            style="width:100%;height:100%;position:absolute;background-color:aliceblue;z-index:0;user-select:none;overflow:hidden">
            <canvas id="gesture_canvas" style="display:none;position:fixed;top:0;left: 0;z-index:-1;"
              langid="warning.browserNotSupportCanvas">您的浏览器不支持 canvas 标签</canvas>
          </div>
        </div>
      </el-dialog>
    </div>
</template>

<script>
import request from '@/api/request.js'
import commonUtils from '@/utils/CommonUtils.js'
import RelationGraph from 'relation-graph'

export default {
  data() {
    return {
      dialogVisible: false,
      dialogVisible1: false,
      showLoading: false,
      oldScrollTop: 0,
      activeName: '企业概况',
      label: [],
      zxcsstableData: [],
      tableData: [],
      parkPolicyData: [],
      AttractInvestmentData: [],
      titIndex: 0,
      InformationList: [],
      title: { path: '', val: "" },
      type: 0,
      list: [],
      valDetails: {},
      parkCapital: [],
      parkServiceList: [],
      establishFundData: [],
      directionData: [],
      investmentProject: [],
      correlationList: [],
      PrimaryTeamList: [],
      establishFund: [],
      financeList: [],
      financeList1: [],
      productOrganization1: { page: 1, pagesize: 10, total: 0, fields: {} },
      fundraisingStatusData: [],
      similarEnterprise: [],
      parkExternallyList: [],
      MainProductList: [],
      zhuanlijishuList: [],
      FinancingSituation: [],
      FinancingSituation1: [],
      FinancingSituationll33: { page: 1, pagesize: 10, total: 0, fields: {} },
      institutionsData: [],
      OperatingConditionData: [],
      lawData: [],
      BPlist: [],
      enterpriseBPlist: [],
      OutwardInvestmentData: [],
      matchingCooperationData: [],
      matchingCooperationData1: [],
      matchingCooperation1: { page: 1, pagesize: 10, total: 0, fields: {} },
      matchingCooperationData2: [],
      matchingCooperation2: { page: 1, pagesize: 10, total: 0, fields: {} },
      matchingCooperationData3: [],
      matchingCooperation3: { page: 1, pagesize: 10, total: 0, fields: {} },
      matchingCooperationData4: [],
      matchingCooperation4: { page: 1, pagesize: 10, total: 0, fields: {} },
      matchingCooperationData5: [],
      matchingCooperation5: { page: 1, pagesize: 10, total: 0, fields: {} },
      matchingCooperationData6: [],
      matchingCooperation6: { page: 1, pagesize: 10, total: 0, fields: {} },
      matchingCooperationData66: [],
      matchingCooperation66: { page: 1, pagesize: 10, total: 0, fields: {} },

      matchingCooperationData7: [],
      matchingCooperation7: { page: 1, pagesize: 10, total: 0, fields: {} },

      parkSupportingCooperationData: [],
      relatedInformation: { page: 1, pagesize: 10, total: 0, fields: {} },
      relatedParkpolicy: { page: 1, pagesize: 10, total: 0, fields: {} },
      relatedCapital: { page: 1, pagesize: 10, total: 0, fields: {} },
      relatedService: { page: 1, pagesize: 10, total: 0, fields: {} },
      investment: { page: 1, pagesize: 10, total: 0, fields: {} },
      project: { page: 1, pagesize: 10, total: 0, fields: {} },
      correlation: { page: 1, pagesize: 10, total: 0, fields: {} },
      PrimaryTeam: { page: 1, pagesize: 10, total: 0, fields: {} },
      fund: { page: 1, pagesize: 10, total: 0, fields: {} },
      productOrganization: { page: 1, pagesize: 10, total: 0, fields: {} },
      fundraisingStatus: { page: 1, pagesize: 10, total: 0, fields: {} },
      similar: { page: 1, pagesize: 10, total: 0, fields: {} },
      MainProduct: { page: 1, pagesize: 10, total: 0, fields: {} },
      zhuanlijishu: { page: 1, pagesize: 10, total: 0, fields: {} },
      relatedExternally: { page: 1, pagesize: 10, total: 0, fields: {} },
      FinancingSituationll: { page: 1, pagesize: 10, total: 0, fields: {} },
      FinancingSituationll2: { page: 1, pagesize: 10, total: 0, fields: {} },
      OperatingCondition: { page: 1, pagesize: 10, total: 0, fields: {} },
      lawll: { page: 1, pagesize: 10, total: 0, fields: {} },
      enterpriseBPll: { page: 1, pagesize: 10, total: 0, fields: {} },
      OutwardInvestment: { page: 1, pagesize: 10, total: 0, fields: {} },
      matchingCooperation: { page: 1, pagesize: 10, total: 0, fields: {} },
      matchingCooperation1: { page: 1, pagesize: 10, total: 0, fields: {} },
      zxcsstable: { page: 1, pagesize: 10, total: 0, fields: {} },
      BPd: { page: 1, pagesize: 10, total: 0, fields: {} },
      parkSupportingCooperation: { page: 1, pagesize: 10, total: 0, fields: {} },
      institutionsDataPage: { page: 1, pagesize: 10, total: 0, fields: {} },
      FinancingSituation2: [],
      FinancingSituationll3: { page: 1, pagesize: 10, total: 0, fields: {} },
      FinancingSituation3: [],

      g_loading: true,
      demoname: '---',
      graphOptions: {
        "backgrounImage": "http://ai-mark.cn/images/ai-mark-desc.png",
        "backgrounImageNoRepeat": false,
        "layouts": [
          {
            "label": "中心",
            "layoutName": "tree",
            "layoutClassName": "seeks-layout-center",
            "defaultJunctionPoint": "border",
            "defaultNodeShape": 0,
            "defaultLineShape": 5,  // 1直线
            "min_per_width": "200",
            "max_per_width": "300",
            "min_per_height": "150",
            "max_per_height": "300",
            "centerOffset_x": "0",
            "centerOffset_y": "0",
          }
        ],
        "defaultLineMarker": {  // 箭头样式
          "markerWidth": 22,
          "markerHeight": 22,
          "refX": 15,
          "refY": 6,
          "data": "M2,2 L10,6 L2,10 L6,6 L2,2"
        },
        "allowShowMiniNameFilter": true,
        "allowShowMiniToolBar": true,
        "allowSwitchLineShape": true,
        "allowSwitchJunctionPoint": true,
        "defaultNodeShape": 1,
        "defaultNodeWidth": "150",
        "defaultNodeHeight": "50",
        "defaultLineShape": 4,
        "defaultJunctionPoint": "tb",
        "defaultShowLineLabel": true,
        "disableZoom": false,
        "disableDragNode": false,
        "defaultExpandHolderPosition": "top",
        "defaultNodeBorderWidth": 2,
        "defaultNodeColor": "rgba(144, 238, 144, 1)",
        "isMoveByParentNode": false,
        isHideArrow: false,
      },
      tabDatas: [
        { name: '基本情况', ids: 'basicInformation' },
        { name: '生产经营', ids: 'productionOperation' },
        { name: '研发状况', ids: 'developmentStatus' },
        { name: '供应链管理', ids: 'supplyManagement' },
        { name: '经营状况', ids: 'performance' },
        { name: '投融资状况', ids: 'investmentStatus' },
        { name: '相关资讯', ids: 'relatedInformation' },
        { name: '联系方式', ids: 'contactInformation' }
      ],
      nowCheckedTab: { name: '基本情况', ids: 'basicInformation' },
      lxfzList: [],
      lxfzListTitle: {},
      lxfzList1: [],
      lxfzListTitle1: {},
      cp3dList: [],
      cp3dListPage: { page: 1, pagesize: 10, total: 0, fields: {} },
      gongchangshebei: [],
      gongchangshebei1: { page: 1, pagesize: 10, total: 0, fields: {} },
    }
  },
  components: { RelationGraph },
  mounted() {
    this.type = this.$route.query.type;
    if (this.$route.query.type == 4) {
      this.title = {
        path: '/FindListInformation' + commonUtils.getParam(this.$route.query),
        val: "找投资"
      };
      this.label = [
        { label: '企业概况', name: 'AnOverview' },
        { label: '工商注册', name: 'registered' },
        { label: '投资风格', name: 'style' },
        { label: '投资方向/项目', name: 'direction' },
        { label: '投资项目', name: 'project' },
        { label: '相关资讯', name: 'consulting' },
        { label: '主要团队', name: 'team' },
        { label: '设立基金', name: 'establishFund' },
        { label: '金融产品', name: 'financial' },
        { label: '募资状况', name: 'toraise' },
        { label: '同类企业', name: 'enterprise' },
        { label: '联系方式', name: 'way' },
      ];
      window.addEventListener('scroll', this.handleScroll)
      this.findInvestmentDetails(this.$route.query.id)
      this.findInvestmentDetailsInvestment(this.$route.query.id)
      this.findInvestmentDetailsType(this.$route.query.id, 'jigfxxm', 'tzfxxm')
      this.findInvestmentDetailsType(this.$route.query.id, 'touzixiangmu', 'touzixiangmu')
      this.findInvestmentDetailsType(this.$route.query.id, 'touzixiangmu', 'touzixiangmu')
      this.relatedInformationList(this.$route.query.id)
      this.findInvestmentDetailsType(this.$route.query.id, 'zhaoshequn', 'zytd')
      this.findInvestmentDetailsType(this.$route.query.id, 'shelijijin', 'shelijijin')
      this.findInvestmentDetailsType(this.$route.query.id, 'zhuyaochanpin', 'jrcp')
      this.findInvestmentDetailsType(this.$route.query.id, 'rzzk', 'mzzk')
      this.similarEnterpriseC(this.$route.query.id)
    } else if (this.$route.query.type == 6) {
      this.title = {
        path: '/FindListInformation' + commonUtils.getParam(this.$route.query),
        val: "找园区"
      }
      this.parkDetails(this.$route.query.id)
      this.parkDetailsInformation(this.$route.query.id)
      this.parkDetailsParkpolicy(this.$route.query.id)
      this.parkDetailCapital(this.$route.query.id)
      this.parkService(this.$route.query.id)
      this.parkExternally(this.$route.query.id)
      this.ParkTeam(this.$route.query.id)
      this.parkDetailsType(this.$route.query.id, 'zhuyaochanpin', 'zycp')
      this.parkDetailsType(this.$route.query.id, 'zhuanlijishu', 'zhuanlijishu')
      this.parkDetailsType(this.$route.query.id, 'rzzk', 'rzzk')
      this.parkDetailsType(this.$route.query.id, 'peitaohezuo', 'peitaohezuo')
    } else if (this.$route.query.type == 3) {
      this.title = {
        path: '/enterpriseList' + commonUtils.getParam(this.$route.query),
        val: "找企业"
      }
      this.enterpriseDetails(this.$route.query.id)
      this.enterprisePrimaryTeam(this.$route.query.id)
      this.requestGet1('yanfajigou', 'yanfajigou', this.$route.query.id, 'institutionsDataPage', 'institutionsData')
      this.requestGet1('duiwaitouzi', 'duiwaitouzi', this.$route.query.id, 'OutwardInvestment', 'OutwardInvestmentData')
      this.requestGet1('zhishichanquan', 'zscq', this.$route.query.id, 'FinancingSituationll2', 'FinancingSituation2')
      this.requestGet1('rzzk', 'rzzk', this.$route.query.id, 'FinancingSituationll', 'FinancingSituation')
      this.requestGet1('falv', 'falu', this.$route.query.id, 'lawll', 'lawData')
      this.requestGet1('cangchu', 'cangchu', this.$route.query.id, 'matchingCooperation3', 'matchingCooperationData3')
      this.requestGet1('peitaokehu', 'jjptkh', this.$route.query.id, 'matchingCooperation1', 'matchingCooperationData1')
      this.requestGet1('peitaokehu', 'zjptkh', this.$route.query.id, 'matchingCooperation', 'matchingCooperationData')
      this.requestGet1('caigouchanpin', 'cgcp', this.$route.query.id, 'matchingCooperation2', 'matchingCooperationData2')
      this.requestGet1('wuliu', 'wuliu', this.$route.query.id, 'matchingCooperation4', 'matchingCooperationData4')
      this.requestGet1('caiwuzhichu', 'caiwushouzhi', this.$route.query.id, 'matchingCooperation6', 'matchingCooperationData6')
      this.requestGet1('caiwuzhichu', 'baobiao', this.$route.query.id, 'matchingCooperation66', 'matchingCooperationData66')
      this.requestGet1('leisiqiye', 'leisiqiye', this.$route.query.id, 'matchingCooperation7', 'matchingCooperationData7')
      this.gcsbrequestGet1('gongchangshebei', 'gcsb', this.$route.query.id, 'productOrganization', 'financeList')
      request.newget1('hexincp', 'hexin', this.$route.query.id, this.productOrganization1.pagesize, this.productOrganization1.page).then(({ data }) => {
        if (data.data != null) {
          this.financeList1 = data.data.list;
          this.productOrganization1.total = data.data.total;
          this.productOrganization1.fields = data.data.fields || {};
        }
      })

      //获取3d模型数据
      request.newget1('sdchanpin', 'hexin', this.$route.query.id, this.cp3dListPage.pagesize, this.cp3dListPage.page).then(res => {
        this.cp3dList = res.data.data.list || []
        this.cp3dListPage.total = res.data.data.total
        this.cp3dListPage.fields = res.data.data.fields || {}
      })

      this.requestGet1('trzbp', 'tourongzibp', this.$route.query.id, 'enterpriseBPll', 'enterpriseBPlist')
      // this.enterpriseDetailsType(this.$route.query.id, 'zhuanlijishu', 'zhuanlijishu')
      this.requestGet1('zlrztx', 'zlrztx', this.$route.query.id, 'FinancingSituationll3', 'FinancingSituation3')
      // this.enterpriseDetailsType(this.$route.query.id, 'jingyingzhuangk', 'jyzk')
      this.requestGet1('jingyingzhuangk', 'jyzk', this.$route.query.id, 'OperatingCondition', 'OperatingConditionData')
      this.relatedInformationList(this.$route.query.id)
      this.requestGet1('jinchukou', 'jckqk', this.$route.query.id, 'matchingCooperation5', 'matchingCooperationData5')
      this.requestGet1('gongchangshebei', 'gcsb', this.$route.query.id, 'gongchangshebei1', 'gongchangshebei')

      this.demoname = this.$route.params.demoname
      this.newenterpriseDetailsType('guquanjiegou')
      // request.newget1('lianxifangshi', 'lxfs', this.$route.query.id, 20, 1).then(({ data }) => {
      request.newget1('zongbu', 'zongbu', this.$route.query.id, 20, 1).then(({ data }) => {
        if (data.data != null) {
          this.lxfzList = data.data.list || []
          this.lxfzListTitle = data.data.fields || {}
        }
      })
      request.newget1('fenzhi', 'fenzhijiegou', this.$route.query.id, 20, 1).then(({ data }) => {
        if (data.data != null) {
          this.lxfzList1 = data.data.list || []
          this.lxfzListTitle1 = data.data.fields || {}
        }
      })
    }
    setTimeout(() => {
      this.showLoading = true

      // 监听滚动
      window.addEventListener("scroll", this.handleScrollEvent, true);
    }, 2500)

  },
  methods: {
    ckbb(item,key) {
      window.open(item[key])
    },
    zcasdasdas(item) {
      this.$router.push({
        path: '/DetailsConsultationN',
        query: { type: 0, sublevel: this.$route.query.sublevel, typeId: this.$route.query.typeId, id: item.id }
      })
    },
    hxcpImg(url) {
      if (url && url.includes('http')) {
        return url
      } else {
        // return window.location.origin + url
        return 'http://admin.aiauto.shop' + url
      }
    },
    viewEvent(item) {
      window.open(item.url)
    },
    productddOrganizationChange() {
      this.productOrganization.page = $event;
      request.newget1('sdchanpin', 'hexin', this.$route.query.id, this.cp3dListPage.pagesize, this.cp3dListPage.page).then(res => {
        this.cp3dList = res.data.data.list || []
        this.cp3dListPage.total = res.data.data.total
        this.cp3dListPage.fields = res.data.data.fields || {}
      })
    },
    productddOrganizationSize($event) {
      this.cp3dListPage.pagesize = $event;
      this.cp3dListPage.page = 1;
      request.newget1('sdchanpin', 'hexin', this.$route.query.id, this.cp3dListPage.pagesize, this.cp3dListPage.page).then(res => {
        this.cp3dList = res.data.data.list || []
        this.cp3dListPage.total = res.data.data.total
        this.cp3dListPage.fields = res.data.data.fields || {}
      })
    },
    arrToString(item, key) {
      if (typeof (item) == 'string') {
        return item
      } else {
        return item.join(key)
      }
    },
    ckmodel(item) {
      request.zhuyaochanpin3d(item.id).then(res => {

        let modelUrl = res.data.data.sdmxwj
        this.dialogVisible1 = true
        //3D模型展示
        setTimeout(() => {
          window.language = SView.UIManager.languageConstants.CN;

          sview0 = new Main("sview_1");

          let configManager = sview0.sviewFrame.getConfigManager();

          configManager.loadCommandConfigs().then((ret) => {
            let commandsManager = sview0.sviewFrame.getCommandsManager();
            let openCommand = null;
            let array = new Map();
            array.set("fullClassName", "SView.Commands.OpenCommand");
            openCommand = commandsManager.getCommand(SView.Commands.CommandNames.OPENSAMPLEFILE, array);
            openCommand.path = modelUrl
            commandsManager.execute(SView.Commands.CommandNames.OPENSAMPLEFILE);
          });
        }, 700)
      })
    },
    chaxunWeb(item) {
      window.open(item.chaxun)
    },
    //滚动时与左侧导航栏对应
    handleScrollEvent() {

      let newarr = this.tabDatas.map(e => {
        return document.getElementById(e.ids).getBoundingClientRect().top
      })
      let tabIndex = this.tabDatas.length
      for (let index = 1; index < newarr.length; index++) {
        if (newarr[index] >= 30) {
          tabIndex = index - 1
          break
        }
      }
      this.nowCheckedTab = this.tabDatas[tabIndex]
    },
    requestGet(s, id, page, dataList,) {
      request.newget(s, id, this[page].pagesize, this[page].page).then(({ data }) => {
        if (data.data != null) {
          this[dataList] = data.data.list || [];
          this[page].total = data.data.total;
          this[page].fields = data.data.fields || {};
        }
      })
    },
    requestGet1(s, s1, id, page, dataList) {
      request.newget1(s, s1, id, this[page].pagesize, this[page].page).then(({ data }) => {
        if (data.data != null) {
          this[dataList] = data.data.list || [];
          this[page].total = data.data.total;
          this[page].fields = data.data.fields || {};
          this.$set(this[page], 'fields', data.data.fields || {})
          if(s == 'gongchangshebei'){
            const arr = []
            data.data.list.forEach(item => {
              arr.push(item)
              if(item.chejianxinxis != null){
                for(var i = 0; i< Object.keys(item.chejianxinxis).length;i++){
                  arr.push(Object.values(item.chejianxinxis)[i])
                }
              }
            })
            this[dataList] = arr || [];
            this[page].total = data.data.total;
            this[page].fields = data.data.fields || {};
            this.$set(this[page], 'fields', data.data.fields || {})
          }
        }
      })
    },
    gcsbrequestGet1(s, s1, id, page, dataList) {
      request.newget1(s, s1, id, this[page].pagesize, this[page].page).then(({ data }) => {
        if (data.data != null) {
          this[dataList] = data.data.list || [];
          this[page].total = data.data.total;
          this[page].fields = data.data.fields || {};
          this.$set(this[page], 'fields', data.data.fields || {})
          if (this[dataList]) {
            this[dataList].forEach(e => {
              if (e.chejianxinxis) {
                let newarr = []
                for (const key in e.chejianxinxis) {
                  if (Object.hasOwnProperty.call(e.chejianxinxis, key)) {
                    const element = e.chejianxinxis[key];
                    newarr.push(element)
                  }
                }
                e.chejianxinxisArr = newarr
              }
            })
          }
        }
      })
    },
    scollTo(id) {
      if (document.getElementById(id)) {
        document.getElementById(id).scrollIntoView({ behavior: 'smooth' })
      }
    },
    scollEvent(item) {
      this.nowCheckedTab = item
      if (document.getElementById(item.ids)) {
        document.getElementById(item.ids).scrollIntoView({ behavior: 'smooth' })
      }
    },
    tableStyle() {
      return "height:80px;"
    },
    handleScroll() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop <= '100') {
        this.activeName = 'AnOverview'
      }
    },
    casdasdas(id) {
      window.localStorage.setItem('type', 0);
      this.$router.push({
        path: '/DetailsConsultation',
        query: { type: 0, sublevel: this.$route.query.sublevel, typeId: this.$route.query.typeId, id: id.id }
      })
    },
    parkDetails(id) {
      request.parkDetails(id).then((res) => {
        if (res.data.data != null) {
          this.valDetails = res.data.data;
        }
      })
    },
    enterpriseDetails(id) {
      request.enterpriseDetails(id).then((res) => {
        // if (res.data.data != null) {
        //   this.valDetails = res.data.data;
        // }
        if (res.data.code == 1) {
          this.valDetails = res.data.data;
        } else {
          this.valDetails = {
            fields: {}
          }
        }
      })
    },
    parkDetailsInformation(id) {
      request.parkDetailsInformation(id, this.relatedInformation.pagesize, this.relatedInformation.page).then((res) => {
        if (res.data.data != null) {
          console.log('找园区', res.data.data)
          this.InformationList = res.data.data.list;
          this.relatedInformation.total = res.data.data.total;
        }
      })
    },
    findInvestmentDetails(id) {
      request.findInvestmentDetails(id).then((res) => {
        if (res.data.data != null) {
          this.valDetails = res.data.data;
        }
      })
    },
    parkDetailsParkpolicy(id) {
      request.parkDetailsParkpolicy(id, this.relatedInformation.pagesize, this.relatedInformation.page).then((res) => {
        if (res.data.data != null) {
          if (this.type == 6) {
            this.zxcsstableData = res.data.data.list;
            this.zxcsstable.total = res.data.data.total;
          } else {
            this.parkPolicyData = res.data.data.list;
            this.relatedParkpolicy.total = res.data.data.total;
          }
        }
      })
    },
    findInvestmentDetailsInvestment(id) {
      request.findInvestmentDetailsInvestment(id, this.relatedService.pagesize, this.relatedService.page).then((res) => {
        if (res.data.data != null) {
          this.parkServiceList = res.data.data.list;
          this.relatedService.total = res.data.data.total;
        }
      })
    },
    findInvestmentDetailsType(id, table, field) {
      let pagesize = 0;
      let page = 0;
      if (table == 'jigfxxm') {
        pagesize = this.investment.pagesize;
        page = this.investment.page;
      } else if (table == 'touzixiangmu') {
        pagesize = this.project.pagesize;
        page = this.project.page;
      } else if (table == 'zhaoshequn') {
        pagesize = this.PrimaryTeam.pagesize;
        page = this.PrimaryTeam.page;
      } else if (table == 'shelijijin') {
        pagesize = this.fund.pagesize;
        page = this.fund.page;
      } else if (table == 'zhuyaochanpin') {
        pagesize = this.productOrganization.pagesize;
        page = this.productOrganization.page;
      } else if (table == 'rzzk') {
        pagesize = this.fundraisingStatus.pagesize;
        page = this.fundraisingStatus.page;
      }
      request.findInvestmentDetailsType(id, table, field, pagesize, page).then((res) => {
        if (res.data.data != null) {
          if (table == 'jigfxxm') {
            this.directionData = res.data.data.list;
            this.investment.total = res.data.data.total;
          } else if (table == 'touzixiangmu') {
            this.investmentProject = res.data.data.list;
            this.project.total = res.data.data.total;
          } else if (table == 'zhaoshequn') {
            this.PrimaryTeamList = res.data.data.list;
            this.PrimaryTeam.total = res.data.data.total;
          } else if (table == 'shelijijin') {
            this.establishFund = res.data.data.list;
            this.fund.total = res.data.data.total;
          } else if (table == 'zhuyaochanpin') {
            this.financeList = res.data.data.list;
            this.productOrganization.total = res.data.data.total;
          } else if (table == 'rzzk') {
            this.fundraisingStatusData = res.data.data.list;
            this.fundraisingStatus.total = res.data.data.total;
          }
        }
      })
    },
    enterprisePrimaryTeam(id) {
      // request.enterprisePrimaryTeam(id, this.PrimaryTeam.pagesize, this.PrimaryTeam.page).then((res) => {
      //   if (res.data.data != null) {
      //     this.PrimaryTeamList = res.data.data.list;
      //     this.PrimaryTeam.total = res.data.data.total;
      //   }
      // })
      this.requestGet1('zhuyaotuandui', 'zytd', id, 'PrimaryTeam', 'PrimaryTeamList')
    },
    newenterpriseDetailsType(s) {
      let pagesize = this.FinancingSituationll33.pagesize;
      let page = this.FinancingSituationll33.page;
      request.newget1(s, s, this.$route.query.id, pagesize, page).then(({ data }) => {
        if (data.data != null) {
          this.FinancingSituation1 = data.data.list || []
          this.FinancingSituationll33.fields = data.data.fields || {}
          this.FinancingSituationll33.total = data.data.total || 0
        }
      })
    },
    FinancingSituationSize22($event) {
      this.FinancingSituationll33.pagesize = $event;
      this.FinancingSituationll33.page = 1;
      this.newenterpriseDetailsType('guquanjiegou')
    },
    FinancingSituationChange22($event) {
      this.FinancingSituationll33.page = $event;
      this.newenterpriseDetailsType('guquanjiegou')
    },
    gongchangshebei1Size1($event) {
      this.gongchangshebei1.pagesize = $event;
      this.gongchangshebei1.page = 1;
      this.requestGet1('gongchangshebei', 'gcsb', this.$route.query.id, 'gongchangshebei1', 'gongchangshebei')
    },
    gongchangshebei1Change1($event) {
      this.gongchangshebei1.page = $event;
      this.requestGet1('gongchangshebei', 'gcsb', this.$route.query.id, 'gongchangshebei1', 'gongchangshebei')
    },
    enterpriseDetailsType(id, table, field) {
      let pagesize = 0;
      let page = 0;
      if (table == 'zhuyaochanpin') {
        pagesize = this.productOrganization.pagesize;
        page = this.productOrganization.page;
      } else if (table == 'zhuanlijishu') {
        pagesize = this.zhuanlijishu.pagesize;
        page = this.zhuanlijishu.page;
      } else if (table == 'rzzk') {
        pagesize = this.FinancingSituationll.pagesize;
        page = this.FinancingSituationll.page;
      } else if (table == 'jingyingzhuangk') {
        pagesize = this.OperatingCondition.pagesize;
        page = this.OperatingCondition.page;
      } else if (table == 'falv') {
        pagesize = this.lawll.pagesize;
        page = this.lawll.page;
      } else if (table == 'bpwj') {
        pagesize = this.enterpriseBPll.pagesize;
        page = this.enterpriseBPll.page;
      } else if (table == 'duiwaitouzi') {
        pagesize = this.OutwardInvestment.pagesize;
        page = this.OutwardInvestment.page;
      } else if (table == 'peitaohezuo') {
        pagesize = this.matchingCooperation.pagesize;
        page = this.matchingCooperation.page;
      }

      request.enterpriseDetailsType(id, table, field, pagesize, page).then((res) => {
        if (res.data.data != null) {
          if (table == 'zhuyaochanpin') {
            this.financeList = res.data.data.list;
            this.productOrganization.total = res.data.data.total;
            this.productOrganization.fields = res.data.data.fields || {};
          } else if (table == 'zhuanlijishu') {
            this.zhuanlijishuList = res.data.data.list;
            this.zhuanlijishu.total = res.data.data.total;
          } else if (table == 'rzzk') {
            this.FinancingSituation = res.data.data.list;
            this.FinancingSituationll.total = res.data.data.total;
          } else if (table == 'jingyingzhuangk') {
            this.OperatingConditionData = res.data.data.list;
            this.OperatingCondition.total = res.data.data.total;
            this.OperatingCondition.fields = res.data.data.fields || {};
          } else if (table == 'falv') {
            this.lawData = res.data.data.list;
            this.lawll.total = res.data.data.total;
          } else if (table == 'bpwj') {
            this.enterpriseBPlist = res.data.data.list;
            this.enterpriseBPll.total = res.data.data.total;
          } else if (table == 'duiwaitouzi') {
            this.OutwardInvestmentData = res.data.data.list;
            this.OutwardInvestment.total = res.data.data.total;
          } else if (table == 'peitaohezuo') {
            this.matchingCooperationData = res.data.data.list;
            this.matchingCooperation.total = res.data.data.total;
          }
        }
      })
    },
    parkDetailsType(id, table, field) {
      let pagesize = 0;
      let page = 0;
      if (table == 'zhuyaochanpin') {
        pagesize = this.productOrganization.pagesize;
        page = this.productOrganization.page;
      } else if (table == 'zhuanlijishu') {
        pagesize = this.zhuanlijishu.pagesize;
        page = this.zhuanlijishu.page;
      } else if (table == 'rzzk') {
        pagesize = this.BPd.pagesize;
        page = this.BPd.page;
      } else if (table == 'peitaohezuo') {
        pagesize = this.parkSupportingCooperation.pagesize;
        page = this.parkSupportingCooperation.page;
      }
      request.parkDetailsType(id, table, field, pagesize, page).then((res) => {
        if (res.data.data != null) {
          if (table == 'zhuyaochanpin') {
            this.financeList = res.data.data.list;
            this.productOrganization.total = res.data.data.total;
          } else if (table == 'zhuanlijishu') {
            this.zhuanlijishuList = res.data.data.list;
            this.zhuanlijishu.total = res.data.data.total;
          } else if (table == 'rzzk') {
            this.BPlist = res.data.data.list;
            this.BPd.total = res.data.data.total;
          } else if (table == 'peitaohezuo') {
            this.parkSupportingCooperationData = res.data.data.list;
            this.parkSupportingCooperation.total = res.data.data.total;
          }
        }
      })
    },
    similarEnterpriseC(id) {
      request.similarEnterprise(id, this.similar.pagesize, this.similar.page).then((res) => {
        if (res.data.data != null) {
          this.similarEnterprise = res.data.data.list;
          this.similar.total = res.data.data.total;
        }
      })
    },
    relatedInformationList(id) {
      // request.relatedInformation(id, this.correlation.pagesize, this.correlation.page).then((res) => {
      //   if (res.data.data != null) {
      //     this.correlationList = res.data.data.list;
      //     this.correlation.total = res.data.data.total;
      //   }
      // })
      this.requestGet1('xgzx', 'xgzx', this.$route.query.id, 'correlation', 'correlationList')
    },
    parkDetailCapital(id) {
      request.parkDetailCapital(id, this.relatedCapital.pagesize, this.relatedCapital.page).then((res) => {
        if (res.data.data != null) {
          this.parkCapital = res.data.data.list;
          this.relatedParkpolicy.total = res.data.data.total;
        }
      })
    },
    parkService(id) {
      request.parkService(id, this.relatedService.pagesize, this.relatedService.page).then((res) => {
        if (res.data.data != null) {
          this.parkServiceList = res.data.data.list;
          this.relatedService.total = res.data.data.total;
        }
      })
    },
    parkExternally(id) {
      request.parkExternally(id, this.relatedExternally.pagesize, this.relatedExternally.page).then((res) => {
        if (res.data.data != null) {
          this.parkExternallyList = res.data.data.list;
          this.relatedExternally.total = res.data.data.total;
        }
      })
    },
    ParkTeam(id) {
      request.ParkTeam(id, this.relatedExternally.pagesize, this.relatedExternally.page).then((res) => {
        if (res.data.data != null) {
          this.PrimaryTeamList = res.data.data.list;
          this.PrimaryTeam.total = res.data.data.total;
        }
      })
    },
    relateDsizeChange($event) {
      this.correlationList.pagesize = $event;
      this.correlationList.page = 1;
      this.parkDetailsInformation(this.$route.query.id)
    },
    relateDcurrentChange($event) {
      this.relatedInformation.page = $event;
      this.parkDetailsInformation(this.$route.query.id)
    },
    ParkpolicyDsizeChange($event) {
      this.relatedInformation.pagesize = $event;
      this.relatedInformation.page = 1;
      this.parkDetailsParkpolicy(this.$route.query.id)
    },
    ParkpolicyDcurrentChange($event) {
      this.relatedInformation.page = $event;
      this.parkDetailsParkpolicy(this.$route.query.id)
    },
    CapitalDsizeChange($event) {
      this.relatedService.pagesize = $event;
      this.relatedService.page = 1;
      this.parkDetailCapital(this.$route.query.id)
    },
    CapitalDcurrentChange($event) {
      this.relatedService.page = $event;
      this.parkDetailCapital(this.$route.query.id)
    },
    ServicesizeChange($event) {
      this.relatedService.pagesize = $event;
      this.relatedService.page = 1;
      this.parkService(this.$route.query.id)
    },
    ServicecurrentChange($event) {
      this.relatedService.page = $event;
      this.parkService(this.$route.query.id)
    },
    investmentsizeChange($event) {
      this.investment.pagesize = $event;
      this.investment.page = 1;
      if (this.type == 4) {
        this.findInvestmentDetailsType(this.$route.query.id, 'jigfxxm', 'tzfxxm')
      }
    },
    investmentcurrentChange($event) {
      this.investment.page = $event;
      if (this.type == 4) {
        this.findInvestmentDetailsType(this.$route.query.id, 'jigfxxm', 'tzfxxm')
      }
    },
    projectsizeChange($event) {
      this.project.pagesize = $event;
      this.project.page = 1;
      if (this.type == 4) {
        this.findInvestmentDetailsType(this.$route.query.id, 'touzixiangmu', 'touzixiangmu')
      }
    },
    projectcurrentChange($event) {
      this.project.page = $event;
      if (this.type == 4) {
        this.findInvestmentDetailsType(this.$route.query.id, 'touzixiangmu', 'touzixiangmu')
      }
    },
    correlationsizeChange($event) {
      this.correlation.pagesize = $event;
      this.correlation.page = 1;

      this.requestGet1('xgzx', 'xgzx', this.$route.query.id, 'correlation', 'correlationList')
    },
    correlationcurrentChange($event) {
      this.correlation.page = $event;
      this.requestGet1('xgzx', 'xgzx', this.$route.query.id, 'correlation', 'correlationList')
    },
    PrimaryTeamSize($event) {
      this.PrimaryTeam.pagesize = $event;
      this.PrimaryTeam.page = 1;
      if (this.type == 4) {
        this.findInvestmentDetailsType(this.$route.query.id, 'zhaoshequn', 'zytd')
      } else if (this.type == 3) {
        this.requestGet1('zhuyaotuandui', 'zytd', this.$route.query.id, 'PrimaryTeam', 'PrimaryTeamList')
      } else if (this.type == 6) {
        this.ParkTeam(this.$route.query.id)
      }
    },
    PrimaryTeamChange($event) {
      this.PrimaryTeam.page = $event;
      if (this.type == 4) {
        this.findInvestmentDetailsType(this.$route.query.id, 'zhaoshequn', 'zytd')
      } else if (this.type == 3) {
        this.enterprisePrimaryTeam(this.$route.query.id)
      } else if (this.type == 6) {
        this.ParkTeam(this.$route.query.id)
      }
    },
    fundSize($event) {
      this.fund.pagesize = $event;
      this.fund.page = 1;
      if (this.type == 4) {
        this.findInvestmentDetailsType(this.$route.query.id, 'shelijijin', 'shelijijin')
      }
    },
    fundChange($event) {
      this.fund.page = $event;
      if (this.type == 4) {
        this.findInvestmentDetailsType(this.$route.query.id, 'shelijijin', 'shelijijin')
      }
    },
    productOrganizationSize11($event) {
      this.productOrganization1.pagesize = $event;
      this.productOrganization1.page = 1;
      request.newget1('hexincp', 'hexin', this.$route.query.id, this.productOrganization1.pagesize, this.productOrganization1.page).then(({ data }) => {
        if (data.data != null) {
          this.financeList1 = data.data.list;
          this.productOrganization1.total = data.data.total;
          this.productOrganization1.fields = data.data.fields;
        }
      })
    },
    productOrganizationChange11($event) {
      this.productOrganization1.page = $event;
      request.newget1('hexincp', 'hexin', this.$route.query.id, this.productOrganization1.pagesize, this.productOrganization1.page).then(({ data }) => {
        if (data.data != null) {
          this.financeList1 = data.data.list;
          this.productOrganization1.total = data.data.total;
          this.productOrganization1.fields = data.data.fields;
        }
      })
    },
    productOrganizationSize1($event) {
      this.productOrganization.pagesize = $event;
      this.productOrganization.page = 1;
      this.gcsbrequestGet1('gongchangshebei', 'gcsb', this.$route.query.id, 'productOrganization', 'financeList')
    },
    productOrganizationChange1($event) {
      this.productOrganization.page = $event;
      this.gcsbrequestGet1('gongchangshebei', 'gcsb', this.$route.query.id, 'productOrganization', 'financeList')
    },
    productOrganizationSize($event) {
      this.productOrganization.pagesize = $event;
      this.productOrganization.page = 1;
      if (this.type == 4) {
        this.findInvestmentDetailsType(this.$route.query.id, 'zhuyaochanpin', 'jrcp')
      } else if (this.type == 3) {
        this.enterpriseDetailsType(this.$route.query.id, 'zhuyaochanpin', 'zycp')
      } else if (this.type == 6) {
        this.parkDetailsType(this.$route.query.id, 'zhuyaochanpin', 'zycp')
      }
    },
    productOrganizationChange($event) {
      this.productOrganization.page = $event;
      if (this.type == 4) {
        this.findInvestmentDetailsType(this.$route.query.id, 'zhuyaochanpin', 'jrcp')
      } else if (this.type == 3) {
        this.enterpriseDetailsType(this.$route.query.id, 'zhuyaochanpin', 'zycp')
      } else if (this.type == 6) {
        this.parkDetailsType(this.$route.query.id, 'zhuyaochanpin', 'zycp')
      }
    },
    fundraisingStatusSize($event) {
      this.productOrganization.pagesize = $event;
      this.productOrganization.page = 1;
      if (this.type == 4) {
        this.findInvestmentDetailsType(this.$route.query.id, 'rzzk', 'mzzk')
      }
    },
    fundraisingStatusChange($event) {
      this.productOrganization.page = $event;
      if (this.type == 4) {
        this.findInvestmentDetailsType(this.$route.query.id, 'rzzk', 'mzzk')
      }
    },
    similarSize($event) {
      this.productOrganization.pagesize = $event;
      this.productOrganization.page = 1;
      if (this.type == 4) {
        this.similarEnterpriseC(this.$route.query.id, 'rzzk', 'mzzk')
      }
    },
    similarChange($event) {
      this.productOrganization.page = $event;
      if (this.type == 4) {
        this.similarEnterpriseC(this.$route.query.id, 'rzzk', 'mzzk')
      }
    },
    zhuanlijishuSize($event) {
      this.relatedService.pagesize = $event;
      this.relatedService.page = 1;
      if (this.type == 6) {
        this.parkDetailsType(this.$route.query.id, 'zhuanlijishu', 'zhuanlijishu')
      } else if (this.type == 3) {
        this.enterpriseDetailsType(this.$route.query.id, 'zhuanlijishu', 'zhuanlijishu')
      }
    },
    zhuanlijishuChange($event) {
      this.zhuanlijishu.page = $event;
      if (this.type == 6) {
        this.parkDetailsType(this.$route.query.id, 'zhuanlijishu', 'zhuanlijishu')
      } else if (this.type == 3) {
        this.enterpriseDetailsType(this.$route.query.id, 'zhuanlijishu', 'zhuanlijishu')
      }
    },
    FinancingSituationSize1($event) {
      this.institutionsDataPage.pagesize = $event;
      this.institutionsDataPage.page = 1;
      this.requestGet1('yanfajigou', 'yanfajigou', this.$route.query.id, 'institutionsDataPage', 'institutionsData')
    },
    FinancingSituationChange1($event) {
      this.institutionsDataPage.page = $event;
      this.requestGet1('yanfajigou', 'yanfajigou', this.$route.query.id, 'institutionsDataPage', 'institutionsData')
    },
    FinancingSituationSize2($event) {
      this.FinancingSituationll2.pagesize = $event;
      this.FinancingSituationll2.page = 1;
      this.requestGet1('zhishichanquan', 'zscq', this.$route.query.id, 'FinancingSituationll2', 'FinancingSituation2')
    },
    FinancingSituationChange2($event) {
      this.FinancingSituationll2.page = $event;
      this.requestGet1('zhishichanquan', 'zscq', this.$route.query.id, 'FinancingSituationll2', 'FinancingSituation2')
    },
    FinancingSituationSize3($event) {
      this.FinancingSituationll3.pagesize = $event;
      this.FinancingSituationll3.page = 1;
      this.requestGet1('zlrztx', 'zlrztx', this.$route.query.id, 'FinancingSituationll3', 'FinancingSituation3')
    },
    FinancingSituationChange3($event) {
      this.FinancingSituationll3.page = $event;
      this.requestGet1('zlrztx', 'zlrztx', this.$route.query.id, 'FinancingSituationll3', 'FinancingSituation3')
    },
    FinancingSituationSize($event) {
      this.FinancingSituationll.pagesize = $event;
      this.FinancingSituationll.page = 1;
      this.requestGet1('rzzk', 'rzzk', this.$route.query.id, 'FinancingSituationll', 'FinancingSituation')
    },
    FinancingSituationChange($event) {
      this.FinancingSituationll.page = $event;
      this.requestGet1('rzzk', 'rzzk', this.$route.query.id, 'FinancingSituationll', 'FinancingSituation')
    },
    OperatingConditionsize($event) {
      this.OperatingCondition.pagesize = $event;
      this.OperatingCondition.page = 1;
      // this.enterpriseDetailsType(this.$route.query.id, 'jingyingzhuangk', 'jyzk')
      this.requestGet1('jingyingzhuangk', 'jyzk', this.$route.query.id, 'OperatingCondition', 'OperatingConditionData')
    },
    OperatingConditioncurrent($event) {
      this.OperatingCondition.page = $event;
      // this.enterpriseDetailsType(this.$route.query.id, 'jingyingzhuangk', 'jyzk')
      this.requestGet1('jingyingzhuangk', 'jyzk', this.$route.query.id, 'OperatingCondition', 'OperatingConditionData')
    },
    lawllSize($event) {
      this.lawll.pagesize = $event;
      this.lawll.page = 1;
      this.requestGet1('falv', 'falu', this.$route.query.id, 'lawll', 'lawData')
    },
    lawllCurrent($event) {
      this.lawll.page = $event;
      this.requestGet1('falv', 'falu', this.$route.query.id, 'lawll', 'lawData')
    },
    enterpriseBPllSize($event) {
      this.enterpriseBPll.pagesize = $event;
      this.enterpriseBPll.page = 1;
      this.requestGet1('trzbp', 'tourongzibp', this.$route.query.id, 'enterpriseBPll', 'enterpriseBPlist')
    },
    enterpriseBPllCurrent($event) {
      this.enterpriseBPll.page = $event;
      this.requestGet1('trzbp', 'tourongzibp', this.$route.query.id, 'enterpriseBPll', 'enterpriseBPlist')
    },
    OutwardInvestmentSize($event) {
      this.OutwardInvestment.pagesize = $event;
      this.OutwardInvestment.page = 1;
      this.requestGet1('duiwaitouzi', 'duiwaitouzi', this.$route.query.id, 'OutwardInvestment', 'OutwardInvestmentData')
    },
    OutwardInvestmentCurrent($event) {
      this.OutwardInvestment.page = $event;
      this.requestGet1('duiwaitouzi', 'duiwaitouzi', this.$route.query.id, 'OutwardInvestment', 'OutwardInvestmentData')
    },

    matchingCooperationSize7($event) {
      this.matchingCooperation7.pagesize = $event;
      this.matchingCooperation7.page = 1;
      this.requestGet1('leisiqiye', 'leisiqiye', this.$route.query.id, 'matchingCooperation7', 'matchingCooperationData7')
    },
    matchingCooperationCurrent7($event) {
      this.matchingCooperation7.page = $event;
      this.requestGet1('leisiqiye', 'leisiqiye', this.$route.query.id, 'matchingCooperation7', 'matchingCooperationData7')
    },
    matchingCooperationSize66($event) {
      this.matchingCooperation66.pagesize = $event;
      this.matchingCooperation66.page = 1;
      this.requestGet1('caiwuzhichu', 'baobiao', this.$route.query.id, 'matchingCooperation66', 'matchingCooperationData66')
    },
    matchingCooperationCurrent66($event) {
      this.matchingCooperation66.page = $event;
      this.requestGet1('caiwuzhichu', 'baobiao', this.$route.query.id, 'matchingCooperation66', 'matchingCooperationData66')
    },
    matchingCooperationSize6($event) {
      this.matchingCooperation6.pagesize = $event;
      this.matchingCooperation6.page = 1;
      this.requestGet1('caiwuzhichu', 'caiwushouzhi', this.$route.query.id, 'matchingCooperation6', 'matchingCooperationData6')
    },
    matchingCooperationCurrent6($event) {
      this.matchingCooperation6.page = $event;
      this.requestGet1('caiwuzhichu', 'caiwushouzhi', this.$route.query.id, 'matchingCooperation6', 'matchingCooperationData6')
    },
    matchingCooperationSize5($event) {
      this.matchingCooperation5.pagesize = $event;
      this.matchingCooperation5.page = 1;
      this.requestGet('jinchukou', this.$route.query.id, 'matchingCooperation5', 'matchingCooperationData5')
    },
    matchingCooperationCurrent5($event) {
      this.matchingCooperation5.page = $event;
      this.requestGet('jinchukou', this.$route.query.id, 'matchingCooperation5', 'matchingCooperationData5')
    },
    matchingCooperationSize4($event) {
      this.matchingCooperation4.pagesize = $event;
      this.matchingCooperation4.page = 1;
      this.requestGet1('wuliu', 'wuliu', this.$route.query.id, 'matchingCooperation4', 'matchingCooperationData4')
    },
    matchingCooperationCurrent4($event) {
      this.matchingCooperation4.page = $event;
      this.requestGet1('wuliu', 'wuliu', this.$route.query.id, 'matchingCooperation4', 'matchingCooperationData4')
    },
    matchingCooperationSize3($event) {
      this.matchingCooperation3.pagesize = $event;
      this.matchingCooperation3.page = 1;
      this.requestGet1('cangchu', 'cangchu', this.$route.query.id, 'matchingCooperation3', 'matchingCooperationData3')
    },
    matchingCooperationCurrent3($event) {
      this.matchingCooperation3.page = $event;
      this.requestGet1('cangchu', 'cangchu', this.$route.query.id, 'matchingCooperation3', 'matchingCooperationData3')
    },
    matchingCooperationSize2($event) {
      this.matchingCooperation2.pagesize = $event;
      this.matchingCooperation2.page = 1;
      this.requestGet1('caigouchanpin', 'cgcp', this.$route.query.id, 'matchingCooperation2', 'matchingCooperationData2')
    },
    matchingCooperationCurrent2($event) {
      this.matchingCooperation2.page = $event;
      this.requestGet1('caigouchanpin', 'cgcp', this.$route.query.id, 'matchingCooperation2', 'matchingCooperationData2')
    },
    matchingCooperationSize1($event) {
      this.matchingCooperation1.pagesize = $event;
      this.matchingCooperation1.page = 1;
      this.requestGet1('peitaokehu', 'jjptkh', this.$route.query.id, 'matchingCooperation1', 'matchingCooperationData1')
    },
    matchingCooperationCurrent1($event) {
      this.matchingCooperation1.page = $event;
      this.requestGet1('peitaokehu', 'jjptkh', this.$route.query.id, 'matchingCooperation1', 'matchingCooperationData1')
    },
    matchingCooperationSize($event) {
      this.matchingCooperation.pagesize = $event;
      this.matchingCooperation.page = 1;
      this.requestGet1('peitaokehu', 'zjptkh', this.$route.query.id, 'matchingCooperation', 'matchingCooperationData')
    },
    matchingCooperationCurrent($event) {
      this.matchingCooperation.page = $event;
      this.requestGet1('peitaokehu', 'zjptkh', this.$route.query.id, 'matchingCooperation', 'matchingCooperationData')
    },
    relatedExternallysizeChange($event) {
      this.relatedExternally.pagesize = $event;
      this.relatedExternally.page = 1;
      this.enterpriseDetailsType(this.$route.query.id, 'peitaohezuo', 'peitaohezuo')
    },
    relatedExternallycurrentChange($event) {
      this.relatedExternally.page = $event;
      this.enterpriseDetailsType(this.$route.query.id, 'peitaohezuo', 'peitaohezuo')
    },
    zxcsstableSize($event) {
      this.zxcsstable.pagesize = $event;
      this.zxcsstable.page = 1;
      this.parkDetailsParkpolicy(this.$route.query.id)
    },
    zxcsstableChange($event) {
      this.zxcsstable.page = $event;
      this.parkDetailsParkpolicy(this.$route.query.id)
    },
    BPdSize($event) {
      this.BPd.pagesize = $event;
      this.BPd.page = 1;
      this.parkDetailsType(this.$route.query.id, 'rzzk', 'rzzk')
    },
    BPdChange($event) {
      this.BPd.page = $event;
      this.parkDetailsType(this.$route.query.id, 'rzzk', 'rzzk')
    },
    parkSupportingCooperationSize($event) {
      this.parkSupportingCooperation.pagesize = $event;
      this.parkSupportingCooperation.page = 1;
      this.parkDetailsType(this.$route.query.id, 'peitaohezuo', 'peitaohezuo')
    },
    parkSupportingCooperationCurrent($event) {
      this.parkSupportingCooperation.page = $event;
      this.parkDetailsType(this.$route.query.id, 'peitaohezuo', 'peitaohezuo')
    },
    handleClick(event) {
      let ar = '';
      this.label.forEach(item => {
        if (item.label == event.name) {
          ar = item.name
        }
      })

      if (document.getElementById(item.name)) {
        document.getElementById(ar).scrollIntoView({ behavior: "smooth" });
      }
    },
    zxczcasd(item) {
      this.$router.push({
        path: '/InvestmentDetails',
        query: { ...this.$route.query, id: item.id }
      })
    },
    ccasdawz(item) {
      this.$router.push({
        path: '/ggDetails',
        query: { ...this.$route.query, id: item.id }
      })
    },
    TLccasdawz(id) {
      this.$router.push({
        path: '/InvestmentDetails',
        query: { ...this.$route.query, id: id }
      })
    },
    bpclick(item) {
      window.open(item.bpwenjian, "_blank");
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScrollEvent, true);
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/investementDetails.scss";
</style>

<style lang="scss">
.Industrial_in {
  .el-table__header {
    th.el-table__cell {
      background-color: #f6faff !important;
    }
  }

  .Industrial_name_right {
    .wx_ck_div {
      .ckxcx_div {
        border-radius: 50px;
        //border-color: #12d269;
        //color: #12d269;
        border-color: #888888;
        color: #888888;
      }
    }
  }
}
</style>

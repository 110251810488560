import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import "./plugins/element.js";
import store from "./store";
import { http } from "../src/utils/myRequest";
import "amfe-flexible";
// import './utils/rem'
import mavonEditor from "mavon-editor";
// import "mavon-editor/public/css/index.css";
import Swiper from "swiper"
import formdata from"../src/utils/time"
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueJsonp from 'vue-jsonp'

import qs from 'qs'
Vue.use(VueAxios,axios,VueJsonp,mavonEditor)
Vue.prototype.qs = qs;
window.axios = axios
window.jsonp = VueJsonp
window.qs = qs
Vue.prototype.$myRequest = http;
Vue.prototype.$formData=formdata
Vue.config.productionTip = false;

import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts
Vue.prototype.$EventBus = new Vue();
Vue.directive('enterNumber', {
  inserted: function (el) {
    el.addEventListener("keypress",function(e){
      e = e || window.event;
      let charcode = typeof e.charCode === 'number' ? e.charCode : e.keyCode;
      let re = /\d/;
      if(!re.test(String.fromCharCode(charcode)) && charcode > 9 && !e.ctrlKey){
        if(e.preventDefault){
          e.preventDefault();
        }else{
          e.returnValue = false;
        }
      }
    });
  }
});


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

<template>
  <div class="logo">
    <img class="logo_img" src="../../public/img/back.png" alt="">
    <div class="logo_in" v-if="type == 1">
      <h4>欢迎来到车找采</h4>
      <div class="logo_val">
        <div class="logo_left">
          <el-input class="logo_left_input" v-model="logo.data.username" placeholder="邮箱/账号"></el-input>
          <el-input class="logo_left_input" v-model="logo.data.password" placeholder="密码" show-password></el-input>
          <div class="logo_left_ccc">
            <el-input class="logo_left_ccc_in" v-model="logo.code" placeholder="验证码"></el-input>
            <button @click="verificationCode()"><img :src="url" alt=""></button>
          </div>
          <el-button type="primary" class="logo_left_but" @click="cclogo()">登陆</el-button>
        </div>
        <div class="logo_right">
          <div class="logo_right_but">
            <div v-for="(d,i) in tyleDl" :key="i" @click="tyleDlIndex = i" :class="tyleDlIndex == i?'logo_right_but_div':''"><span><i :class="d.class"></i>{{d.name}}登录</span></div>
          </div>
          <div style="margin-left: -30px;">
            <p>还没有车找采账号：</p>
            <span @click="type = 2">立即注册</span>
          </div>
          <!-- <button>二维码</button>
          <p>关注车找采微信</p> -->
        </div>
      </div>
    </div>
    <div class="logo_in" v-if="type == 2">
      <h4>欢迎注册</h4>
      <div class="logo_val">
        <div class="logo_left">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-input class="logo_left_input" v-model="register.data.personalNickname" placeholder="个人昵称"></el-input>
            </el-col>
            <el-col :span="12">
              <el-input class="logo_left_input" v-model="register.data.companyName" placeholder="公司名称"></el-input>
            </el-col>
            <el-col :span="12">
              <el-input class="logo_left_input" v-model="register.data.companyAddress" placeholder="公司地址"></el-input>
            </el-col>
            <el-col :span="12">
              <el-input class="logo_left_input" v-model="register.data.department" placeholder="所在部门"></el-input>
            </el-col>
            <el-col :span="12">
              <el-input class="logo_left_input" v-model="register.data.holdPost" placeholder="担任职务"></el-input>
            </el-col>
            <el-col :span="12">
              <el-input class="logo_left_input" v-model="register.data.registeredName" placeholder="注册姓名"></el-input>
            </el-col>
            <el-col :span="12">
              <el-input class="logo_left_input" v-model="register.data.username" placeholder="邮箱/账号"></el-input>
            </el-col>
            <el-col :span="12">
              <el-input class="logo_left_input" v-model="register.data.wx" placeholder="微信"></el-input>
            </el-col>
            <el-col :span="12">
              <el-input class="logo_left_input" v-model="register.data.password" placeholder="密码" show-password></el-input>
            </el-col>
            <el-col :span="12">
              <el-input class="logo_left_input" v-model="register.data.password2" placeholder="确认密码" show-password></el-input>
            </el-col>
            <el-col :span="24">
              <el-input class="logo_left_input" v-model.number="register.data.officeTelephone" placeholder="办公电话 "></el-input>
            </el-col>
            <el-col :span="24">
              <el-input class="logo_left_input" :pattern="numberPattern" v-model.number="register.data.phone" placeholder="手机号电话 "></el-input>
            </el-col>
            <el-col :span="24">
              <div class="logo_left_ccc">
                <el-input class="logo_left_ccc_in" v-model="register.data.code" placeholder="验证码"></el-input>
                <button  @click="verificationCode()"><img :src="url" alt=""></button>
              </div>
            </el-col>
            <el-col :span="24">
              <div class="logo_left_ccc">
                <el-input class="logo_left_ccc_in" v-model="register.sms" placeholder="手机验证码"></el-input>
                 <el-button v-bind:class="{grey:isGrey,blue:!isGrey}"
                            v-bind:disabled="dis" type="primary"
                            @click="Verification">
                      <span v-if="show">发送验证码</span>
                      <span v-else>重新发送({{count}}s)</span>
                  </el-button>
              </div>
            </el-col>
          </el-row>

        <el-checkbox v-model="checked" size="medium " class="logo_check">同意<a style="color: #2C7CD7;" @click="RegistrationAgreement()">《网站注册协议》</a></el-checkbox>
          <el-button type="primary" class="logo_left_but" @click="zxcregister()">注册</el-button>
        </div>
        <div class="logo_right">
          <div class="logo_right_but">
            <div v-for="(d,i) in tyleDl" :key="i" @click="tyleDlIndex = i" :class="tyleDlIndex == i?'logo_right_but_div':''"><span><i :class="d.class"></i>{{d.name}}注册</span></div>
          </div>
          <p>还没有车找采账号：</p>
          <span @click="type = 1">直接登录</span>
          <button>二维码</button>
          <p>关注车找采微信</p>
        </div>
      </div>
    </div>
    <el-dialog
      :title="dialog.title"
      :visible.sync="dialog.Visible"
      width="90%">
      <div v-html="dialog.content"></div>
    </el-dialog>
  </div>
</template>

<script>
import request from '@/api/request.js'
export default {
  data(){
    return{
      type:0,
      checked:false,
      logo:{
        data:{
          username:"",
          password:"",
        },
        code:"",
        groupid:'1',
      },
      numberPattern: '^\\d+(\\.\\d{0,2})?$',
      tyleDl:[
        {name:'采购商',tyle:2,class:'el-icon-s-custom'},
        {name:'供应商',tyle:3,class:'el-icon-s-custom'},
        {name:'个人',tyle:1,class:'el-icon-user'},
      ],
      tyleDlIndex:2,
      dialog:{Visible:false,content:""},
      register:{
        is_ajax:1,
        is_protocol:0,
        data:{
          personalNickname:"",
          companyName:"",
          companyAddress:"",
          department:"",
          holdPost:"",
          registeredName:"",
          wx:"",
          officeTelephone:"",
          username:"",
          password:"",
          password2:"",
          phone:"",
          code:""
        },
        sms:"",
        groupid:'1'
      },
      url:"",
      VerificationCode:'获取验证码',
      dis: false,
      show: true,
      isGrey: false, //按钮样式
      timer: null, //设置计时器,
      count: ""
    }
  },
  watch:{
    $route(to,from){
    this.type = this.$route.query.type
    }
  },
  mounted(){
    this.type = this.$route.query.type;
    this.verificationCode()
  },
  methods:{
    verificationCode(){
      this.url = '';
      setTimeout(() => {
        this.url = request.verificationCode()
      },200)
    },
    Verification(){
      if(this.register.data.phone == ''){
        this.$message({
          message: '请填写手机号',
          type: 'error'
        });
        return
      }else if(this.register.data.code == ''){
        this.$message({
          message: '请填写图形验证码',
          type: 'error'
        });
        return
      }
      request.verificationCodeShort(this.register.data.phone,this.register.data.code).then((res) => {
        if(res.data.code == 1){
          let TIME_COUNT = 60;
          if (!this.timer) {
            this.count = TIME_COUNT;
            this.isGrey = true;
            this.show = false;
            this.dis = true;
            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= TIME_COUNT) {
                this.count--;
              } else {
                this.dis = false;
                this.isGrey = false;
                this.show = true;
                this.timer = null;
              }
            }, 1000);
          }
        }else{
          this.$message.error(res.data.msg);
        }
      })
    },
    cclogo(){
      this.logo.groupid = this.tyleDl[this.tyleDlIndex].type;
      request.logo(this.logo).then((res) => {
        if(res.data.code == 1){
          this.$message({
            message: '登录成功',
            type: 'success'
          });
          window.localStorage.setItem('code',res.data.data.auth)
          window.localStorage.setItem('id',res.data.data.member.id)
          window.localStorage.setItem('individual',JSON.stringify(res.data.data.member))
          setTimeout(() => {
            this.$router.push("/");
          },1000)
        }else{
          this.$message.error(res.data.msg);
        }
      })
    },
    zxcregister(){
      this.register.is_protocol = this.checked*1;
      this.register.groupid = this.tyleDl[this.tyleDlIndex].type;
      request.register(this.register).then((res) => {
        if(res.data.code == 1){
          this.$message({
            message: '注册成功',
            type: 'success'
          });
          // window.localStorage.setItem('code',res.data.data.auth)
          // window.localStorage.setItem('id',res.data.data.member.id)
          this.type = 1;
        }else{
          this.$message.error(res.data.msg);
        }
      })
    },
    RegistrationAgreement(){
      request.RegistrationAgreement().then((res) => {
        res.data.data.content = this.html_decode(res.data.data.content)
        this.dialog = {
          Visible:true,
          ...res.data.data
        }
      })
    },
    html_decode(str){
        var s = "";
        if (str.length == 0) return "";
        s = str.replace(/&amp;/g, "&");
        s = s.replace(/&lt;/g, "<");
        s = s.replace(/&gt;/g, ">");
        s = s.replace(/&nbsp;/g, " ");
        s = s.replace(/&#39;/g, "\'");
        s = s.replace(/&quot;/g, "\"");
        s = s.replace(/<br\/>/g, "\n");
        return s;
    },
  }
}
</script>

<style lang="scss" scoped>
  .logo{
    width: 100%;
    height:80vh;
    position: relative;
    .logo_img{
      display: block;
      width: 100%;
      height: 100%;
    }
    .logo_in{
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 750px;
      margin: auto;
      background: #fff;
      height: fit-content;
      padding-bottom: 50px;
      h4{
        line-height: 92px;
        text-align: center;
        font-size: 22px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: normal;
        color: #000000;
      }
      .logo_val{
        display: flex;
        justify-content: center;
        width: 95%;
        margin: auto;
      }
    }
  }
  .logo_left{
    padding-right: 50px;
    border-right: 1px dashed #DBDBDB;
    .logo_left_input{
      margin-bottom: 12px;
      display: block;
    }
    .logo_left_ccc{
      .logo_left_ccc_in{
        display: inline-block;
        width: auto;
        margin-right: 25px;
        margin-bottom: 20px;
      }
      button{
        width: 114px;
        height: 34px;
        background: #D8D8D8;
        line-height: 0px;
        text-align: center;
        border: 0;
        float: right;
        margin-top: 6px;
      }
    }
    .logo_left_but{
      width: 100%;
      margin-top: 35px;
    }
  }
  .logo_check{
    display: block;
    text-align: right;
  }
  .logo_right{
    margin-left: 50px;
    .logo_right_but{
      margin-left: -50px;
      div{
        width: 262px;
        height: 40px;
        background: rgba(208,208,208,0.38);
        line-height: 40px;
        font-size: 0;
        text-align: center;
        margin-bottom: 10px;
        transition:all 0.4s;
        span{
          display: inline-block;
          vertical-align: middle;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #575757;
        }
      }
      .logo_right_but_div{
        text-align: left;
        background-color: #2C7CD7;
        color: #fff;
        span{margin-left: 20%;color: #fff;}
      }
    }
    p{
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 22px;
    }
    span{
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #2C7CD7;
      line-height: 36px;
      display: block;
      cursor: pointer;
    }
    button{
      width: 137px;
      height: 136px;
      background: #D8D8D8;
      border: 1px solid #979797;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #2C7CD7;
      line-height: 136px;
      text-align: center;
      margin-bottom: 15px;
    }
  }
  @media screen and (max-width:1200px) {
    .logo{height: 82vh;}
    .logo_val{
      display: block !important;
      .logo_left{
        padding: 0;
        border: 0;
        width: 90%;
        margin: auto;
      }
      .logo_right{
        padding:20px 0;
        width: 80%;
        margin:30px auto 0;
        position: relative;
        button{
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }
  }
  @media screen and (max-width:849px) {
    .logo{
      margin-bottom: -70px;
      .logo_in{
        width: 90%;
      }
    }
  }
  ::v-deep {
    .title-label-input {
      width: 160px;
      margin-right: 5%;
      .el-input__inner {
        border-radius: 28px;
        background: transparent;
      }
      .el-input__inner:focus {
        border-color: #fff;
      }
    }
    .el-pagination.is-background .el-pager li:not(.disabled).active {
      background: #2c7cd7;
    }
    .el-pagination.is-background .el-pager li:not(.disabled):hover {
      color: #2c7cd7;
    }
    .el-button--primary:hover,
    .el-button--primary:focus,
    .el-button--primary {
      color: #ffffff;
      background: #66b1ff;
      border-color: #66b1ff;
    }
    .el-button--primary ,
    .el-button--primary.is-plain:hover, .el-button--primary.is-plain:focus{
      color: #ffffff;
      background: #2c7cd7;
      border-color: #2c7cd7;
    }
    .el-button--primary.is-plain {
      border-color: #2c7cd7;
      color: #2c7cd7;
    }
    .el-table th{
      background: #F0F7FF;
    }
    .el-checkbox__inner{
      border-radius: 50%;
    }
    .el-checkbox__input.is-checked + .el-checkbox__label,.el-checkbox__input.is-checked .el-checkbox__inner{
      color: #2c7cd7;
    }
    .el-checkbox__input.is-checked .el-checkbox__inner{
      border-color: #2c7cd7;
      background: #2c7cd7;
    }
  }
</style>

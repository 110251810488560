<template>
  <div>
    <div class="CreateNews" style="margin-top: 20px;">
      <div class="CreateNews_in">
        <div class="Individual_title" style="display: flex;justify-content: space-between;">
          <h4>用户中心</h4>
          <div class="Individual_title_right" style="display: flex;align-items: center;">
            <div class="Individual_title_right_in" style="display: block;height: 30px;line-height: 0;overflow: initial;">
              <!-- <div class="swiper-wrapper" style="width: 100%;">
                    <div v-for="( i, idx) in list" :key="idx" :class="listindex == idx?'Individual_title_right_color':''" class="swiper-slide" style="width: 84px;" @click="lgrzxidx(idx)">{{i}}</div>
              </div> -->
              <div v-for="( i, idx) in list" :key="idx" :class="listindex == idx?'Individual_title_right_color':''" class="swiper-slide" style="width: 84px;" @click="lgrzxidx(idx)">{{i}}</div>
            </div>
          </div>
        </div>
        <div class="Individual_in">
          <div class="Individual_tab" v-if="TypeButton.type">
            <el-button type="primary" @click="TypeButtonClick()" round class="Individual_tab2">{{TypeButton.title}}</el-button>
            <!-- <el-tabs class="Individual_tab1">
              <el-tab-pane v-for="(item, index) in label" :key="index" :label="item.label" :name="item.name"></el-tab-pane>
            </el-tabs> -->
          </div>
          <div v-if="listindex === 0" class="Individual_in_1">
            <div class="Individual_in_1_from">
              <el-form ref="form" :model="individual" label-width="80px" disabled>
                <el-form-item label="个人昵称">
                  <el-input v-model="individual.nickname"></el-input>
                </el-form-item>
                <el-form-item label="注册姓名">
                  <el-input v-model="individual.name"></el-input>
                </el-form-item>
                <el-form-item label="手机电话">
                  <el-input v-model="individual.phone"></el-input>
                </el-form-item>
                <el-form-item label="介绍">
                  <el-input
                    type="textarea"
                    :rows="2"
                    v-model="individual.desc">
                  </el-input>
                </el-form-item>
                <el-form-item label="公司名称">
                  <el-input v-model="individual.company"></el-input>
                </el-form-item>
                <el-form-item label="公司地址">
                  <el-input v-model="individual.companyAddress"></el-input>
                </el-form-item>
                <el-form-item label="所在部门">
                  <el-input v-model="individual.department"></el-input>
                </el-form-item>
                <el-form-item label="担任职务">
                  <el-input v-model="individual.zhiwei"></el-input>
                </el-form-item>
                <el-form-item label="办公电话">
                  <el-input v-model="individual.officeTelephone"></el-input>
                </el-form-item>
                <el-form-item label="微信">
                  <el-input v-model="individual.wx"></el-input>
                </el-form-item>
                <el-form-item label="邮箱">
                  <el-input v-model="individual.email"></el-input>
                </el-form-item>
              </el-form>
            </div>
            <el-button class="Individual_in_1_but" type="primary" plain @click="exitAccount()">退出账号</el-button>
          </div>
          <div v-if="listindex === 1" class="Individual_in_1">
            <div class="Individual_in_1_from">
              <el-form ref="form" :model="registerForm" label-width="80px">
                <el-form-item label="个人昵称">
                  <el-input v-model="individual.nickname"></el-input>
                </el-form-item>
                <el-form-item label="注册姓名">
                  <el-input v-model="individual.name"></el-input>
                </el-form-item>
                <el-form-item label="手机电话">
                  <el-input v-model="individual.phone"></el-input>
                </el-form-item>
                <el-form-item label="介绍">
                  <el-input
                    type="textarea"
                    :rows="2"
                    v-model="individual.desc">
                  </el-input>
                </el-form-item>
                <el-form-item label="公司名称">
                  <el-input v-model="individual.company"></el-input>
                </el-form-item>
                <el-form-item label="公司地址">
                  <el-input v-model="individual.companyAddress"></el-input>
                </el-form-item>
                <el-form-item label="所在部门">
                  <el-input v-model="individual.department"></el-input>
                </el-form-item>
                <el-form-item label="担任职务">
                  <el-input v-model="individual.zhiwei"></el-input>
                </el-form-item>
                <el-form-item label="办公电话">
                  <el-input v-model="individual.officeTelephone"></el-input>
                </el-form-item>
                <el-form-item label="微信">
                  <el-input v-model="individual.wx"></el-input>
                </el-form-item>
                <el-form-item label="邮箱">
                  <el-input v-model="individual.email"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="modifyData()">确认</el-button>
                </el-form-item>
              </el-form>
            </div>
          </div>
          <div v-if="listindex === 2">
            <div class="Individual_in_1_from" style="height: 40vh;">
              <el-form ref="form" :model="passwordForm" label-width="80px">
                <el-form-item label="原密码">
                  <el-input v-model="passwordForm.password" placeholder="原密码" show-password></el-input>
                </el-form-item>
                <el-form-item label="新密码">
                  <el-input v-model="passwordForm.password2" placeholder="原密码" show-password></el-input>
                </el-form-item>
                <el-form-item label="确认密码">
                  <el-input v-model="passwordForm.password3" placeholder="确认密码" show-password></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="ChangePassword()">确认</el-button>
                </el-form-item>
              </el-form>
            </div>
          </div>
          <div v-if="listindex === 3">
            <el-table
              stripe
              :data="tableData"
              class="Individual_in_table">
              <el-table-column
                label="机构名称">
                <template slot-scope="scope">
                  <div class="FindIndustry_sapn">
                    <p>{{ scope.row.title }}</p>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="主办单位" prop="zhubanfang" width="120"></el-table-column>
              <el-table-column label="时间" width="220">
                <template slot-scope="scope">
                  <div>{{scope.row.start_time}}</div>
                </template>
              </el-table-column>
              <el-table-column label="地点" prop="hdcs" width="120"></el-table-column>
              <el-table-column
                label="活动形式"
                prop="huodongxingshi"
                width="120">
              </el-table-column>
            </el-table>
          </div>
          <div v-if="listindex === 4">
            <div class="Individual_in_4">
              <ListFiles :users="''"></ListFiles>
            </div>
          </div>
          <div v-if="listindex === 5">
            <div class="Individual_in_4">
              <el-row v-if="Member.length > 0" :gutter="56">
                <el-col :span="12" v-for="(item,i) in Member" :key="i">
                  <div class="Individual_in_5">
                    <h4>{{item.title}}</h4>
                    <div class="Individual_in_5_bot">
                      <img src="../../public/img/img24.png" alt="">
                    </div>
                    <div class="Individual_in_5_bottom">
                      <p>到期时间：{{item.result}}</p>
                      <span  @click="xufei(item)">立即续费</span>
                    </div>
                  </div>
                </el-col>
              </el-row>
              <el-empty v-else description="暂无会员"></el-empty>
            </div>
          </div>
          <div v-if="listindex === 6">
            <div class="Individual_in_4">
              <el-row :gutter="20" class="Graphiclist_but">
                <el-col :span="6" v-for="(d,num) in courseList" :key="num">
                  <div class="Graphiclist">
                    <div class="img">
                      <img :src="d.thumb" alt="">
                    </div>
                    <h4>
                      <div>{{d.title}}</div>
                    </h4>
                    <div>{{d.mid}}</div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
          <div v-if="listindex === 7" style="overflow: hidden;">
            <div class="temporarilyAbsent" v-if="advertisement == 0">
              <img src="../../public/img/img31.png" alt="">
              <p>暂无广告</p>
            </div>
            <div class="temporarilyAbsentAdd" v-if="advertisement == 1">
              <el-table :data="advertisementList" style="width: 100%;margin-top: 20px;">
                <el-table-column prop="location" label="位置" width="150"></el-table-column>
                <el-table-column prop="expire_time" label="到期时间" width="200"></el-table-column>
                <el-table-column prop="img" label="缩略图">
                  <template slot-scope="scope">
                    <img
                      style="vertical-align: middle;height: 70px;"
                      :src="scope.row.img"
                      alt=""
                    />
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <el-form v-if="advertisement == 2" ref="form" :model="memberForm" label-width="80px" class="sxxs">
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="主栏目">
                    <el-select @change="TotalCostClick()" v-model="memberForm.MainColumn" placeholder="请选择活动区域">
                      <el-option v-for="(d,i) in columnlist" :key="i" :label="d.name" :value="d.id"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="级别等级">
                    <el-input v-model="memberForm.class" v-enter-number @blur="TotalCostClick()"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-form-item label="购买时间">
                <el-input placeholder="填写月数" v-model="memberForm.PurchaseTime" @blur="TotalCostClick()" v-enter-number>
                    <template slot="append">月</template>
                  </el-input>
              </el-form-item>
              <el-form-item label="合计费用">
                <el-input v-model="memberForm.TotalCost" readonly="readonly"></el-input>
              </el-form-item>
              <el-button type="primary" @click="buying()">立即购买</el-button>

            </el-form>
          </div>
          <div v-if="listindex === 8" class="zxc">
            <el-form ref="form" :model="zxc" label-width="80px">
              <el-form-item label="主栏目">
                <el-select style="width: 100%;" v-model="zxc.aa1" placeholder="请选择主栏目" @change="asweqwzxc">
                  <el-option v-for="(d,i) in DataColumn" :key="i" :label="d.name" :value="d.id"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="子栏目">
                <el-select style="width: 100%;" v-model="zxc.aa2" placeholder="请选择子栏目">
                  <el-option v-for="(d,i) in zlist" :key="i" :label="d.name" :value="d.id" ></el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <div class="picture-card">
              <!-- <el-upload
                class="upload-demo"
                :action="`https://admin.aiauto.shop/index.php?v=1&appid=2&appsecret=CHEZHUANGTOUE921805707FA3&s=api&c=file&m=upload&api_auth_code=${toke}&api_auth_uid=${id}&siteid=1&fid=175`"
                :show-file-list="false"
                multiple
                :limit="1">
                <div class="picture-card_left">
                  <i class="el-icon-plus"></i>
                </div>
              </el-upload>

              <span>上传资料</span> -->
              <el-upload
                class="upload-demo"
                :action="`https://admin.aiauto.shop/index.php?v=1&appid=2&appsecret=CHEZHUANGTOUE921805707FA3&s=api&c=file&m=upload&api_auth_code=${toke}&api_auth_uid=${id}&siteid=1&fid=175`"
                multiple
                accept=".pdf, .doc, .docx, .excel"
                :limit="1"
                :file-list="fileList"
                :on-success="onSuccess">
                <el-button size="small" type="primary">上传资料</el-button>
              </el-upload>
            </div>
            <div class="centerzxc">
              <el-button class="centerzxc_but" type="primary" @click="asdasdwqeqw()">提交</el-button>
              <!-- <el-button class="centerzxc_but" plain>提交</el-button> -->
            </div>
          </div>
          <div v-if="listindex === 9">
            <myPurchase></myPurchase>
          </div>
          <div v-if="listindex === 10">
            <mySupply></mySupply>
          </div>
        </div>
      </div>
      <el-dialog title="开通会员" :visible.sync="dialogFormVisible">
        <div v-if="!memberCode.type">
          <el-row :gutter="20">
            <el-col :span="8" v-for="(item, index) in memberList" :key="index">
              <div class="memberLClass" style="margin-bottom: 20px;" :class="index == memberIndex?'memberLClassIndex':''" @click="memberIndex = index">
                <p>{{item.time}}年</p>
                <span>{{item.price}}元</span>
              </div>
            </el-col>

          </el-row>
          <div slot="footer" class="dialog-footer">
            <el-button style="width: 80%;margin:30px auto 0;display: block;" type="primary" @click="huiyuan()">确 定</el-button>
          </div>
        </div>
        <div v-else class="memberPayment">
          <div class="memberPayment_on">
            <div @click="() => {memberCode.paymentType = 1;payment()}"><img src="../../public/NewImage/wx.png" alt="" srcset="">微信支付</div>
            <div @click="() => {memberCode.paymentType = 2;payment()}"><img src="../../public/NewImage/zfb.png" alt="" srcset="">支付宝支付</div>
          </div>
          <div id="qrcode" class="memberPayment_code" ref="qrcode" style="width: 432px;margin: 48px auto;"></div>
          <div class="memberPayment_on">
            <el-button type="primary" @click="checkPayment()">已支付</el-button>
            <el-button plain @click="payment()">重新支付</el-button>
          </div>
        </div>
      </el-dialog>
      <div id="zfb"></div>
    </div>
  </div>
</template>

<script>
import ListFiles from "@/components/general/ListFiles.vue";
import Graphiclist from "@/components/general/Graphiclist.vue";
import myPurchase from "@/components/general/myPurchase.vue";
import mySupply from "@/components/general/mySupply.vue";
import 'swiper/css/swiper.css'  //引入swiper样式
import Swiper from 'swiper';	//引入swiper
import request from '@/api/request.js';
import { number } from "echarts";
import QRCode from 'qrcodejs2'
let mySwiper;
export default {
  components: {ListFiles,Graphiclist,myPurchase,mySupply},
  data() {
    return {
      toke:window.localStorage.getItem('code'),
      id:'',
      headerObj:{'Content-Type':'application/x-www-form-urlencoded'},
      individual:{},
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      zxc:{aa1:"",aa2:"",aa3:""},
      Adform:{ad1:"",ad2:"",ad3:"",ad4:"300"},
      tableData: [],
      list: ["首页","修改资料","修改密码","我的报名","我的下载","我的会员","我的课程","我的广告","资料上传","我的采购","我的供应"],
      listindex:0,
      label:[{label:'首页',name:'HomePage'}],
      dialogFormVisible: false,
      registerForm: { is_ajax:1, data:{nickname: '',name: '',desc: '',company: '',zhiwei: ''}},
      passwordForm:{password:'',password2:'',password3:''},
      nameform:{ name:'', name1:'', introduce:'', company:'', position:'', area:'0' },
      Member:[],
      column:[],
      Twocolumn:[],
      courseList:[],
      form:{},
      fileList:[],
      columnIdx:0,
      huiyuantype:0,
      formLabelWidth: '120px',
      advertisement:0,
      TypeButton:{type:false,title:''},
      columnlist:[],
      memberForm:{MainColumn:'',class:'',PurchaseTime:'',TotalCost:''},
      AdvertisingAddition:[],
      advertisementList:[],
      DataColumn:[],
      zlist:[],
      fileListaaaa:{},
      memberform:{oneregion:'',tworegion:''},
      memberList:[],
      memberIndex:null,
      memberCode:{ type:false, order_id:0, paymentType:0, ttppee:1 }
    };
  },
  mounted(){
    setTimeout(() => {
      mySwiper = new Swiper('.Individual_title_right_in',{
          //配置分页器内容
          setWrapperSize :true,
          slidesPerView: 'auto',
          slideToClickedSlide: true,//设置为true则点击slide会过渡到这个slide。
          slidesOffsetBefore: 0,
          slidesOffsetAfter: 0,
      })
    },400)
    this.individual = JSON.parse(window.localStorage.getItem("individual"));
    this.id = JSON.parse(window.localStorage.getItem("individual")).id;
    this.form ={
      is_ajax:1,
      ...JSON.parse(window.localStorage.getItem("individual"))
    };
    this.zxcascolumn();
    this.columssss();
  },
  methods:{
    modifyData(){
      let that = this;
      request.modifyData(that.registerForm,window.localStorage.getItem('code'),window.localStorage.getItem('id')).then((res) => {
        if(res.data.code == 1){
          that.individual.nickname = that.registerForm.data.nickname;
          that.individual.name = that.registerForm.data.name;
          that.individual.desc = that.registerForm.data.desc;
          that.individual.company = that.registerForm.data.company;
          that.individual.zhiwei = that.registerForm.data.zhiwei;
          window.localStorage.setItem('individual',JSON.stringify(that.individual))
          that.$message({
            message: '修改完成',
            type: 'success'
          });
          setTimeout(() =>{
            this.listindex = 0
            mySwiper.slideTo(this.listindex, 100, false);
          },1000)
        }else{
          that.$message.error(res.data.msg);
        }
      })
    },
    ChangePassword(){
      request.ChangePassword(this.passwordForm,window.localStorage.getItem('code'),window.localStorage.getItem('id')).then((res) => {
        if(res.data.code == 1){
          this.$message({
            message: '修改完成',
            type: 'success'
          });
          setTimeout(() =>{
            this.listindex = 0
            mySwiper.slideTo(this.listindex, 100, false);
          },1000)
        }else{
          this.$message.error(res.data.msg);
        }
      })
    },
    zxcascolumn(){
      let arr = [];
      request.column(0).then((res) => {
        this.column = res.data.data
      })
    },
    columssss(){
      request.memberColumn(0,1,0).then((res) => {
        if(res.data.code == 1){
          this.columnlist = res.data.data
        }
      })
    },
    lgrzxidx(idx){
      this.listindex = idx;
      this.TypeButton.type = false;
      if(idx == 0){
        this.label = [{label:'首页',name:'0'}]
      }else if(idx == 1){
        this.label = [{label:'修改资料',name:'0'}]
        this.form = {
          is_ajax:1,
          ...JSON.parse(window.localStorage.getItem("individual"))
        };
      }else if(idx == 2){
        this.label = [{label:'修改密码',name:'0'}]
      }else if(idx == 3){
        request.myRegistration(60,window.localStorage.getItem('code'),window.localStorage.getItem('id')).then((res) => {
          if(res.data.code == 1){
            this.tableData = res.data.data
          }else{
            this.$message.error(res.data.msg);
          }
        })
      }else if(idx == 4){
        request.myMember(window.localStorage.getItem('code'),window.localStorage.getItem('id')).then((res) => {
          if(res.data.code == 1){
            this.tableData = res.data.data
          }else{
            this.$message.error(res.data.msg);
          }
        })
      }else if(idx == 5){
        this.label = [{label:'我的会员',name:'0'}];
        this.TypeButton = {type:true,title:'开通会员'};
        this.individual = {
          MainColumn:'',
          class:'',
          PurchaseTime:'',
          TotalCost:''
        }
        this.openingRecord()
      }else if(idx == 6){
        this.label = [
          {label:'我的课程',name:'0'},
        ];
        this.mydownload()
      }else if(idx == 7){
        this.label = [
          {label:'我的广告',name:'0'},
        ]
        this.TypeButton = {type:true,title:'添加广告'};
        this.myAD()
      }else if(idx == 8){
        this.label = [
          {label:'我的资料',name:'0'},
        ]
        this.myDataColumn()
      }
    },
    TypeButtonClick(){
      if(this.TypeButton.title == '开通会员'){
        this.dialogFormVisible = true;
        this.memberCode.type = false;
        this.huiyuantype = false;
        this.memberform = {
          type:false,
          order_id:0,
          paymentType:0
        };
        this.membershipFeeList()
      }else if(this.TypeButton.title == '添加广告'){this.advertisement = 2;};
    },
    membershipFeeList(){
      request.membershipFeeList().then((res) => {
        if(res.data.code == 1){
          this.memberList = res.data.data
        }
      })
    },
    TotalCostClick(){
      if(this.memberForm.MainColumn != '' && this.memberForm.class != '' && this.memberForm.PurchaseTime != ''){
        let num = '';
        this.columnlist.forEach(item => {if(item.id == this.memberForm.MainColumn){num = item.price}})
        this.memberForm.TotalCost = Number(num)*this.memberForm.PurchaseTime;
      }
    },
    buying(){
      request.BuyAdvertising({
        is_ajax:1,
        data:{
          category_id:this.memberForm.MainColumn,
          buy_time:this.memberForm.PurchaseTime,
          level:this.memberForm.class,
          price:this.memberForm.TotalCost
        }
      }).then((res) => {
        if(res.data.code == 1){
          this.$message({
            message: '添加成功',
            type: 'success'
          });
          this.TypeButton.type = false;
          this.advertisement = 0;
          this.lgrzxidx(7)
        }else{
          this.$message.error(res.data.msg);
        }
      })
    },
    oneregion(event){
      request.memberColumn(event,0,0).then((res) => {
        if(res.data.code == 1){
          this.Twocolumn = res.data.data;
          this.memberform.tworegion = '';
        }else{
          this.$message.error(res.data.msg);
        }
      })
    },
    myAD(){
      request.myAD().then((res) => {
        if(res.data.code == 1){
          if(res.data.data.length > 0){
            this.advertisement = 1;
            this.advertisementList = res.data.data
          }else{
            this.advertisement = 0;
            this.memberForm = {MainColumn:'',class:'',PurchaseTime:'',TotalCost:''}
          }
        }
      })
    },
    myCourse(){
      request.myAD().then((res) => {
        if(res.data.code == 1){
          this.courseList = res.data.data
        }
      })
    },
    myDataColumn(idx){
      request.myDataColumn().then((res) => {
        if(res.data.code == 1){
          this.DataColumn = res.data.data
        }
      })
    },
    asweqwzxc(d){
      this.asdscolumn(d);
    },
    asdscolumn(idx){
      request.column(idx).then((res) => {
        this.zlist = res.data.data
      })
    },
    mydownload(){
      request.mydownload().then((res) => {
        if(res.data.code == 1){
          this.courseList = res.data.data
        }
      })
    },
    huiyuan(){
      request.openMembership({id:this.memberList[this.memberIndex].id}).then((res) => {
        if(res.data.code == 1){
          this.memberCode.type = true;
          this.memberCode.order_id = res.data.data.order_id;
          this.memberCode.paymentType = 1
          this.payment();
        }
      })
    },
    payment(){
      if(document.getElementById('qrcode') != null){
        document.getElementById('qrcode').innerHTML = '';
      }
      request.payment({
        order_id:this.memberCode.order_id,
        client:1,
        pay_type:this.memberCode.paymentType,
        ttppee:this.memberCode.ttppee
      }).then((res) => {
        if(this.memberCode.paymentType == 1){
          let qrcode = new QRCode('qrcode', {
             width: 432, // 设置宽度，单位像素
             height: 432, // 设置高度，单位像素
             text: res.data.data // 设置二维码内容或跳转地址
          })
        }else{
          const div = document.getElementById('zfb')
          div.innerHTML = res.data.data
          document.body.appendChild(div)
          document.forms[0].acceptCharset = 'UTF-8' //保持与支付宝默认编码格式一致，如果不一致将会出现：调试错误，请回到请求来源地，重新发起请求，错误代码 invalid-signature 错误原因: 验签出错，建议检查签名字符串或签名私钥与应用公钥是否匹配
          document.forms[0].submit()
        }
      })
    },
    checkPayment(){
      request.checkPayment({paylog_id:this.memberCode.order_id}).then((res) => {
        if(res.data.code == 1){
          this.$message({
            message: res.data.msg,
            type: 'success'
          });
          this.memberCode.type = false;
          if(document.getElementById('qrcode') != null){
            document.getElementById('qrcode').innerHTML = '';
          }

        }else{
          this.$message.error(res.data.msg);
        }
      })
    },
    openingRecord(){
      request.openingRecord().then((res) => {
        if(res.data.code == 1){
          this.Member = res.data.data;
          if(res.data.data.length > 0){
            this.TypeButton.type = false;
          }
        }else{
          this.$message.error(res.data.msg);
        }
      })
    },
    onSuccess(response, file, fileList) {
      if(response.code == 0){
        this.$message.error(response.msg);
        this.fileList = []
      }else{
        this.zxc.aa3 = response.data.file_id;
        this.fileList = fileList;
        this.fileListaaaa = response.data;
      }
    },
    asdasdwqeqw(){
      let dat = {};
      if(this.zxc.aa1 == ''){this.$message.error('请选择主栏目');
      }else if(this.zxc.aa2 == ''){this.$message.error('请选择子栏目');
      }else if(this.zxc.fileListaaaa == {}){this.$message.error('请选择上传资料');
      }else{
        dat = {
          cate1:this.zxc.aa1,
          cate2:this.zxc.aa2,
          file_id:this.fileListaaaa.file_id,
          file_name:this.fileListaaaa.file_name,
        }
        request.tjzl(dat,window.localStorage.getItem('code'),window.localStorage.getItem('id')).then((res) => {
          if(res.data.code == 1){
            this.$message({
              message: '提交成功',
              type: 'success'
            });
            this.zxc.aa1 = '';
            this.zxc.aa2 = '';
            this.zxc.aa3 = '';
            this.fileList = [];
            this.fileListaaaa = {};
          }else{
            this.$message.error(res.data.msg)
          }
        })
      }
    },
    xufei(e){
      request.renew({
        vip_id:e.id,
      }).then((res) => {
        if(res.data.code == 1){
          this.dialogFormVisible = true;
          this.memberCode.type = false;
          this.huiyuantype = false;
          this.memberform = {
            type:true,
            order_id:res.data.data.order_id,
            paymentType:1
          };
        }
      })

    },
    exitAccount(){
      this.logoasdas();
      window.localStorage.removeItem('code');
      window.localStorage.removeItem('individual');
      setTimeout(() => {
        this.$router.push({ path:'/logo',query:{type:1} })
      },100)
    },
  }
};
</script>

<style lang="scss" scoped>
.Individual_in_1_but{display: none;}
.CreateNews {
  min-width: 1200px;
  width: 73%;
  margin: 0px auto;
  padding-top: 40px;
  background: #fff;
  overflow: hidden;
  min-height: 72vh;
  .CreateNews_in {
    width: 90%;
    margin: auto;
  }
}
.Individual_in{
  box-shadow: 0px 2px 6px 2px rgba(181,181,181,0.5);
  border-radius: 11px;
  margin-bottom: 60px;
}
.Individual_title {
  height: 95px;
  line-height: 95px;
  overflow: hidden;
  h4{
    font-size: 24px;
    font-family: PingFangSC-Semibold, PingFang SC;
    color: #2C7CD7;
  }
  .Individual_title_right{
    float: right;
    font-size: 0;
    width: 60%;
    .Individual_title_right_in{
      width: 100%;overflow: hidden;
       transform: rotateZ( 0deg );
    }
    div{
      display: inline-block;
      vertical-align: top;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #080808;
      margin-left: 5px;
      cursor: pointer;
    }
    .Individual_title_right_color{
      color: #2C7CD7;
    }
  }
}
.Individual_tab{
  width: 94%;
  margin: auto;
  line-height: 54px;
  position: relative;
  overflow: hidden;
  min-height: 90px;
  .Individual_tab1{width: 100%;}
  .Individual_tab2{
    position: absolute;
    right: 0;
    top: 0;
    bottom: 11px;
    margin: auto;
    width: 105px;
    height: 35px;
    line-height:0px;
    z-index: 1000 !important;
  }
}
.Individual_in_1_from{
  width: 750px;
  margin: auto;
  padding: 65px 0;
}
.Individual_in_table,.Individual_in_4{
  width: 94%;
  margin: auto;
  padding-bottom: 64px;
}
.Individual_in_5{
  height: 205px;
  position: relative;
  background: #EEF9FF;
  border-radius: 8px;
  margin-bottom: 12px;
  h4{
    line-height: 67px;
    margin-left: 30px;
    font-size: 22px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #080808;
  }
  .Individual_in_5_bot{
    text-align: right;
    img{
      width: 144px;
      height: 117px;
      margin-right: 22px;
    }
  }
  .Individual_in_5_bottom{
    position: absolute;
    bottom: 28px;
    left: 35px;
    p{
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #747474;
      line-height: 20px;
    }
    span{
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #2C7CD7;
      line-height: 20px;
      cursor: pointer;
    }
  }
}
.Graphiclist{
    margin-top: 20px;
    border-bottom: 1px solid #D7D7D7;
    cursor: pointer;
    .img{
      width: 100%;
      height: 144px;
      overflow: hidden;
      margin-bottom: 0;
      img{
        width: 100%;
        transition: all .5s ease .1s;
      }
    }
    .img:hover > img{
      transform: scale(1.3);
    }
    h4{
      height: 52px;
      display: flex;
      align-items: center;
      div{
        margin: 0;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #060606;
      }
    }
    div{
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #A8A8A8;
      line-height: 17px;
      margin: 0px auto 8px;
      p{
        float: right;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #F10000;
        line-height: 22px;
        span{
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          line-height: 17px;
          font-size: 12px;
        }
      }
    }

  }
.temporarilyAbsent{
  padding: 10vh 0 40vh;
  text-align: center;
  p{
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #080808;
    line-height: 25px;
    padding: 46px 0;
  }
}
.temporarilyAbsentAdd{
  width: 90%;
  padding: 30px;
  background: rgba(215,233,255,.52);
  overflow: hidden;
  margin:10px auto 30vh;
}
.picture-card{
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #343434;
  line-height: 25px;
  span{
    vertical-align: bottom;
  }
  .picture-card_left{
    width: 54px;
    height: 54px;
    border-radius: 8px;
    border: 1px solid #D5D5D5;
    text-align: center;
    line-height: 54px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 12px;
    cursor: pointer;
  }
}
.sxxs{
  width: 80%;
  margin: auto;
  min-width: 700px;
  height: 60vh;
  .el-button{float: right !important;}
}
.memberLClass{
  border: 1px solid #BCBCBC;
  text-align: center;
  padding: 6px;
  cursor: pointer;
  p{
    line-height: 24px;
  }
}
.memberLClass:hover,.memberLClassIndex{
  background-color: #2C7CD7;
  color: #fff;
  border-color: #2C7CD7;
}
.memberPayment_on{
  text-align: center;
  line-height: 60px;
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #282828;
  img{
    height: 60px;
    margin-right: 10px;
    vertical-align: middle;
  }
  div{
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
  }
  div:nth-child(1){margin-right: 50px;}
}
@media screen and (max-width:1200px) {
  .CreateNews{
    width: 90%;
    min-width: auto;
    margin-top: 120px;
  }
  .Individual_in_1_from{
    width: 90%;
  }
  .sxxs{
    width: 80%;
    margin: auto;
    min-width: 700px;
    .el-col-12{width: 100% !important;}
  }
}
::v-deep{
  .zxc{
    width: 90%;
    margin: auto;
    padding: 20px 0 40vh;
    .el-form,.picture-card,.centerzxc{width: 50%;}
    .centerzxc{
      margin-top: 140px;
      text-align: center;
      .centerzxc_but{
        width: 245px;
        height: 60px;
        line-height: 0;
        margin-right: 65px;
      }
    }
  }
  @media screen and (max-width:1200px) {
    .zxc{
      .el-form,.picture-card,.centerzxc{width: 100%;}
    }
    .Individual_in_1{overflow: hidden;}
    .Individual_in_1_but{
      display: block;margin:0 auto 65px;
      color: #2C7CD7 !important;
      border-color:#2C7CD7 !important;
      background-color: rgba(44, 124, 215, 0.1) !important;
          width: 70%;
          padding: 14px 0px;
          font-size: 20px;
    }
  }
  .el-tabs__item.is-active,.el-tabs__item:hover{
    color: #2C7CD7;
  }
  .el-tabs__active-bar{
    background: #2C7CD7;
  }
  .el-button--primary ,.el-button--primary:hover, .el-button--primary:focus{
      color: #FFFFFF;
      background-color: #2C7CD7;
      border-color: #2C7CD7;
  }
  .el-table th{
    background: #F5F5F5;
  }
  .el-dialog__header{text-align: center;}
  .el-input-group__append{border-left: 0;}
  .el-input.is-disabled .el-input__inner{border-right: 0;}
}
</style>

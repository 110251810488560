<template>
  <div>
    <div class="CreateNews_val">
          <div class="CreateNews_val_list">
            <div v-for="(i,idx) in users" :key="idx" @click="ccc(idx)">{{i.title}}</div>
          </div>
          <div class="CreateNews_advertising" v-if="advertisingList.length > 0">
            <a v-for="(d, i) in advertisingList" :key="i" @click="advertisement(d.wzid)" class="Information_Advertising">
              <img :src="d.img" style="width: 100%;height: 100%;display: block;" />
              <!-- <p>{{d.content}}</p> -->
            </a>
          </div>
        </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
    }
  },
  props:["users", "advertisingList", "lableName"],
  methods:{
    ccc(idx){
      if(this.$route.path == '/ListIndustries'){
        this.$router.push({
            path:'/DetailsConsultation',
            query:{...this.$route.query,id:this.users[idx].id}
        })
      }else if(this.$route.path == '/FindListInformation' && this.$route.query.type == 0){
        this.$router.push({
            path:'/DetailsConsultation',
            query:{...this.$route.query,id:this.users[idx].id}
        })
      } else if (this.$route.path == '/FindListInformation' && this.$route.query.type == 4) {
        if (this.users[idx].category_name == '投资事件' || this.users[idx].category_name == '募资事件' || this.users[idx].category_name == '投资人说') {
        this.$router.push({
            path:'/DetailsConsultation',
            query:{...this.$route.query,id:this.users[idx].id}
        })
        } else {
          this.$router.push({
            path:'/InvestmentDetailsChange',
            query:{...this.$route.query,id:this.users[idx].id}
          })
        }
      } else if (this.$route.path == '/FindListInformation' && this.$route.query.type == 6) {
        if (this.users[idx].category_name == '省市政策' || this.users[idx].category_name == '园区政策' || this.users[idx].category_name == '招商引资') {
        this.$router.push({
            path:'/vueoffice',
            query:{...this.$route.query,id:this.users[idx].id}
        })
        } else {
        this.$router.push({
            path:'/InvestmentDetailsChange',
            query:{...this.$route.query,id:this.users[idx].id}
        })
        }
      }
    },
    advertisement(id){
      this.$router.push({ path:'/videoDetails',query:{ wzid:id }})
    },
  }
}
</script>

<style lang="scss" scoped>
.CreateNews_val{
  display: flex;
  min-height: 50vh;
  .CreateNews_advertising{
    width: 240px;
    margin: 20px 20px 0 0;
  }
  .Information_Advertising{
    width: 100%;
    height: 273px;
    display: block;
    margin-bottom: 30px;
    position: relative;
    cursor: pointer;
    p{
      position: absolute;
      font-size: 12px;
      width: 92%;
      bottom: 5%;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
  .CreateNews_val_list{
    width: calc(100% - 20px);
    margin-right: 20px;
    div{
      height: 65px;
      line-height: 65px;
      font-size: 14px;
      border-bottom: 1px solid #E7E6E6;
      overflow: hidden;
      text-overflow:ellipsis;
      white-space: nowrap;
      transition: all 0.3s;
      cursor: pointer;
    }
    div:hover{
      color: #2C7CD7;
    }
  }
}

::v-deep {
  .pagination{
    text-align: center;
    display: block;
    margin:0 auto 50px;
  }
  @media screen and (max-width:1200px) {
    .CreateNews_advertising,.pagination{display: none !important;}
    .el-pagination{
      padding: 0;
      margin: 20px 0;
    }
  }
}
</style>

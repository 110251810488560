<template>
  <div class="settlingProcess">
    <el-backtop />
    <div class="settlingProcess_top">
      <img src="../../public/NewImage/enter.png" alt="">
      <h4>企业入驻流程</h4>
      <div class="settlingProcess_top_liu">
        <div class="settlingProcess_top_liu_in">
          <i></i>
          <div class="settlingProcess_top_liu_in_list" v-for="(d,i) in enterTitle" :key="i" @click="flowClick(i)">
            <div>{{i+1}}</div>
            <p :class="flowIndex == i?'settlingProcess_top_liu_in_list_p':''">{{d.name}}</p>
            <span :class="d.required?'settlingProcess_top_liu_in_list_span':''">{{d.required?'（必填）':'选填'}}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="settlingProcess_tit">
      <div>
        <img src="../../public/img/bottom.png" alt="">
        <span>车找采</span>
      </div>
    </div> -->
    <div class="settlingProcess_Back">
      <!-- <button class="settlingProcess_Back_but" @click="Back()"><i class="el-icon-back"></i>返回</button> -->
      <el-breadcrumb class="settlingProcess_breadcrumb" separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>入驻流程</el-breadcrumb-item>
        <el-breadcrumb-item><span style="color: #0289EF;">{{enterTitle[flowIndex].name}}</span></el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div v-if="flowIndex == 0">
      <div class="settlingProcess_title"><img src="../../public/img/img17.png" alt="">基本信息</div>
      <div class="settlingProcess_form">
        <el-form :model="enterFlow.basic" :rules="enterFlow.rules" ref="ruleForm0" :validate-on-rule-change="false" label-width="94px" class="demo-ruleForm"  size="small">
          <el-row>
            <el-col :span="8"><el-form-item class="settlingProcess_form_item" label="公司名称" prop="title"><el-input placeholder="请输入公司名称" v-model="enterFlow.basic.title" style="width: 100%;"></el-input></el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item class="settlingProcess_form_item" label="企业认证">
                <!-- <el-input placeholder="请输入企业认证" v-model="enterFlow.basic.qiyerenzheng" style="width: 100%;"></el-input> -->
                <el-select v-model="enterFlow.basic.qiyerenzheng" placeholder="请选择企业认证">
                  <el-option label="本公司已认证" value="本公司已认证"></el-option>
                  <el-option label="本公司未认证" value="本公司未认证"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8"><el-form-item class="settlingProcess_form_item" label="英文名称"><el-input placeholder="请输入英文名称" v-model="enterFlow.basic.ywmc" style="width: 100%;"></el-input></el-form-item>
            </el-col>
            <el-col :span="8"><el-form-item class="settlingProcess_form_item" label="公司简称"><el-input placeholder="请输入公司简称" v-model="enterFlow.basic.gsjc" style="width: 100%;"></el-input></el-form-item>
            </el-col>
            <el-col :span="8"><el-form-item class="settlingProcess_form_item" label="产业类型"><el-input placeholder="请输入产业类型" v-model="enterFlow.basic.cylx" style="width: 100%;"></el-input></el-form-item>
            </el-col>
            <el-col :span="8"><el-form-item class="settlingProcess_form_item" label="中外公司"><el-input placeholder="请输入中外公司" v-model="enterFlow.basic.zwgs" style="width: 100%;"></el-input></el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item class="settlingProcess_form_item" label="机构性质" prop="jigouxingzhi">
                <el-select v-model="enterFlow.basic.jigouxingzhi" placeholder="请输入机构性质" style="width: 100%;">
                  <el-option label="股份公司" value="股份公司" />
                  <el-option label="有限公司" value="有限公司" />
                  <el-option label="无限公司" value="无限公司" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item class="settlingProcess_form_item" label="公司称号">
                <el-select v-model="enterFlow.basic.gsch" placeholder="请输入公司称号" style="width: 100%;">
                  <el-option label="独角兽" value="独角兽" />
                  <el-option label="千里马" value="千里马" />
                  <el-option label="小巨人" value="小巨人" />
                  <el-option label="瞪羚" value="瞪羚" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item class="settlingProcess_form_item" label="资本阶段">
                <el-select v-model="enterFlow.basic.zibenjieduanh" placeholder="请输入资本阶段" style="width: 100%;">
                  <el-option label="A轮" value="A轮" />
                  <el-option label="B轮" value="B轮" />
                  <el-option label="C轮" value="C轮" />
                  <el-option label="D轮" value="D轮" />
                  <el-option label="E轮" value="E轮" />
                  <el-option label="F轮" value="F轮" />
                  <el-option label="G轮" value="G轮" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item class="settlingProcess_form_item" label="高新企业">
                <el-select v-model="enterFlow.basic.jishuleibie" placeholder="请输入高新企业" style="width: 100%;">
                  <el-option label="国家级" value="国家级" />
                  <el-option label="省级" value="省级" />
                  <el-option label="地市级" value="地市级" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item class="settlingProcess_form_item" label="专家特新">
                <el-select v-model="enterFlow.basic.zhuanjingts" placeholder="请输入专家特新" style="width: 100%;">
                  <el-option label="国家级" value="国家级" />
                  <el-option label="省级" value="省级" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item class="settlingProcess_form_item" label="上市公司">
                <el-select v-model="enterFlow.basic.shangshigs" placeholder="请输入上市公司" style="width: 100%;">
                  <el-option label="上交所" value="国家级" />
                  <el-option label="深交所" value="深交所" />
                  <el-option label="北交所" value="北交所" />
                  <el-option label="港交所" value="港交所" />
                  <el-option label="纽交所" value="纽交所" />
                  <el-option label="纳斯达克" value="纳斯达克" />
                  <el-option label="新三版" value="新三版" />
                  <el-option label="其他" value="其他" />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8"><el-form-item class="settlingProcess_form_item" label="成立时间" prop="clsj">
                <el-date-picker
                  placeholder="请选择成立时间"
                  style="width: 100%;"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  v-model="enterFlow.basic.clsj"
                  type="date">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8"><el-form-item class="settlingProcess_form_item" label="机构总部">
              <el-input placeholder="请输入机构总部" v-model="enterFlow.basic.jigouzongbu" style="width: 100%;"></el-input></el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item class="settlingProcess_form_item" label="公司视频（文件）">
                <el-upload
                  class="upload-demo"
                  :action="`https://admin.aiauto.shop/index.php?v=1&appid=2&appsecret=CHEZHUANGTOUE921805707FA3&s=api&c=file&m=upload&api_auth_code=${toke}&api_auth_uid=${id}&siteid=1&fid=175`"
                  multiple
                  :limit="1"
                  :file-list="fileList"
                  :on-success="(response, file, fileList) => {
                    return onSuccess(response, 'enterFlow.basic');
                  }">
                  <el-button size="small" type="primary">点击上传</el-button>
                </el-upload>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item class="settlingProcess_form_item" label="注册地址" prop="dizhi">
                <el-cascader size="small" :options="pcaTextArr" v-model="enterFlow.basic.dizhi" style="width: 100%;" />
              </el-form-item>
            </el-col>
            <el-col :span="8"><el-form-item class="settlingProcess_form_item" label="官方网址"><el-input placeholder="请输入官方网址" v-model="enterFlow.basic.gfwz" style="width: 100%;"></el-input></el-form-item>
            </el-col>
            <el-col :span="8"><el-form-item class="settlingProcess_form_item" label="法定代表人" prop="fddbr"><el-input placeholder="请输入法定代表人" v-model="enterFlow.basic.fddbr" style="width: 100%;"></el-input></el-form-item>
            </el-col>
            <el-col :span="8"><el-form-item class="settlingProcess_form_item" label="主要客户"><el-input placeholder="请输入主要客户" v-model="enterFlow.basic.zhuyaokehu" style="width: 100%;"></el-input></el-form-item>
            </el-col>
            <el-col :span="8"><el-form-item class="settlingProcess_form_item" label="企业荣誉"><el-input placeholder="请输入企业荣誉" v-model="enterFlow.basic.qiyerongyu" style="width: 100%;"></el-input></el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="settlingProcess_title"><img src="../../public/img/img17.png" alt="">企业概况</div>
      <el-input
        class="settlingProcess_input"
        type="textarea"
        :autosize="{ minRows: 60, maxRows: 3}"
        placeholder="请输入内容"
        v-model="enterFlow.basic.qiyegaikuang">
      </el-input>
      <div class="settlingProcess_title"><img src="../../public/img/img17.png" alt="">工商注册</div>
      <div class="settlingProcess_Industry settlingProcess_form_Sign">
        <el-row>
          <el-col :span="12">
            <div class="settlingProcess_Industry_name"><p>企业名称</p></div>
            <input placeholder="请输入企业名称" v-model="enterFlow.jobRegistration.title" class="settlingProcess_Industry_input">
          </el-col>
          <el-col :span="12">
            <div class="settlingProcess_Industry_name"><p>英文名称</p></div>
            <input placeholder="请输入英文名称" v-model="enterFlow.jobRegistration.ywmc" class="settlingProcess_Industry_input">
          </el-col>
          <el-col :span="12">
            <div class="settlingProcess_Industry_name"><p>注册资本</p></div>
            <input placeholder="请输入注册资本" v-model="enterFlow.jobRegistration.zhuceziben" class="settlingProcess_Industry_input" :pattern="'^\\d+(\\.\\d{0,2})?$'">
          </el-col>
          <el-col :span="12">
            <div class="settlingProcess_Industry_name"><p>实缴资本</p></div>
            <input placeholder="请输入实缴资本" v-model="enterFlow.jobRegistration.shijiaoziben" class="settlingProcess_Industry_input" :pattern="'^\\d+(\\.\\d{0,2})?$'">
          </el-col>
          <el-col :span="12">
            <div class="settlingProcess_Industry_name"><p>公司类型</p></div>
            <input v-model="enterFlow.jobRegistration.qiyeleixing" placeholder="请输入" class="settlingProcess_Industry_input">
          </el-col>
          <el-col :span="12">
            <div class="settlingProcess_Industry_name"><p>成立时间</p></div>
            <el-date-picker
              class="settlingProcess_Industry_input"
              v-model="enterFlow.jobRegistration.clsj"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="选择日期">
            </el-date-picker>
          </el-col>
          <el-col :span="12">
            <div class="settlingProcess_Industry_name"><p>法定代表人</p></div>
            <input placeholder="请输入法定代表人" v-model="enterFlow.jobRegistration.fddbr" class="settlingProcess_Industry_input">
          </el-col>
          <el-col :span="12">
            <div class="settlingProcess_Industry_name"><p>营业期限</p></div>
            <el-date-picker
              class="settlingProcess_Industry_input"
              v-model="enterFlow.jobRegistration.yingyeqixian"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="选择日期">
            </el-date-picker>
          </el-col>
          <el-col :span="12">
            <div class="settlingProcess_Industry_name"><p>工商注册代码</p></div>
            <input placeholder="请输入工商注册代码" v-model="enterFlow.jobRegistration.gszcdm" class="settlingProcess_Industry_input">
          </el-col>
          <el-col :span="12">
            <div class="settlingProcess_Industry_name"><p>经营状态</p></div>
            <input placeholder="请输入经营状态" v-model="enterFlow.jobRegistration.jyzk" class="settlingProcess_Industry_input">
          </el-col>
          <el-col :span="12">
            <div class="settlingProcess_Industry_name"><p>所属行业</p></div>
            <input placeholder="请输入所属行业" v-model="enterFlow.jobRegistration.xingye" class="settlingProcess_Industry_input">
          </el-col>
          <el-col :span="12">
            <div class="settlingProcess_Industry_name"><p>所属省市</p></div>
            <input placeholder="请输入所属省市" v-model="enterFlow.jobRegistration.sssf" class="settlingProcess_Industry_input">
          </el-col>
          <el-col :span="12">
            <div class="settlingProcess_Industry_name"><p>登记机关</p></div>
            <input placeholder="请输入登记机关" v-model="enterFlow.jobRegistration.dengjijiguan" class="settlingProcess_Industry_input">
          </el-col>
          <el-col :span="12">
            <div class="settlingProcess_Industry_name"><p>登记时间</p></div>
            <el-date-picker
              class="settlingProcess_Industry_input"
              v-model="enterFlow.jobRegistration.djsj"
              format="yyyy-MM-dd"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择日期">
            </el-date-picker>
          </el-col>
          <el-col :span="24">
            <div class="settlingProcess_Industry_name"><p>注册地址</p></div>
            <input placeholder="请输入注册地址" v-model="enterFlow.jobRegistration.zhucedidian" class="settlingProcess_Industry_input">
          </el-col>
          <el-col :span="24">
            <div class="settlingProcess_Industry_name settlingProcess_Industry_addree"><p>经营范围</p></div>
            <textarea rows="10" cols="30" placeholder="请输入经营范围" v-model="enterFlow.jobRegistration.jyfw" class="settlingProcess_Industry_input settlingProcess_Industry_addree_input">
            </textarea>
          </el-col>
        </el-row>
      </div>
      <div class="settlingProcess_title"><img src="../../public/img/img17.png" alt="">股权结构</div>
      <div class="settlingProcess_form">
        <el-table
          stripe
          :cell-style="tableStyle"
          :data="enterFlow.ownershipStructure"
          style="width: 95%;margin: auto;">
          <el-table-column type="index" label="序号" width="50"></el-table-column>
          <el-table-column label="公司名称">
            <template slot-scope="scope">
              <el-input v-model="scope.row.title" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="投资金额">
            <template slot-scope="scope">
              <el-input v-model="scope.row.shijiaojine" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="所占股份">
            <template slot-scope="scope">
              <el-input v-model="scope.row.suozhangubi" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="结构图" width="120">
            <template slot-scope="scope">
              <el-upload
                class="avatar-uploader"
                :action="`https://admin.aiauto.shop/index.php?v=1&appid=2&appsecret=CHEZHUANGTOUE921805707FA3&s=api&c=file&m=upload&api_auth_code=${toke}&api_auth_uid=${id}&siteid=1&fid=175`"
                multiple
                :show-file-list="false"
                :on-success="(response, file, fileList) => {
                  return onSuccess(response, 'enterFlow.ownershipStructure.jiegoutu', scope.$index);
                }">
                <img v-if="scope.row.jiegoutuSrc" :src="scope.row.jiegoutuSrc" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </template>
          </el-table-column>
          <el-table-column label="饼状图" width="120">
            <template slot-scope="scope">
              <el-upload
                class="avatar-uploader"
                :action="`https://admin.aiauto.shop/index.php?v=1&appid=2&appsecret=CHEZHUANGTOUE921805707FA3&s=api&c=file&m=upload&api_auth_code=${toke}&api_auth_uid=${id}&siteid=1&fid=175`"
                multiple
                :show-file-list="false"
                :on-success="(response, file, fileList) => {
                  return onSuccess(response, 'enterFlow.ownershipStructure.bingzhuangtu', scope.$index);
                }">
                <img v-if="scope.row.bingzhuangtuSrc" :src="scope.row.bingzhuangtuSrc" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="60">
            <template slot-scope="scope">
              <button class="settlingProcess_form_but" @click="structureClick1(scope.$index)">
                <i :class="scope.$index == 0?'el-icon-circle-plus-outline':'el-icon-remove-outline'"></i>
              </button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="settlingProcess_title"><img src="../../public/img/img17.png" alt="">主要团队</div>
      <div class="settlingProcess_form">
        <el-table
          stripe
          :cell-style="tableStyle"
          :data="enterFlow.primaryTeam"
          style="width: 95%;margin: auto;">
          <el-table-column type="index" label="序号" width="50"></el-table-column>
          <el-table-column label="名称">
            <template slot-scope="scope">
              <el-input v-model="scope.row.title" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="职务">
            <template slot-scope="scope">
              <el-input v-model="scope.row.zhiwei" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="主管业务">
            <template slot-scope="scope">
              <el-input v-model="scope.row.zhuguanyewu" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="照片" width="120">
            <template slot-scope="scope">
              <el-upload
                class="avatar-uploader"
                :action="`https://admin.aiauto.shop/index.php?v=1&appid=2&appsecret=CHEZHUANGTOUE921805707FA3&s=api&c=file&m=upload&api_auth_code=${toke}&api_auth_uid=${id}&siteid=1&fid=175`"
                multiple
                :show-file-list="false"
                :on-success="(response, file, fileList) => {
                  return onSuccess(response, 'enterFlow.primaryTeam.txwj', scope.$index);
                }">
                <img v-if="scope.row.txwjSrc" :src="scope.row.txwjSrc" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </template>
          </el-table-column>
          <el-table-column label="简介">
            <template slot-scope="scope">
              <el-input v-model="scope.row.jianjie" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="60">
            <template slot-scope="scope">
              <button class="settlingProcess_form_but" @click="structureClick2(scope.$index)">
                <i :class="scope.$index == 0?'el-icon-circle-plus-outline':'el-icon-remove-outline'"></i>
              </button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div v-if="flowIndex == 1" class="equipment">
      <div class="settlingProcess_title"><img src="../../public/img/img17.png" alt="">工厂设备</div>
      <div class="equipment_table">
        <el-table
          stripe
          border
          :cell-style="tableStyle"
          row-key="num"
          default-expand-all
          :tree-props="{children: 'children'}"
          :data="productionManagement.plantEquipment"
          style="width: 95%;margin: auto;">
          <el-table-column label="工厂基地" width="210">
            <template slot-scope="scope">
              <div class="equipment_table_div"><p>{{scope.row.name}}</p></div>
            </template>
          </el-table-column>
          <el-table-column label="照片" width="120">
            <template slot-scope="scope">
              <el-upload
                class="avatar-uploader"
                :action="`https://admin.aiauto.shop/index.php?v=1&appid=2&appsecret=CHEZHUANGTOUE921805707FA3&s=api&c=file&m=upload&api_auth_code=${toke}&api_auth_uid=${id}&siteid=1&fid=175`"
                multiple
                :show-file-list="false"
                :on-success="(response) => {
                  return onSuccess(response, 'productionManagement.plantEquipment.jidi1tupian1Src', scope.$index, scope.row);
                }">
                <img v-if="scope.row.jidi1tupian1Src" :src="scope.row.jidi1tupian1Src" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </template>
          </el-table-column>
          <el-table-column label="文字介绍">
            <template slot-scope="scope">
              <el-input v-model="scope.row.jd1tp1wzsm" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="主要设备图片" width="120">
            <template slot-scope="scope">
              <el-upload
                class="avatar-uploader"
                :action="`https://admin.aiauto.shop/index.php?v=1&appid=2&appsecret=CHEZHUANGTOUE921805707FA3&s=api&c=file&m=upload&api_auth_code=${toke}&api_auth_uid=${id}&siteid=1&fid=175`"
                multiple
                :show-file-list="false"
                :on-success="(response) => {
                  return onSuccess(response, 'productionManagement.plantEquipment.jidi1tupian2Src', scope.$index, scope.row);
                }">
                <img v-if="scope.row.jidi1tupian2Src" :src="scope.row.jidi1tupian2Src" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </template>
          </el-table-column>
          <el-table-column label="主要设备介绍">
            <template slot-scope="scope">
              <el-input v-model="scope.row.jd1tp2wzsm" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="60">
            <template slot-scope="scope">
              <button class="settlingProcess_form_but" @click="PlantEquipment(scope.row, scope.$index)">
                <i :class="scope.row.num == 1 || (scope.row.num + '').split('')[1] == '1'?'el-icon-circle-plus-outline':'el-icon-remove-outline'"></i>
              </button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="settlingProcess_title"><img src="../../public/img/img17.png" alt="">主要产品</div>
      <div class="equipment_table equipment_table1">
        <el-table
          stripe
          border
          :cell-style="tableStyle"
          :data="productionManagement.coreProduct"
          style="width: 95%;margin: auto;">
          <el-table-column label="产品名称" width="140">
            <template slot-scope="scope">
              <el-input v-model="scope.row.title" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="照片" width="120">
            <template slot-scope="scope">
              <el-upload
                class="avatar-uploader"
                :action="`https://admin.aiauto.shop/index.php?v=1&appid=2&appsecret=CHEZHUANGTOUE921805707FA3&s=api&c=file&m=upload&api_auth_code=${toke}&api_auth_uid=${id}&siteid=1&fid=175`"
                multiple
                :show-file-list="false"
                :on-success="(response, file) => {
                  return onSuccess(response, 'productionManagement.coreProduct.thumb', scope.$index);
                }">
                <img v-if="scope.row.thumbSrc" :src="scope.row.thumbSrc" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </template>
          </el-table-column>
          <el-table-column label="文字说明">
            <template slot-scope="scope">
              <el-input v-model="scope.row.content" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="技术参数">
            <template slot-scope="scope">
              <el-input v-model="scope.row.cpsm" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="所属一级分类">
            <template slot-scope="scope">
              <el-select v-model="scope.row.ONsort" style="width: 100%;" placeholder="请选择" @change="sortChange(scope.row,'ONsort',scope.$index)">
                  <el-option
                    v-for="item in scope.row.sortOptions[0]"
                    :key="item.value"
                    :label="item.lable"
                    :value="item.value">
                  </el-option>
                </el-select>
            </template>
          </el-table-column>
          <el-table-column label="所属二级分类">
            <template slot-scope="scope">
              <el-select v-model="scope.row.TWOsort" style="width: 100%;" placeholder="请选择" @change="sortChange(scope.row,'TWOsort',scope.$index)">
                  <el-option
                    v-for="item in scope.row.sortOptions[1]"
                    :key="item.value"
                    :label="item.lable"
                    :value="item.value">
                  </el-option>
                </el-select>
            </template>
          </el-table-column>
          <el-table-column label="所属三级分类">
            <template slot-scope="scope">
              <el-select v-model="scope.row.ThreeOsort" style="width: 100%;" placeholder="请选择">
                  <el-option
                    v-for="item in scope.row.sortOptions[2]"
                    :key="item.value"
                    :label="item.lable"
                    :value="item.value">
                  </el-option>
                </el-select>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="60">
            <template slot-scope="scope">
              <button class="settlingProcess_form_but" @click="supplyChainClick3('coreProduct',scope.$index)">
                <i :class="scope.$index == 0?'el-icon-circle-plus-outline':'el-icon-remove-outline'"></i>
              </button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="settlingProcess_title"><img src="../../public/img/img17.png" alt="">3D产品模型展示</div>
      <div class="equipment_table equipment_table1">
        <el-table
          stripe
          border
          :cell-style="tableStyle"
          :data="productionManagement.Dproduct"
          style="width: 95%;margin: auto;">
          <el-table-column label="产品名称" width="140">
            <template slot-scope="scope">
              <el-input v-model="scope.row.title" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="照片" width="120">
            <template slot-scope="scope">
              <el-upload
                class="avatar-uploader"
                :action="`https://admin.aiauto.shop/index.php?v=1&appid=2&appsecret=CHEZHUANGTOUE921805707FA3&s=api&c=file&m=upload&api_auth_code=${toke}&api_auth_uid=${id}&siteid=1&fid=175`"
                multiple
                :show-file-list="false"
                :on-success="(response, file) => {
                  return onSuccess(response, 'productionManagement.Dproduct.thumb', scope.$index);
                }">
                <img v-if="scope.row.thumbSrc" :src="scope.row.thumbSrc" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </template>
          </el-table-column>
          <el-table-column label="产品介绍">
            <template slot-scope="scope">
              <el-input v-model="scope.row.cpjs" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="3D模型文件">
            <template slot-scope="scope">
              <el-upload
                class="upload-demo"
                :action="`https://admin.aiauto.shop/index.php?v=1&appid=2&appsecret=CHEZHUANGTOUE921805707FA3&s=api&c=file&m=upload&api_auth_code=${toke}&api_auth_uid=${id}&siteid=1&fid=175`"
                multiple
                :limit="1"
                :file-list="scope.row.sdmxwjSrc"
                :on-success="(response, file) => {
                  return onSuccess(response, 'productionManagement.Dproduct.sdmxwj', scope.$index);
                }">
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
            </template>
          </el-table-column>
          <el-table-column label="内容">
            <template slot-scope="scope">
              <el-input v-model="scope.row.contentj" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="60">
            <template slot-scope="scope">
              <button class="settlingProcess_form_but" @click="equipmentClick2(scope.$index)">
                <i :class="scope.$index == 0?'el-icon-circle-plus-outline':'el-icon-remove-outline'"></i>
              </button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div v-if="flowIndex == 2" class="equipment">
      <div class="settlingProcess_title"><img src="../../public/img/img17.png" alt="">研发机构</div>
      <div class="equipment_table">
        <el-table
          stripe
          border
          :cell-style="tableStyle"
          :data="researchDevelopment.institution"
          style="width: 95%;margin: auto;">
          <el-table-column label="研发机构" width="156" prop="institution">
            <template slot-scope="scope">
              <el-input v-model="scope.row.institution" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="名称">
            <template slot-scope="scope">
              <el-input v-model="scope.row.title" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="人员">
            <template slot-scope="scope">
              <el-input v-model="scope.row.personnel" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="项目">
            <template slot-scope="scope">
              <el-input v-model="scope.row.item" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="成果">
            <template slot-scope="scope">
              <el-input v-model="scope.row.achievement" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="60">
            <template slot-scope="scope">
              <button class="settlingProcess_form_but" @click="supplyChainClick3('institution', scope.$index)">
                <i :class="scope.$index == 0?'el-icon-circle-plus-outline':'el-icon-remove-outline'"></i>
              </button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="settlingProcess_title"><img src="../../public/img/img17.png" alt="">知识产权</div>
      <div class="equipment_table">
        <el-table
          stripe
          border
          :cell-style="tableStyle"
          :data="researchDevelopment.knowledge"
          style="width: 95%;margin: auto;">
          <el-table-column label="类型" width="156" prop="type">
            <template slot-scope="scope">
              <el-input v-model="scope.row.type" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="名称">
            <template slot-scope="scope">
              <el-input v-model="scope.row.title" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="授权号码">
            <template slot-scope="scope">
              <el-input v-model="scope.row.accredit" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="简要内容">
            <template slot-scope="scope">
              <el-input v-model="scope.row.value" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="60">
            <template slot-scope="scope">
              <button class="settlingProcess_form_but" @click="supplyChainClick3('knowledge', scope.$index)">
                <i :class="scope.$index == 0?'el-icon-circle-plus-outline':'el-icon-remove-outline'"></i>
              </button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="settlingProcess_title"><img src="../../public/img/img17.png" alt="">质量认证体系</div>
      <div class="equipment_table">
        <el-table
          stripe
          border
          :cell-style="tableStyle"
          :data="researchDevelopment.quality"
          style="width: 95%;margin: auto;">
          <el-table-column label="体系名称" width="156" prop="name">
            <template slot-scope="scope">
              <el-input v-model="scope.row.name" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="认证机构">
            <template slot-scope="scope">
              <el-input v-model="scope.row.jgName" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="认证编号">
            <template slot-scope="scope">
              <el-input v-model="scope.row.rzbh" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="认证时间">
            <template slot-scope="scope">
              <el-date-picker
                placeholder="请选择认证时间"
                style="width: 100%;"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                v-model="scope.row.rzsj"
                type="date">
              </el-date-picker>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="60">
            <template slot-scope="scope">
              <button class="settlingProcess_form_but" @click="supplyChainClick3('quality', scope.$index)">
                <i :class="scope.$index == 0?'el-icon-circle-plus-outline':'el-icon-remove-outline'"></i>
              </button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="settlingProcess_title"><img src="../../public/img/img17.png" alt="">其他</div>
      <div class="equipment_table">
        <el-table
          stripe
          border
          :cell-style="tableStyle"
          :data="researchDevelopment.other"
          style="width: 95%;margin: auto;">
          <el-table-column label="体系名称" width="156" prop="name">
            <template slot-scope="scope">
              <el-input v-model="scope.row.name" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="认证机构">
            <template slot-scope="scope">
              <el-input v-model="scope.row.jgName" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="认证编号">
            <template slot-scope="scope">
              <el-input v-model="scope.row.rzbh" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="认证时间">
            <template slot-scope="scope">
              <el-date-picker
                placeholder="请选择认证时间"
                style="width: 100%;"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                v-model="scope.row.rzsj"
                type="date">
              </el-date-picker>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="60">
            <template slot-scope="scope">
              <button class="settlingProcess_form_but" @click="supplyChainClick3('researchDevelopment.other', scope.$index)">
                <i :class="scope.$index == 0?'el-icon-circle-plus-outline':'el-icon-remove-outline'"></i>
              </button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div v-if="flowIndex == 3" class="equipment">
      <div class="settlingProcess_title"><img src="../../public/img/img17.png" alt="">直接配套客户</div>
      <div class="equipment_table equipment_table1">
        <el-table
          stripe
          border
          :cell-style="tableStyle"
          :data="supplyChainManage.direct"
          style="width: 95%;margin: auto;">
          <el-table-column label="直接配套客户" width="550">
            <template slot-scope="scope">
              <div class="equipment_table_div"><p>直接配套客户{{scope.$index + 1}}</p></div>
            </template>
          </el-table-column>
          <el-table-column label="">
            <template slot-scope="scope">
              <el-input v-model="scope.row.title" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="60">
            <template slot-scope="scope">
              <button class="settlingProcess_form_but" @click="supplyChainClick1(scope.$index)">
                <i :class="scope.$index == 0?'el-icon-circle-plus-outline':'el-icon-remove-outline'"></i>
              </button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="settlingProcess_title"><img src="../../public/img/img17.png" alt="">间接配套客户</div>
      <div class="equipment_table equipment_table1">
        <el-table
          stripe
          border
          :cell-style="tableStyle"
          :data="supplyChainManage.indirect"
          style="width: 95%;margin: auto;">
          <el-table-column label="间接配套客户" width="550">
            <template slot-scope="scope">
              <div class="equipment_table_div"><p>间接配套客户{{scope.$index + 1}}</p></div>
            </template>
          </el-table-column>
          <el-table-column label="">
            <template slot-scope="scope">
              <el-input v-model="scope.row.title" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="60">
            <template slot-scope="scope">
              <button class="settlingProcess_form_but" @click="supplyChainClick2(scope.$index)">
                <i :class="scope.$index == 0?'el-icon-circle-plus-outline':'el-icon-remove-outline'"></i>
              </button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="settlingProcess_title"><img src="../../public/img/img17.png" alt="">采购产品</div>
      <div class="settlingProcess_form">
        <el-table
          stripe
          :cell-style="tableStyle"
          :data="supplyChainManage.addcaigoucp"
          style="width: 95%;margin: auto;">
          <el-table-column type="index" label="序号" width="50"></el-table-column>
          <el-table-column label="采购产品">
            <template slot-scope="scope">
              <el-input v-model="scope.row.title" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="采购数量">
            <template slot-scope="scope">
              <el-input v-model="scope.row.shuliang" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="采购时间">
            <template slot-scope="scope">
              <el-date-picker
                placeholder="请选择成立时间"
                style="width: 100%;"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                v-model="scope.row.cgsj"
                type="date">
              </el-date-picker>
            </template>
          </el-table-column>
          <el-table-column label="采购方式">
            <template slot-scope="scope">
              <el-input v-model="scope.row.cgfs" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="洽谈">
            <template slot-scope="scope">
              <el-input v-model="scope.row.negotiateWith" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="60">
            <template slot-scope="scope">
              <button class="settlingProcess_form_but" @click="supplyChainClick3('addcaigoucp', scope.$index)">
                <i :class="scope.$index == 0?'el-icon-circle-plus-outline':'el-icon-remove-outline'"></i>
              </button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="settlingProcess_title"><img src="../../public/img/img17.png" alt="">仓储</div>
      <div class="settlingProcess_form">
        <el-table
          stripe
          :cell-style="tableStyle"
          :data="supplyChainManage.storage"
          style="width: 95%;margin: auto;">
          <el-table-column type="index" label="序号" width="50"></el-table-column>
          <el-table-column label="仓储地点">
            <template slot-scope="scope">
              <el-input v-model="scope.row.title" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="仓储规模">
            <template slot-scope="scope">
              <el-input v-model="scope.row.guimo" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="仓储数量">
            <template slot-scope="scope">
              <el-input v-model="scope.row.shuliang" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="仓储方式">
            <template slot-scope="scope">
              <el-input v-model="scope.row.fangshi" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="洽谈">
            <template slot-scope="scope">
              <el-input v-model="scope.row.negotiateWith" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="60">
            <template slot-scope="scope">
              <button class="settlingProcess_form_but" @click="supplyChainClick3('storage', scope.$index)">
                <i :class="scope.$index == 0?'el-icon-circle-plus-outline':'el-icon-remove-outline'"></i>
              </button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="settlingProcess_title"><img src="../../public/img/img17.png" alt="">物流</div>
      <div class="settlingProcess_form">
        <el-table
          stripe
          :cell-style="tableStyle"
          :data="supplyChainManage.materialFlow"
          style="width: 95%;margin: auto;">
          <el-table-column type="index" label="序号" width="50"></el-table-column>
          <el-table-column label="发/到货地点">
            <template slot-scope="scope">
              <el-input v-model="scope.row.locations" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="物流数量">
            <template slot-scope="scope">
              <el-input v-model="scope.row.shuliang" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="物流规模">
            <template slot-scope="scope">
              <el-input v-model="scope.row.guimo" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="物流方式">
            <template slot-scope="scope">
              <el-input v-model="scope.row.fangshi" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="洽谈">
            <template slot-scope="scope">
              <el-input v-model="scope.row.negotiateWith" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="60">
            <template slot-scope="scope">
              <button class="settlingProcess_form_but" @click="supplyChainClick3('materialFlow', scope.$index)">
                <i :class="scope.$index == 0?'el-icon-circle-plus-outline':'el-icon-remove-outline'"></i>
              </button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="settlingProcess_title"><img src="../../public/img/img17.png" alt="">进出口情况</div>
      <div class="settlingProcess_form">
        <el-table
          stripe
          :cell-style="tableStyle"
          :data="supplyChainManage.entrance"
          style="width: 95%;margin: auto;">
          <el-table-column type="index" label="序号" width="50"></el-table-column>
          <el-table-column label="进口">
            <template slot-scope="scope">
              <el-input v-model="scope.row.title" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="进口产品">
            <template slot-scope="scope">
              <el-input v-model="scope.row.cpxh" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="进口数量">
            <template slot-scope="scope">
              <el-input v-model="scope.row.cpsl" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="金额">
            <template slot-scope="scope">
              <el-input v-model="scope.row.jine" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="60">
            <template slot-scope="scope">
              <button class="settlingProcess_form_but" @click="supplyChainClick3('entrance', scope.$index)">
                <i :class="scope.$index == 0?'el-icon-circle-plus-outline':'el-icon-remove-outline'"></i>
              </button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="settlingProcess_form">
        <el-table
          stripe
          :cell-style="tableStyle"
          :data="supplyChainManage.exit"
          style="width: 95%;margin: auto;">
          <el-table-column type="index" label="序号" width="50"></el-table-column>
          <el-table-column label="出口">
            <template slot-scope="scope">
              <el-input v-model="scope.row.title" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="出口产品">
            <template slot-scope="scope">
              <el-input v-model="scope.row.cpxh" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="出口数量">
            <template slot-scope="scope">
              <el-input v-model="scope.row.cpsl" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="金额">
            <template slot-scope="scope">
              <el-input v-model="scope.row.jine" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="60">
            <template slot-scope="scope">
              <button class="settlingProcess_form_but" @click="supplyChainClick3('exit', scope.$index)">
                <i :class="scope.$index == 0?'el-icon-circle-plus-outline':'el-icon-remove-outline'"></i>
              </button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div v-if="flowIndex == 4" class="equipment">
      <div class="settlingProcess_title"><img src="../../public/img/img17.png" alt="">经营状况报表</div>
      <div class="equipment_table equipment_table1">
        <el-table
          stripe
          border
          :cell-style="tableStyle"
          :data="operatingCondition.listingAnnualReport"
          style="width: 95%;margin: auto;">
          <el-table-column type="index" label="序号" width="50"></el-table-column>
          <el-table-column label="上市公司年报">
            <template slot-scope="scope">
              <el-input v-model="scope.row.gongsiming" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="年月">
            <template slot-scope="scope">
              <el-date-picker
                placeholder="请选择年月"
                style="width: 100%;"
                class="settlingProcess_form_Sign"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                v-model="scope.row.nbnf"
                type="date">
              </el-date-picker>
            </template>
          </el-table-column>
          <el-table-column label="报告" width="120">
            <template slot-scope="scope">
              <el-upload
                class="avatar-uploader"
                action="https://admin.aiauto.shop/index.php?v=1&appid=2&appsecret=CHEZHUANGTOUE921805707FA3&s=api&c=file&m=upload&api_auth_code=${toke}&api_auth_uid=${id}&siteid=1&fid=175`"
                :show-file-list="false"
                :on-success="(response, file, fileList) => {
                  return onSuccess(response, 'operatingCondition.listingAnnualReport.baogao', scope.$index);
                }">
                <img v-if="scope.row.baogaoSrc" :src="scope.row.baogaoSrc" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="60">
            <template slot-scope="scope">
              <button class="settlingProcess_form_but" @click="supplyChainClick3('listingAnnualReport', scope.$index)">
                <i :class="scope.$index == 0?'el-icon-circle-plus-outline':'el-icon-remove-outline'"></i>
              </button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="equipment_table equipment_table1">
        <el-table
          stripe
          border
          :cell-style="tableStyle"
          :data="operatingCondition.workAnnualReport"
          style="width: 95%;margin: auto;">
          <el-table-column type="index" label="序号" width="50"></el-table-column>
          <el-table-column label="工商局年报">
            <template slot-scope="scope">
              <el-input v-model="scope.row.gongsiming" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="年月">
            <template slot-scope="scope">
              <el-date-picker
                placeholder="请选择年月"
                style="width: 100%;"
                class="settlingProcess_form_Sign"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                v-model="scope.row.nbnf"
                type="date">
              </el-date-picker>
            </template>
          </el-table-column>
          <el-table-column label="报告" width="120">
            <template slot-scope="scope">
              <el-upload
                class="avatar-uploader"
                :action="`https://admin.aiauto.shop/index.php?v=1&appid=2&appsecret=CHEZHUANGTOUE921805707FA3&s=api&c=file&m=upload&api_auth_code=${toke}&api_auth_uid=${id}&siteid=1&fid=175`"
                multiple
                :show-file-list="false"
                :on-success="(response, file, fileList) => {
                  return onSuccess(response, 'nbwjSrc', scope.$index);
                }">
                <img v-if="scope.row.nbwjSrc" :src="scope.row.nbwjSrc" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="60">
            <template slot-scope="scope">
              <button class="settlingProcess_form_but" @click="supplyChainClick3('workAnnualReport', scope.$index)">
                <i :class="scope.$index == 0?'el-icon-circle-plus-outline':'el-icon-remove-outline'"></i>
              </button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="equipment_table equipment_table1">
        <el-table
          stripe
          border
          :cell-style="tableStyle"
          :data="operatingCondition.otherAnnualReport"
          style="width: 95%;margin: auto;">
          <el-table-column type="index" label="序号" width="50"></el-table-column>
          <el-table-column label="其他年报">
            <template slot-scope="scope">
              <el-input v-model="scope.row.gongsiming" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="年月">
            <template slot-scope="scope">
              <el-date-picker
                placeholder="请选择年月"
                style="width: 100%;"
                class="settlingProcess_form_Sign"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                v-model="scope.row.nbnf"
                type="date">
              </el-date-picker>
            </template>
          </el-table-column>
          <el-table-column label="报告" width="120">
            <template slot-scope="scope">
              <el-upload
                class="avatar-uploader"
                :action="`https://admin.aiauto.shop/index.php?v=1&appid=2&appsecret=CHEZHUANGTOUE921805707FA3&s=api&c=file&m=upload&api_auth_code=${toke}&api_auth_uid=${id}&siteid=1&fid=175`"
                multiple
                :show-file-list="false"
                :on-success="(response, file, fileList) => {
                  return onSuccess(response, 'operatingCondition.otherAnnualReport.baogaoSrc', scope.$index);
                }">
                <img v-if="scope.row.baogaoSrc" :src="scope.row.baogaoSrc" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="60">
            <template slot-scope="scope">
              <button class="settlingProcess_form_but" @click="supplyChainClick3('otherAnnualReport', scope.$index)">
                <i :class="scope.$index == 0?'el-icon-circle-plus-outline':'el-icon-remove-outline'"></i>
              </button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="settlingProcess_title"><img src="../../public/img/img17.png" alt="">财务收支</div>
      <div class="equipment_table equipment_table1">
        <el-table
          stripe
          border
          :cell-style="tableStyle"
          :data="operatingCondition.financialReceipts"
          style="width: 95%;margin: auto;">
          <el-table-column type="index" label="序号" width="50"></el-table-column>
          <el-table-column label="年">
            <template slot-scope="scope">
              <el-date-picker
                class="settlingProcess_Industry_input"
                style="width: 100%;"
                v-model="scope.row.niandu"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="选择日期">
              </el-date-picker>
            </template>
          </el-table-column>
          <el-table-column label="营业收入">
            <template slot-scope="scope">
              <el-input v-model="scope.row.yingyeshouru" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="成本费用">
            <template slot-scope="scope">
              <el-input v-model="scope.row.cbfy" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="盈亏金额">
            <template slot-scope="scope">
              <el-input v-model="scope.row.yingkui" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="利润率">
            <template slot-scope="scope">
              <el-input v-model="scope.row.lirunlu" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="60">
            <template slot-scope="scope">
              <button class="settlingProcess_form_but" @click="supplyChainClick3('financialReceipts', scope.$index)">
                <i :class="scope.$index == 0?'el-icon-circle-plus-outline':'el-icon-remove-outline'"></i>
              </button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="equipment_table equipment_table1">
        <el-table
          stripe
          border
          :cell-style="tableStyle"
          :data="operatingCondition.financialOtherReceipts"
          style="width: 95%;margin: auto;">
          <el-table-column type="index" label="序号" width="50"></el-table-column>
          <el-table-column label="年">
            <template slot-scope="scope">
              <el-date-picker
                class="settlingProcess_Industry_input"
                style="width: 100%;"
                v-model="scope.row.niandu"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="选择日期">
              </el-date-picker>
            </template>
          </el-table-column>
          <el-table-column label="资产负债表">
            <template slot-scope="scope">
              <el-upload
                class="avatar-uploader"
                :action="`https://admin.aiauto.shop/index.php?v=1&appid=2&appsecret=CHEZHUANGTOUE921805707FA3&s=api&c=file&m=upload&api_auth_code=${toke}&api_auth_uid=${id}&siteid=1&fid=175`"
                multiple
                :show-file-list="false"
                :on-success="(response, file, fileList) => {
                  return onSuccess(response, 'operatingCondition.financialOtherReceipts.zcfzbSrc', scope.$index);
                }">
                <img v-if="scope.row.zcfzbSrc" :src="scope.row.zcfzbSrc" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </template>
          </el-table-column>
          <el-table-column label="损益表">
            <template slot-scope="scope">
              <el-upload
                class="avatar-uploader"
                :action="`https://admin.aiauto.shop/index.php?v=1&appid=2&appsecret=CHEZHUANGTOUE921805707FA3&s=api&c=file&m=upload&api_auth_code=${toke}&api_auth_uid=${id}&siteid=1&fid=175`"
                multiple
                :show-file-list="false"
                :on-success="(response, file, fileList) => {
                  return onSuccess(response, 'operatingCondition.financialOtherReceipts.sunyibiaoSrc', scope.$index);
                }">
                <img v-if="scope.row.sunyibiaoSrc" :src="scope.row.sunyibiaoSrc" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </template>
          </el-table-column>
          <el-table-column label="现金流量表">
            <template slot-scope="scope">
              <el-upload
                class="avatar-uploader"
                :action="`https://admin.aiauto.shop/index.php?v=1&appid=2&appsecret=CHEZHUANGTOUE921805707FA3&s=api&c=file&m=upload&api_auth_code=${toke}&api_auth_uid=${id}&siteid=1&fid=175`"
                multiple
                :show-file-list="false"
                :on-success="(response, file, fileList) => {
                  return onSuccess(response, 'operatingCondition.financialOtherReceipts.xjllbSrc', scope.$index);
                }">
                <img v-if="scope.row.xjllbSrc" :src="scope.row.xjllbSrc" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="60">
            <template slot-scope="scope">
              <button class="settlingProcess_form_but" @click="supplyChainClick3('financialOtherReceipts',scope.$index)">
                <i :class="scope.$index == 0?'el-icon-circle-plus-outline':'el-icon-remove-outline'"></i>
              </button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="settlingProcess_title"><img src="../../public/img/img17.png" alt="">法律</div>
      <div class="equipment_table equipment_table1">
        <el-table
          stripe
          border
          :cell-style="tableStyle"
          :data="operatingCondition.financialLawReceipts"
          style="width: 95%;margin: auto;">
          <el-table-column type="index" label="序号" width="50"></el-table-column>
          <el-table-column label="司法类型">
            <template slot-scope="scope">
              <el-input v-model="scope.row.leixing" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="案件名称">
            <template slot-scope="scope">
              <el-input v-model="scope.row.ajmc" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="日期">
            <template slot-scope="scope">
              <el-date-picker
                class="settlingProcess_Industry_input"
                style="width: 100%;"
                v-model="scope.row.riqi"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="选择日期">
              </el-date-picker>
            </template>
          </el-table-column>
          <el-table-column label="案件身份">
            <template slot-scope="scope">
              <el-input v-model="scope.row.ajsf" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="详情">
            <template slot-scope="scope">
              <el-input v-model="scope.row.content" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="60">
            <template slot-scope="scope">
              <button class="settlingProcess_form_but" @click="supplyChainClick3('financialLawReceipts', scope.$index)">
                <i :class="scope.$index == 0?'el-icon-circle-plus-outline':'el-icon-remove-outline'"></i>
              </button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div v-if="flowIndex == 5" class="equipment">
      <div class="settlingProcess_title"><img src="../../public/img/img17.png" alt="">融资状况</div>
      <div class="equipment_table equipment_table1">
        <el-table
          stripe
          border
          :cell-style="tableStyle"
          :data="financingSituation.financingSituation"
          style="width: 95%;margin: auto;">
          <el-table-column type="index" label="序号" width="50"></el-table-column>
          <el-table-column label="融资年月">
            <template slot-scope="scope">
              <el-date-picker
                placeholder="请选择年月"
                style="width: 100%;"
                class="settlingProcess_form_Sign"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                v-model="scope.row.rongziriqi"
                type="date">
              </el-date-picker>
            </template>
          </el-table-column>
          <el-table-column label="轮次">
            <template slot-scope="scope">
              <el-input v-model="scope.row.rongzilunci" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="融资金额">
            <template slot-scope="scope">
              <el-input v-model="scope.row.rongzijine" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="投资方">
            <template slot-scope="scope">
              <el-input v-model="scope.row.tzjgxx" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="60">
            <template slot-scope="scope">
              <button class="settlingProcess_form_but" @click="supplyChainClick3('financingSituation', scope.$index)">
                <i :class="scope.$index == 0?'el-icon-circle-plus-outline':'el-icon-remove-outline'"></i>
              </button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="settlingProcess_title"><img src="../../public/img/img17.png" alt="">对外投资</div>
      <div class="equipment_table equipment_table1">
        <el-table
          stripe
          border
          :cell-style="tableStyle"
          :data="financingSituation.outwardInvestment"
          style="width: 95%;margin: auto;">
          <el-table-column type="index" label="序号" width="50"></el-table-column>
          <el-table-column label="投资项目">
            <template slot-scope="scope">
              <el-input v-model="scope.row.xmmc" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="投资轮次">
            <template slot-scope="scope">
              <el-input v-model="scope.row.xiangmubp" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="投资金额">
            <template slot-scope="scope">
              <el-input v-model="scope.row.content" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="被投资方">
            <template slot-scope="scope">
              <el-input v-model="scope.row.btzf" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="60">
            <template slot-scope="scope">
              <button class="settlingProcess_form_but" @click="supplyChainClick3('outwardInvestment', scope.$index)">
                <i :class="scope.$index == 0?'el-icon-circle-plus-outline':'el-icon-remove-outline'"></i>
              </button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="settlingProcess_title"><img src="../../public/img/img17.png" alt="">投融资BP</div>
      <div class="equipment_table equipment_table1">
        <el-table
          stripe
          border
          :cell-style="tableStyle"
          :data="financingSituation.bp"
          style="width: 95%;margin: auto;">
          <el-table-column type="index" label="序号" width="50"></el-table-column>
          <el-table-column label="项目名称">
            <template slot-scope="scope">
              <el-input v-model="scope.row.xmmc" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="项目BP">
            <template slot-scope="scope">
              <el-input v-model="scope.row.xiangmubp" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="项目轮次">
            <template slot-scope="scope">
              <el-input v-model="scope.row.xiangmulunci" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="BP时间">
            <template slot-scope="scope">
              <el-date-picker
                placeholder="请选择年月"
                style="width: 100%;"
                class="settlingProcess_form_Sign"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                v-model="scope.row.shijian"
                type="date">
              </el-date-picker>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="60">
            <template slot-scope="scope">
              <button class="settlingProcess_form_but" @click="supplyChainClick3('bp', scope.$index)">
                <i :class="scope.$index == 0?'el-icon-circle-plus-outline':'el-icon-remove-outline'"></i>
              </button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="settlingProcess_title"><img src="../../public/img/img17.png" alt="">其他</div>
      <div class="equipment_table equipment_table1">
        <el-table
          stripe
          border
          :cell-style="tableStyle"
          :data="financingSituation.other"
          style="width: 95%;margin: auto;">
          <el-table-column type="index" label="序号" width="50"></el-table-column>
          <el-table-column label="项目名称">
            <template slot-scope="scope">
              <el-input v-model="scope.row.val1" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="详情">
            <template slot-scope="scope">
              <el-input v-model="scope.row.xmmc" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="60">
            <template slot-scope="scope">
              <button class="settlingProcess_form_but" @click="supplyChainClick3('other', scope.$index)">
                <i :class="scope.$index == 0?'el-icon-circle-plus-outline':'el-icon-remove-outline'"></i>
              </button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div v-if="flowIndex == 6" class="equipment RelatedInformation">
      <div class="settlingProcess_title"><img src="../../public/img/img17.png" alt="">相关资讯</div>
      <el-form :model="relatedInformation.news" ref="ruleForm" label-width="94px" class="demo-ruleForm"  size="small">
        <el-form-item label="资讯标题">
          <el-input v-model="relatedInformation.news.title"></el-input>
        </el-form-item>
        <el-form-item label="正文内容">
          <el-input
            class="settlingProcess_input"
            type="textarea"
            :autosize="{ minRows: 60, maxRows: 3}"
            placeholder="请输入内容"
            v-model="relatedInformation.news.content">
          </el-input>
        </el-form-item>
        <el-form-item label="头图上传">
          <el-upload
            class="avatar-uploader"
            :action="`https://admin.aiauto.shop/index.php?v=1&appid=2&appsecret=CHEZHUANGTOUE921805707FA3&s=api&c=file&m=upload&api_auth_code=${toke}&api_auth_uid=${id}&siteid=1&fid=175`"
            multiple
            :show-file-list="false"
            :on-success="(response, file, fileList) => {
              return onSuccess(response, 'relatedInformation.news', 0);
            }">
            <img v-if="relatedInformation.news.thumbSrc" :src="relatedInformation.news.thumbSrc" class="avatar" style="width: 120px;height: 120px;">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <div class="RelatedInformation_div">
            <p>请上传办公照片 </p>
             <p>支持png、jpg、jpeg文件格式，大小不超过20MB</p>
          </div>
        </el-form-item>
        <el-form-item label="发布媒体">
          <el-input v-model="relatedInformation.news.publishingMedia"></el-input>
        </el-form-item>
      </el-form>
      <div class="settlingProcess_title"><img src="../../public/img/img17.png" alt="">类似企业</div>
      <div class="equipment_table equipment_table1">
        <el-table
          stripe
          border
          :cell-style="tableStyle"
          :data="relatedInformation.leisi"
          style="width: 95%;margin: auto;">
          <el-table-column type="index" label="序号" width="50"></el-table-column>
          <el-table-column label="公司名称">
            <template slot-scope="scope">
              <el-input v-model="scope.row.title" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="公司产品">
            <template slot-scope="scope">
              <el-input v-model="scope.row.jpmc" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="公司介绍">
            <template slot-scope="scope">
              <el-input v-model="scope.row.gsjs" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="配套客户">
            <template slot-scope="scope">
              <el-input v-model="scope.row.peitaokehu" style="width: 100%;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="60">
            <template slot-scope="scope">
              <button class="settlingProcess_form_but" @click="supplyChainClick3('leisi', scope.$index)">
                <i :class="scope.$index == 0?'el-icon-circle-plus-outline':'el-icon-remove-outline'"></i>
              </button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div v-if="flowIndex == 7" class="equipment ContactInformation">
      <div class="settlingProcess_title"><img src="../../public/img/img17.png" alt="">总部及分子公司联系方式</div>
      <div>
        <div class="corporation_tit">
          <span>公司总部：</span>
          <!-- <div class="corporation_tit_right" @click="OfficeClick(i)">
            <i :class="i == 0?'el-icon-circle-plus-outline':'el-icon-remove-outline'"></i>
          </div> -->
        </div>
        <div class="ContactInformation_form">
          <el-form :model="fenzih[0].addfenzhi[0]" :rules="fenzih[0].gongs.rules[0]" :ref="'corporationForm'" label-width="70px" size="small">
            <el-row :gutter="20">
              <el-col :span="8">
                <el-form-item label="公司名称" style="margin-top: 20px;">
                  <el-input style="width: 100%;" v-model="fenzih[0].addfenzhi[0].title"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="地址" style="margin-top: 20px;">
                  <el-input style="width: 100%;" v-model="fenzih[0].addfenzhi[0].dizhi"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="邮编" style="margin-top: 20px;">
                  <el-input style="width: 100%;" v-model="fenzih[0].addfenzhi[0].youbian"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="电话" style="margin-top: 20px;" prop="dianhua">
                  <el-input style="width: 100%;" v-model.number="fenzih[0].addfenzhi[0].dianhua" maxlength="11"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="网址" style="margin-top: 20px;" prop="wangzhi">
                  <el-input style="width: 100%;" v-model="fenzih[0].addfenzhi[0].wangzhi"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="联系人" style="margin-top: 20px;">
                  <el-input style="width: 100%;" v-model="fenzih[0].addfenzhi[0].lianxiren"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="手机" style="margin-top: 20px;" prop="shouji">
                  <el-input style="width: 100%;" v-model.number="fenzih[0].addfenzhi[0].shouji" maxlength="11"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="微信" style="margin-top: 20px;">
                  <el-input style="width: 100%;" v-model="fenzih[0].addfenzhi[0].weixin"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="邮箱" style="margin-top: 20px;" prop="youxiang">
                  <el-input style="width: 100%;" v-model="fenzih[0].addfenzhi[0].youxiang"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="抖音" style="margin-top: 20px;">
                  <el-input style="width: 100%;" v-model="fenzih[0].addfenzhi[0].douyin"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
      <div v-for="(d,idx) in fenzih[0].addfenzhi[1]" :key="idx">
        <div class="corporation_tit">
          <span>子分公司名称{{idx+1}}：</span>
          <div class="corporation_tit_right" @click="OfficeClick1(idx)">
            <i :class="idx == 0?'el-icon-circle-plus-outline':'el-icon-remove-outline'"></i>
          </div>
        </div>
        <div class="ContactInformation_form">
          <el-form :model="d" :ref="'corporationFormIncc'+idx" label-width="70px" size="small">
            <el-row :gutter="20">
              <el-col :span="8">
                <el-form-item label="公司名称" style="margin-top: 20px;">
                  <el-input style="width: 100%;" v-model="d.title"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="地址" style="margin-top: 20px;">
                  <el-input style="width: 100%;" v-model="d.dizhi"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="邮编" style="margin-top: 20px;">
                  <el-input style="width: 100%;" v-model="d.youbian"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="电话" style="margin-top: 20px;">
                  <el-input style="width: 100%;" v-model="d.dianhua"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="网址" style="margin-top: 20px;">
                  <el-input style="width: 100%;" v-model="d.wangzhi"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="联系人" style="margin-top: 20px;">
                  <el-input style="width: 100%;" v-model="d.lianxiren"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="手机" style="margin-top: 20px;">
                  <el-input style="width: 100%;" v-model="d.shouji"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="微信" style="margin-top: 20px;">
                  <el-input style="width: 100%;" v-model="d.weixin"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="邮箱" style="margin-top: 20px;">
                  <el-input style="width: 100%;" v-model="d.youxiang"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="注册地址" style="margin-top: 20px;">
                  <el-input style="width: 100%;" v-model="d.zhucedizhi"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
    </div>
    <div class="settlingProcess_but">
      <el-button :type="flowIndex == 7?'primary':'info'" plain @click="save()">{{flowIndex == 7?'提交':'保存'}}</el-button>
      <el-button type="primary" @click="next()" v-if="flowIndex != 7">下一步</el-button>
      <el-button v-if="showId" type="danger" @click="del()">删除</el-button>
    </div>
  </div>
</template>
<script>
import request from '@/api/request.js'
import Vue from 'vue';
import { pcaTextArr } from "element-china-area-data";

export default {
  data() {
    return {
      pcaTextArr,
      enterTitle:[
        {name:'基本概况', required:true},
        {name:'生产经营', required:false},
        {name:'研发状况', required:false},
        {name:'供应链管理', required:false},
        {name:'经营状况', required:false},
        {name:'投融资状况', required:false},
        {name:'相关资讯', required:false},
        {name:'联系方式', required:true},
      ],
      enterFlow:{
        basic:{ title:'', qiyerenzheng:'', ywmc:'', gsjc:'', cylx:'', zwgs:'', jigouxingzhi:'', gsch:'', zibenjieduanh:'', jishuleibie:'', clsj:'', jigouzongbu:'', gsspwj:'', dizhi:'', gfwz:'', fddbr:'', hyxj:'', zhuyaokehu:'', qiyerongyu:'', qiyegaikuang:'' },
        rules:{
          title: [ { required: true, message: '请输入公司名称', trigger: 'change' } ],
          jigouxingzhi: [ { required: true, message: '请输入机构性质', trigger: 'change' } ],
          gsch: [ { required: true, message: '请输入公司称号', trigger: 'change' } ],
          clsj: [ { required: true, message: '请选择成立时间', trigger: 'change' } ],
          dizhi: [ { required: true, message: '请输入注册地址', trigger: 'change' } ],
          fddbr: [ { required: true, message: '请输入法定代表人', trigger: 'change' } ],
        },
        jobRegistration:{ title:'', ywmc:'', zhuceziben:'', shijiaoziben:'', qiyeleixing:'', clsj:'', fddbr:'', yingyeqixian:'', gszcdm:'', jyzk:'', xingye:'', sssf:'', dengjijiguan:'', djsj:'', zhucedidian:'', jyfw:'' },
        ownershipStructure:[ {
            title:'',
            shijiaojine:'',
            suozhangubi:'',
            bingzhuangtu:'',
            bingzhuangtuSrc:'',
            jiegoutu:'',
            jiegoutuSrc:'',
        }],
        primaryTeam:[{title:'', zhiwei:'', zhuguanyewu:'', txwj:'', txwjSrc:'', jianjie:''}],
      },
      productionManagement:{
        plantEquipment:[{
          num:'1',
          name:'生产总厂基地1',
          jidi1tupian1:'',
          jidi1tupian1Src:'',
          jd1tp1wzsm:'',
          jidi1tupian2:'',
          jidi1tupian2Src:'',
          jd1tp2wzsm:'',
          children:[{
            num:'11',
            name:'生产车间1',
            jidi1tupian1:'',
            jidi1tupian1Src:'',
            jd1tp1wzsm:'',
            jidi1tupian2:'',
            jidi1tupian2Src:'',
            jd1tp2wzsm:'',
          }]
        }],
        coreProduct:[{title:'', thumb:'', thumbSrc:'', content:'', cpsm:'', sortOptions:[
          [],[],[]
        ], ONsort:'', TWOsort:'', ThreeOsort:'',}],
        mainProduct:[{title:'', tyep1:'', type2:''}],
        Dproduct:[{title:'', thumb:'', thumbSrc:'', cpjs:'', sdmxwj:'', contentj:''}]
      },
      supplyChainManage:{
        direct:[{title:''}],
        indirect:[{title:''}],
        addcaigoucp:[{title:'', shuliang:'', cgsj:'', cgfs:'', negotiateWith:''}],
        storage:[{title:'', guimo:'', shuliang:'', fangshi:'', negotiateWith:''}],
        materialFlow:[{locations:'', guimo:'', shuliang:'', fangshi:'', negotiateWith:''}],
        entrance:[{title:'', cpxh:'', cpsl:'', jine:''}],
        exit:[{title:'', cpxh:'', cpsl:'', jine:''}],
      },
      operatingCondition:{
        listingAnnualReport:[{gongsiming:'', nbnf:'', baogao:'', baogaoSrc:''}],
        workAnnualReport:[{gongsiming:'', nbnf:'', nbwj:'', nbwjSrc:''}],
        otherAnnualReport:[{gongsiming:'', baogaoriqi:'',baogaoSrc:'' , baogao:''}],
        financialReceipts:[{niandu:'', yingyeshouru:'', cbfy:'', yingkui:'', lirunlu:''}],
        financialOtherReceipts:[{niandu:'',zcfzbSrc:'', zcfzb:'',sunyibiaoSrc:'', sunyibiao:'',xjllbSrc:'', xjllb:''}],
        financialLawReceipts:[{leixing:'', ajmc:'', riqi:'', ajsf:'', content:''}]
      },
      financingSituation:{
        financingSituation:[{rongziriqi:'', rongzilunci:'', rongzijine:'', tzjgxx:''}],
        outwardInvestment:[{xmmc:'', xiangmubp:'', content:'', btzf:''}],
        bp:[{xmmc:'', xiangmubp:'', xiangmulunci:'', shijian:''}],
        other:[{xmmc:'', content:''}]
      },
      relatedInformation:{
        news:{
          title:'',
          content:'',
          thumb:'',
          thumbSrc:'',
          publishingMedia:''
        },
        leisi:[{title:'', jpmc:'', gsjs:'', peitaokehu:''}]
      },
      fenzih:[{
        addfenzhi:[
          {title:'', dizhi:'', youbian:'', dianhua:'', wangzhi:'', lianxiren:'', shouji:'', weixin:'', youxiang:'', douyin:''},
          [{title:'', wangzhi:'', youxiang:'', weixin:'', shouji:'', lianxiren:'', dianhua:'', youbian:'', zhucedizhi:''}]
        ],
        gongs:{
          rules:[{
            dianhua: [
              { required: true, message: '请输入电话', trigger: 'change' },
              { validator: (rule, value, callback) => {
                if (!value) { return callback(new Error("手机号不能为空"))} else { const reg = /^1[3-9][0-9]\d{8}$/;
                  if (reg.test(value)) {callback()} else {
                    return callback(new Error("请输入正确的手机号"));
                  }
                }
              }, trigger: "blur" }
            ],
            wangzhi: [
              { required: true, message: '请输入网址', trigger: 'change' },
              { validator: (rule, value, callback) => {
                let url = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/[\]@!\$&'\*\+,;=.]+$/;
                if (!url.test(value)) {
                  return callback(new Error("请输入正确的接收地址"));
                }
              }, trigger: "blur" }
            ],
            shouji: [
              { required: true, message: '请输入手机号', trigger: 'change' } ,
              { validator: (rule, value, callback) => {
                if (!value) { return callback(new Error("手机号不能为空"))} else { const reg = /^1[3-9][0-9]\d{8}$/;
                  if (reg.test(value)) {callback()} else {
                    return callback(new Error("请输入正确的手机号"));
                  }
                }
              }, trigger: "blur" }
            ],
            youxiang: [
              { required: true, message: '请输入邮箱', trigger: 'change' } ,
              { validator: (rule, value, callback) => {
                let reg = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
                if (!reg.test(value)) {
                  return callback(new Error("请输入正确邮箱"));
                }
              }, trigger: "blur" }
            ],
          }],
          rules1:[{
            dianhua: [
              { required: true, message: '请输入电话', trigger: 'change' },
              { validator: (rule, value, callback) => {
                if (!value) { return callback(new Error("手机号不能为空"))} else { const reg = /^1[3-9][0-9]\d{8}$/;
                  if (reg.test(value)) {callback()} else {
                    return callback(new Error("请输入正确的手机号"));
                  }
                }
              }, trigger: "blur" }
            ],
            wangzhi: [
              { required: true, message: '请输入网址', trigger: 'change' },
              { validator: (rule, value, callback) => {
                let url = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/[\]@!\$&'\*\+,;=.]+$/;
                if (!url.test(value)) {
                  return callback(new Error("请输入正确的接收地址"));
                }
              }, trigger: "blur" }
            ],
            shouji: [
              { required: true, message: '请输入手机号', trigger: 'change' } ,
              { validator: (rule, value, callback) => {
                if (!value) { return callback(new Error("手机号不能为空"))} else { const reg = /^1[3-9][0-9]\d{8}$/;
                  if (reg.test(value)) {callback()} else {
                    return callback(new Error("请输入正确的手机号"));
                  }
                }
              }, trigger: "blur" }
            ],
            youxiang: [
              { required: true, message: '请输入邮箱', trigger: 'change' } ,
              { validator: (rule, value, callback) => {
                let reg = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
                if (!reg.test(value)) {
                  return callback(new Error("请输入正确邮箱"));
                }
              }, trigger: "blur" }
            ],
          }]
        },
      }],
      researchDevelopment:{
        institution:[{institution:'', title:'', personnel:'', item:'', achievement:''}],
        knowledge:[{type:'', title:'', accredit:'', value:'' }],
        quality:[{name:'', jgName:'', rzbh:'', rzsj:''}],
        other:[{name:'', jgName:'', rzbh:'', rzsj:''}],
      },
      flowIndex:0,
      fileList:[],
      toke:window.localStorage.getItem('code'),
      id:'',
      sort:{},
      supplyChain1:[
        {
          val1:'核心配套客户1',
          val2:'',
        }
      ],
      equipmentData:[{val1:'生产总厂基地一',val2:'',val3:'',val4:'',val5:''}],
      equipmentData1:[{val1:'电动产品',val2:'',val3:''}],
      BusinessStatus:[{val1:'',val2:'',val3:'',val4:'',val5:''}],
      cplb:[],
      bigtype:[],
      valType: [
        'enterFlow',
        'productionManagement',
        'researchDevelopment',
        'supplyChainManage',
        'operatingCondition',
        'financingSituation',
        'relatedInformation',
        'fenzih',
      ],
      showId:false,
    }
  },
  mounted() {
    this.id = JSON.parse(window.localStorage.getItem("individual")).id;
    let valname = '';
    this.valType.forEach( (item, index) => {
      if(index == this.flowIndex){
        valname = item
      }
    })
    if(window.localStorage.getItem(valname) != undefined){
      this.$data[valname] = JSON.parse(window.localStorage.getItem(valname))
    }
    this.column(0, '', 0);
    // this.getcompanyinfo();
    this.HomeIndexClick(0, '', 0)
  },
  methods:{
    tableStyle() { return "height:60px;"},
    HomeIndexClick(id, name, idx){
      request.HomeIndex().then((res) => {
        this.cplb = this.changeNodes(Object.values(res.data.data.cplb));
        this.$set(this.productionManagement.coreProduct[idx].sortOptions,0,this.cplb);
        if(window.localStorage.getItem("id")){
          this.getCompanyInfo()
        }
      })
    },
    changeNodes (data) {
      if (data.length > 0) {
        data.forEach(d => {
          if(d.children){
            d.children = Object.values(d.children)
            d.children.forEach(dn => {
              if(dn.children){
                dn.children = Object.values(dn.children)
                dn.children.forEach(dni => {
                  if(dni.children){
                    dni.children = Object.values(dni.children)
                    dni.children.forEach(dnic => {
                      if(dnic.children){
                        dnic.children = Object.values(dnic.children)
                      }
                    })
                  }
                })
              }
            })
          }
        })
      }
      return data;
    },
    flowClick(idx){
      let valname = '';
      this.valType.forEach( (item, index) => {
        if(index == this.flowIndex){
          valname = item
        }
      })
      if(window.localStorage.getItem(valname) != undefined){
        this.$data[valname] = JSON.parse(window.localStorage.getItem(valname))
      }
      this.flowIndex = idx
    },
    getCompanyInfo(){
        request.getCompanyInfo({
          id:window.localStorage.getItem("id")
        }).then((res) => {
          if(res.data.data != {}){
            if(res.data.data){
              this.showId = true;
              let val = res.data.data;
              this.enterFlow = val.enterFlow;
              this.financingSituation = val.financingSituation;
              this.operatingCondition = val.operatingCondition;
              let productionManagement = val.productionManagement;
              for(var i = 0; i< productionManagement.coreProduct.length;i++){
                productionManagement.coreProduct[i].sortOptions = [];
                this.$set(productionManagement.coreProduct[i].sortOptions,0,[...this.cplb, [], []]);
                if(productionManagement.coreProduct[i].TWOsort != ''){
                  let val = this.cplb.findIndex(e => e.value == productionManagement.coreProduct[i].ONsort);
                  productionManagement.coreProduct[i].sortOptions.push(this.cplb[val].children);
                  if(productionManagement.coreProduct[i].ThreeOsort != ''){
                    let val1 = this.cplb[val].children.findIndex(e => e.value == productionManagement.coreProduct[i].TWOsort);
                    productionManagement.coreProduct[i].sortOptions.push(this.cplb[val].children[val1].children);
                  }
                }
              }
              if(productionManagement.plantEquipment){
                for(var a = 0; a< productionManagement.plantEquipment.length;a++){
                  productionManagement.plantEquipment[a].num = productionManagement.plantEquipment[a].num + '';
                  if(productionManagement.plantEquipment[a].children){
                    productionManagement.plantEquipment[a].children = Object.values(productionManagement.plantEquipment[a].children)
                  }
                }
                for(var a = 0; a< productionManagement.plantEquipment.length;a++){
                  if(productionManagement.plantEquipment[a].children){
                    for(var z = 0; z< productionManagement.plantEquipment[a].children;z++){
                      productionManagement.plantEquipment[a].children[z].num = productionManagement.plantEquipment[a].children[z].num + ''
                    }
                  }
                }
              }else{
                productionManagement.plantEquipment = this.$options.data().productionManagement.plantEquipment
              }
              this.productionManagement = productionManagement;
              this.relatedInformation = val.relatedInformation;
              this.researchDevelopment = val.researchDevelopment;
              this.supplyChainManage = val.supplyChainManage;
              this.fenzih[0].addfenzhi = val.fenzih.addfenzhi;
            }else{
              this.showId = false
            }

          }else{
            this.showId = false
          }
        })
    },
    del(){
      let that = this;
      that.$confirm('是否删除企业信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        request.Deletecompanyinfo({
          api_auth_uid:JSON.parse(window.localStorage.getItem("individual")).id
        }).then((res) => {
          if(res.data.code == 200){
            that.$message({
              message: res.data.msg,
              type: 'success'
            });
            setTimeout(() => {
              that.data = that.$options.data();
              that.id = JSON.parse(window.localStorage.getItem("individual")).id;
              let valname = '';
              that.valType.forEach( (item, index) => {
                if(index == that.flowIndex){
                  valname = item
                }
              })
              if(window.localStorage.getItem(valname) != undefined){
                that.$data[valname] = JSON.parse(window.localStorage.getItem(valname))
              }
              that.column(0, '', 0);
              that.HomeIndexClick(0, '', 0)
              if(window.localStorage.getItem("id")){
                that.getCompanyInfo()
              }
            },800)
          }else{
            that.$message.error(res.data.msg);
          }
        })
      })
    },
    save(){
      let that = this;
      let productionManagement = JSON.parse(JSON.stringify(this.productionManagement));
      let production = [];
      productionManagement.coreProduct.forEach(item => {
        production.push({
          ONsort:item.ONsort,
          TWOsort:item.TWOsort,
          ThreeOsort:item.ThreeOsort,
          content:item.content,
          cpsm:item.cpsm,
          thumb:item.thumb,
          thumbSrc:item.thumbSrc,
          title:item.title,
          id:item.id,
        })
      })
      productionManagement.coreProduct = production;
      let valData = {};
      for(var i = 0; i < productionManagement.plantEquipment.length; i++){
        delete productionManagement.plantEquipment[i].num;
        if(productionManagement.plantEquipment[i].children){
          for(var a = 0; a < productionManagement.plantEquipment[i].children.length; a++){
            delete productionManagement.plantEquipment[i].children[a].num;
          }
        }

      }
      if(this.showId == true){
        valData = {
          api_auth_uid:window.localStorage.getItem("id"),
          enterFlow:that.enterFlow,
          productionManagement:productionManagement,
          researchDevelopment:that.researchDevelopment,
          supplyChainManage:that.supplyChainManage,
          operatingCondition:that.operatingCondition,
          financingSituation:that.financingSituation,
          relatedInformation:that.relatedInformation,
          fenzih:that.fenzih[0].addfenzhi,
        }
      }else{
        valData = {
          enterFlow:that.enterFlow,
          productionManagement:productionManagement,
          researchDevelopment:that.researchDevelopment,
          supplyChainManage:that.supplyChainManage,
          operatingCondition:that.operatingCondition,
          financingSituation:that.financingSituation,
          relatedInformation:that.relatedInformation,
          fenzih:that.fenzih[0].addfenzhi,
        }
      };
      console.log('valData----------------------------------------------------------------', valData)
      request.ceshi(valData).then((res) => {
        if(res.data.code == 1){
          if(that.flowIndex == 7){
            that.$message({
              message: "信息已提交",
              type: 'success'
            });
          }else{
            that.$message({
              message: res.data.msg,
              type: 'success'
            });
          }

          that.valType.forEach( (item, index) => {
            window.localStorage.removeItem(item)
          })
        }else{
          that.$message({
            message: res.data.msg,
            type: 'error'
          });
        }
      })
    },
    corporationFormInClick(){
      this.fenzih[0].addfenzhi[1].forEach((d, i) => {
        this.$refs['corporationFormIncc'+i][0].validate((valid) => {
          if(valid){
            request.ceshi({
              enterFlow:this.enterFlow,
              productionManagement:this.productionManagement,
              researchDevelopment:this.researchDevelopment,
              supplyChainManage:this.supplyChainManage,
              operatingCondition:this.operatingCondition,
              financingSituation:this.financingSituation,
              relatedInformation:this.relatedInformation,
              fenzih:this.fenzih[0],
            }).then((res) => {
              this.$message({
                message: res.data.msg,
                type: 'success'
              });
              this.valType.forEach( (item, index) => {
                window.localStorage.removeItem(item)
              })
            })
          }else{
            this.$message.error('请填写信息');
          }
        })
      })
    },
    // 本地保存
    LocalSave(){
      let valname = '';
      this.valType.forEach( (item, index) => {
        if(index == this.flowIndex){
          valname = item
        }
      })
      this.$message({
        message: '保存成功',
        type: 'success'
      });
      window.localStorage.setItem(valname, JSON.stringify(this.$data[valname]))
    },
    next(){
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
      this.flowIndex++;
    },
    onSuccess(response, name, index, scope) {
      response.data.url = this.matchFileSuffixType(response.data.url)
      if(name == 'nbwjSrc'){
        let cc = this.operatingCondition.workAnnualReport[index];
        cc.nbwjSrc = response.data.url
        cc.nbwj = response.data.id;
        this.$set(this.operatingCondition.workAnnualReport,index,cc);
      }else if(name == 'operatingCondition.listingAnnualReport.baogao'){
        let cc = this.operatingCondition.listingAnnualReport[index];
        cc.baogaoSrc = response.data.url
        cc.baogao = response.data.id;
        this.$set(this.operatingCondition.listingAnnualReport,index,cc);
      }else if(name == 'enterFlow.basic'){
        this.enterFlow.basic.gsspwj = response.data.id;
      }else if(name == 'enterFlow.ownershipStructure.jiegoutu'){
        let cc = this.enterFlow.ownershipStructure[index];
        cc.jiegoutuSrc = response.data.url;
        cc.jiegoutu = response.data.id;
        this.$set(this.enterFlow.ownershipStructure,index,cc);
      }else if(name == 'enterFlow.ownershipStructure.bingzhuangtu'){
        let cc = this.enterFlow.ownershipStructure[index];
        cc.bingzhuangtuSrc = response.data.url;
        cc.bingzhuangtu = response.data.id;
        this.$set(this.enterFlow.ownershipStructure,index,cc);
      }else if (name == 'enterFlow.primaryTeam.txwj'){
        let cc = this.enterFlow.primaryTeam[index];
        cc.txwjSrc = response.data.url
        cc.txwj = response.data.id;
        this.$set(this.enterFlow.primaryTeam,index,cc);
      }else if (name == 'productionManagement.plantEquipment.jidi1tupian1Src'){
        scope.num = scope.num + '';
        let idx = scope.num.split('');
        let cc = this.productionManagement.plantEquipment[parseInt(idx[0]) - 1];
        if(scope.num.length == 2){
          cc.children[parseInt(idx[1]) - 1].jidi1tupian1Src = response.data.url
          cc.children[parseInt(idx[1]) - 1].jd1cj1tp1 = response.data.id;
        }else{
          cc.jidi1tupian1Src = response.data.url;
          cc.jd1cj1tp1 = response.data.id;
        }
        this.$set(this.productionManagement.plantEquipment,(parseInt(idx[0]) - 1),cc);
      }else if (name == 'productionManagement.plantEquipment.jidi1tupian2Src'){
        scope.num = scope.num + '';
        let idx = scope.num.split('');
        let cc = this.productionManagement.plantEquipment[parseInt(idx[0]) - 1];
        if(scope.num.length == 2){
          cc.children[parseInt(idx[1]) - 1].jidi1tupian2Src = response.data.url
          cc.children[parseInt(idx[1]) - 1].jidi1tupian2 = response.data.id;
        }else{
          cc.jidi1tupian2Src = response.data.url;
          cc.jidi1tupian2 = response.data.id;
        }
        this.$set(this.productionManagement.plantEquipment, (parseInt(idx[0]) - 1),cc);
      }else if (name == 'productionManagement.coreProduct.thumb'){
        let cc = this.productionManagement.coreProduct[index];
        cc.thumbSrc = response.data.url;
        cc.thumb = response.data.id;
        this.$set(this.productionManagement.coreProduct,index,cc);
      }else if(name == 'productionManagement.Dproduct.thumb'){
        let cc = this.productionManagement.Dproduct[index];
        cc.thumbSrc = response.data.url;
        cc.thumb = response.data.id;
        this.$set(this.productionManagement.Dproduct,index,cc);
      }else if(name == 'productionManagement.Dproduct.sdmxwj'){
        let cc = this.productionManagement.Dproduct.sdmxwj[index];
        cc.sdmxwj = response.data.id;
        this.$set(this.productionManagement.Dproduct.sdmxwj, index,cc);
      }else if(name == 'relatedInformation.news'){
        this.relatedInformation.news.thumbSrc = response.data.url;
        this.relatedInformation.news.thumb = response.data.id;
        this.$forceUpdate();
      }else if(name == 'operatingCondition.financialOtherReceipts.zcfzbSrc'){
        let cc = this.operatingCondition.financialOtherReceipts[index];
        cc.zcfzbSrc = response.data.url;
        cc.zcfzb = response.data.id;
        this.$set(this.operatingCondition.financialOtherReceipts, index,cc);
      }else if(name == 'operatingCondition.financialOtherReceipts.sunyibiaoSrc'){
        let cc = this.operatingCondition.financialOtherReceipts[index];
        cc.sunyibiaoSrc = response.data.url;
        cc.sunyibiao = response.data.id;
        this.$set(this.operatingCondition.financialOtherReceipts, index,cc);
      }else if(name == 'operatingCondition.financialOtherReceipts.xjllbSrc'){
        let cc = this.operatingCondition.financialOtherReceipts[index];
        cc.xjllbSrc = response.data.url;
        cc.xjllb = response.data.id;
        this.$set(this.operatingCondition.financialOtherReceipts, index,cc);
      }else if(name == 'operatingCondition.otherAnnualReport.baogaoSrc'){
        let cc = this.operatingCondition.otherAnnualReport[index];
        cc.baogaoSrc = response.data.url;
        cc.baogao = response.data.id;
        this.$set(this.operatingCondition.otherAnnualReport, index,cc);
      }
    },
    getcompanyinfo(){
      request.getcompanyinfo().then((res) => {
        console.log(res);
      })
    },
    structureClick1(row){
      if(row == 0){
        this.enterFlow.ownershipStructure.push({title:'',shijiaojine:'',suozhangubi:'',bingzhuangtu:'',bingzhuangtuSrc:'',jiegoutu:'',jiegoutuSrc:''})
      }else{
        this.enterFlow.ownershipStructure.splice(row,1)
      }
    },
    structureClick2(row){
      if(row == 0){
        this.enterFlow.primaryTeam.push({title:'', zhiwei:'', zhuguanyewu:'', txwj:'', txwjSrc:'', jianjie:''})
      }else{
        this.enterFlow.primaryTeam.splice(row,1)
      }
    },
    equipmentClick2(row){
      if(row == 0){
        this.productionManagement.Dproduct.push({title:'', thumb:'', thumbSrc:'', cpjs:'', sdmxwj:'', sdmxwjSrc:[], contentj:''})
      }else{
        this.productionManagement.Dproduct.splice(row,1)
      }
    },
    supplyChainClick1(row){
      if(row == 0){
        this.supplyChainManage.direct.push({title:''})
      }else{
        this.supplyChainManage.direct.splice(row,1)
      }
    },
    supplyChainClick2(row){
      if(row == 0){
        this.supplyChainManage.indirect.push({title:''})
      }else{
        this.supplyChainManage.indirect.splice(row,1)
      }
    },
    supplyChainClick3(name, row){
      if(row == 0){
        if(name == 'listingAnnualReport'){this.operatingCondition.listingAnnualReport.push({gongsiming:'', nbnf:'', baogao:'', baogaoSrc:''})
        }else if(name == 'workAnnualReport'){
          this.operatingCondition.workAnnualReport.push({gongsiming:'', nbnf:'', nbwj:''})
        }else if(name == 'otherAnnualReport'){
          this.operatingCondition.otherAnnualReport.push({gongsiming:'', baogaoriqi:'', baogao:''})
        }else if(name == 'financialReceipts'){
          this.operatingCondition.financialReceipts.push({niandu:'', yingyeshouru:'', cbfy:'', yingkui:'', lirunlu:''})
        }else if(name == 'financialOtherReceipts'){
          this.operatingCondition.financialOtherReceipts.push({niandu:'', zcfzb:'', sunyibiao:'', xjllb:''})
        }else if(name == 'financialLawReceipts'){
          this.operatingCondition.financialLawReceipts.push({leixing:'', ajmc:'', riqi:'', ajsf:'', content:''})
        }else if(name == 'financingSituation'){
          this.financingSituation.financingSituation.push({rongziriqi:'', rongzilunci:'', rongzijine:'', tzjgxx:''})
        }else if(name == 'bp'){
          this.financingSituation.bp.push({xmmc:'', xiangmubp:'', xiangmulunci:'', shijian:''})
        }else if(name == 'other'){
          this.financingSituation.other.push({xmmc:'', xiangmubp:'', xiangmulunci:'', shijian:'', content:''})
        }else if(name == 'addcaigoucp'){
          this.supplyChainManage.addcaigoucp.push({title:'', shuliang:'', cgsj:'', cgfs:'', negotiateWith:''})
        }else if(name == 'storage'){
          this.supplyChainManage.storage.push({title:'', guimo:'', shuliang:'', fangshi:'', negotiateWith:''})
        }else if(name == 'entrance'){
          this.supplyChainManage.entrance.push({title:'', cpxh:'', cpsl:'', jine:''})
        }else if(name == 'exit'){
          this.supplyChainManage.exit.push({title:'', cpxh:'', cpsl:'', jine:''})
        }else if(name == 'coreProduct'){
          this.productionManagement.coreProduct.push({title:'', thumb:'', thumbSrc:'', content:'', cpsm:'', sortOptions:[
            this.productionManagement.coreProduct[this.productionManagement.coreProduct.length -1].sortOptions[0],[],[]
          ], ONsort:'', TWOsort:'', ThreeOsort:'',});
          this.column(0, '', this.productionManagement.coreProduct.length -1)
        }else if(name == 'mainProduct'){
          this.productionManagement.mainProduct.push({title:'', tyep1:'', type2:''})
        }else if(name == 'institution'){
          this.researchDevelopment.institution.push({institution:'', title:'', personnel:'', item:'', achievement:''})
        }else if(name == 'knowledge'){
          this.researchDevelopment.knowledge.push({type:'', title:'', accredit:'', value:'' })
        }else if(name == 'quality'){
          this.researchDevelopment.quality.push({name:'', jgName:'', rzbh:'', rzsj:''})
        }else if(name == 'researchDevelopment.other'){
          this.researchDevelopment.other.push({name:'', jgName:'', rzbh:'', rzsj:''})
        }else if(name == 'materialFlow'){
          this.supplyChainManage.materialFlow.push({locations:'', guimo:'', shuliang:'', fangshi:'', negotiateWith:''})
        }else if(name == 'outwardInvestment'){
          this.financingSituation.outwardInvestment.push({xmmc:'', xiangmubp:'', content:'', btzf:''})
        }else if(name == 'leisi'){
          this.relatedInformation.leisi.push({title:'', jpmc:'', gsjs:'', peitaokehu:''})
        }
      }else{
        if(name == 'listingAnnualReport'){this.operatingCondition.listingAnnualReport.splice(row,1)
        }else if(name == 'workAnnualReport'){this.operatingCondition.workAnnualReport.splice(row,1)
        }else if(name == 'otherAnnualReport'){this.operatingCondition.otherAnnualReport.splice(row,1)
        }else if(name == 'financialReceipts'){this.operatingCondition.financialReceipts.splice(row,1)
        }else if(name == 'financialOtherReceipts'){this.operatingCondition.financialOtherReceipts.splice(row,1)
        }else if(name == 'financialLawReceipts'){this.operatingCondition.financialLawReceipts.splice(row,1)
        }else if(name == 'financingSituation'){this.financingSituation.financingSituation.splice(row,1)
        }else if(name == 'bp'){this.financingSituation.bp.splice(row,1)
        }else if(name == 'other'){this.financingSituation.other.splice(row,1)
        }else if(name == 'addcaigoucp'){
          this.supplyChainManage.addcaigoucp.splice(row,1)
        }else if(name == 'storage'){
          this.supplyChainManage.storage.splice(row,1)
        }else if(name == 'entrance'){
          this.supplyChainManage.entrance.splice(row,1)
        }else if(name == 'exit'){
          this.supplyChainManage.exit.splice(row,1)
        }else if(name == 'leisi'){
          this.relatedInformation.leisi.splice(row,1)
        }else if(name == 'coreProduct'){
          this.productionManagement.coreProduct.splice(row,1)
        }else if(name == 'mainProduct'){
          this.productionManagement.mainProduct.splice(row,1)
        }else if(name == 'institution'){
          this.researchDevelopment.institution.splice(row,1)
        }else if(name == 'knowledge'){
          this.researchDevelopment.knowledge.splice(row,1)
        }else if(name == 'quality'){
          this.researchDevelopment.quality.splice(row,1)
        }else if(name == 'researchDevelopment.other'){
          this.researchDevelopment.other.splice(row,1)
        }else if(name == 'materialFlow'){
          this.supplyChainManage.materialFlow.splice(row,1)
        }else if(name == 'outwardInvestment'){
          this.financingSituation.outwardInvestment.splice(row,1)
        }
      }
    },
    OfficeClick(row){
      if(row == 0){
        this.fenzih[0].addfenzhi[0].push({title:'', dizhi:'', youbian:'', dianhua:'', wangzhi:'', lianxiren:'', shouji:'', weixin:'', youxiang:'', douyin:''})
      }else{
        this.fenzih[0].addfenzhi[0].splice(row,1)
      }
    },
    OfficeClick1(row){
      if(row == 0){
        this.fenzih[0].addfenzhi[1].push({title:'', wangzhi:'', youxiang:'', weixin:'', shouji:'', lianxiren:'', dianhua:'', youbian:'', zhucedizhi:''})
      }else{
        this.fenzih[0].addfenzhi[1].splice(row,1)
      }
    },
    column(id, name, idx){
      request.column(id).then((res) => {
        this.bigtype = res.data.data;
      })
    },
    sortChange(id, name, idx){
      if(name == 'ONsort'){
        let arr = [];
        this.cplb.forEach(item => {
          if(item.value == id.ONsort){
            arr = item.children
          }
        })
        this.$set(this.productionManagement.coreProduct[idx].sortOptions,1,arr);
        this.productionManagement.coreProduct[idx].TWOsort = '';
        this.productionManagement.coreProduct[idx].ThreeOsort = '';
      }else if(name == 'TWOsort'){
        let arr = [];
        this.cplb.forEach(item => {
          if(item.value == id.ONsort){
            item.children.forEach(d => {
              if(d.value == id.TWOsort){
                arr = d.children
              }
            })
          }
        })
        this.$set(this.productionManagement.coreProduct[idx].sortOptions,2,arr);
        this.productionManagement.coreProduct[idx].ThreeOsort = '';
      }
    },
    PlantEquipment(item, index){
      item.num = item.num + ''
      let val = item.num.split('')
      if(val[0] == '1' && val.length == 1){
        let numVal = this.productionManagement.plantEquipment.length + 1;
        this.productionManagement.plantEquipment.push({
          columnId:this.productionManagement.plantEquipment.length + 1 + '',
          name:'生产总厂基地'+(this.productionManagement.plantEquipment.length+1),
          jidi1tupian1:'',
          jidi1tupian1Src:'',
          jd1tp1wzsm:'',
          jidi1tupian2:'',
          jidi1tupian2Src:'',
          jd1tp2wzsm:'',
          num:numVal,
          children:[{
            columnId:(this.productionManagement.plantEquipment.length + 1)+'1',
            name:'生产车间1',
            jidi1tupian1:'',
            jidi1tupian1Src:'',
            jd1tp1wzsm:'',
            jidi1tupian2:'',
            jidi1tupian2Src:'',
            jd1tp2wzsm:'',
            num:numVal+'1'
          }]
        })
      }else if(val[1] == 1 && val.length > 1){
        let nnum = this.productionManagement.plantEquipment[parseInt(val[0]) - 1].children.length + 1;
        this.productionManagement.plantEquipment[parseInt(val[0]) - 1].children.push({
          columnId:parseInt(val[0]) +''+ nnum,
          name:'生产车间' + nnum,
          jidi1tupian1:'',
          jidi1tupian1Src:'',
          jd1tp1wzsm:'',
          jidi1tupian2:'',
          jidi1tupian2Src:'',
          jd1tp2wzsm:'',
          num:val[0] +''+ nnum
        })
      }else if(val[0] != '0' && val.length == 1){
        console.log(2)
        if(this.productionManagement.plantEquipment.length >= parseInt(val[0])){
          this.productionManagement.plantEquipment.splice((parseInt(val[0]) - 1),1)
        }else{
          this.productionManagement.plantEquipment.splice((this.productionManagement.plantEquipment.length - 1),1)
        }
      }else{
        if(this.productionManagement.plantEquipment[parseInt(val[0]) - 1].children.length >= parseInt(val[1])){
          this.productionManagement.plantEquipment[parseInt(val[0]) - 1].children.splice((parseInt(val[1]) - 1),1)
        }else{
          this.productionManagement.plantEquipment[parseInt(val[0]) - 1].children.splice((this.productionManagement.plantEquipment[parseInt(val[0]) - 1].children.length - 1),1)
        }
      }
    },
    Back(){ this.$router.go(-1) },
    matchFileSuffixType(fileName) {
      // 后缀获取
      var suffix = ''
      // 获取类型结果
      var result = ''
      try {
          var flieArr = fileName.split('.')
          suffix = flieArr[flieArr.length - 1]
      } catch (err) {
          suffix = ''
      }
      // fileName无后缀返回 false
      if (!suffix) {
          result = false
          return result
      }
      // 图片格式
      var imglist = ['png', 'jpg', 'jpeg', 'bmp', 'gif','webp']
      // 进行图片匹配
      result = imglist.some(function (item) {
          return item == suffix
      })
      if (result) {
          result = fileName
          return result
      }
      // 匹配 word
      var wordlist = ['doc', 'docx']
      result = wordlist.some(function (item) {
          return item == suffix
      })
      if (result) {
        result = 'word'
        return 'http://cheweb.zxwkj.net/img/word.png'
      }
      // 匹配 pdf
      var pdflist = ['pdf']
      result = pdflist.some(function (item) {
          return item == suffix
      })
      if (result) {
          result = 'pdf'
          return 'http://cheweb.zxwkj.net/img/pdf.png'
      }
      // 其他 文件类型
      result = 'http://cheweb.zxwkj.net/img/qita.png'
      return result
    }
  }
}
</script>
<style lang="scss" scoped>
  .el-icon-circle-plus-outline{color: #F1521E;}
  .el-icon-remove-outline{color: #0089EF;}
  .settlingProcess{
    min-width: 1200px;
    margin: auto;
    background-color: #fff;
  }
  .settlingProcess_top{
    position: relative;
    img{
      display: block;
      width: 100%;
    }
    h4{
      font-size: 24px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #FFFFFF;
      line-height: 114px;
      text-align: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
    .settlingProcess_top_liu{
      position: absolute;
      top: 114px;
      width: 96%;
      right: 0;
      left: 0;
      margin: auto;
      background: #FFFFFF;
      box-shadow: 0px 0px 4px 4px rgba(175,175,175,0.5);
      border-radius: 14px;
      overflow: hidden;
      .settlingProcess_top_liu_in{
        padding: 0 74px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        i{
          position: absolute;
          display: block;
          width: calc(100% - 152px);
          top: 76px;
          left: 0;
          right: 0;
          margin: auto;
          height: 8px;
          background: #CCE7FC;
          border-radius: 3px;
        }
        .settlingProcess_top_liu_in_list{
          margin: 44px 0 40px;
          text-align: center;
          position: relative;
          cursor: pointer;
          div{
            width: 60px;
            height: 60px;
            background: #0089EF;
            border: 6px solid #CCE7FC;
            line-height: 60px;
            text-align: center;
            font-size: 32px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #FFFFFF;
            border-radius: 50%;
            margin: auto;
          }
          p{
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #222222;
            line-height: 25px;
            margin-top: 18px;
          }
          .settlingProcess_top_liu_in_list_p{color: #0089EF;}
          span{
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #979797;
            line-height: 20px;
          }
          .settlingProcess_top_liu_in_list_span{color: #FF0000;}
        }
      }
    }
  }
  .settlingProcess_tit{
    height: 80px;
    line-height: 80px;
    background: #0089EF;
    margin-top: 108px;
    div{
      width: 96%;
      font-size: 18px;
      font-family: Helvetica;
      color: #FFFFFF;
      margin: auto;
      img{
        height: 46px;
        vertical-align: middle;
        margin-right: 8px;
      }
    }
  }
  .settlingProcess_Back{
    padding: 20px 0;
    font-size: 0;
    width: 96%;
    margin:50px auto 0;
    .settlingProcess_Back_but{
      width: 70px;
      height: 30px;
      background: #0089EF;
      box-shadow: 0px 2px 4px 0px rgba(85,118,207,0.31);
      display: inline-block;
      vertical-align: top;
      border: 0;
      outline: none;
      color: #fff;
      cursor: pointer;
      margin-right: 26px;
      i{margin-right: 7px;}
    }
    .settlingProcess_breadcrumb{
      display: inline-block;
      vertical-align: top;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #9B9B9B;
      line-height: 30px;
    }
  }
  .settlingProcess_title{
    width: 96%;
    margin: auto;
    line-height: 60px;
    border-bottom: 1px solid #979797;
    font-size: 22px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #0F0F0F;
    img{
      height: 20px;
      vertical-align: middle;
      margin-right: 10px;
    }
  }
  .settlingProcess_Industry{
    border-top: 1px solid #E3EDFB;
    border-left: 1px solid #E3EDFB;
    width: 96%;
    margin:20px auto 0;
    .settlingProcess_Industry_name{
      display: inline-block;
      vertical-align: top;
      width: 208px;
      background: #F6FAFF;
      border-right: 1px solid #E3EDFB;
      border-bottom: 1px solid #E3EDFB;
      line-height: 60px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #0F0F0F;
      p{margin-left: 52px;}
    }
    .settlingProcess_Industry_input{
      border: 0;
      line-height: 59px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      border-right: 1px solid #E3EDFB;
      border-bottom: 1px solid #E3EDFB;
      width: calc(100% - 210px - 15px);
      padding-left: 15px;
      outline: none;
    }
    .settlingProcess_Industry_addree{
      line-height: 414px;
    }
    .settlingProcess_Industry_addree_input{
      height: 414px;
    }
  }
  .settlingProcess_but{
    line-height: 100px;
    text-align: center;
  }
  ::v-deep{
    .settlingProcess_input{
      display: block;
      width: 96%;
      margin:20px auto 0;
      .el-textarea__inner{min-height:300px !important;}
    }
    .avatar-uploader .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409EFF;
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 60px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      border: 1px dashed #d9d9d9;
    }
    .avatar {
      width: 60px;
      height: 50px;
      display: block;
    }
    .settlingProcess_form{
      width: 96%;
      margin:auto;
      .settlingProcess_form_item{margin-top: 20px;}

      .settlingProcess_form_but{
        height: 50px;
        font-size: 26px;
        border: 0;
        background: transparent;
        cursor: pointer;
      }
    }
    .el-button--primary,.el-button--primary:hover,.el-button--primary:focus{
      border-color: #0089EF !important;
      background-color: #0089EF !important;
      color: #fff !important;
    }
    .el-table .el-table__cell{
      padding: 6px 0;
    }
    .equipment_table{
      // border: 1px solid #E3EDFB;
      width: 96%;
      margin:20px auto 10px;
      .el-table{
        width: 100% !important;
        .cell{text-align: center;}
        .equipment_table_div{
          position: absolute;
          top: 0px;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        th,.equipment_table_div{background-color:#F6FAFF;}
      }
      .el-table th.el-table__cell.is-leaf,
      .el-table td.el-table__cell,
      .el-table--border .el-table__cell,
      .el-table--group, .el-table--border{
        border-color: #E3EDFB;
      }
    }
    .equipment_table1{
      .el-icon-circle-plus-outline{color: #F1521E;}
      .el-icon-remove-outline{color: #0089EF;}
      .settlingProcess_form_but{
        height: 50px;
        font-size: 26px;
        border: 0;
        background: transparent;
        cursor: pointer;
      }
    }
    .RelatedInformation{
      .settlingProcess_input{width: 100% !important;}
      .demo-ruleForm{
        width:96%;
        margin:20px auto 0;
      }
      .RelatedInformation_uploader{
        width: 120px;
        height:120px;
        display: inline-block;
        vertical-align: top;
      }
      .avatar-uploader-icon{
        width: 120px;
        height:120px;
        line-height: 120px;
      }
      .RelatedInformation_div{
        display: inline-block;
        vertical-align: bottom;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #8A8A8A;
        line-height: 17px;
        margin-left: 12px;
      }
    }
    .ContactInformation{
      .corporation_tit{
        height: 40px;
        line-height: 40px;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #0089EF;
        background: #F6FAFF;
        border-radius: 3px;
        border: 1px solid #E9F1FC;
        width: 96%;
        margin:20px auto 0;
        overflow: hidden;
        span{margin-left: 26px;}
      }
      .ContactInformation_form{
        width: 96%;
        margin: auto;
      }
      .corporation_tit_right{
        float: right;
        padding-right: 20px;
        cursor: pointer;

      }
    }
    .settlingProcess_form_Sign{
      .el-input__inner{border: 0;}
    }
    .el-backtop{
      color: #0089EF;
    }
  }
  .settlingProcess_form_but{
    height: 50px;
    font-size: 26px;
    border: 0;
    background: transparent;
    cursor: pointer;
  }
</style>

<template>
  <div class="Industrial">
    <el-breadcrumb separator-class="el-icon-arrow-right" class="DetailsConsultation_breadcrumb">
      <el-breadcrumb-item :to="{ path: '/' }">
        <i class="el-icon-s-home"></i>首页
      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: title.path }">{{ title.val }}</el-breadcrumb-item>
      <el-breadcrumb-item>{{ valDetails.title }}</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="Industrial_in" v-if='showLoading'>
      <div class="Industrial_name">
        <div>
          <h4 class="Industrial_name_h4" v-if="type == 6">{{ valDetails.title }}</h4>
          <img :src="valDetails.thumb" alt="" />
        </div>
        <div class="Industrial_name_right">
          <h4>{{ valDetails.title }}</h4>
          <div>
            <p class="Industrial_name_right_text">{{ valDetails.fields.jigouxingzhi }}：<span>{{ valDetails.jigouxingzhi
            }}</span></p>
            <p class="Industrial_name_right_text">{{ valDetails.fields.zhucedidian }}：<span>{{ valDetails.zhucedidian
            }}</span></p>
            <p class="Industrial_name_right_text">
              {{ valDetails.fields.chengliriqi }}：<span>{{ valDetails.chengliriqi ? valDetails.chengliriqi.split(" ")[0] :
                valDetails.clsj.split(" ")[0]
              }}</span></p>
            <p class="Industrial_name_right_text">{{ valDetails.fields.fddbr }}：<span>{{ valDetails.fddbr }}</span></p>
            <p class="Industrial_name_right_text">{{ valDetails.fields.gfwz }}：<span style="cursor: pointer;"
                @click="openWeb(valDetails.gfwz)">{{ valDetails.gfwz }}/</span></p>
            <p class="Industrial_name_right_text">{{ valDetails.fields.touzijieduan }}：<span>{{ valDetails.touzijieduan
            }}</span></p>
          </div>
        </div>
      </div>
      <div>
        <!-- <el-tabs v-model="activeName" @tab-click="handleClick" class="Industrial_label">
        <el-tab-pane v-for="(item, index) in label" :key="index" :label="item.label" :name="item.label"></el-tab-pane>
      </el-tabs> -->
        <div class="ydd_Industrial_name" style="display: none;">
          <el-row :gutter="20">
            <el-col :span="8"><img class="ydd_Industrial_img" src="../../public/img/img16.png" alt=""></el-col>
            <el-col :span="16">
              <h4 class="ydd_Industrial_h4">{{ valDetails.title }}</h4>
              <el-row>
                <el-col :span="24">
                  <div class="ydd_Industrial_label_lab">
                    <p>{{ valDetails.fields.jigouxingzhi }}</p>{{ valDetails.jigouxingzhi }}
                  </div>
                </el-col>
                <el-col :span="24">
                  <div class="ydd_Industrial_label_lab">
                    <p>注册地点</p>{{ valDetails.zhucedidian }}
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <div class="ydd_Industrial_label_lab">
                    <p>成立时间</p>{{ valDetails.chengliriqi ? valDetails.chengliriqi : valDetails.clsj }}
                  </div>
                </el-col>
                <el-col :span="24">
                  <div class="ydd_Industrial_label_lab">
                    <p>投资总部</p>{{ valDetails.fddbr }}
                  </div>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <div class="ydd_Industrial_label_lab">
                <p>官方网站</p>{{ valDetails.gfwz }}
              </div>
            </el-col>
            <el-col :span="12">
              <div class="ydd_Industrial_label_lab">
                <p>投资阶段</p>{{ valDetails.touzijieduan ? valDetails.touzijieduan : '暂无' }}
              </div>
            </el-col>
          </el-row>
        </div>
        <div id="AnOverview" v-if="typeName == '法务公司' || typeName == '证券所'">
          <h3 class="Industrial_title"><img src="../../public/img/img17.png" alt="" /><span>{{ type == 5 ?
            '机构概况' : '企业概况' }}</span></h3>
          <div class="AnOverview" v-html="valDetails.content ? valDetails.content : '暂无'"></div>
        </div>
        <div id="registered">
          <h3 class="Industrial_title"><img src="../../public/img/img17.png" alt="" /><span>工商注册</span></h3>
          <div class="registered">
            <el-row>
              <el-col :span="12">
                <div class="registered_div">
                  <div class="registered_left">
                    <div>
                      <p>{{ valDetails.fields.title }}</p>
                    </div>
                  </div>
                  <div class="registered_right">
                    <p>{{ valDetails.title ? valDetails.title : '暂无' }}</p>
                  </div>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="registered_div">
                  <div class="registered_left">
                    <div>
                      <p>{{ valDetails.fields.tyshxydm }}</p>
                    </div>
                  </div>
                  <div class="registered_right">
                    <p>{{ valDetails.tyshxydm ? valDetails.tyshxydm : '暂无' }}</p>
                  </div>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="registered_div">
                  <div class="registered_left">
                    <div>
                      <p>{{ valDetails.fields.zhuceziben }}</p>
                    </div>
                  </div>
                  <div class="registered_right">
                    <p>{{ valDetails.zhuceziben ? valDetails.zhuceziben : '暂无' }}元</p>
                  </div>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="registered_div">
                  <div class="registered_left">
                    <div>
                      <p>{{ valDetails.fields.clsj }}</p>
                    </div>
                  </div>
                  <div class="registered_right">
                    <p>{{ valDetails.clsj ? valDetails.clsj.split(" ")[0] : '暂无' }}</p>
                  </div>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="registered_div">
                  <div class="registered_left">
                    <div>
                      <p>{{ valDetails.fields.gslx }}</p>
                    </div>
                  </div>
                  <div class="registered_right">
                    <p>{{ valDetails.gslx ? valDetails.gslx : '暂无' }}</p>
                  </div>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="registered_div">
                  <div class="registered_left">
                    <div>
                      <p>{{ valDetails.fields.fddbr }}</p>
                    </div>
                  </div>
                  <div class="registered_right">
                    <p>{{ valDetails.fddbr ? valDetails.fddbr : '暂无' }}</p>
                  </div>
                </div>
              </el-col>
              <el-col :span="24">
                <div class="registered_div">
                  <div class="registered_left">
                    <div>
                      <p>{{ valDetails.fields.jyzt }}</p>
                    </div>
                  </div>
                  <div class="registered_right">
                    <p>{{ valDetails.jyzt ? valDetails.jyzt : '暂无' }}</p>
                  </div>
                </div>
              </el-col>
              <el-col :span="24">
                <div class="registered_div">
                  <div class="registered_left">
                    <div>
                      <p>{{ valDetails.fields.xingye }}</p>
                    </div>
                  </div>
                  <div class="registered_right">
                    <p>{{ valDetails.xingye ? valDetails.xingye : '暂无' }}</p>
                  </div>
                </div>
              </el-col>
              <el-col :span="24">
                <div class="registered_div">
                  <div class="registered_left">
                    <div>
                      <p>{{ valDetails.fields.jyfw }}</p>
                    </div>
                  </div>
                  <div class="registered_right">
                    <p>{{ valDetails.jyfw ? valDetails.jyfw : '暂无' }}</p>
                  </div>
                </div>
              </el-col>
              <el-col :span="24">
                <div class="registered_div">
                  <div class="registered_left">
                    <div>
                      <p>{{ valDetails.fields.dianhua }}</p>
                    </div>
                  </div>
                  <div class="registered_right">
                    <p v-if="type == 6">{{ valDetails.dianhua }}</p>
                    <p v-if="type != 6">{{ valDetails.lxfs ? valDetails.lxfs : '暂无' }}</p>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
          <div class="ydd_registered" style="display: none;">
            <el-row>
              <el-col :span="24" class="ydd_registered_label">
                <div>企业名称：</div>
                <p>{{ valDetails.title ? valDetails.title : '暂无' }}</p>
              </el-col>
              <el-col :span="24" class="ydd_registered_label">
                <div>统一社会信用代码：</div>
                <p>{{ valDetails.tyshxydm ? valDetails.tyshxydm : '暂无' }}</p>
              </el-col>
              <el-col :span="24" class="ydd_registered_label">
                <div>注册资本：</div>
                <p>{{ valDetails.zhuceziben ? valDetails.zhuceziben : '暂无' }}元</p>
              </el-col>
              <el-col :span="24" class="ydd_registered_label">
                <div>成立日期：</div>
                <p>{{ valDetails.clsj ? valDetails.clsj : '暂无' }}</p>
              </el-col>
              <el-col :span="12" class="ydd_registered_label">
                <div>公司类型：</div>
                <p>{{ valDetails.gslx ? valDetails.gslx : '暂无' }}</p>
              </el-col>
              <el-col :span="12" class="ydd_registered_label">
                <div>法定代表人：</div>
                <p>{{ valDetails.fddbr ? valDetails.fddbr : '暂无' }}</p>
              </el-col>
              <el-col :span="12" class="ydd_registered_label">
                <div>经营状态：</div>
                <p>{{ valDetails.jyzt ? valDetails.jyzt : '暂无' }}</p>
              </el-col>
              <el-col :span="12" class="ydd_registered_label">
                <div>行业：</div>
                <p>{{ valDetails.xingye ? valDetails.xingye : '暂无' }}</p>
              </el-col>
              <el-col :span="24" class="ydd_registered_label">
                <div>经营范围：</div>
                <p>{{ valDetails.jyfw ? valDetails.jyfw : '暂无' }}</p>
              </el-col>
              <el-col :span="24" class="ydd_registered_label">
                <div>联系方式：</div>
                <p v-if="type == 6">{{ valDetails.dianhua ? valDetails.dianhua : '暂无' }}</p>
                <p v-if="type != 6">{{ valDetails.lxfs ? valDetails.lxfs : '暂无' }}</p>
              </el-col>
            </el-row>
          </div>
        </div>
        <div id="direction11" v-if="typeName == '法务公司'">
          <h3 class="Industrial_title"><img src="../../public/img/img17.png" alt="" /><span>助力IPO上市项目</span></h3>
          <div class="direction">
            <el-table stripe border :cell-style="tableStyle" :data="typeList.directionData" style="width: 100%">
              <el-table-column label="序号" width="78" type="index"></el-table-column>
              <el-table-column :label="change[7].fields.title" prop="title">
              </el-table-column>
              <el-table-column :label="change[7].fields.xingye" prop="xingye">
              </el-table-column>
              <el-table-column :label="change[7].fields.fuzeren" prop="fuzeren">
              </el-table-column>
              <el-table-column :label="change[7].fields.diqu" prop="diqu">
              </el-table-column>
              <el-table-column :label="change[7].fields.chengliriqi" prop="chengliriqi">
              </el-table-column>
            </el-table>
            <el-pagination class="pagination" background :current-page="change[7].page" :page-size="change[7].pagesize"
              layout="total, sizes, prev, pager, next, jumper" @size-change="sizeChange($event, 7)"
              @current-change="currentChange($event, 7)" :total="change[7].total" prev-text="上一页" next-text="下一页">
            </el-pagination>
          </div>
        </div>
        <div id="parkPolicy1" v-if="typeName == '证券所'">
          <h3 class="Industrial_title"><img src="../../public/img/img17.png" alt="" /><span>保荐项目</span></h3>
          <div class="style condition">
            <el-table stripe border :cell-style="tableStyle" :data="typeList.parkPolicy1" style="width: 100%">
              <el-table-column label="序号" width="78" type="index"></el-table-column>
              <el-table-column :label="change[0].fields.title" prop="title"></el-table-column>
              <el-table-column :label="change[0].fields.gufenleixing" prop="gufenleixing"></el-table-column>
              <el-table-column :label="change[0].fields.gufenfengge" prop="gufenfengge"></el-table-column>
              <el-table-column label="操作" width="120">
                <template slot-scope="scope">
                  <el-button type="text" @click="ccasdawz(scope.row)">查看</el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination class="pagination" background :current-page="change[0].page" :page-size="change[0].pagesize"
              layout="total, sizes, prev, pager, next, jumper" @size-change="sizeChange($event, 0)"
              @current-change="currentChange($event, 0)" :total="change[0].total" prev-text="上一页" next-text="下一页">
            </el-pagination>
          </div>
        </div>
        <div id="direction" v-if="typeName == '法务公司' || typeName == '证券所'">
          <h3 class="Industrial_title"><img src="../../public/img/img17.png" alt="" /><span>{{ typeName ==
            '法务公司' ? '法律顾问项目' : '投资方向/项目' }}</span></h3>
          <div class="direction">
            <el-table stripe border :cell-style="tableStyle" :data="typeList.direction" style="width: 100%">
              <el-table-column label="序号" width="78" type="index"></el-table-column>
              <el-table-column :label="change[1].fields.title" prop="title"></el-table-column>
              <el-table-column :label="change[1].fields.touzibili" prop="touzibili"></el-table-column>
              <el-table-column :label="change[1].fields.description" prop="description"></el-table-column>
              <el-table-column label="状态" width="120">
                <template slot-scope="scope">
                  <el-button type="text" @click="ccasdawz(scope.row)">查看</el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination class="pagination" background :current-page="change[1].page" :page-size="change[1].pagesize"
              layout="total, sizes, prev, pager, next, jumper" @size-change="sizeChange($event, 1)"
              @current-change="currentChange($event, 1)" :total="change[1].total" prev-text="上一页" next-text="下一页">
            </el-pagination>
          </div>
        </div>
        <div id="direction211" v-if="typeName == '证券所'">
          <h3 class="Industrial_title"><img src="../../public/img/img17.png" alt="" /><span>机构荣誉</span></h3>
          <div class="direction">
            <el-table stripe border :cell-style="tableStyle" :data="typeList.directionData1" style="width: 100%">
              <el-table-column label="序号" width="78" type="index"></el-table-column>
              <el-table-column :label="change[12].fields.title" prop="title">
              </el-table-column>
              <el-table-column :label="change[12].fields.paiming" prop="paiming">
              </el-table-column>
              <el-table-column :label="change[12].fields.fabushijian" prop="fabushijian">
              </el-table-column>
            </el-table>
            <el-pagination class="pagination" background :current-page="change[12].page" :page-size="change[12].pagesize"
              layout="total, sizes, prev, pager, next, jumper" @size-change="sizeChange($event, 12)"
              @current-change="currentChange($event, 12)" :total="change[12].total" prev-text="上一页" next-text="下一页">
            </el-pagination>
          </div>
        </div>
        <div id="toraisess" v-if="typeName == '证券所'">
          <h3 class="Industrial_title"><img src="../../public/img/img17.png" alt="" />
            <span>经营状况</span>
          </h3>
          <div class="direction condition">
            <el-table stripe border :cell-style="tableStyle" :data="typeList.directionData2" style="width: 100%">
              <el-table-column label="序号" width="78" type="index"></el-table-column>
              <el-table-column :label="change[13].fields.baogao" prop="baogao">
              </el-table-column>
              <el-table-column :label="change[13].fields.nianyue" prop="nianyue">
              </el-table-column>
              <el-table-column :label="change[13].fields.leixing" prop="leixing">
              </el-table-column>
              <el-table-column :label="change[13].fields.chaxun" width="120">
                <template slot-scope="scope">
                  <el-button type="text" @click="ccasdawz(scope.row.id)">查看</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <el-pagination class="pagination" background :current-page="change[13].page" :page-size="change[13].pagesize"
            layout="total, sizes, prev, pager, next, jumper" @size-change="sizeChange($event, 13)"
            @current-change="currentChange($event, 13)" prev-text="上一页" next-text="下一页" :total="change[13].total">
          </el-pagination>
        </div>
        <!-- <div id="parkPolicy">
        <h3 class="Industrial_title"><img src="../../public/img/img17.png" alt="" /><span>机构风格</span></h3>
        <div class="style condition">
          <el-table stripe border :cell-style="tableStyle" :data="typeList.parkPolicy" style="width: 100%">
            <el-table-column label="序号" width="78" type="index"></el-table-column>
            <el-table-column label="股东名称" prop="title"></el-table-column>
            <el-table-column label="股东类型" prop="description"></el-table-column>
            <el-table-column label="风格" prop="fengge"></el-table-column>
            <el-table-column label="状态" width="120">
              <template slot-scope="scope">
                <el-button type="text" @click="ccasdawz(scope.row)">查看</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination class="pagination" background :current-page="change[0].page" :page-size="change[0].pagesize"
            layout="total, sizes, prev, pager, next, jumper" @size-change="sizeChange($event, 0)"
            @current-change="currentChange($event, 0)" :total="change[0].total" prev-text="上一页" next-text="下一页">
          </el-pagination>
        </div>
      </div> -->
        <!-- <div id="direction112" v-if="typeName == '法务公司'">
        <h3 class="Industrial_title"><img src="../../public/img/img17.png" alt="" /><span>法律顾问项目</span></h3>
        <div class="direction">
          <el-table stripe border :cell-style="tableStyle" :data="typeList.direction1" style="width: 100%">
            <el-table-column label="序号" width="78" type="index"></el-table-column>
            <el-table-column :label="change[8].fields.title" prop="title"></el-table-column>
            <el-table-column :label="change[8].fields.hangye" prop="hangye"></el-table-column>
            <el-table-column :label="change[8].fields.fuzeren" prop="fuzeren"></el-table-column>
            <el-table-column :label="change[8].fields.diqu" prop="diqu"></el-table-column>
            <el-table-column :label="change[8].fields.content" prop="content"></el-table-column>
            <el-table-column :label="change[8].fields.chengliriqi" prop="chengliriqi"></el-table-column>
            <el-table-column label="状态" width="120">
              <template slot-scope="scope">
                <el-button type="text" @click="ccasdawz(scope.row)">查看</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination class="pagination" background :current-page="change[8].page" :page-size="change[8].pagesize"
            layout="total, sizes, prev, pager, next, jumper" @size-change="sizeChange($event, 8)"
            @current-change="currentChange($event, 8)" :total="change[8].total" prev-text="上一页" next-text="下一页">
          </el-pagination>
        </div>
      </div> -->
        <div id="direction121" v-if="typeName == '法务公司'">
          <h3 class="Industrial_title"><img src="../../public/img/img17.png" alt="" /><span>代理客户</span></h3>
          <div class="direction">
            <el-table stripe border :cell-style="tableStyle" :data="typeList.direction2" style="width: 100%">
              <el-table-column label="序号" width="78" type="index"></el-table-column>
              <el-table-column :label="change[9].fields.dlkhaj" prop="dlkhaj"></el-table-column>
              <el-table-column :label="change[9].fields.fddbr" prop="fddbr"></el-table-column>
              <el-table-column :label="change[9].fields.title" prop="title"></el-table-column>
              <el-table-column :label="change[9].fields.zhuceshijian" prop="zhuceshijian"></el-table-column>
              <el-table-column :label="change[9].fields.zhuceziben" prop="zhuceziben"></el-table-column>
            </el-table>
            <el-pagination class="pagination" background :current-page="change[9].page" :page-size="change[9].pagesize"
              layout="total, sizes, prev, pager, next, jumper" @size-change="sizeChange($event, 9)"
              @current-change="currentChange($event, 9)" :total="change[9].total" prev-text="上一页" next-text="下一页">
            </el-pagination>
          </div>
        </div>
        <!-- <div id="toraise" v-if="typeName != '证券所'&&typeName == '法务公司'">
        <h3 class="Industrial_title"><img src="../../public/img/img17.png" alt="" />
          <span>募资状况</span>
        </h3>
        <div class="direction condition">
          <el-table stripe border :cell-style="tableStyle" :data="fundraisingStatusData" style="width: 100%">
            <el-table-column label="序号" width="78" type="index"></el-table-column>
            <el-table-column label="股东名称" prop="title">
            </el-table-column>
            <el-table-column label="股份类型" prop="gufenleixing">
            </el-table-column>
            <el-table-column label="持股数量（股）" prop="cgsl">
            </el-table-column>
            <el-table-column label="持股比例" prop="chigubili">
            </el-table-column>
            <el-table-column label="状态" width="120">
              <template slot-scope="scope">
                <el-button type="text" @click="ccasdawz(scope.row.id)">查看</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-pagination class="pagination" background :current-page="fundraisingStatus.page"
          :page-size="fundraisingStatus.pagesize" layout="total, sizes, prev, pager, next, jumper"
          @size-change="fundraisingStatusSize" @current-change="fundraisingStatusChange" prev-text="上一页" next-text="下一页"
          :total="fundraisingStatus.total">
        </el-pagination>
      </div> -->
        <!-- <div id="project">
        <h3 class="Industrial_title"><img src="../../public/img/img17.png" alt="" /><span>机构项目</span></h3>
        <div class="project">
          <div class="project_list" v-for="(d, i) in typeList.project" :key="i" @click="openWeb(d.gfwz)">
            <img :src="d.gongsilog" alt="" />
            <h4>{{ d.title }}</h4>
            <div><i class="el-icon-arrow-right"></i>{{ d.description }}</div>
          </div>
        </div>
        <el-pagination class="pagination" background :current-page="change[2].page" :page-size="change[2].pagesize"
          layout="total, sizes, prev, pager, next, jumper" @size-change="sizeChange($event, 2)"
          @current-change="currentChange($event, 2)" :total="change[2].total" prev-text="上一页" next-text="下一页">
        </el-pagination>
      </div> -->
        <div id="consulting">
          <h3 class="Industrial_title"><img src="../../public/img/img17.png" alt="" /><span>相关资讯</span></h3>
          <div class="consulting">
            <div class="List_Information" v-for="(item, index) in RelatedInformationList" :key="index"
              @click="zcasdasdas(item)">
              <div class="List_Information_left"><img :src="item.thumb" alt=""></div>
              <div class="List_Information_right">
                <h6>{{ item.title }}</h6>
                <p>{{ item.description }}</p>
              </div>
            </div>
            <el-pagination class="pagination" background :current-page="RelatedInformation.page"
              :page-size="RelatedInformation.pagesize" layout="total, sizes, prev, pager, next, jumper"
              @size-change="RelatedInformationSize($event, 2)" @current-change="RelatedInformationCurrent($event, 2)"
              :total="RelatedInformation.total" prev-text="上一页" next-text="下一页">
            </el-pagination>
          </div>
        </div>
        <div id="PrimaryTeam">
          <h3 class="Industrial_title"><img src="../../public/img/img17.png" alt="" /><span>主要团队</span></h3>
          <div class="project">
            <div class="project_list" v-for="(d, i) in typeList.PrimaryTeam" :key="i" @click="ccasdawz(d)">
              <img :src="d.thumb" alt="" />
              <h4>{{ d.title }}</h4>
              <div><i class="el-icon-arrow-right"></i>{{ d.fubiaoti }}</div>
            </div>
          </div>
          <el-pagination class="pagination" background :current-page="change[3].page" :page-size="change[3].pagesize"
            layout="total, sizes, prev, pager, next, jumper" @size-change="sizeChange($event, 3)"
            @current-change="currentChange($event, 3)" :total="change[3].total" prev-text="上一页" next-text="下一页">
          </el-pagination>
        </div>
        <div id="establishFund5" v-if="typeName == '证券所'">
          <h3 class="Industrial_title"><img src="../../public/img/img17.png" alt="" /><span>设立基金</span></h3>
          <div class="direction condition">
            <el-table stripe border :cell-style="tableStyle" :data="typeList.establishFund" style="width: 100%">
              <el-table-column label="序号" width="78" type="index"></el-table-column>
              <el-table-column :label="change[4].fields.title" prop="title"></el-table-column>
              <el-table-column :label="change[4].fields.jjglr" prop="jjglr"></el-table-column>
              <el-table-column :label="change[4].fields.jijinleixing" prop="jijinleixing"></el-table-column>
              <el-table-column :label="change[4].fields.jjzt" prop="jjzt"></el-table-column>
              <el-table-column :label="change[4].fields.content" prop="content"></el-table-column>
            </el-table>
          </div>
          <el-pagination class="pagination" background :current-page="change[4].page" :page-size="change[4].pagesize"
            layout="total, sizes, prev, pager, next, jumper" @size-change="sizeChange($event, 4)"
            @current-change="currentChange($event, 4)" :total="change[4].total" prev-text="上一页" next-text="下一页">
          </el-pagination>
        </div>
        <div id="establishFund" v-if="typeName == '法务公司'">
          <h3 class="Industrial_title"><img src="../../public/img/img17.png" alt="" /><span>代理案件</span></h3>
          <div class="direction condition">
            <el-table stripe border :cell-style="tableStyle" :data="typeList.direction3" style="width: 100%">
              <el-table-column label="序号" width="78" type="index"></el-table-column>
              <el-table-column :label="change[10].fields.title" prop="title"></el-table-column>
              <el-table-column :label="change[10].fields.panjueanhao" prop="panjueanhao"></el-table-column>
              <el-table-column :label="change[10].fields.ajsf" prop="ajsf"></el-table-column>
              <el-table-column :label="change[10].fields.panjueriqi" prop="panjueriqi"></el-table-column>
            </el-table>
          </div>
          <el-pagination class="pagination" background :current-page="change[10].page" :page-size="change[10].pagesize"
            layout="total, sizes, prev, pager, next, jumper" @size-change="sizeChange($event, 10)"
            @current-change="currentChange($event, 10)" :total="change[10].total" prev-text="上一页" next-text="下一页">
          </el-pagination>
        </div>
        <div id="direction12" v-if="typeName == '证券所'">
          <h3 class="Industrial_title"><img src="../../public/img/img17.png" alt="" /><span>解读报告</span></h3>
          <div class="direction">
            <el-table stripe border :cell-style="tableStyle" :data="typeList.directionData3" style="width: 100%">
              <el-table-column label="序号" width="78" type="index"></el-table-column>
              <el-table-column label="名称" prop="description">
              </el-table-column>
              <el-table-column label="时间" prop="status">
              </el-table-column>
            </el-table>
            <el-pagination class="pagination" background :current-page="change[14].page" :page-size="change[14].pagesize"
              layout="total, sizes, prev, pager, next, jumper" @size-change="sizeChange($event, 14)"
              @current-change="currentChange($event, 14)" prev-text="上一页" next-text="下一页" :total="change[14].total">
            </el-pagination>
          </div>
        </div>
        <div id="financial" v-if="typeName == '证券所'">
          <h3 class="Industrial_title"><img src="../../public/img/img17.png" alt="" /><span>主要产品</span></h3>
          <div class="pc_financial">
            <div class="financial" v-for="(i, t) in typeList.financial" :key="t">
              <div class="financial_left" style="height: 100%;"><img :src="i.thumb" alt=""></div>
              <div class="financial_right">
                <h3>{{ i.title }}</h3>
                <el-row>
                  <el-col :span="9">
                    <p class="financial_text">发电机组型号 {{ i.fdjzxh }}</p>
                    <p class="financial_text">柴油机 型号 {{ i.cyjxh }}</p>
                    <p class="financial_text">行程MM {{ i.xingchengmm }}</p>
                    <p class="financial_text">额定功率KW {{ i.edglkw }}</p>
                    <p class="financial_text">额定电流A {{ i.eddla }}</p>
                    <p class="financial_text">额定功率KW {{ i.eddla }}</p>
                    <p class="financial_text">额定转速r/min {{ i.edingzhuansu }}</p>
                  </el-col>
                  <el-col :span="8">
                    <p class="financial_text">额定频率Hz {{ i.edingpinlu }}</p>
                    <p class="financial_text">额定电压V {{ i.edingdianyav }}</p>
                    <p class="financial_text">相数及接法 {{ i.xsjjf }}</p>
                    <p class="financial_text">输出路数 {{ i.shuchulushu }}</p>
                    <p class="financial_text">额定功率因数 {{ i.edglys }}</p>
                    <p class="financial_text">燃油消耗率g/kwh {{ i.ryxh }}</p>
                    <p class="financial_text">机油消耗率g/kwh {{ i.jyxhl }}</p>
                  </el-col>
                  <el-col :span="7">
                    <p class="financial_text">控制方法 {{ i.kzff }}</p>
                    <p class="financial_text">电压 {{ i.dianya }}</p>
                    <p class="financial_text">频态调整率% {{ i.pinlu }}</p>
                    <p class="financial_text">稳定时间S 1 </p>
                    <p class="financial_text">波动率 0.5 </p>
                    <p class="financial_text">频率 稳态调整率% {{ i.wdsjs }}</p>
                    <p class="financial_text">频态调整率% {{ i.pintai }}</p>
                  </el-col>
                </el-row>
              </div>
            </div>
            <el-pagination class="pagination" background :current-page="change[5].page" :page-size="change[5].pagesize"
              layout="total, sizes, prev, pager, next, jumper" @size-change="sizeChange($event, 5)"
              @current-change="currentChange($event, 5)" :total="change[5].total" prev-text="上一页" next-text="下一页">
            </el-pagination>
          </div>
          <div class="ydd_financial" style="display: none;">
            <div class="ydd_financial_list" v-for="(i, t) in typeList.financial" :key="t">
              <el-row :gutter="30">
                <el-col :span="12"><img class="ydd_financial_list_img" :src="i.thumb" alt=""></el-col>
                <el-col :span="12">
                  <h3 class="ydd_financial_list_h3">{{ i.title }}</h3>
                </el-col>
              </el-row>
              <el-row style="padding: 50px 0;">
                <el-col :span="14">
                  <p class="financial_text">发电机组型号 {{ i.fdjzxh }}</p>
                  <p class="financial_text">柴油机 型号 {{ i.cyjxh }}</p>
                  <p class="financial_text">行程MM {{ i.xingchengmm }}</p>
                  <p class="financial_text">额定功率KW {{ i.edglkw }}</p>
                  <p class="financial_text">额定电流A {{ i.eddla }}</p>
                  <p class="financial_text">额定功率KW {{ i.eddla }}</p>
                  <p class="financial_text">额定转速r/min {{ i.edingzhuansu }}</p>
                </el-col>
                <el-col :span="10">
                  <p class="financial_text">额定频率Hz {{ i.edingpinlu }}</p>
                  <p class="financial_text">额定电压V {{ i.edingdianyav }}</p>
                  <p class="financial_text">相数及接法 {{ i.xsjjf }}</p>
                  <p class="financial_text">输出路数 {{ i.shuchulushu }}</p>
                  <p class="financial_text">额定功率因数 {{ i.edglys }}</p>
                  <p class="financial_text">燃油消耗率g/kwh {{ i.ryxh }}</p>
                  <p class="financial_text">机油消耗率g/kwh {{ i.jyxhl }}</p>
                </el-col>
              </el-row>
            </div>
            <el-pagination class="pagination" background :current-page="change[5].page" :page-size="change[5].pagesize"
              layout="total, sizes, prev, pager, next, jumper" @size-change="sizeChange($event, 5)"
              @current-change="currentChange($event, 5)" :total="change[5].total" prev-text="上一页" next-text="下一页">
            </el-pagination>
          </div>
        </div>
        <div id="toraise11" v-if="typeName == '法务公司'">
          <h3 class="Industrial_title"><img src="../../public/img/img17.png" alt="" />
            <span>汽车领域法律服务</span>
          </h3>
          <div class="direction condition">
            <p v-for="(item, index) in fundraisingStatusData.qclyflfw" :key="index" style="margin: 6px">
              {{ fundraisingStatusData.checkbox_qclyflfw[item] }}
            </p>
          </div>
          <el-pagination class="pagination" background :current-page="fundraisingStatus.page"
            :page-size="fundraisingStatus.pagesize" layout="total, sizes, prev, pager, next, jumper"
            @size-change="fundraisingStatusSize" @current-change="fundraisingStatusChange" prev-text="上一页" next-text="下一页"
            :total="fundraisingStatus.total">
          </el-pagination>
        </div>
        <div id="toraise1" v-if="typeName == '法务公司'">
          <h3 class="Industrial_title"><img src="../../public/img/img17.png" alt="" />
            <span>业绩成就</span>
          </h3>
          <div class="direction condition">
            <el-table stripe border :cell-style="tableStyle" :data="typeList.direction4" style="width: 100%">
              <el-table-column label="序号" width="78" type="index"></el-table-column>
              <el-table-column :label="change[11].fields.title" prop="title">
              </el-table-column>
              <el-table-column :label="change[11].fields.shijian" prop="shijian">
              </el-table-column>
              <el-table-column :label="change[11].fields.description" prop="description">
              </el-table-column>
              <el-table-column :label="change[11].fields.content" prop="content">
              </el-table-column>
            </el-table>
          </div>
          <el-pagination class="pagination" background :current-page="change[11].page" :page-size="change[11].pagesize"
            layout="total, sizes, prev, pager, next, jumper" @size-change="sizeChange($event, 11)"
            @current-change="currentChange($event, 11)" prev-text="上一页" next-text="下一页" :total="change[11].total">
          </el-pagination>
        </div>
        <!-- <div id="toraise">
          <h3 class="Industrial_title"><img src="../../public/img/img17.png" alt="" />
            <span>机构状况</span>
          </h3>
          <div class="direction condition">
            <el-table stripe border :cell-style="tableStyle" :data="typeList.toraise" style="width: 100%">
              <el-table-column label="序号" width="78" type="index"></el-table-column>
              <el-table-column :label="change[6].fields.title" prop="title"></el-table-column>
              <el-table-column :label="change[6].fields.description" prop="description"></el-table-column>
              <el-table-column :label="change[6].fields.chigubili" prop="chigubili"></el-table-column>
              <el-table-column :label="change[6].fields.cgsl" prop="cgsl"></el-table-column>
              <el-table-column label="状态" width="120">
                <template slot-scope="scope">
                  <el-button type="text" @click="ccasdawz(scope.row)">查看</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <el-pagination class="pagination" background :current-page="change[6].page" :page-size="change[6].pagesize"
            layout="total, sizes, prev, pager, next, jumper" @size-change="sizeChange($event, 6)"
            @current-change="currentChange($event, 6)" :total="change[6].total" prev-text="上一页" next-text="下一页">
          </el-pagination>
        </div> -->
        <div id="enterprise" v-if="typeName == '法务公司' || typeName == '财税审计公司'">
          <h3 class="Industrial_title"><img src="../../public/img/img17.png" alt="" /><span>同类企业</span></h3>
          <div class="project">
            <div class="project_list" v-for="(d, i) in SimilarEnterpriseList" :key="i" @click="zxczcasd(d)">
              <img :src="d.thumb" alt="" />
              <h4>{{ d.title }}</h4>
              <div><i class="el-icon-arrow-right"></i> {{ d.description }}</div>
            </div>
          </div>
          <el-pagination class="pagination" background :current-page="SimilarEnterprise.page"
            :page-size="SimilarEnterprise.pagesize" layout="total, sizes, prev, pager, next, jumper"
            @size-change="SimilarEnterpriseSize($event, 2)" @current-change="SimilarEnterpriseCurrent($event, 2)"
            :total="SimilarEnterprise.total" prev-text="上一页" next-text="下一页">
          </el-pagination>
        </div>
        <div id="way">
          <h3 class="Industrial_title"><img src="../../public/img/img17.png" alt="" /><span>联系方式</span></h3>
          <div class="way registered">
            <el-row>
              <el-col :span="24">
                <div class="registered_div">
                  <div class="registered_left way_list_left">
                    <div>
                      <p>公司名称</p>
                    </div>
                  </div>
                  <div class="registered_right way_list_right">
                    <p>{{ valDetails.title ? valDetails.title : '无' }}</p>
                  </div>
                </div>
              </el-col>
              <el-col :span="24">
                <div class="registered_div">
                  <div class="registered_left way_list_left">
                    <div>
                      <p>地址</p>
                    </div>
                  </div>
                  <div class="registered_right way_list_right">
                    <p>{{ valDetails.dizhi ? valDetails.dizhi : '无' }}</p>
                  </div>
                </div>
              </el-col>
              <el-col :span="24">
                <div class="registered_div">
                  <div class="registered_left way_list_left">
                    <div>
                      <p>邮编</p>
                    </div>
                  </div>
                  <div class="registered_right way_list_right">
                    <p>{{ valDetails.youbian ? valDetails.youbian : '无' }}</p>
                  </div>
                </div>
              </el-col>
              <el-col :span="24">
                <div class="registered_div">
                  <div class="registered_left way_list_left">
                    <div>
                      <p>电话</p>
                    </div>
                  </div>
                  <div class="registered_right way_list_right">
                    <p>{{ valDetails.lxfs ? valDetails.lxfs : '无' }}</p>
                  </div>
                </div>
              </el-col>
              <el-col :span="24">
                <div class="registered_div">
                  <div class="registered_left way_list_left">
                    <div>
                      <p>网址</p>
                    </div>
                  </div>
                  <div class="registered_right way_list_right">
                    <p>{{ valDetails.gfwz ? valDetails.gfwz : '无' }}</p>
                  </div>
                </div>
              </el-col>
              <el-col :span="24">
                <div class="registered_div">
                  <div class="registered_left way_list_left">
                    <div>
                      <p>联系人</p>
                    </div>
                  </div>
                  <div class="registered_right way_list_right">
                    <p>{{ valDetails.lianxiren ? valDetails.lianxiren : '无' }}</p>
                  </div>
                </div>
              </el-col>
              <el-col :span="24">
                <div class="registered_div">
                  <div class="registered_left way_list_left">
                    <div>
                      <p>微信</p>
                    </div>
                  </div>
                  <div class="registered_right way_list_right">
                    <p>{{ valDetails.weixin ? valDetails.weixin : '无' }}</p>
                  </div>
                </div>
              </el-col>
              <el-col :span="24">
                <div class="registered_div">
                  <div class="registered_left way_list_left">
                    <div>
                      <p>邮箱</p>
                    </div>
                  </div>
                  <div class="registered_right way_list_right">
                    <p>{{ valDetails.youxiang ? valDetails.youxiang : '无' }}</p>
                  </div>
                </div>
              </el-col>
              <el-col :span="24">
                <div class="registered_div">
                  <div class="registered_left way_list_left">
                    <div>
                      <p>抖音</p>
                    </div>
                  </div>
                  <div class="registered_right way_list_right">
                    <p>{{ valDetails.douyin ? valDetails.douyin : '无' }}</p>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
    <div v-else v-loading="!showLoading" element-loading-text="拼命加载中" style="height:67vh"></div>
  </div>
</template>

<script>
import request from '@/api/request.js'
import commonUtils from '@/utils/CommonUtils.js'
export default {
  data() {
    return {
      showLoading: false,
      activeName: '企业概况',
      label: [],
      tableData: [],
      change: [
        { page: 1, pagesize: 10, total: 0, fields: {} },
        { page: 1, pagesize: 10, total: 0, fields: {} },
        { page: 1, pagesize: 10, total: 0, fields: {} },
        { page: 1, pagesize: 10, total: 0, fields: {} },
        { page: 1, pagesize: 10, total: 0, fields: {} },
        { page: 1, pagesize: 10, total: 0, fields: {} },
        { page: 1, pagesize: 10, total: 0, fields: {} },
        { page: 1, pagesize: 10, total: 0, fields: {} },
        { page: 1, pagesize: 10, total: 0, fields: {} },
        { page: 1, pagesize: 10, total: 0, fields: {} },
        { page: 1, pagesize: 10, total: 0, fields: {} },
        { page: 1, pagesize: 10, total: 0, fields: {} },
        { page: 1, pagesize: 10, total: 0, fields: {} },
        { page: 1, pagesize: 10, total: 0, fields: {} },
        { page: 1, pagesize: 10, total: 0, fields: {} },
        { page: 1, pagesize: 10, total: 0, fields: {} },
        { page: 1, pagesize: 10, total: 0, fields: {} },
        { page: 1, pagesize: 10, total: 0, fields: {} },
        { page: 1, pagesize: 10, total: 0, fields: {} },
        { page: 1, pagesize: 10, total: 0, fields: {} },
      ],
      typeList: {
        parkPolicy: [],
        direction: [],
        project: [],
        PrimaryTeam: [],
        establishFund: [],
        financial: [],
        toraise: [],
        directionData: [],
        direction1: [],
        direction2: [],
        directionData1: [],
        directionData2: [],
        directionData3: []
      },
      directionData2: [],
      RelatedInformationList: [],
      SimilarEnterpriseList: [],
      RelatedInformation: { page: 1, pagesize: 10, total: 0 },
      SimilarEnterprise: { page: 1, pagesize: 10, total: 0 },
      valDetails: [],
      establishFundData: [],
      parkPolicyData: [],
      AttractInvestmentData: [],
      titIndex: 0,
      list: [],
      title: { path: '', val: "" },
      type: 0,
      typeName: '',
      fundraisingStatusData: [],
      fundraisingStatus: { page: 1, pagesize: 10, total: 0 },
      directionData: [],
      investment: { page: 1, pagesize: 10, total: 0 },
    }
  },
  mounted() {
    this.typeName = this.$route.query.typeName
    this.type = this.$route.query.type
    if (this.$route.query.type == 4) {
      this.title = {
        path: '/FindListInformation' + commonUtils.getParam(this.$route.query),
        val: "找投资"
      }
    } else if (this.$route.query.type == 5) {
      this.title = {
        path: '/FindListInformation' + commonUtils.getParam(this.$route.query),
        val: "找投行"
      };
      this.label = [
        { label: '机构概况', name: 'AnOverview' },
        { label: '工商注册', name: 'registered' },
        { label: '保荐项目', name: 'parkPolicy' },
        { label: '投资方向/项目', name: 'direction' },
        { label: '机构项目', name: 'project' },
        { label: '相关资讯', name: 'consulting' },
        { label: '主要团队', name: 'PrimaryTeam' },
        { label: '设立基金', name: 'establishFund' },
        { label: '机构业务', name: 'financial' },
        { label: '机构状况', name: 'toraise' },
        { label: '同类企业', name: 'enterprise' },
        { label: '联系方式', name: 'way' },
      ];
      this.investmentBankDetails(this.$route.query.id);
      this.investmentType(this.$route.query.id, 'jigoufengge', 'jigoufengge', 0)
      this.investmentType1(this.$route.query.id, 'baojianxiangmu', 'bjxm', 0)
      this.investmentType(this.$route.query.id, 'jigfxxm', 'jgfxxm', 1)
      this.investmentType(this.$route.query.id, 'zhaoqiye', 'jigouxiangmu', 2)
      this.investmentTypeCorrelation(this.$route.query.id);
      this.investmentType(this.$route.query.id, 'zhaoshequn', 'zytd', 3)
      this.investmentType(this.$route.query.id, 'shelijijin', 'shelijijin', 4)
      this.investmentType(this.$route.query.id, 'zhuyaochanpin', 'jigouyewu', 5)
      this.investmentType(this.$route.query.id, 'jgzk', 'jgzk', 6)
      this.investmentSimilarEnterprise(this.$route.query.id);


      this.investmentType1(this.$route.query.id, 'jigourongyu', 'jigourongyu', 12)
      this.investmentType1(this.$route.query.id, 'jingyingzhuangk', 'mzzk', 13)
      this.investmentType1(this.$route.query.id, 'jingyingzhuangkuang', 'mzzk', 14)


      this.investmentType(this.$route.query.id, 'ipojssgsfw', 'ipossxm', 7)
      this.investmentType(this.$route.query.id, 'flgwxm', 'flgwxm', 8)
      this.investmentType(this.$route.query.id, 'dailikehu', 'dailikehu', 9)
      this.investmentType(this.$route.query.id, 'dailianjian', 'dailianjian', 10)
      this.investmentType(this.$route.query.id, 'yejichengjiu', 'yejichengjiu', 11)

      request.falvfuwu(this.$route.query.id).then(({ data }) => {
        console.log(data)
        this.fundraisingStatusData = data.data
      })

    } else if (this.$route.query.type == 6) {
      this.title = {
        path: '/FindListInformation' + commonUtils.getParam(this.$route.query),
        val: "找园区"
      }
    }
    setTimeout(() => { this.showLoading = true }, 2500)
  },
  methods: {
    openWeb(url) {  window.open(url, '_blank') },
    investmentsizeChange($event) {
      this.investment.pagesize = $event;
      this.investment.page = 1;
    },
    investmentcurrentChange($event) { this.investment.page = $event; },
    fundraisingStatusSize($event) {
      this.productOrganization.pagesize = $event;
      this.productOrganization.page = 1;
    },
    fundraisingStatusChange($event) {
      this.productOrganization.page = $event;
    },
    tableStyle() { return "height:80px;" },
    zcasdasdas(id) {
      window.localStorage.setItem('type', 0);
      this.$router.push({
        path: '/DetailsConsultation',
        query: { type: 0, sublevel: this.$route.query.sublevel, typeId: this.$route.query.typeId, id: id.id }
      })
    },
    investmentBankDetails(id) {
      request.parkDetails(id).then((res) => {
        if (res.data.data != null) {
          this.valDetails = res.data.data;
          console.log('找投行', res.data.data)
        }
      })
    },
    investmentType1(id, table, field, type) {
      let pagesize = this.change[type].pagesize;
      let page = this.change[type].page;
      request.newget2(table, field, id, pagesize, page, 'ztzqs').then((res) => {
        if (res.data.data != null) {
          if (type == 0) {
            this.typeList.parkPolicy1 = res.data.data.list
          }
          if (type == 12) {
            this.typeList.directionData1 = res.data.data.list
          }
          if (type == 13) {
            this.typeList.directionData2 = res.data.data.list
          }
          if (type == 14) {
            this.typeList.directionData3 = res.data.data.list
          }
          this.change[type].total = res.data.data.total;
          this.change[type].fields = res.data.data.fields;
        }
      })
    },
    investmentType(id, table, field, type) {
      let pagesize = this.change[type].pagesize;
      let page = this.change[type].page;
      if (type > 6) {
        request.newget2(table, field, id, pagesize, page, 'fawugongsi').then((res) => {
          if (res.data.data != null) {
            if (type == 7) { this.typeList.directionData = res.data.data.list; }
            else if (type == 8) {
              this.typeList.direction1 = res.data.data.list;
            } else if (type == 9) {
              this.typeList.direction2 = res.data.data.list;
            } else if (type == 10) {
              this.typeList.direction3 = res.data.data.list;
            } else if (type == 11) {
              this.typeList.direction4 = res.data.data.list;
            }
            this.change[type].total = res.data.data.total;
            this.change[type].fields = res.data.data.fields;
          }
        })
      } else {
        request.investmentType(id, table, field, pagesize, page).then((res) => {
          if (res.data.data != null) {
            if (type == 0) {
              this.typeList.parkPolicy = res.data.data.list
            } else if (type == 1) {
              this.typeList.direction = res.data.data.list;
            } else if (type == 2) {
              this.typeList.project = res.data.data.list;
            } else if (type == 3) {
              this.typeList.PrimaryTeam = res.data.data.list;
            } else if (type == 4) {
              this.typeList.establishFund = res.data.data.list;
            } else if (type == 5) {
              this.typeList.financial = res.data.data.list;
            } else if (type == 6) {
              this.typeList.toraise = res.data.data.list;
            };
            this.change[type].total = res.data.data.total;
            this.change[type].fields = res.data.data.fields;
          }
        })
      }
    },
    investmentTypeCorrelation(id) {
      request.investmentTypeCorrelation(id, this.RelatedInformation.pagesize, this.RelatedInformation.page).then((res) => {
        if (res.data.data != null) {
          this.RelatedInformationList = res.data.data.list
          this.RelatedInformation.total = res.data.data.total;
        }
      })
    },
    investmentSimilarEnterprise(id) {
      request.investmentSimilarEnterprise(id, this.SimilarEnterprise.pagesize, this.SimilarEnterprise.page).then((res) => {
        if (res.data.data != null) {
          this.SimilarEnterpriseList = res.data.data.list;
          console.log(res.data.data.list)
          this.SimilarEnterprise.total = res.data.data.total;
        }
      })
    },
    sizeChange(event, type) {
      this.change[type].pagesize = $event;
      this.change[type].page = 1;
      if (type == 0) {
        this.investmentType(this.$route.query.id, 'jigoufengge', 'jigoufengge', 0)
      } else if (type == 1) {
        this.investmentType(this.$route.query.id, 'jigfxxm', 'jgfxxm', 1)
      } else if (type == 2) {
        this.investmentType(this.$route.query.id, 'zhaoqiye', 'jigouxiangmu', 2)
      } else if (type == 3) {
        this.investmentType(this.$route.query.id, 'zhaoshequn', 'zytd', 3)
      } else if (type == 4) {
        this.investmentType(this.$route.query.id, 'shelijijin', 'shelijijin', 4)
      } else if (type == 5) {
        this.investmentType(this.$route.query.id, 'zhuyaochanpin', 'jigouyewu', 5)
      } else if (type == 6) {
        this.investmentType(this.$route.query.id, 'jgzk', 'jgzk', 6)
      } else if (type == 7) {
        this.investmentType(this.$route.query.id, 'ipojssgsfw', 'ipossxm', 7)
      } else if (type == 8) {
        this.investmentType(this.$route.query.id, 'flgwxm', 'flgwxm', 8)
      } else if (type == 9) {
        this.investmentType(this.$route.query.id, 'dailikehu', 'dailikehu', 9)
      } else if (type == 10) {
        this.investmentType(this.$route.query.id, 'dailianjian', 'dailianjian', 10)
      } else if (type == 11) {
        this.investmentType(this.$route.query.id, 'yejichengjiu', 'yejichengjiu', 11)
      } else if (type == 12) {
        this.investmentType1(this.$route.query.id, 'jigourongyu', 'jigourongyu', 12)
      } else if (type == 13) {
        this.investmentType1(this.$route.query.id, 'jingyingzhuangk', 'mzzk', 13)
      } else if (type == 14) {
        this.investmentType1(this.$route.query.id, 'jingyingzhuangkuang', 'mzzk', 13)
      }
    },
    currentChange(event, type) {
      this.change[type].page = $event;
      if (type == 0) {
        this.investmentType(this.$route.query.id, 'jigoufengge', 'jigoufengge', 0)
      } else if (type == 1) {
        this.investmentType(this.$route.query.id, 'jigfxxm', 'jgfxxm', 1)
      } else if (type == 2) {
        this.investmentType(this.$route.query.id, 'zhaoqiye', 'jigouxiangmu', 2)
      } else if (type == 3) {
        this.investmentType(this.$route.query.id, 'zhaoshequn', 'zytd', 3)
      } else if (type == 4) {
        this.investmentType(this.$route.query.id, 'shelijijin', 'shelijijin', 4)
      } else if (type == 5) {
        this.investmentType(this.$route.query.id, 'zhuyaochanpin', 'jigouyewu', 5)
      } else if (type == 6) {
        this.investmentType(this.$route.query.id, 'jgzk', 'jgzk', 6)
      } else if (type == 7) {
        this.investmentType(this.$route.query.id, 'ipojssgsfw', 'ipossxm', 7)
      } else if (type == 8) {
        this.investmentType(this.$route.query.id, 'flgwxm', 'flgwxm', 8)
      } else if (type == 9) {
        this.investmentType(this.$route.query.id, 'dailikehu', 'dailikehu', 9)
      } else if (type == 10) {
        this.investmentType(this.$route.query.id, 'dailianjian', 'dailianjian', 10)
      } else if (type == 11) {
        this.investmentType(this.$route.query.id, 'yejichengjiu', 'yejichengjiu', 11)
      } else if (type == 12) {
        this.investmentType1(this.$route.query.id, 'jigourongyu', 'jigourongyu', 12)
      } else if (type == 13) {
        this.investmentType1(this.$route.query.id, 'jingyingzhuangk', 'mzzk', 13)
      } else if (type == 14) {
        this.investmentType1(this.$route.query.id, 'jingyingzhuangkuang', 'mzzk', 13)
      }
    },
    RelatedInformationSize(event, type) {
      this.RelatedInformation.pagesize = $event;
      this.RelatedInformation.page = 1;
      this.investmentTypeCorrelation(this.$route.query.id);
    },
    RelatedInformationCurrent(event, type) {
      this.cRelatedInformation.page = $event;
      this.investmentTypeCorrelation(this.$route.query.id);
    },
    SimilarEnterpriseSize(event, type) {
      this.SimilarEnterprise.pagesize = $event;
      this.SimilarEnterprise.page = 1;
      this.investmentSimilarEnterprise(this.$route.query.id);
    },
    SimilarEnterpriseCurrent(event, type) {
      this.SimilarEnterprise.page = $event;
      this.investmentSimilarEnterprise(this.$route.query.id);
    },
    ccasdawz(item) {
      this.$router.push({
        path: '/ggDetails',
        query: { ...this.$route.query, id: item.id }
      })
    },
    handleClick(event) {
      let ar = '';
      this.label.forEach(item => { if (item.label == event.name) { ar = item.name } })
      document.getElementById(ar).scrollIntoView({ behavior: "smooth" });
    },
    zxczcasd(item) {
      this.$router.push({
        path: '/InvestmentBankDetails',
        query: { ...this.$route.query, id: item.id }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.DetailsConsultation_breadcrumb {
  line-height: 44px;
}

.registered_right_img {
  font-size: 0;
  margin: 23px 0px 0px 26px;

  img {
    display: inline-block;
    vertical-align: top;
    width: 168px;
    height: 150px;
    margin: 0 20px 20px 0;
  }
}

.Industrial {
  min-width: 1200px;
  width: 73%;
  margin: 30px auto 0;
  overflow: hidden;
  background: #fff;

  #AnOverview,
  #registered,
  #style,
  #team,
  #way,
  #park {
    margin-bottom: 48px;
  }

  .Industrial_in {
    width: 90%;
    margin: 50px auto 0;
    overflow: hidden;

    .Industrial_name {
      display: flex;
      margin-bottom: 60px;

      .Industrial_name_h4 {
        font-size: 24px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #0F0F0F;
        line-height: 33px;
        margin-bottom: 20px;
      }

      img {
        display: block;
        width: 200px;
        // object-fit: scale-down;
        margin-right: 44px;
      }

      .Industrial_name_right {
        h4 {
          font-size: 24px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #0F0F0F;
          line-height: 33px;
          margin: 30px 0;
        }

        .Industrial_name_right_text {
          display: inline-block;
          vertical-align: top;
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #757575;
          line-height: 44px;
          width: 50%;

          span {
            color: #0F0F0F;
          }
        }
      }
    }

    .Industrial_title {
      font-size: 22px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #0F0F0F;
      line-height: 44px;

      img,
      span {
        vertical-align: middle;
        margin-right: 10px;
      }
    }

    .AnOverview {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #555555;
      line-height: 31px;
    }

    .registered {
      border: 1px solid #E3EDFB;
      border-left: 0;
      border-bottom: 0;

      .registered_div {
        display: flex;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #0F0F0F;
        border: 1px solid #E3EDFB;
        border-top: 0;
        border-right: 0;

        .registered_left {
          border-top: 0;
          background: #F6FAFF;
          text-align: center;
          width: 225px;
          position: relative;
          border-right: 1px solid #E3EDFB;

          div {
            width: 225px;

            p {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              margin: auto;
              height: 58px;
              line-height: 58px;
            }
          }
        }

        .registered_right {
          display: flex;
          line-height: 58px;

          align-items: center;

          p {
            margin-left: 30px;
            margin-right: 12px;
          }
        }

        .way_list_left {
          width: 339px;
        }

        .way_list_right {
          margin-left: 137px;
        }
      }
    }

    .style,
    .way {
      margin-top: 40px;
    }

    .project {
      margin-top: 30px;
      font-size: 0;
      line-height: 0;

      .project_list {
        width: calc(20% - 22px);
        margin-right: 27px;
        margin-bottom: 22px;
        display: inline-block;
        vertical-align: top;

        img {
          display: block;
          width: 100%;
        }

        h4 {
          font-size: 20px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #111111;
          line-height: 28px;
          margin-top: 8px;
        }

        div {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ACACAC;
          display: -webkit-box;
          line-height: 20px;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;

          i {
            float: right;
          }
        }
      }

      .project_list:nth-child(5n) {
        margin-right: 0;
      }
    }

    .consulting {
      font-size: 0;
    }

    .List_Information {
      display: inline-block;
      vertical-align: top;
      width: calc(50% - 27px);
      margin-right: 54px;
      height: 100px;
      margin-bottom: 20px;

      .List_Information_left {
        width: 200px;
        height: 100%;
        display: inline-block;
        vertical-align: top;

        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }

      .List_Information_right {
        display: inline-block;
        vertical-align: top;
        height: 100%;
        width: calc(100% - 12px - 200px);
        margin-left: 12px;
        position: relative;
        cursor: pointer;

        h6 {
          display: -webkit-box;
          font-size: 20px;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }

        p {
          position: absolute;
          left: 0;
          bottom: 0;
          font-size: 12px;
          color: #757575;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
      }
    }

    .List_Information:nth-child(2n) {
      margin-right: 0;
    }

    .financial {
      height: 236px;
      font-size: 0;
      margin-bottom: 30px;

      .financial_left {
        display: inline-block;
        vertical-align: top;

        img {
          display: block;
          width: 277px;
          height: 100%;
          object-fit: scale-down;
        }
      }

      .financial_right {
        display: inline-block;
        vertical-align: top;
        width: calc(100% - 315px);
        background: #FFFFFF;
        border: 1px solid #E9E9E9;
        padding-left: 36px;
        padding-bottom: 12px;

        h3 {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #605F5F;
          line-height: 53px;
        }

        .financial_text {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #605F5F;
          line-height: 24px;
        }
      }
    }
  }
}

.List_Information:hover {
  .List_Information_right h6 {
    color: #2C7CD7;
  }
}

@media screen and (max-width:1200px) {
  .tyxxx {
    width: 100% !important;
  }

  .Industrial {
    width: 90%;
    min-width: auto;

    .Industrial_in {
      .Industrial_title {
        text-align: center;
        line-height: 64px;
        font-size: 24px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #0F0F0F;

        img {
          display: none;
        }
      }
    }
  }

  .Industrial_name,
  .Industrial_label {
    display: none !important;
  }

  .ydd_Industrial_name {
    display: block !important;
    overflow: hidden;

    .ydd_Industrial_h4 {
      font-size: 30px;
      color: #0F0F0F;
      line-height: 42px;
    }

    .ydd_Industrial_img {
      object-fit: scale-down;
      width: 100%;
    }

    .ydd_Industrial_label_lab {
      border-bottom: 1px solid #D4D4D4;
      line-height: 40px;
      font-size: 20px;
      padding: 10px 0;

      p {
        color: #757575;
      }
    }
  }

  .way_list_right {
    margin-left: 0 !important;
  }

  .way_list_left {
    width: initial !important;

    div {
      width: 160px !important;
    }
  }

  .registered {
    display: none;
  }

  .ydd_registered {
    display: block !important;

    .ydd_registered_label {
      font-size: 20px;
      line-height: 44px;
      border-bottom: 1px solid #D4D4D4;
      padding: 10px 0;

      div {
        display: inline-block;
        vertical-align: top;
        color: #757575;
        font-size: 24px;
      }

      p {
        display: inline-block;
        vertical-align: top;
      }
    }
  }

  .OwnershipStructure_val {
    .OwnershipStructure_val_in {
      p {
        width: 17% !important;
        flex: auto;
      }

      div {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          width: auto;
          flex: 1;
        }

        .div_i_top {
          margin-top: 42px;
        }

        .div_i_bottom {
          margin-bottom: 42px;
        }
      }
    }

    .OwnershipStructure_val_but {
      width: 45% !important;

      i {
        width: 14% !important;
      }

      .OwnershipStructure_val_but_el {
        width: 72% !important;
        font-size: 21px;
        height: 64px;
      }
    }
  }

  .project_list,
  .project_list:nth-child(5n) {
    width: calc(50% - 27px) !important;
    margin-right: 27px !important;
  }

  .project_list:nth-child(2n) {
    margin-right: 0;
  }

  .pc_financial {
    display: none;
  }

  .ydd_financial {
    display: block !important;
  }

  .ydd_financial_list_img {
    width: 100%;
  }

  .ydd_financial_list_h3 {
    font-size: 24px;
    color: #0F0F0F;
    line-height: 44px;
    font-weight: 400;
  }

  .financial_text {
    font-size: 22px;
    color: #605F5F;
    line-height: 34px;
  }

  .List_Information {
    width: 100% !important;
  }

  .way {
    display: block !important;
  }
}

::v-deep {
  .el-table th.el-table__cell>.cell {
    text-align: center;
  }

  .el-tabs__item:hover,
  .el-tabs__item.is-active {
    color: #2C7CD7;
  }

  .el-tabs__active-bar {
    background-color: #2C7CD7;
  }

  .el-table th {
    background: #F6FAFF;
  }

  .el-table td {
    text-align: center;
  }

  .pagination {
    text-align: center;
    display: block;
    margin: 50px auto 50px;
  }

  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background: #2C7CD7;
  }

  .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #2C7CD7;
  }

  .condition {
    .el-table .el-table__cell {
      text-align: center;
    }
  }

  .el-button--text {
    color: #2C7CD7;
  }
}
</style>

<template>
  <div>
    <div class="CreateNews_title">
      <h4>{{users}}</h4>
      <div class="CreateNews_right_in">
        <div class="CreateNews_right_in_div">
          <div>
            <div>学习资料</div>
            <div>文档资料</div>
            <div>课程</div>
            <div>直播</div>
            <div>讲师</div>
            <div>视频学习</div>
            <div>现场培训</div>
            <div>备注</div>
            <div>备注</div>
            <div>备注</div>
            <div>备注</div>
          </div>
        </div>
      </div>
      <el-input
          size="mini"
          class="title-label-input"
          placeholder="请输入内容"
          suffix-icon="el-icon-search"
          v-model="input2">
        </el-input>
    </div>
    <Graphiclist></Graphiclist>
  </div>
</template>

<script>
import Graphiclist from "@/components/general/Graphiclist.vue";
export default {
  components: {
    Graphiclist,
  },
  data(){
    return{
      users:"",
    }
  },
  props:{
    users:{           //这个就是父组件中子标签自定义名字
      type:String,
      required:true
    }
  },
}
</script>

<style lang="scss" scoped>
.CreateNews_title{
      height: 62px;
      background: #2C7CD7;
      color: #fff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      h4{
        font-weight: normal;
        font-size: 20px;
        padding: 0 48px;
      }
    }
    .CreateNews_right_in{
  width: calc(100% - 176px);
  text-align: right;
}
.CreateNews_right_in_div{
  width: 74%;
  display: inline-block;
  vertical-align: middle;
  div{
    display: flex;
    justify-content: space-around;
    font-size: 14px;
  }
}
::v-deep {
  .title-label-input{
    width: 160px;
    margin-right: 5%;
    .el-input__inner{
      border-radius: 28px;
      background: transparent;
    }
    .el-input__inner:focus{
      border-color: #fff;
    }
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active{
    background: #2C7CD7;
  }
  .el-pagination.is-background .el-pager li:not(.disabled):hover{
    color: #2C7CD7;
  }
}
</style>
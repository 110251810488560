<template>
  <div id="app">
    <bartop v-if="name == ''"></bartop>
    <router-view />
    <barBottom></barBottom>
  </div>
</template>

<script>
import axios from "axios";
import bartop from "@/components/general/bartop.vue";
import barBottom from "@/components/general/barBottom.vue";
export default {
  components: {
    bartop,
    barBottom
  },
  data() {
    return {
      urls: "",
      screenWidth: document.body.clientWidth,
      name:'',
    };
  },
  watch: {
    $route(to, from) {
      const that = this;
        that.name = that.$route.path == '/logo'? that.$route.path:that.$route.path == '/search'?that.$route.path:''
    },
    immediate: true,
    deep: true
  },

  async mounted() {
    this.name = this.$route.path == '/logo'? this.$route.path:this.$route.path == '/search'?this.$route.path:''
    //console.log('App界面',this.$store.state.screenWidth);
    // 窗口监听
    const that = this;
    window.onresize = () => {
      window.screenWidth = document.body.clientWidth;
      that.screenWidth = window.screenWidth;
      if (window.screenWidth <= 1000) {
        that.$store.commit("change", true);
      } else {
        that.$store.commit("change", false);
      }
    };
    var html = document.getElementsByTagName("html")[0];
    var oWidth = document.body.clientWidth || document.documentElement.clientWidth;
    // 这里的750是指设计图的大小,自己根据实际情况改变
    html.style.fontSize = oWidth / 750 * 100 + "px";
  },
  methods: {
    dataURItoBlob(base64Data) {
      var byteString;
      if (base64Data.split(",")[0].indexOf("base64") >= 0)
        byteString = atob(base64Data.split(",")[1]);
      else byteString = unescape(base64Data.split(",")[1]);
      var mimeString = base64Data
        .split(",")[0]
        .split(":")[1]
        .split(";")[0];
      var ia = new Uint8Array(byteString.length);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ia], { type: mimeString });
    }
  }
};
</script>

<style>
/* html {网页黑白
        filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
        -webkit-filter: grayscale(100%);} */
@font-face {
  font-family: "iconfont"; /* project id 2418318 */
  src: url("//at.alicdn.com/t/font_2418318_5rk0r6au6je.eot");
  src: url("//at.alicdn.com/t/font_2418318_5rk0r6au6je.eot?#iefix")
      format("embedded-opentype"),
    url("//at.alicdn.com/t/font_2418318_5rk0r6au6je.woff2") format("woff2"),
    url("//at.alicdn.com/t/font_2418318_5rk0r6au6je.woff") format("woff"),
    url("//at.alicdn.com/t/font_2418318_5rk0r6au6je.ttf") format("truetype"),
    url("//at.alicdn.com/t/font_2418318_5rk0r6au6je.svg#iconfont") format("svg");
}

@font-face {
  font-family: "webfont";
  font-display: swap;
  src: url("//at.alicdn.com/t/webfont_387b1o0xqcc.eot"); /* IE9*/
  src: url("//at.alicdn.com/t/webfont_387b1o0xqcc.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("//at.alicdn.com/t/webfont_387b1o0xqcc.woff2")
      format("woff2"),
    url("//at.alicdn.com/t/webfont_387b1o0xqcc.woff") format("woff"),
    /* chrome、firefox */ url("//at.alicdn.com/t/webfont_387b1o0xqcc.ttf")
      format("truetype"),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
      url("//at.alicdn.com/t/webfont_387b1o0xqcc.svg#NotoSansHans-DemiLight")
      format("svg"); /* iOS 4.1- */
}
.iconfont {
  font-family: "iconfont" !important;
  font-size: 8px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}

.web-font {
  font-family: "webfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}
* {
  padding: 0;
  margin: 0;
  font-family: "web-font";
}
body {
  background: #F0F0F0;
  -webkit-tap-highlight-color: transparent;
}
a {
  color: #000;
  outline: none;
  text-decoration: none;
  -moz-outline-style:none;
  -webkit-tap-highlight-color: transparent;
}
li {
  list-style: none;
}
img {
  /* pointer-events: none; */
}
/* 手机屏幕的字体大小 */
@media screen and (max-width: 768px) {
  #app {
    font-size: 0.05rem;
    width: 100%;
    overflow: hidden;
  }
}
/* 笔记本电脑的字体大小 */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  #app {
    font-size: 0.08rem;

  }
}
/* 台式电脑显示器屏幕字体大小 */
@media screen and (min-width: 1024px) {
  #app {
    font-size: 0.08rem;
  }
}
</style>

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    screenWidth:Boolean
  },
  mutations: {
    change(state, screenWidth) {
      state.screenWidth = screenWidth
    }
  },
  actions: {
  },
  getters: {
    screenWidth: state => state.screenWidth
  },
  modules: {
  }
})

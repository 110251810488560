<template>
  <div>
    <a v-if="advertisingDel.img" @click="advertisement(advertisingDel)"><img class="Advertising" :src="advertisingDel.img" alt=""></a>
    <div class="CreateNews">
      <div class="CreateNews_in">
        <div class="CreateNews_left">
          <div v-for="(item, index) in label" :key="index" :class="(index == labelIdx&&!firstIn ? 'CreateNews_left_color' : '')"
            @click="cssdawd(index)">{{ item.name }}</div>
          <!-- <div style="margin-top: 44px;">
            <img class="asdadasdd" v-if="type == 5" v-for="(itm,i) in 10" :key="i" src="../../public/img/img32.png" alt="">
          </div> -->
        </div>
        <div class="CreateNews_right">
          <div class="ydd_label">
            <div class="swiper-wrapper" style="width: 100%;">
              <div v-for="(item, index) in label" :key="index" :class="index == labelIdx ? 'CreateNews_left_color' : ''"
                class="swiper-slide" @click="cssdawd(index)">{{ item.name }}</div>
            </div>
          </div>
          <div class="CreateNews_title">
            <h4 v-if="!firstIn">{{ label[labelIdx].name }}</h4>
            <div class="CreateNews_right_in">
              <div class="CreateNews_right_in_div">
                <div>
                  <div v-for="(t, i) in twoLbel" :key="i" :class="i == twoLbelIdx ? 'CreateNews_right_in_div_color' : ''"
                    @click="twoLbelIdxClick(i)">{{ t.name }}</div>
                </div>
              </div>
              <!-- <el-input v-if="type == 0" size="mini" class="title-label-input" placeholder="请输入内容"
                suffix-icon="el-icon-search" v-model="input2" @keyup.enter.native="informationList($route.query.typeId)">
              </el-input>
              <el-input v-if="type == 4" size="mini" class="title-label-input" placeholder="请输入内容"
                suffix-icon="el-icon-search" v-model="input2" @keyup.enter.native="LookingInvestmentList($route.query.typeId)">
              </el-input>
              <el-input v-if="type == 5" size="mini" class="title-label-input" placeholder="请输入内容"
                suffix-icon="el-icon-search" v-model="input2" @keyup.enter.native="investmentBank($route.query.typeId)">
              </el-input>
              <el-input v-if="type == 6" size="mini" class="title-label-input" placeholder="请输入内容"
                suffix-icon="el-icon-search" v-model="input2" @keyup.enter.native="park()">
              </el-input> -->
            </div>
            <div class="ydd_CreateNews_right">
              <div class="swiper-wrapper" style="width: 100%;">
                <div class="swiper-slide" v-for="(t, i) in twoLbel" :key="i"
                  :class="i == twoLbelIdx ? 'CreateNews_right_in_div_color' : ''" @click="twoLbelIdxClick(i)">{{ t.name }}</div>
              </div>
            </div>
          </div>
          <div v-if="type != 5 && label.length > 0">
            <PlainTextList :users="list" :advertisingList="advertisingList" :lableName="label[labelIdx].name"></PlainTextList>
            <el-pagination v-if="list.length > 0" class="pagination" background :current-page="page" :page-size="pagesize"
              layout="total, sizes, prev, pager, next, jumper" @size-change="sizeChange" @current-change="currentChange"
              prev-text="上一页" next-text="下一页" :total="total">
            </el-pagination>
            <el-pagination v-if="list.length > 0" class="pagination1" background :current-page="1" :page-size="pagesize"
              prev-text="上一页" next-text="下一页" layout=" prev, pager, next" @size-change="sizeChange"
              @current-change="currentChange" :total="total">
            </el-pagination>
          </div>
          <div class="FindIndustry" v-if="type == 5">
            <el-table stripe :cell-style="tableStyle" :data="tableData" style="width: 100%">
              <el-table-column label=" 机构名称">
                <template slot-scope="scope">
                  <div style="display: flex;">
                    <img style="height: 65px;" :src="scope.row.thumb" alt="">
                    <div class="FindIndustry_sapn">
                      <h4>{{ scope.row.title }}</h4>
                      <span>{{ scope.row.date }}</span>
                    </div>
                  </div>
                </template>
              </el-table-column>
<!--              <el-table-column label="今年投资数" prop="jntzs">-->
<!--              </el-table-column>-->
<!--              <el-table-column label="总投资数" prop="zongtouzishu">-->
<!--              </el-table-column>-->
              <el-table-column label="详情" width="90" align="center">
                <template slot-scope="scope">
                  <el-button @click="zxczcasd(scope.row)" type="primary" plain size="mini">详情</el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination class="pagination" background :current-page="1" :page-size="pagesize" prev-text="上一页"
              next-text="下一页" layout=" prev, pager, next" @size-change="sizeChange" @current-change="currentChange"
              :total="total">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PlainTextList from "@/components/general/PlainTextList.vue";
import CommonUtils from "@/utils/CommonUtils";
import 'swiper/css/swiper.css'  //引入swiper样式
import Swiper from 'swiper';	//引入swiper
import request from '@/api/request.js'
export default {
  components: {
    PlainTextList,
  },
  data() {
    return {
      label: [[]],
      labelIdx: 0,
      twoLbel: [],
      twoLbelIdx: 0,
      list: [],
      type: 0,
      input2: '',
      pagesize: 20,
      page: 1,
      total: 0,
      tableData: [],
      typeName: "",
      advertisingList:[],
      advertisingDel: {},
      firstIn:true
    }
  },
  watch: {
    $route(to, from) {
      if (this.$route.query.type == 0) {
        this.label = CommonUtils.consultation;
      } else if (this.$route.query.type == 4) {
        this.label = CommonUtils.investment;
      } else if (this.$route.query.type == 5) {
        this.label = CommonUtils.Investment_bank;
      } else if (this.$route.query.type == 6) {
        this.label = CommonUtils.park;
      }
      this.type = this.$route.query.type;
      this.labelIdx = this.$route.query.sublevel;
      this.column(this.$route.query.typeId, 0)
      this.advertisingSpace(2, 1, this.$route.query.typeId)
      this.advertisingSpace(2, 2, this.$route.query.typeId)
    }
  },
  mounted() {
    if (this.$route.query.type == 0) {
      this.label = CommonUtils.consultation;
    } else if (this.$route.query.type == 4) {
      this.label = CommonUtils.investment;
    } else if (this.$route.query.type == 5) {
      this.label = CommonUtils.Investment_bank;
    } else if (this.$route.query.type == 6) {
      this.label = CommonUtils.park;
    }
    this.type = this.$route.query.type;
    this.labelIdx = this.$route.query.sublevel;
    setTimeout(() => {
      var mySwiper = new Swiper('.ydd_label', {
        //配置分页器内容
        setWrapperSize: true,
        slidesPerView: 'auto',
        slideToClickedSlide: true,//设置为true则点击slide会过渡到这个slide。
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 0,
      })
      var mySwiper1 = new Swiper('.ydd_CreateNews_right', {
        //配置分页器内容
        setWrapperSize: true,
        slidesPerView: 'auto',
        slideToClickedSlide: true,//设置为true则点击slide会过渡到这个slide。
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 0,
      })
    }, 400)
    this.column(this.$route.query.typeId, 0)
    this.advertisingSpace(2, 1, this.$route.query.typeId)
    this.advertisingSpace(2, 2, this.$route.query.typeId)

    this.$EventBus.$on("globalSearch", (keywords) => {
      this.input2 = keywords
      if (this.type == 0) {
        this.informationList(this.$route.query.typeId)
      }else if (this.type == 4) {
        this.LookingInvestmentList(this.$route.query.typeId)
      }else if (this.type == 5) {
        this.investmentBank(this.$route.query.typeId)
      }else if (this.type == 6) {
        this.park()
      }
    });
  },
  methods: {
    tableStyle() { return "height:80px;" },
    column(idx, i) {
      request.column(idx).then((res) => {
        if (i == 0) {
          this.label = res.data.data;
          if (this.$route.query.twotypeId) {
            this.column(this.$route.query.twotypeId, 1)
          } else {
            this.column(this.$route.query.typeId, 1)
          }
        } else {
          if (this.$route.query.twotypeId) {
            this.twoLbel = res.data.data;
          }
          // this.page = 0;
          // this.pagesize = 20;
          if (this.twoLbel.length > 0) {
            if (this.$route.query.type == 0) {
              this.informationList(idx)
            } else {
              if (this.$route.query.type == 4) {
                this.LookingInvestmentList(idx)
              } else if (this.$route.query.type == 5) {
                this.investmentBank(idx)
              } else if (this.$route.query.type == 6) {
                this.parkList(idx)
              }
            }
          } else {
            if (this.$route.query.type == 0) {
              this.informationList(idx)
            } else if (this.$route.query.type == 4) {
              this.LookingInvestmentList(idx)
            } else if (this.$route.query.type == 5) {
              this.investmentBank(idx)
            } else if (this.$route.query.type == 6) {
              this.parkList(idx)
            }
          }
        }
      })
    },
    cssdawd(idx) {
      this.firstIn = false
      this.page = 1;
      this.pagesize = 20;
      this.labelIdx = idx;
      this.input2 = ''
      this.$EventBus.$emit('clearSearch')
      this.column(this.label[idx].id, 1);
    },
    informationList(catid) {
      request.informationList(catid, this.pagesize, this.page,this.input2).then((res) => {
        if (res.data.data != null) {
          this.list = res.data.data.list;
          this.total = res.data.data.total
        } else {
          this.list = []
        }
      })
    },
    LookingInvestmentList(catid) {
      request.LookingInvestmentList(catid, this.pagesize, this.page,this.input2).then((res) => {
        if (res.data.data != null) {
          this.list = res.data.data.list;
          this.total = res.data.data.total
        } else {
          this.list = []
        }
      })
    },
    parkList(catid) {
      request.parkList(this.input2, catid, this.pagesize, this.page).then((res) => {
        if (res.data.data != null) {
          this.list = res.data.data.list;
          this.total = res.data.data.total
        } else {
          this.list = []
        }
      })
    },
    investmentBank(catid) {
      // if (this.label[this.labelIdx].name == '券商公司' || this.label[this.labelIdx].name == '法务公司') {
      //   request.investmentBank1(this.input2, catid, this.pagesize, this.pagesize,'zhaotouhang','zqylist').then((res) => {
      //     if (res.data.data != null) {
      //       this.tableData = res.data.data.list;
      //       this.total = res.data.data.total
      //     } else {
      //       this.list = []
      //     }
      //   })
      // } else {
        request.investmentBank(this.input2, catid, this.pagesize, this.page).then((res) => {
          if (res.data.data != null) {
            this.tableData = res.data.data.list;
            this.total = res.data.data.total
          } else {
            this.list = []
          }
        })
      // }
    },
    investmentBank1(catid) {
      request.investmentBank1(this.input2, catid, this.pagesize, this.page,'zhaotouhang','zqylist').then((res) => {
        if (res.data.data != null) {
          this.tableData = res.data.data.list;
          this.total = res.data.data.total
        } else {
          this.list = []
        }
      })
    },
    sizeChange($event) {
      this.pagesize = $event;
      this.page = 1;
      if (this.twoLbel.length > 0) {
        if (this.$route.query.type == 0) {
          this.informationList(this.twoLbel[this.twoLbelIdx].id)
        } else if (this.$route.query.type == 4) {
          this.investmentList(this.twoLbel[this.twoLbelIdx].id)
        } else if (this.$route.query.type == 5) {
          this.investmentBank(this.twoLbel[this.twoLbelIdx].id)
        } else if (this.$route.query.type == 6) {
          this.parkList(this.twoLbel[this.twoLbelIdx].id)
        }
      } else {
        if (this.$route.query.type == 0) {
          this.informationList(this.label[this.labelIdx].id)
        } else if (this.$route.query.type == 4) {
          this.investmentList(this.label[this.labelIdx].id)
        } else if (this.$route.query.type == 5) {
          this.investmentBank(this.twoLbel[this.twoLbelIdx].id)
        } else if (this.$route.query.type == 6) {
          this.parkList(this.label[this.labelIdx].id)
        }
      }
    },
    currentChange($event) {
      this.page = $event;
      if (this.firstIn) {
        this.column(this.$route.query.typeId, 0)
      }else{
        this.column(this.label[this.labelIdx].id, 1);
      }
    },
    twoLbelIdxClick(i) {
      this.twoLbelIdx = i;
      if (this.$route.query.type == 0) {
        this.informationList(this.twoLbel[i].id)
      } else {
        if (this.$route.query.type == 4) {
          this.LookingInvestmentList(this.twoLbel[i].id)
        } else if (this.$route.query.type == 5) {
          this.investmentBank(this.twoLbel[i].id)
        } else if (this.$route.query.type == 6) {
          this.parkList(this.twoLbel[i].id)
        }
      }
    },
    park() {
      if (this.twoLbel.length > 0) {
        this.parkList(this.twoLbel[this.twoLbelIdx].id)
      } else {
        this.column(this.label[this.labelIdx].id, 1);
      }
    },
    zxczcasd(item) {
      if (this.$route.query.type == 5) {
        this.$router.push({
          path: '/InvestmentBankDetails',
          query: { ...this.$route.query, id: item.id, typeName: this.label[this.labelIdx].name }
        })
      }
    },
    advertisingSpace(localtion, type, cate_id){
      request.advertisingSpace(localtion, type, cate_id).then((res) => {
        if(type == 2){
          this.advertisingList = res.data.data;
        }else{
          this.advertisingDel = res.data.data[0];
        }
      })
    },
    advertisement(id){
      if(id.url != ''){
        window.open(id.url);
      }else{
        this.$router.push({ path:'/videoDetails',query:{ wzid:id.wzid }})
      }
    },
  },
  beforeDestroy() {
    this.$EventBus.$off("globalSearch");
  },
}
</script>

<style lang="scss" scoped>
.ydd_label {
  display: none;
  height: 100px;
  overflow: hidden;

  div {
    div {
      width: 120px;
      text-align: center;
      line-height: 100px;
      font-size: 20px;
      display: inline-block;
      vertical-align: top;
    }
  }
}

.Advertising {
  display: block;
  min-width: 1200px;
  width: 73%;
  margin: 20px auto 0;
  background: burlywood;
}

.CreateNews {
  min-width: 1200px;
  width: 73%;
  margin: 20px auto 0;
  // padding-top: 40px;
  background: #fff;
}

.CreateNews_in {
  width: 100%;
  margin: auto;
  display: flex;

  .CreateNews_left {
    width: 132px;
    margin-right: 20px;
    background: #F6F6F6;
    border-radius: 8px;
    font-size: 18px;
    line-height: 68px;
    color: #666666;
    text-align: center;
    padding: 24px 0;

    div {
      cursor: pointer;
      width: 132px;
    }
  }

  .CreateNews_right {
    width: 100%;

    .CreateNews_title {
      height: 62px;
      background: #2C7CD7;
      color: #fff;
      display: flex;
      justify-content: space-between;
      align-items: center;

      h4 {
        font-weight: normal;
        font-size: 20px;
        padding-left: 48px;
        width: 128px;
      }
    }
  }

  .CreateNews_left_color {
    color: #2C7CD7;
  }
}

.CreateNews_right_in {
  width: calc(100% - 160px);
}

.CreateNews_right_in_div {
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 170px);

  div {
    width: 100%;
    display: flex;
    justify-content: space-around;
    font-size: 14px;
  }
}

.FindIndustry_sapn {
  display: inline-block;
  vertical-align: top;
  margin-left: 24px;
  height: 65px;
  position: relative;
  width: calc(100% - 65px - 24px);
  text-align: left;

  h4 {
    width: 100%;
    font-size: 18px;
    margin-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  span {
    font-size: 12px;
    line-height: 16px;
    position: absolute;
    bottom: 0;
    width: 100%;
    color: #A7A7A7;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
}

.asdadasdd {
  width: 127px;
  display: block;
  margin-bottom: 41px;
}

.ydd_CreateNews_right {
  display: none;
}

.CreateNews_right_in_div_color {
  font-size: 18px !important;
}

@media screen and (max-width:1200px) {
  .CreateNews_in {

    .CreateNews_left,
    .CreateNews_right_in {
      display: none;
    }

    .ydd_label {
      display: block;
    }
  }

  .CreateNews {
    padding: 0;
    min-width: auto;
    width: 90%;
  }

  .Advertising {
    width: 100%;
  }

  .CreateNews_title h4 {
    padding: 0 !important;
    text-align: center;
  }

  .ydd_CreateNews_right {
    width: calc(100% - 128px);
    overflow: hidden;
    line-height: 62px;
    font-size: 18px;

    div {
      div {
        width: 80px;
        text-align: center;
      }
    }
  }
}

::v-deep {
  .el-table th.el-table__cell>.cell {
    //text-align: center;
  }

  .title-label-input {
    width: 160px;
    margin-right: 6px;

    .el-input__inner {
      border-radius: 28px;
      background: transparent;
    }

    .el-input__inner:focus {
      border-color: #fff;
    }
  }

  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background: #2C7CD7;
  }

  .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #2C7CD7;
  }

  .el-table th {
    background: rgba(88, 82, 65, .1);
  }

  .el-table td {
    text-align: center;
    padding: 6px 0;
  }

  .el-button--primary.is-plain:focus,
  .el-button--primary.is-plain:hover {
    background: #409eff;
    border-color: #409eff;
    color: #fff;
  }

  .el-button--primary.is-plain {
    color: #409eff;
    border-color: #c6e2ff;
    background-color: #ecf5ff;
  }

  .pagination {
    text-align: center;
    display: block;
    margin: 50px auto 50px;
  }

  .pagination1 {
    display: none;
  }

  @media screen and (max-width:1200px) {
    .pagination {
      display: none !important;
    }

    .pagination1 {
      display: block;
    }

    .el-pagination {
      padding: 0;
      margin: 20px 0;
    }
  }
}</style>

<template>
    <div class="tobottom">
        <div class="tobottom_top">
          <div class="tobottom_top_in">
            <div class="tobottom_top_span">
              <h4>关于我们</h4>
              <p>关于我们</p>
              <p>关于我们</p>
              <p>关于我们</p>
            </div>
            <div class="tobottom_top_span">
              <h4>联系我们</h4>
              <p>联系我们</p>
              <p>联系我们</p>
              <p>联系我们</p>
            </div>
            <div class="tobottom_top_span">
              <h4>广告服务</h4>
              <p>广告服务</p>
              <p>广告服务</p>
              <p>广告服务</p>
            </div>
            <div class="tobottom_top_span">
              <h4>企业入驻</h4>
              <p>企业入驻</p>
              <p>企业入驻</p>
              <p>企业入驻</p>
            </div>
            <div class="tobottom_top_span">
              <h4>企业推广</h4>
              <p>企业推广</p>
              <p>企业推广</p>
              <p>企业推广</p>
            </div>
            <div class="tobottom_top_span">
              <h4>商务合作</h4>
              <p>商务合作</p>
              <p>商务合作</p>
              <p>商务合作</p>
            </div>
          </div>
        </div>
        <div class="tobottom_bottom">
          {{val.SITE_ICP}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CommonUtils from "@/utils/CommonUtils";
import request from '@/api/request.js'
export default {
  data(){
    return{
      val:{}
    }
  },
  mounted() {
    this.websiteInformation()
  },
  methods:{
    websiteInformation(){
      request.websiteInformation().then((res) => {
        this.val = res.data.data;
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .barBottom{
    height: 194px;
    background: #2C7CD7;
    .barBottom_div{
      width: 73%;
      margin:0px auto;
      height: 100%;
      display: flex;
      .barBottom_div_left{
        width: 194px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .barBottom_div_right{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        div{
          width: 94%;
          margin: auto;
        }
        p{
          font-size: 14px;
          text-align: center;
          color: #fff;
          margin-top: 30px;
        }
        .barBottom_div_right_ul{
          display: flex;
          justify-content: center;
          text-align: center;
          a{
            font-size: 18px;
          color: #FFFFFF;
          line-height: 25px;
          }
          a:hover{
            text-decoration: underline;
          }
        }
      }
    }
  }
  .tobottom{
    .tobottom_top{
      height: 210px;
      background: #0089EF;
      .tobottom_top_in{
        min-width: 1200px;
        width: 74%;
        margin:auto;
        overflow: hidden;
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 100%;
        .tobottom_top_span{
          h4{
            font-size: 24px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 33px;
            margin-bottom: 15px;
          }
          p{
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 22px;
          }
        }
      }
    }
    .tobottom_bottom{
      height: 66px;
      line-height: 66px;
      background: #035EA2;
      text-align: center;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      span{margin-right: 22px;}
    }
  }
  @media screen and (max-width: 850px) {
    .main {
      height: 70px;
    }
    .barBottom{
      margin-top: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      .barBottom_div{
        width: auto;
        height: auto;
        min-width: auto;
        display: block;
        .barBottom_div_left{
          display: block;
          width: auto;
          height: auto;
          text-align: center;
        }
        .barBottom_div_right_ul{display: none !important;}
        .barBottom_div_right{
          display: block;
          p{
            margin-top: 23px;
          }
          div{width: auto;}
        }
      }
    }
  }
</style>

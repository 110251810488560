<template>
  <div class="search">
    <div class="top_type">
      <ul class="top_type_text">
        <li v-for="(a,i) in tyepList" :key="i">{{a}}</li>
      </ul>
      <div class="top_type_right">
        <img src="../../public/NewImage/phone.png" alt="">400-999-9999
      </div>
    </div>
    <div class="search_title">
      <div class="home_logo">
        <div class="home_logo_in">
          <a href="/" style="float: left;height: 100%;"><img class="home_logo_img" :src="imgLogo" alt=""></a>
          <div class="home_logo_search">
            <el-input placeholder="请输入内容" v-model="input2" class="home_logo_search_input" @keyup.enter.native="globalSearch">
                <template slot="prepend">
                  <el-select v-model="select" slot="prepend" placeholder="请选择" style="width: 120px;">
                    <el-option v-for="item in typeClassification" :label="item.name" :value="item.id"></el-option>
                  </el-select>
                </template>
                <el-button slot="append" class="home_logo_search_button2" @click="globalSearch">搜索</el-button>
            </el-input>
          </div>
          <div class="home_logo_right">
            <el-button  class="home_logo_right_button" type="primary" plain @click="exitAccount()">返回</el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="search_val">
      <div v-for="item in list" @click="linkToComp(item)" >{{ item.title }}</div>
    </div>
    <div class="pagination_div">
      <el-pagination :current-page="page" :page-size="pagesize" :total="total" background layout="prev, pager, next"
        next-text="下一页" prev-text="上一页" @current-change="currentChange">
      </el-pagination>
    </div>
  </div>
</template>

<script lang="ts">
  import request from '@/api/request.js'
  export default {
  data() {
    return {
      tyepList:['采购商','供应商','产业园区','创业人','投资人','专家人','采购人'],
      imgLogo:'',
      input2:'',
      select:'',
      typeClassification:[],
      list:[],
      total:0,
      pagesize: 10,
      page: 1
    }
  },
  mounted(){
    this.logoIMG()
    this.column()
  },
  methods:{
    logoIMG(){
      request.logoIMG().then((res) => {
        this.imgLogo = res.data.data;
      })
    },
    column(){
      request.column(0).then((res) => {
        this.typeClassification = res.data.data;
        this.select = this.$route.query.type
        this.input2 = this.$route.query.val
        this.page = 1;
        this.listvvv()
      })
    },
    currentChange($event) {
      this.page = $event;
      this.listvvv()
    },
    listvvv(){
      let val = {}
      this.typeClassification.forEach(item => { if(item.id == this.select){ val = item ; return } })
      if(val.name == "找企业"){
        this.enterpriseList()
      }else if(val.name == "找采供"){
        this.qgxxSearch()
      }else if(val.name == "找活动"){
        this.activityList()
      }else if(val.name == "找园区"){
        this.parkList()
      }else if(val.name == "找资讯"){
        this.informationList()
      }else if(val.name == "找产业"){
        this.industryList()
      }else if(val.name == "找投资"){
        this.LookingInvestmentList()
      }else if(val.name == "找投行"){
        this.investmentBank()
      }else if(val.name == "找社群"){
        this.investmentList()
      }
    },
    enterpriseList(){
      request.enterpriseList({ Keyword:this.input2, pagesize:this.pagesize, page:this.page }).then((res) => {
        if(res.data.data != null){
          this.list = res.data.data.list;
          this.total = parseInt(res.data.data.total);
        }else{
          this.list = [];
          this.total = 0;
        }
      })
    },
    qgxxSearch(){
      request.qgxxSearch({ Keyword:this.input2, pagesize:this.pagesize, page:this.page }).then((res) => {
        if(res.data.data != null){
          this.list = res.data.data.list;
          this.total = parseInt(res.data.data.total);
        }else{
          this.list = [];
          this.total = 0;
        }
      })
    },
    activityList(catid){
      request.activityList({Keyword:this.input2, pagesize:this.pagesize, page:this.page}).then((res) => {
        if(res.data.data != null){
          this.list = res.data.data.list;
          this.total = parseInt(res.data.data.total);
        }else{
          this.list = [];
          this.total = 0;
        }
      })
    },
    globalSearch() {
      this.page = 1;
      this.listvvv()
    },
    parkList() {
      request.parkList(this.input2,'', this.pagesize, this.page).then((res) => {
        if (res.data.data != null) {
          this.list = res.data.data.list;
          this.total = parseInt(res.data.data.total);
        } else {
          this.list = []
          this.total = 0;
        }
      })
    },
    informationList(catid) {
      request.informationList({Keyword:this.input2, pagesize:this.pagesize, page:this.page}).then((res) => {
        if (res.data.data != null) {
          this.list = res.data.data.list;
          this.total = parseInt(res.data.data.total);
        } else {
          this.list = []
          this.total = 0;
        }
      })
    },
    industryList(catid){
      request.industryList({Keyword:this.input2, pagesize:this.pagesize, page:this.page}).then((res) => {
        if(res.data.data != null){
          this.list = res.data.data.list;
          this.total = parseInt(res.data.data.total);
        }else{
          this.list = []
          this.total = 0;
        }
      })
    },
    LookingInvestmentList(catid) {
      request.LookingInvestmentList({Keyword:this.input2, pagesize:this.pagesize, page:this.page}).then((res) => {
        if (res.data.data != null) {
          this.list = res.data.data.list;
          this.total = parseInt(res.data.data.total);
        } else {
          this.list = []
          this.total = 0;
        }
      })
    },
    investmentBank(){
      request.investmentBank(this.input2,'', this.pagesize, this.page).then((res) => {
        if (res.data.data != null) {
          this.list = res.data.data.list;
          this.total = parseInt(res.data.data.total);
        } else {
          this.list = []
          this.total = 0;
        }
      })
    },
    investmentList(){
      request.investmentList({Keyword:this.input2, pagesize:this.pagesize, page:this.page}).then((res) => {
        if (res.data.data != null) {
          this.list = res.data.data.list;
          this.total = parseInt(res.data.data.total);
        } else {
          this.list = []
        }
      })
    },
    linkToComp(item) {
      let val = {}
      this.typeClassification.forEach(item => { if(item.id == this.select){ val = item ; return } })
      if(val.name == "找企业"){
        this.$router.push({
          path: '/InvestmentDetailsNew',
          query: { type:3,sublevel:0,typeId:21, id: item.id }
        })
      }else if(val.name == "找采供"){
        this.$router.push({
          path:'/purchaseDetail',
          query:{type:9,sublevel:0,typeId:170,id:item.id}
        })
      }else if(val.name == "找活动"){
        this.$router.push({
          path:'/collegeDetails',
          query:{type:8,sublevel:0,typeId:77,id:item.id}
        })
      }else if(val.name == "找园区"){
        this.$router.push({
            path:'/InvestmentDetailsChange',
            query:{type:6,sublevel:0,typeId:63,id:item.id}
        })
      }else if(val.name == "找资讯"){
        this.$router.push({
            path:'/DetailsConsultation',
            query:{type:0,sublevel:0,typeId:9,id:item.id}
        })
      }else if(val.name == "找产业"){
        this.$router.push({
            path:'/DetailsConsultation',
            query:{type:1,sublevel:0,typeId:13,id:item.id}
        })
      }else if(val.name == "找投资"){
        this.$router.push({
          path:'/InvestmentDetailsChange',
          query:{type:4,sublevel:0,typeId:49,id:item.id}
        })
      }else if(val.name == "找投行"){
        this.$router.push({
          path: '/InvestmentBankDetails',
          query: {type:5,sublevel:0,typeId:55,id:item.id}
        })
      }else if(val.name == "找社群"){
        this.$router.push({
          path: '/communityDetails',
          query: {type:7,sublevel:0,typeId:76,id:item.id}
        })
      }
    },
    exitAccount(){
      this.$router.push('/')
    }
  }
}
</script>

<style lang="scss" scoped>
  .search{background-color: #fff;min-height: 71vh;}
  .pagination_div {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 20px 0;

  }
  .top_type{
      min-width: 1200px;
      width: 74%;
      margin: auto;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 48px;
      .top_type_text{
        li{
          display: inline-block;
          padding: 0 14px;
          border-right: 1px solid #DCDCDC;
          height: 14px;
          line-height: 14px;
          vertical-align: middle;
          cursor: pointer;
          color: #999;
        }
        li:last-child{
          border: 0;
        }
        li:hover{color: #000;}
      }
      .top_type_right{
        position: absolute;
        top: 0;
        right: 0;
        font-size: 16px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: #008DF0;
        img{
          vertical-align: middle;
          margin-right: 10px;
        }
      }
    }
  .home_logo{
    background-color: #fff;
    .home_logo_in{
      min-width: 1200px;
      width: 74%;
      margin: auto;
      height: 100px;
      line-height: 100px;
      position: relative;
      .home_logo_img{height: 80%;vertical-align: middle;}
      .home_logo_right{
        float: right;
        font-size: 16px;
        font-family: MicrosoftYaHei;
        color: #999999;
        position: relative;
        img{height: 30px;vertical-align: middle;margin-right: 10px;cursor: pointer;}
        span{cursor: pointer;}
        .home_logo_right_button{
          margin: auto;
          z-index: -1;
          border-color: #0089EF;
          color: #0089EF;
          background-color: rgb(209, 235, 255);
          animation: all 10s;
          cursor: pointer;
          min-width: 70px;
        }
      }
      .home_logo_right:hover .home_logo_right_button{
        opacity: 1;
        z-index: 10;
      }
      .home_logo_search{
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 60%;
        .home_logo_search_input{
          width: calc(100% - 140px);
        }
        &_button2{
          margin: 0;
          height: 38px;
          background: #F5F7FA;
          border: 0;
          color: #909399;
          width: 120px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          cursor: pointer;
        }
        &_button1{
          display: inline-block;vertical-align: middle;
          height: 32px;
          width: 120px;
          border: 0;
          background: #EF5200;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          margin-left: 20px;
          margin-top: -2px;
          cursor: pointer;
        }
      }
    }
  }
  .search_val{
    div{
      height: 60px;
      line-height: 60px;
      padding: 0 20px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      border-bottom: 1px solid #E7E6E6;
    }
    div:hover{
      color: #0089EF;
    }
    width: 1160px;
    margin: auto;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 20px;
    color: #171717;
    cursor: pointer;
  }
  ::v-deep {
    .el-input-group__append{
      padding: 0;
    }
  }

</style>

const CommonUtils = {
  // 找咨询，找金能,找社群
  information_label:[
    {name:'路线图谱',type:0},
    {name:'研究报告',type:0},
    {name:'产销数据',type:0},
    {name:'技术标准',type:0},
    {name:'技术专利',type:0},
    {name:'政策法规',type:0},
    {name:'汽车图书',type:1},
  ],
  Goldcan_label:[
    {name:'BP',type:1},
    {name:'商业模式',type:0},
    {name:'经营管理',type:2},
    {name:'股权融资',type:0},
    {name:'债权融资',type:0},
    {name:'上市辅导',type:0},
    {name:'IPO',type:1},
    {name:'市值管理',type:1},
    {name:'兼并重组',type:1}
  ],
  community:[
    {name:'创业人',type:1},
    {name:'投资人',type:1},
    {name:'专家人',type:1},
    {name:'园区人',type:1},
    {name:'政府人',type:1},
    {name:'分析师',type:1},
    {name:'保荐人',type:1},
    {name:'律师',type:1},
    {name:'注册会计师',type:1},
    {name:'董秘',type:1},
    {name:'证券部长',type:1}
  ],
  // 找活动
  Searchlabel:[
    {name:'榜单',type:0},
    {name:'会议',type:1},
    {name:'培训',type:2},
    {name:'路演',type:0},
    {name:'大赛',type:1},
    {name:'活动',type:2},
    {name:'孵化',type:0},
    {name:'加速',type:1},
    {name:'俱乐部',type:2},
  ],
  // 咨询
  consultation:['车新闻','投新闻','车新闻','车新闻','创新闻','人新闻','园新闻','上新闻','周月报'],
  //投资
  investment:['投资事件','募资事件','投资人说','早期投资','风险投资VC','私募股权PE','战略投资CVC','政府基金GVC','母基金FOFS','投资者LP'],
  //找投行
  Investment_bank:['证监会','证券所','券商公司','法务公司','财税审计公司','业务咨询公司','登记清算公司'],
  //找园区
  park:['省市政策','园区动态','招商引资','孵化加速','京津冀鲁','长三角','珠三角','东北区','华中区','西南区','西北区','华南区'],
   getParam(obj) {
      var arr = Object.keys(obj);
      var str = "?";
      for (var i in obj) {
        str += i;
        str += "=";
        str += obj[i];
        str += "&";
      }
      str = str.substr(0, str.length - 1);
      return str;
    },
  
    getUrlParam() {
      var url = location.search; //获取url中"?"符后的字串
      var theRequest = new Object();
      if (url.indexOf("?") != -1) {
        var str = url.substr(1);
        var strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
        }
      }
      return theRequest;
    },
}

export default CommonUtils;